import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { NavigaceModule } from '../navigace';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UtilsModule } from '../../utils/utils.module';
import { KontaktModule } from '../kontakt';

@NgModule({
  imports: [CommonModule, NavigaceModule, FontAwesomeModule,  UtilsModule, KontaktModule],
  exports: [FooterComponent],
  entryComponents: [FooterComponent],
  declarations: [FooterComponent]
})
export class FooterModule {}

