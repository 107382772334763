// fixme medium should be in common entities in lib :(
import Medium from '../../../../../../apps/obce/api/src/app/entities/media/medium';

export class StyleDefinition{
  borders?: BoxSides<Border>;
  margins?: BoxSides<string>;
  paddings?: BoxSides<string>;
  background?: BackgroundDefinition;
}

export interface BackgroundDefinition{
  // should try to resolve into override first, if not then medium if not then color.
  medium?: Medium;
  color?: String;
  override?: String; // this is plain CSS - you can do gradients and whatevs... this has precedence
}

export class SymetricalBoxSides<T>{
  horizontal?: T;
  vertical?: T;
}

export class BoxSides<T>{
  left?: T;
  right?: T;
  top?: T;
  bottom?: T;

  static applyFromSymetrical<T>(symetrical: SymetricalBoxSides<T>) : BoxSides<T> {
    return {top: symetrical.vertical, bottom: symetrical.vertical, left: symetrical.horizontal, right: symetrical.horizontal};
  }

  static applySideToAll<T>(side: T) : BoxSides<T> {
    return {top: side, bottom: side, left:side, right:side} as BoxSides<T>
  }
}

//export interface SizeAndUnit{size:number, unit: "px" | "em" | "rem" | '%' | "mm"}

export interface Border{
  width?: string; //typed number
  color?: string;
  style?: "dotted" | "solid" | "dashed" | "groove" | "ridge" | "outset";
  radius?: string;
}
