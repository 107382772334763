import { IColumnDefinition } from '@nx-monorepo/cms-base/interfaces';

export const strankaColumnDefinitions: IColumnDefinition[] = [
    {
      title: 'select',
      key: ['select'],
      type: 'select',
      sortable: false,
      align: 'left',
      fxHide: {
        sm: false,
        md: false,
        lg: false,
      }
    },
    {
        title: 'ID',
        key: ['id'],
        type: 'string',
        sortable: true,
      shrink: true,
      align: 'left',
      fxHide: {
        sm: false,
        md: false,
        lg: false,
      }
    },
    {
        title: 'Název',
        key: ['nazev'],
        type: 'string',
        sortable: true,
        align: 'left',
      fxHide: {
        sm: false,
        md: false,
        lg: false,
      }
    },
  {
    title: 'Odkaz',
    key: ['slug'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
    {
        title: 'Autor',
        key: ['autor', 'jmeno'],
        type: 'string',
        sortable: true,
        align: 'left',
        fxHide: {
          sm: false,
          md: false,
          lg: false,
        }
    },
];
