import { Component, Input, OnInit } from '@angular/core';
import { IDynamicComponent, IDynamicTheme } from '@nx-monorepo/ng-shared';
import { NavigaceBarrel } from '@nx-monorepo/node-frontend';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ColorUtilsService, ObecService } from '@nx-monorepo/obce/ng/services';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { BaseComponent } from '../../base.component';
import { getChildrenInTreeRepresentation, NavPol } from '../../../utils';


@Component({
  selector: 'macms-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss']
})
export class HeaderNavComponent extends BaseComponent implements IDynamicComponent, IDynamicTheme, OnInit {
  @Input() data: any;
  @Input() settings: NavigaceBarrel.HeaderNavProps;
  @Input() primaryColor: string;



  constructor(public colorUtilsService: ColorUtilsService, private iconLibrary: FaIconLibrary, faConfig: FaConfig, protected obecService: ObecService) {
    super(colorUtilsService, obecService);
    iconLibrary.addIconPacks(fas, fab);
    faConfig.defaultPrefix = 'fas';
  }

  navigacePolozky: NavPol[];

  getComponentName() {
    return NavigaceBarrel.name_header;
  }

  ngOnInit(): void {
    this.generateColors();
    this.navigacePolozky = getChildrenInTreeRepresentation(this.data.children);
  }
}
