<mat-form-field appearance="outline" fxFlex [formGroup]="settings.form">
  <mat-label>{{ settings.nazev }}</mat-label>
  <input
    matInput
    [formControlName]="settings.formControlName"
    [matDatepicker]="picker"
    placeholder="{{ settings.nazev }}"
    [required]="settings.povinnost"
    (click)="picker.open()"
  />
  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info</mat-icon>

  <mat-datepicker #picker></mat-datepicker>

  <!-- <mat-error *ngIf="settings.form.get(settings.formControlName)?.errors"><div *ngIf="settings.form.get(settings.formControlName)?.errors.pkerror">{{settings.form.get(settings.formControlName)?.errors}}</div>Toto pole je povinné!</mat-error> -->
  <mat-error *ngIf="firstError">
    {{firstError}}
  </mat-error>
</mat-form-field>
