import {  Component} from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute,  } from '@angular/router';
import { IRouteData } from '@nx-monorepo/cms-base/helpers';
import { CategoryTabsActions, CategoryTabsStoreSelector, LayoutStoreActions} from '@nx-monorepo/cms-base/store';
import { State } from '../../../store/store.state';

//never actually used alone
@Component({
  selector: 'PK-base-page',
  template: 'pls'
})
export class BasePageComponent{
  //protected settings: PageSettings;
  public routeData: IRouteData;

  constructor(protected store$: Store<State>, protected currentRoute: ActivatedRoute) {
    this.routeData = currentRoute.snapshot.data as IRouteData;
    this.setStoreWithSettings();
    this.store$.dispatch(new LayoutStoreActions.SetLayoutActiveTabAction({ activeTabName: this.routeData.activeTab }));
  }

  private setStoreWithSettings() {
    this.store$.dispatch(new LayoutStoreActions.SetLayoutPageNameAction({ pageName: this.routeData.pageName }));
    this.store$.dispatch(new LayoutStoreActions.SetLayoutWithAddCategory({ withAddButton: this.routeData.withAddCategory }));
    this.store$.dispatch(new LayoutStoreActions.SetLayoutPageIconAction({ pageIcon: this.routeData.pageIcon }));
    this.store$.dispatch(new LayoutStoreActions.SetLayoutSearchVisibilityAction({ withSearch: this.routeData.withSearch }));
    this.store$.dispatch(new LayoutStoreActions.SetLayoutButtonsAction({ buttons: this.routeData.buttons }));
    this.store$.dispatch(new LayoutStoreActions.SetLayoutTabsAction({ tabs: this.routeData.tabs }));
    this.store$.dispatch(new LayoutStoreActions.SetLayoutWithCategories({hideCategories: this.routeData.hideCategories}));

    // this is set in 'layout.component.ts' where we have the entire router always
    this.store$.select(CategoryTabsStoreSelector.selectShouldRefetchCategories).subscribe(shouldFetch => {
      // dispatch fetch action to get categories from DB
      if (shouldFetch) {
        this.store$.dispatch(new CategoryTabsActions.FetchCategoriesAction({ entityType: this.routeData.entityType }));
      }
    });
  }



}
