import gql from 'graphql-tag';

export const addTransakce = gql`
    mutation addTransakce($input: [TransakceInput!]!){
      response: addTransakce(inputTransakce: $input){
          id
          url
          stav
      }
    }
`;
