import { IPkDialogData, IPkFormDialogCompanion } from '../../../pk-dialog/pk-dialog-data';
import { IPkInputWysiwygProps } from '../../../pk-input-fields/pk-input-wysiwyg/pk-input-wysiwyg.props';
import { getDefaultDialogButtons, getNazevSettings } from '../common-settings-objects';

export class GenericDialogCompanion implements IPkFormDialogCompanion {
  getDialogData(settings: IPkInputWysiwygProps, data: {}, emitters: {dialogSubmit, dialogClose}) {
    const dialogData: IPkDialogData = {
      title: 'Upravit',
      components: [
        getNazevSettings(settings.nazev),
      ],
      buttons: getDefaultDialogButtons(emitters)
    };
    return dialogData;
  }
}
