import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { IPkHiddenFieldProps, IPkInputTextFieldProps } from '@nx-monorepo/cms-base/components';
import { Validators } from '@angular/forms';

export const strankaEntityDefinition: IEntityDefinition[] = [
  {
    componentName: 'PkHiddenFieldComponent',
    settings: {
      type: 'number',
      formControlName: 'id',
      defaultValue: 0
    } as IPkHiddenFieldProps,
    data: null,
    validators: [],
    cols: 0,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Název',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Název/nadpis stránky'
      },
      isTextArea: false,
      formControlName: 'nazev',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 1,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Název v adresovém řádku',
      povinnost: false,
      napoveda: {
        text: 'Toto pole umožňuje vytvořit vlastní adresu stránky. Pole smí obsahovat pouze malé písmena, číslice, pomlčky a podtržítka. Nejsou povoleny mezery. Pokud pole necháte prázdné, bude URL vygenerována automaticky z názvu.',
        isIcon: false
      },
      isTextArea: false,
      formControlName: 'slug',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    validators: [Validators.pattern('^[a-z\\-\\_0-9]+$')],
    data: null,
    cols: 1,
    x: 0,
    y: 1
  },
];
