import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
// noinspection TypeScriptPreferShortImport
import { BasePageComponent } from '../base-page.component';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, ActionsSubject, createAction, Store } from '@ngrx/store';
import { CategoryTabsStoreSelector, LayoutStoreActions, MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { PkVstupniPoleFormComponent } from '../pk-vstupni-pole-form/pk-vstupni-pole-form.component';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../../services/auth.service';
import { KolekceService, MediaService } from '@nx-monorepo/obce/ng/services';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PkDialogComponent } from '../../../../components/pk-dialog/pk-dialog.component';
import { IPkDialogData } from '../../../../components/pk-dialog/pk-dialog-data';
import { Validators } from '@angular/forms';
import { Token } from '@nx-monorepo/api-base/lib/interfaces/i-token';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { IAction } from '@nx-monorepo/cms-base/interfaces';
import { FormularStav } from '@nx-monorepo/obce/common/enums';
import { getEnumKeyByEnumValue } from '@nx-monorepo/obce/common/utils';

const ULOZIT_ACTION = createAction('[NewFormular] Ulozit', (payload: {}) => ({ payload }))({});
const PODAT_ACTION = createAction('[NewFormular] Podat', (payload: {}) => ({ payload }))({ stav: getEnumKeyByEnumValue(FormularStav, FormularStav.Podany) });
const ODVOLAT_ACTION = createAction('[NewFormular] Odvolat', (payload: {}) => ({ payload }))({ stav: getEnumKeyByEnumValue(FormularStav, FormularStav.Odvolany) });
const SCHVALIT_ACTION = createAction('[NewFormular] Schvalit', (payload: {}) => ({ payload }))({ stav: getEnumKeyByEnumValue(FormularStav, FormularStav.Schvaleny) });
const VRATIT_ACTION = createAction('[NewFormular] Vratit', (payload: {}) => ({ payload }))({ stav: getEnumKeyByEnumValue(FormularStav, FormularStav.Vraceny) });
const ZAMITNOUT_ACTION = createAction('[NewFormular] Zamitnout', (payload: {}) => ({ payload }))({ stav: getEnumKeyByEnumValue(FormularStav, FormularStav.Zamitnuty) });
const EXPORTOVAT_PDF_ACTION = createAction('[NewFormular] Exportovat', (payload: {}) => ({ payload }))({});

// @deprecated const ARCHIVOVAT_ACTION = createAction('[NewFormular] Archivovat', (payload: {}) => ({ payload }))({ stav: FormularStav.a });


interface IHistory {
  id: number;
  created_at: string;
  stav: string;
  text: string;
}

@Component({
  selector: 'pk-new-formular-page',
  templateUrl: './pk-new-formular.component.html',
  styleUrls: ['./pk-new-formular.component.scss'],
  animations: fuseAnimations
})
export class PkNewFormularComponent extends BasePageComponent implements OnInit, OnDestroy {
  // inner state
  private activeCategory$: Observable<IAction>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private userToken: Token;
  public historie: Array<IHistory>;
  public token: string;

  // dialog
  private dialogRef: MatDialogRef<PkDialogComponent, string>;
  private dialogSubmit = new EventEmitter<any>();
  private dialogClose = new EventEmitter<void>();

  // refs
  @ViewChild('vstupniPoleFormComponent')
  private vstupniPoleFormComponent: PkVstupniPoleFormComponent;

  public canUserEditForm = true;
  public formularStavEnum = FormularStav;

  /**
   * VSECHNY STAVY: (https://sketchboard.me/TBOFkXD6QKyJ#/ - 123456)
   * Rozpracovany,
   * Podany,
   * Odvolany,
   * Schvaleny,
   * Vraceny,
   * Zamitnuty
   */

  constructor(store$: Store<MACMSStoreState.State>, currentRoute: ActivatedRoute, private actionsSubject$: ActionsSubject, public dialog: MatDialog, private authService: AuthService, private kolekceService: KolekceService,
              private _fuseProgressBarService: FuseProgressBarService, protected router: Router, private mediaService: MediaService) {
    super(store$, currentRoute);
    this.userToken = this.authService.getTokenData();
  }

  ngOnInit(): void {
    this.activeCategory$ = this.store$.select(CategoryTabsStoreSelector.selectActiveCategory);
    this.subscribeToDialogClose();
    //this.subscribeToDialogSubmit();

    // set proper buttons based on create/edit
    const formId = this.currentRoute.snapshot.paramMap.get('id');
    if (formId) {
      // edditing form - we have to fetch it with ID from route
      const currentFormId = parseInt(formId, 10);
      //this.vstupniPoleFormComponent.id = currentFormId;
      this.kolekceService.fetchSingle(this.routeData.entityType, currentFormId).pipe(
        take(1),
        map((res: any) => {
          // get the history of form
          this.historie = res.historie;
          this.token = res.pdf_uuid;
          // get the state of form and set appropriate buttons
          const stavFormulare = res.stav || 'Rozpracovany';
          this.setLayoutButtons(FormularStav[stavFormulare]);
          this.setEditPrivileges(FormularStav[stavFormulare]);

        })
      ).subscribe();

    } else {
      // creating new, its alwazs Rozpracovany by default
      this.setLayoutButtons(FormularStav.Rozpracovany);
    }

    // subskrajb to aktions
    this.subscribeToFormActions();
    this._fuseProgressBarService.hide()
  }

  private subscribeToFormActions() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) =>
        action === ULOZIT_ACTION ||
        action === PODAT_ACTION ||
        action === SCHVALIT_ACTION ||
        action === ZAMITNOUT_ACTION ||
        action === VRATIT_ACTION ||
        action === ODVOLAT_ACTION ||
        action === EXPORTOVAT_PDF_ACTION
      )
    ).subscribe((action: any) => {
      switch (action) {
        case ULOZIT_ACTION:
        case PODAT_ACTION:
        case ODVOLAT_ACTION:
        case SCHVALIT_ACTION:
          this.saveFormular(action.payload);
          break;
        case EXPORTOVAT_PDF_ACTION:
          (window as any).open(`https://obce.s3.eu-central-1.amazonaws.com/MQ%3D%3D/${this.token}_formular${this.currentRoute.snapshot.paramMap.get('id')}.pdf`, "_blank");
          break;
        case ZAMITNOUT_ACTION:
        case VRATIT_ACTION:
          this.saveFormular(action.payload, true);
          break;
      }
    });
  }

  private saveFormular(stav: {}, withReason: boolean = false) {
    // ulozeni bez dialogu se zduvodnenim
    if (!withReason) {
      return this.vstupniPoleFormComponent.saveForm(stav);
    }

    // ulozeni s dialogem o zduvodneni
    this.openDialogForReason();
    this.dialogSubmit
      .pipe(
        take(1)
      ).subscribe((result) => {
      const { reason } = result;
      this.vstupniPoleFormComponent.saveForm(stav, reason);
      this.dialogRef.close();
    });
  }

  private setEditPrivileges(currentStav: FormularStav){
    if(currentStav === FormularStav.Zamitnuty ||  currentStav === FormularStav.Schvaleny){
      this.canUserEditForm = false;
      return;
    }
    if(currentStav !== FormularStav.Rozpracovany && currentStav !== FormularStav.Odvolany && currentStav !== FormularStav.Vraceny ) {
      if(this.userToken.role.pravomoce.canManageForms !== true){
        this.canUserEditForm = false;
      }
    } else {
      if(this.userToken.role.pravomoce.canManageForms === true){
        this.canUserEditForm = false;
      }
    }
  }

  private setLayoutButtons(currentStav: FormularStav) {
    switch (currentStav) {
      case FormularStav.Rozpracovany:
      case FormularStav.Vraceny:
        this.setRozpracovanyButtons();
        break;
      case FormularStav.Podany:
        this.setPodanyButtons();
        break;
      case FormularStav.Odvolany:
        this.setOdvolanyButtons();
        break;
      case FormularStav.Schvaleny:
        this.setSchvalenyButtons();
        break;
      case FormularStav.Zamitnuty:
        //this is here and not in default, because we wanna detect wrong states
        this.setNoButtons();
        break;
      default:
        this.setNoButtons();
        console.error("INVALID STATUS IN FORMULAR", currentStav);
        break;
    }
  }

  private setRozpracovanyButtons() {
    // create empty payload
    const payload = {
      buttons: []
    };

    // if user is obcan, give him some powers
    if (this.userToken.role.pravomoce.canManageForms !== true) {
      payload.buttons = [
        { id: 1, nazev: 'Uložit', action: ULOZIT_ACTION },
        { id: 2, nazev: 'Podat', action: PODAT_ACTION },
      ];
      if (this.currentRoute.snapshot.url[1].path !== 'new') {
        payload.buttons.push({id: 3, nazev: 'Exportovat do PDF', action: EXPORTOVAT_PDF_ACTION})
      }
    }

    this.store$.dispatch(new LayoutStoreActions.SetLayoutButtonsAction(payload));
  }

  private setPodanyButtons() {
    // create empty payload
    const payload = {
      buttons: []
    };

    // if user is obcan, give him some powers
    if (this.userToken.role.pravomoce.canManageForms !== true) {
      payload.buttons = [
        { id: 1, nazev: 'Odvolat', action: ODVOLAT_ACTION },
        { id: 2, nazev: 'Exportovat do PDF', action: EXPORTOVAT_PDF_ACTION}
      ];
    } else {
      payload.buttons = [
        { id: 1, nazev: 'Schválit', action: SCHVALIT_ACTION },
        { id: 2, nazev: 'Zamítnout', action: ZAMITNOUT_ACTION },
        { id: 3, nazev: 'Uložit', action: ULOZIT_ACTION },
        { id: 4, nazev: 'Vrátit', action: VRATIT_ACTION },
        { id: 5, nazev: 'Exportovat do PDF', action: EXPORTOVAT_PDF_ACTION}
      ];
    }

    this.store$.dispatch(new LayoutStoreActions.SetLayoutButtonsAction(payload));
  }

  private setOdvolanyButtons() {
    // create empty payload
    const payload = {
      buttons: []
    };

    // if user is obcan, give him some powers
    if (this.userToken.role.pravomoce.canManageForms !== true) {
      payload.buttons = [
        { id: 1, nazev: 'Uložit', action: ULOZIT_ACTION },
        { id: 2, nazev: 'Podat', action: PODAT_ACTION },
        { id: 3, nazev: 'Exportovat do PDF', action: EXPORTOVAT_PDF_ACTION}
      ];
    }

    this.store$.dispatch(new LayoutStoreActions.SetLayoutButtonsAction(payload));
  }

  private setSchvalenyButtons() {
    const payload = {
      buttons: []
    };

    // if user is obcan, give him some powers
      payload.buttons = [
        { id: 1, nazev: 'Exportovat do PDF', action: EXPORTOVAT_PDF_ACTION}
      ];

    this.store$.dispatch(new LayoutStoreActions.SetLayoutButtonsAction(payload));
  }

  private setNoButtons() {
    // create empty payload
    const payload = {
      buttons: []
    };

    this.store$.dispatch(new LayoutStoreActions.SetLayoutButtonsAction(payload));
  }

  private subscribeToDialogClose() {
    this.dialogClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  private openDialogForReason() {
    if (this.dialogRef == null) {
      const dialogData: IPkDialogData = {
        title: 'Uveďte důvod',
        components: [
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'reason',
              type: 'text',
              nazev: 'Důvod',
              povinnost: true,
              defaultValue: ''
            },
            data: null,
            validators: [Validators.required],
            cols: 1,
            x: 0,
            y: 0
          }
        ],
        buttons: [
          {
            color: 'warn',
            text: 'Ne, díky',
            action: this.dialogClose
          },
          {
            color: 'primary',
            text: 'Uložit',
            shouldValidateForm: true,
            action: this.dialogSubmit
          }
        ]
      };

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = dialogData;

      this.dialogRef = this.dialog.open(PkDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.dialogRef = null;
      });
    }
  }

  ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
