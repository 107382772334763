import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { readSelectedPrispevekQuery } from '@nx-monorepo/obce/common/queries';

@Injectable({
  providedIn: 'root'
})
export class PrispevekService {
  constructor(private apollo: Apollo) {}

  public fetchData(
    id: number,
  ) {
    const variables = {
      id: id
    };

    return this.apollo.query({
      query: readSelectedPrispevekQuery,
      variables: variables
    });
  }
}
