import { Injectable } from '@angular/core';
import * as Color from 'color';

@Injectable()
export class ColorUtilsService {

  checkContrast(bg,fg){
    return new Color(fg).contrast( new Color(bg));
  }

  /**
   * @param bg - send what color is set on background
   * @param minRatio - minimal contrast ratio, 4.5 minimum, 7 optimal
   * @param fg - send what colors to test, will return FIRST good color
   */
  decideForegroundColor(bg, minRatio, ...fg) {
    // fallback
    fg.push('white');
    fg.push('black');

    let bestRatioValue = 0;
    let bestRatioIndex = 0;

    const bgColor = new Color(bg);
    for (const [idx, colString] of fg.entries()) {
      if (colString != null) {
        const fgColor = new Color(colString);
        const currentContrast = fgColor.contrast(bgColor);
        if (currentContrast > minRatio) {
          return fgColor.hex();
        } else if(currentContrast > bestRatioValue){
          bestRatioValue = currentContrast;
          bestRatioIndex = idx;
        }
      }
    }
    //console.warn(`Barva ${bgColor}/${bg} má nejlepší kontrast ${bestRatioValue} (${fg[bestRatioIndex]}). Nesplňuje AAA.`);
    return fg[bestRatioIndex];
  }

  darken(color, fraction) {
    return new Color(color).darken(fraction).hex();
  }

  decideLuminosity(color){
    return new Color(color).luminosity();
  }

  setLightness(color, lightness) {
    return new Color(color).lightness(lightness).hex();
  }

  lighten(color, fraction) {
    return new Color(color).lighten(fraction).hex();
  }
}
