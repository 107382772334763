import gql from 'graphql-tag';

export const readSelectedPrispevekQuery = gql`
  query ReadSelectedPrispevek($ids: [Int!], $slug: String) {
    response: readSelectedPrispevek(
      ids: $ids,
      slug: $slug
    ) {
      id
      nazev
      obsah
      uvod
      slug
      featured
      media{
        id
        url
        mime
        kategorie_id
      }
      datum_zverejneni
      kategorie_id
      kategorie{
        nazev
      }
      autor_id
      priloha {
          id
          url
          nazev
        }
    }
  }
`;
