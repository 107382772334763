import gql from 'graphql-tag';

export const countItems = gql`
    query countItems($entity_type: String!, $casovy_usek: CasovyUsekTyp!, $datum_od: DateTime!, $datum_do: DateTime!, $nezaplacene: Boolean) {
        response: countItems(
            entity_type: $entity_type
            casovy_usek: $casovy_usek,
            datum_od: $datum_od,
            datum_do: $datum_do,
            nezaplacene: $nezaplacene
        ){
            pocet
            den
            mesic
            rok    
        }
    }
`
