import gql from 'graphql-tag';

export const readNFutureUdalost = gql`
  query readNFutureUdalost($numberOfEvents: Float!, $today: DateTime!) {
    response: readNFutureUdalost(
      numberOfEvents: $numberOfEvents,
      today: $today
    ) {
      id
      kategorie {
        id
        nazev
      }
      slug
      autor {
        jmeno
        prijmeni
      }
      media{
        id
        url
        mime
        popis
      }
      nazev
      popis
      datum_konec
      datum_zacatek
      }
    }
`;
