import { IColumnDefinition } from '@nx-monorepo/cms-base/interfaces';

export const transakceColumnDefinitions: IColumnDefinition[] = [
    {
      title: 'select',
      key: ['select'],
      type: 'select',
      sortable: false,
      shrink: true,
      align: 'left',
      fxHide: {
        sm: false,
        md: false,
        lg: false,
      }
    },
    {
        title: 'ID',
        key: ['id'],
        type: 'string',
        sortable: true,
      shrink: true,
      align: 'left',
      fxHide: {
        sm: false,
        md: false,
        lg: false,
      }
    },
    {
        title: 'Vytvořeno',
        key: ['created_at'],
        type: 'date',
        sortable: true,
      shrink: true,
      align: 'left',
      fxHide: {
        sm: false,
        md: false,
        lg: false,
      }
    },
    {
        title: 'Variabilní symbol',
        key: ['variabilni_symbol'],
        type: 'string',
        sortable: false,
      shrink: true,
      align: 'left',
      fxHide: {
        sm: true,
        md: false,
        lg: false,
      }
    },
    {
        title: 'Stav',
        key: ['stav'],
        type: 'string',
        sortable: true,
      shrink: true,
      align: 'left',
      fxHide: {
        sm: false,
        md: false,
        lg: false,
      }
    },
    
];
