import { Component, Input, OnInit } from '@angular/core';
import { ObecService, ColorUtilsService } from '@nx-monorepo/obce/ng/services';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ITabs } from './i-tabs';
import { TabsProps } from './tabs.props';
import { combineLatest, Observable } from 'rxjs';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'front-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent extends BaseComponent implements OnInit {
  @Input()
  tabs$: Observable<ITabs[]>;

  tabsWithActivated$: Observable<ITabs[]>;
  @Input()
  settings: TabsProps;


  constructor(colorUtilsService: ColorUtilsService, obecService: ObecService, private router: Router, private activatedRoute: ActivatedRoute) {
    super(colorUtilsService, obecService);
  }

  ngOnInit(){
    this.tabsWithActivated$ = combineLatest(this.activatedRoute.paramMap,this.tabs$).pipe(map(([params, tabs]) => {
      return tabs.map(tab =>{
        tab.active = params.get('kategorie') === tab.slug;
        return tab;
      })
    }));
  }

}
