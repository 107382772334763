import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Validators } from '@angular/forms';
import { filter, take, takeUntil } from 'rxjs/operators';
import { MACMSStoreState, TableStoreActions } from '@nx-monorepo/cms-base/store';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { PkDataTableComponent } from '../../pk-data-table/pk-data-table.component';
import { PkDialogComponent } from '../../pk-dialog/pk-dialog.component';
import { IAction } from '@nx-monorepo/cms-base/interfaces';
import { KolekceService } from '@nx-monorepo/obce/ng/services';
import { selectFilteredCategories, selectActiveCategory } from '@nx-monorepo/cms-base/store/category-tabs/category-tabs.selectors';
import { SnackbarStoreActions } from '@nx-monorepo/cms-base/store/snackbar';
import { TableActionTypes } from '@nx-monorepo/cms-base/store/table/table.actions';
import { getTableItemById } from '@nx-monorepo/cms-base/store/table/table.selectors';
import { IPkDialogData } from '../../pk-dialog/pk-dialog-data';

@Component({
  selector: 'pk-media-list',
  templateUrl: './pk-media-list.component.html',
  styleUrls: ['./pk-media-list.component.scss']
})
export class PkMediaListComponent implements OnInit, OnDestroy {
  @Input()
  canSelectMultiple = true;

  @Output()
  dialogClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  dialogSubmitEdit: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  dialogSubmitDelete: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dataTable') dataTable: PkDataTableComponent;

  private destroy$: Subject<any> = new Subject();
  public selectedItem: any;
  public isImageDetail: boolean;
  private dialogRef: MatDialogRef<PkDialogComponent, string>;
  private categories: IAction[] = [];
  public mediaEntity = EntityTypes.Media;


  constructor(private store$: Store<MACMSStoreState.State>, private actionsSubject$: ActionsSubject, public dialog: MatDialog, private kolekceService: KolekceService) {

  }

  ngOnInit(): void {
    this.subscribeToDialogClose();
    this.subscribeToDialogSubmit();

    this.store$.select(selectFilteredCategories).pipe(
      takeUntil(this.destroy$)
    ).subscribe(categories => {
      this.categories = categories;
    });

    // vynulovat selected item, kdyz zmenime kategorii
    this.store$.select(selectActiveCategory).subscribe(activeCategory => {
      this.selectedItem = null;
    });

  }

  private subscribeToDialogClose() {
    this.dialogClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  private subscribeToDialogSubmit() {
    // EDIT SUBSCRIBPTION
    this.dialogSubmitEdit
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((result) => {
      const { nazev, kategorie_id } = result;
      if ((nazev && nazev !== this.selectedItem.nazev) || (kategorie_id && kategorie_id !== this.selectedItem.kategorie_id)) {

        // create payload object
        const payload = {
          id: this.selectedItem.id,
          nazev: nazev,
          kategorie_id: parseInt(kategorie_id, 10)
        };

        // send save
        this.kolekceService.save(EntityTypes.Media, { input: [payload] }).pipe(
          take(1)
        ).subscribe((res: any) => {
          console.log(res);
          // refetch data
          this.dataTable.refetch();

          // reselect selected item
          this.getSelectedItemAfterEdit();

          // dispatch success
          this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({
            message: `Soubor s id '${res.data.response.id}' byl úspěšně editován`,
            action: 'OK',
            config: { duration: 5000 }
          }));
        });

      }
      this.dialogRef.close();
    });
  }

  private getSelectedItemAfterEdit() {
    // prihlasit se k odberu dispatchnutych akci
    this.actionsSubject$.pipe(
      filter(action => action.type === TableActionTypes.SET_TABLE_DATA_SUCCESS),
      take(1)
    ).subscribe(() => {
      // pokud jsme uspesne dostali znovu data, reselectneme item a odhalsime se z odberu
      this.store$.select(getTableItemById, { id: this.selectedItem.id })
        .pipe(take(1))
        .subscribe(updateSelectedItem => {
          console.log(this.selectedItem, updateSelectedItem);
          this.selectedItem = updateSelectedItem;
        });
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions

    this.store$.dispatch(new TableStoreActions.ClearAllSelected());
    this.destroy$.next();
    this.destroy$.complete();
  }

  itemClicked(item) {
    console.log(item);
    this.selectedItem = item;
  }

  copyToClipboard() {
    const content = this.selectedItem.url;

    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', content);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  openDialogForEdit() {
    if (this.dialogRef == null) {
      // console.log('selected', this.selectedItem);
      // create dialog data
      const dialogData: IPkDialogData = {
        title: `Editovat #${this.selectedItem.id}`,
        components: [
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'nazev',
              type: 'text',
              nazev: 'Přejmenovat',
              povinnost: true,
              defaultValue: this.selectedItem.nazev
            },
            data: null,
            validators: [Validators.required],
            cols: 1,
            x: 0,
            y: 0
          },
          {
            componentName: 'PkSelectFieldComponent',
            settings: {
              povinnost: true,
              nazev: 'Kategorie',
              isMultiple: false,
              formControlName: 'kategorie_id',
              defaultValue: this.selectedItem.kategorie_id
            },
            data: this.categories.map(item => ({ id: item.id.toString(), nazev: item.nazev })),
            validators: [Validators.required],
            cols: 1,
            x: 0,
            y: 0
          }
        ],
        buttons: [
          {
            color: 'warn',
            text: 'Ne, díky',
            action: this.dialogClose
          },
          {
            color: 'primary',
            text: 'Uložit',
            action: this.dialogSubmitEdit
          }
        ]
      };

      this.openDialog(dialogData);
    }
  }

  openDialog(dialogData: any) {
    // create dialog config and pass dialog data to it
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = dialogData;

    // open dialog and subscribe to its afterClosed event to remove the reference
    this.dialogRef = this.dialog.open(PkDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialogRef = null;
    });
  }

  getType() {

    if (this.selectedItem.mime.includes('image')) {
      return 'image';
    } else if (this.selectedItem.mime.includes('video')) {
      return 'video';
    } else {
      return 'document';
    }

  }


}
