// auth.guard.ts
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const tokenData = this.authService.getTokenData()
    const canManageContent = tokenData?.role.pravomoce.canManageContent ?? false;
    if(!canManageContent){
      this.router.navigate(['']);
    }
    return canManageContent;
  }

}
