import gql from 'graphql-tag';

export const resendActivationEmail = gql`
    query resendActivationEmail($email: String!) {
        response: resendActivationEmail(
            email: $email
        ) {
            message
            isSuccessful
        }
    }`