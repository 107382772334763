<gridster [options]="gridsterOptions" #gridster>
  <gridster-item [item]="item" *ngFor="let item of gridsterItems">
    <pk-gridster-base-item
      [item]="item"
      [resizeEvent]="resizeEvent"
      [showPreview]="showPreview"
      (deleteEvent)="removeItem($event, item)"
    ></pk-gridster-base-item>
  </gridster-item>
</gridster>
