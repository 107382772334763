import { ComponentFactory, ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';

import { PkInputTextFieldComponent } from '../components/pk-input-fields/pk-input-text-field/pk-input-text-field.component';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { PkInputWysiwygComponent } from '../components/pk-input-fields/pk-input-wysiwyg/pk-input-wysiwyg.component';
import { PkSelectFieldComponent } from '../components/pk-input-fields/pk-select-field/pk-select-field.component';
import { PkInputDateFieldComponent } from '../components/pk-input-fields/pk-input-date-field/pk-input-date-field.component';
import { PkInputCheckboxFieldComponent } from '../components/pk-input-fields/pk-input-checkbox-field/pk-input-checkbox-field.component';
import { PkInputEntityFieldComponent } from '../components/pk-input-fields/pk-input-entity-field/pk-input-entity-field.component';
import { PkHiddenFieldComponent } from '../components/pk-input-fields/pk-hidden-field/pk-hidden-field.component';
import { PkInputChipsFieldComponent } from '../components/pk-input-fields/pk-input-chips-field/pk-input-chips-field.component';
import { PkInputAutocompleteComponent } from '../components/pk-input-fields/pk-input-autocomplete/pk-input-autocomplete.component';
import { PkColorPickerFieldComponent } from '../components/pk-input-fields/pk-color-picker-field/pk-color-picker-field.component';
import { PkInputRepeaterFieldComponent } from '../components/pk-input-fields/pk-input-repeater-field/pk-input-repeater-field.component';
import { PkInputDateTimeFieldComponent } from '../components/pk-input-fields/pk-input-date-time-field/pk-input-date-time-field.component';
import { PkInputBoxFieldComponent } from '../components/pk-input-fields/pk-input-box-field/pk-input-box-field.component';

@Directive({
  selector: '[cmsComponentFactory]'
})
export class DynamicCMSComponentFactoryDirective implements OnInit {
  @Input() componentName: string;
  @Input() settings: any;
  @Input() data: any;

  constructor(private resolver: ComponentFactoryResolver, private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    const factory = this.findComponentFactory(this.componentName);
    this.createComponent(factory);
  }

  private createComponent(factory) {

    // clear the container
    this.viewContainerRef.clear();

    //create component with given factory
    const instance = this.viewContainerRef.createComponent(factory, null).instance as IDynamicComponent;

    //set data and settings to the component directly
    instance.data = this.data;
    instance.settings = this.settings;
  }

  private findComponentFactory(componentName: string): ComponentFactory<any> {
    // get list of all possible factories
    // console.log(this.resolver);
    // THIS NO LONGER WORKS IN ANGULAR 9 and there are no articles that I could find
    // const factories = Array.from<any>(this.resolver['_factories'].keys());


    // const factories = obceTypes;
    // fixme this is temporary solution that just lists all our components manualy
    // find factory for out type
    // simple comparison of name of the class doesn't work because of AOT does Uglification :(, we lose all class names

    //Takže

    //Stáhneme si máster classu z rootu Frontend knihovny
    //je to mapa typu název => Type<any> resp předpis FE Komponenty
    const map = {};

    map['PkInputTextFieldComponent'] = PkInputTextFieldComponent;
    map['PkHiddenFieldComponent'] = PkHiddenFieldComponent;
    map['PkInputEntityFieldComponent'] = PkInputEntityFieldComponent;
    map['PkInputCheckboxFieldComponent'] = PkInputCheckboxFieldComponent;
    map['PkInputDateFieldComponent'] = PkInputDateFieldComponent;
    map['PkSelectFieldComponent'] = PkSelectFieldComponent;
    map['PkInputWysiwygComponent'] = PkInputWysiwygComponent;
    map['PkInputChipsFieldComponent'] = PkInputChipsFieldComponent;
    map['PkInputAutocompleteComponent'] = PkInputAutocompleteComponent;
    map['PkColorPickerFieldComponent'] = PkColorPickerFieldComponent;
    map['PkInputRepeaterFieldComponent'] = PkInputRepeaterFieldComponent;
    map['PkInputDateTimeFieldComponent'] = PkInputDateTimeFieldComponent;
    map['PkInputBoxFieldComponent'] = PkInputBoxFieldComponent;

    //vytáhnem všechny jména
    const mapKeys = Object.keys(map);
    //vyhledáme komponentu
    const idx = mapKeys.indexOf(componentName);
    let factory = null;
    if (idx > -1) {
      factory = map[mapKeys[idx]];
    }

    // zabrečíme když to nenajdem
    if (!factory) {
      throw Error(`Could not find factory for component ${componentName} !`);
    }
    // console.log("Found smth", factory);

    // vrátíme factory dané komponenty
    // (prakticky to vrátí component.factory(), ale díky obfuskaci to sám nenajdeš)
    // https://medium.com/angular-in-depth/asynchronous-modules-and-components-in-angular-ivy-1c1d79d45bd3
    return this.resolver.resolveComponentFactory(factory);
  }
}
