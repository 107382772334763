import {Component, Input, OnInit, AfterViewInit, OnChanges, SimpleChanges, ViewEncapsulation, ChangeDetectorRef} from '@angular/core';
import {IPkInputDateTimeFieldProps} from './pk-input-date-time-field.props';
import {getFirstError} from '@nx-monorepo/cms-base/helpers';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'pk-input-date-time-field',
  templateUrl: './pk-input-date-time-field.component.html',
  styleUrls: ['./pk-input-date-time-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PkInputDateTimeFieldComponent implements OnInit, AfterViewInit {
  public firstError ?: string;

  @Input()
  settings: IPkInputDateTimeFieldProps;

  ngOnInit(): void {
    this.firstError = getFirstError(this.settings.formControlName, this.settings.form);   
  }

  ngAfterViewInit(): void {
    const formControl = this.settings.form.get(this.settings.formControlName);
    // MatDateTime potrebuje date, takze prvni vec co prijde z DB musi se transformovat na Date
    formControl?.valueChanges.pipe(first()).toPromise().then(_ => {
      // transformuj stavajici hodnotu na date
      formControl?.setValue(moment(formControl?.value).toDate());

      //tohle tu mozna byt nemusi a staci v subscribe
      this.firstError = getFirstError(this.settings.formControlName, this.settings.form);

      // potrebujeme subscribe na kazdou dalsi zmenu aby fungovaly nase validatory
      formControl?.valueChanges.subscribe((_) => {
        this.firstError = getFirstError(this.settings.formControlName, this.settings.form);
      });
    })
      
  }
}

