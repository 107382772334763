import { Validators } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { IPkSelectFieldProps } from '../..';
import { IPkDialogButton } from '../../pk-dialog/pk-dialog-data';

export const getNazevSettings = (defaultNazev: string) => ({
  componentName: 'PkInputTextFieldComponent',
  settings: {
    isTextArea: false,
    formControlName: 'nazev',
    type: 'text',
    nazev: 'Název',
    povinnost: true,
    napoveda: {
      isIcon: false,
      text: 'Zadejte název pro toto vstupní pole'
    },
    defaultValue: defaultNazev ? defaultNazev : ''
  },
  data: null,
  validators: [Validators.required],
  cols: 1,
  x: 0,
  y: 0
} as IEntityDefinition);


export const getPovinnostSettings = (defaultPovinnost) => ({
  componentName: 'PkSelectFieldComponent',
  settings: {
    povinnost: true,
    nazev: 'Povinnost',
    napoveda: {
      isIcon: true,
      text: 'Napoveda lorem ipsum'
    },
    isMultiple: false,
    formControlName: 'povinnost',
    defaultValue: defaultPovinnost ? '1' : '0'
  } as IPkSelectFieldProps,
  data: [
    {
      id: '1',
      nazev: 'Ano'
    },
    {
      id: '0',
      nazev: 'Ne'
    }
  ],
  validators: [Validators.required],
  cols: 1,
  x: 0,
  y: 0
} as IEntityDefinition);

export const getNapovedaSettings = (napoveda) => ({
  componentName: 'PkInputTextFieldComponent',
  settings: {
    isTextArea: false,
    formControlName: 'napoveda',
    type: 'text',
    nazev: 'Nápověda',
    povinnost: true,
    napoveda: {
      isIcon: false,
      text: 'Zadejte nápovědu uživateli pro toto vstupní pole'
    },
    defaultValue: napoveda.text ? napoveda.text : ''
  },
  data: null,
  validators: [Validators.required],
  cols: 1,
  x: 0,
  y: 0
} as IEntityDefinition);


export const getDefaultDialogButtons = (emitters: {dialogClose: EventEmitter<void>, dialogSubmit: EventEmitter<any>}) => ([
  {
    color: 'warn',
    text: 'Zrušit',
    action: emitters.dialogClose
  } as IPkDialogButton,
  {
    color: 'primary',
    text: 'Potvrdit',
    action: emitters.dialogSubmit
  } as IPkDialogButton
]);
