import gql from 'graphql-tag';

export const setPassword = gql`
    mutation setPassword($token: String!, $password: String!){
      response: setPassword(token: $token, password: $password) {
        isSuccessful
        message
      }
    }
`;
