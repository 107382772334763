export enum FormularStav {
  Rozpracovany = 'Rozpracovaný',
  Podany = "Podaný",
  Odvolany = "Odvolaný",
  Schvaleny = "Schválený",
  Vraceny = "Vrácený",
  Zamitnuty = "Zamítnutý"
}


