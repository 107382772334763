import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../base-page.component';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ActionsSubject, Store} from '@ngrx/store';
import {CategoryTabsStoreSelector, MACMSStoreState} from '@nx-monorepo/cms-base/store';
import {of as observableOf, Subject, Subscription} from 'rxjs';
import {PkGridsterItemDefinitionService} from '../pk-gridster-item-definition.service';
import {FormGroup} from '@angular/forms';
import {FuseProgressBarService} from '@fuse/components/progress-bar/progress-bar.service';
import { VstupniPoleService } from '@nx-monorepo/obce/ng/services';
import { PkGridsterComponent } from '@nx-monorepo/cms-base/components';
import { IAction } from '@nx-monorepo/cms-base/interfaces';
import { PkGridsterItem } from '@nx-monorepo/cms-base/components/pk-gridster-items/pk-gridster-item';
import { LayoutActionTypes } from '@nx-monorepo/cms-base/store/layout/layout.actions';
import { displaySnack } from '@nx-monorepo/cms-base/helpers';

@Component({
  selector: 'pk-form-gridster-page',
  templateUrl: './pk-form-gridster-page.component.html',
  styleUrls: ['./pk-form-gridster-page.component.scss']
})
export class PkFormGridsterPageComponent extends BasePageComponent implements OnDestroy, OnInit {

  @ViewChild('gridster') gridster: PkGridsterComponent;

  @Input() needsCategory = true;
  @Input() isFrontend = false;
  @Input() showPreview = true;

  gridsterForm: FormGroup = new FormGroup({});

  destroy$: Subject<boolean> = new Subject<boolean>();
  saveAction$: Subscription;
  activeCategory: IAction;
  private idsToDelete: number[] = [];

  constructor(store$: Store<MACMSStoreState.State>,
              currentRoute: ActivatedRoute,
              private router: Router,
              private actionsSubject$: ActionsSubject,
              private vstupniPoleService: VstupniPoleService,
              private itemDefinitionService: PkGridsterItemDefinitionService,
              private _fuseProgressBarService: FuseProgressBarService
  ) {
    super(store$, currentRoute);
  }

  getItemList() {
    return this.itemDefinitionService.getFormItems();
  }

  ngOnInit(): void {
    // get the curent selected category
    this.store$.select(CategoryTabsStoreSelector.selectActiveCategory)
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((activeCategory) => {
      console.log(activeCategory); //fixme this is happening 1

      this.activeCategory = activeCategory;

      // id 0 = vse
      if (this.activeCategory.id > 0) {
        this.subscribeToSaveButtonAction();
        this.getVstupniPoleForCategory(this.activeCategory.id);
      }
    });
  }

  private itemDeleted(id: number) {
    if (id) {
      this.idsToDelete.push(id);
    }
  }

  private getVstupniPoleForCategory(category_id: number) {
    this.vstupniPoleService.fetchAll({categories: [category_id]}).pipe(
      takeUntil(this.destroy$),
      map(result => {
        // @ts-ignore
        return result.data.response.items.map(item => {
          const settings = JSON.parse(item.vstupni_komponenta_settings);
          settings.form = this.gridsterForm;
          const gridItem = {
            id: item.id,
            cols: item.cols,
            rows: 1,
            x: item.coord_x,
            y: item.coord_y,
            settings,
            data: JSON.parse(item.vstupni_komponenta_data),
            moduleItem: JSON.parse(item.vstupni_komponenta),
            resizeEvent: this.gridster.resizeEvent
          } as PkGridsterItem;
          return gridItem;
        });
      }),
      catchError((error) => {
        // todo zobrazit error
        console.error(error);
        return observableOf(error);
      })
    ).subscribe((items) => {
      this.gridster.setItems(items);
      this._fuseProgressBarService.hide();
    });
  }

  private subscribeToSaveButtonAction() {
    if (this.saveAction$) {
      this.saveAction$.unsubscribe();
    }

    this.saveAction$ = this.actionsSubject$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(action => {
      if (action.type === LayoutActionTypes.SAVE_BUTTON_PRESSED) {
        // todo validace
        const vstupniPole = this.transformGridsterItemsToVstupniPole(this.gridster.gridsterItems);

        this.saveVstupniPole(vstupniPole)
          .then(() => {
            return this.deleteVstupniPole()
          })
          .then(() => {
            displaySnack('Úspěšně uloženo!', this.store$);
            this.router.navigateByUrl(this.currentRoute.snapshot.url[0].path || '/');
          })
          .catch(err => {
            displaySnack(err.message || 'Nepodařilo se uložit vstupní pole!', this.store$);
          })
      }
    });
  }

  private transformGridsterItemsToVstupniPole(gridsterItems: PkGridsterItem[]): any[] {
    return gridsterItems.map((pole) => {
      return {
        id: pole.id,
        cols: pole.cols,
        coord_x: pole.x,
        coord_y: pole.y,
        kategorie_id: this.activeCategory.id,
        nazev: pole.settings.nazev,
        vstupni_komponenta: JSON.stringify(pole.moduleItem),
        vstupni_komponenta_settings: JSON.stringify({...pole.settings, form: undefined, formControlName: undefined}),
        vstupni_komponenta_data: JSON.stringify(pole.data)
      };
    });
  }

  private saveVstupniPole(vstupniPole: any[]) {
    return this.vstupniPoleService.save({input: vstupniPole}).toPromise()
  }

  private deleteVstupniPole() {
    if (this.idsToDelete.length > 0) {
      return this.vstupniPoleService.delete({ids: this.idsToDelete}).toPromise()
    }
  }

  ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

}
