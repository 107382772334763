import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { VideoCategory, Videos } from './videos.types';

@Injectable({
  providedIn: 'root',
})
export class VideosService
{
  onCategoriesChanged: BehaviorSubject<any>;
  onVideosChanged: BehaviorSubject<any>;

  videos: Array<Videos>;
  categories: Array<string>;

  constructor()
  {
    // Set the defaults
    this.onCategoriesChanged = new BehaviorSubject([]);
    this.onVideosChanged = new BehaviorSubject([]);

    this.videos = this.getVideos();
    this.categories = this.getCategories();

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------


  getCategories(): Array<string>
  {
    this.onCategoriesChanged.next(Object.keys(VideoCategory));
    return Object.keys(VideoCategory);
  }

  getVideos(): Array<Videos>
  {
      this.videos = [
        { category: VideoCategory.CMS,
          icon: "insert_drive_file",
          length: 1,
          title: "Média a soubory",
          updated: "30.04.2020",
          url: "https://www.youtube.com/watch?v=IIWyNBXSTdo&list=PLIciwDP4vVR_f3zxTg9jIPyIzH7OBA362&index=2&t=0s"},
        { category: VideoCategory.WEB,
          icon: 'web',
          length: 1.5,
          title: "Stránky",
          updated: "30.04.2020",
          url: "https://www.youtube.com/watch?v=IIWyNBXSTdo&list=PLIciwDP4vVR_f3zxTg9jIPyIzH7OBA362&index=3&t=0s"},
        { category: VideoCategory.WEB,
          length: 1.5,
          icon: 'web',
          title: "Příspěvky",
          updated: "30.04.2020",
          url: "https://www.youtube.com/watch?v=IIWyNBXSTdo&list=PLIciwDP4vVR_f3zxTg9jIPyIzH7OBA362&index=4&t=0s"},
        { category: VideoCategory.WEB,
          length: 1,
          icon: 'web',
          title: "Úřední deska",
          updated: "30.04.2020",
          url: "https://www.youtube.com/watch?v=IIWyNBXSTdo&list=PLIciwDP4vVR_f3zxTg9jIPyIzH7OBA362&index=5&t=0s"},
        { category: VideoCategory.WEB,
          length: 1,
          icon: 'web',
          title: "Navigace",
          updated: "30.04.2020",
          url: "https://www.youtube.com/watch?v=IIWyNBXSTdo&list=PLIciwDP4vVR_f3zxTg9jIPyIzH7OBA362&index=6&t=0s"},
        { category: VideoCategory.WEB,
          length: 1,
          icon: 'web',
          title: "Galerie",
          updated: "30.04.2020",
          url: "https://www.youtube.com/watch?v=IIWyNBXSTdo&list=PLIciwDP4vVR_f3zxTg9jIPyIzH7OBA362&index=7&t=0s"},
        { category: VideoCategory.MODULY,
          length: 4.5,
          icon: 'toc',
          title: "Formuláře",
          updated: "30.04.2020",
          url: "https://www.youtube.com/watch?v=AxK07RTZva8&list=PLIciwDP4vVR_f3zxTg9jIPyIzH7OBA362&index=8&t=0s"},
        { category: VideoCategory.MODULY,
          length: 1.5,
          icon: 'money',
          title: "Poplatky",
          updated: "30.04.2020",
          url: "https://www.youtube.com/watch?v=AxK07RTZva8&list=PLIciwDP4vVR_f3zxTg9jIPyIzH7OBA362&index=9&t=0s"},
        { category: VideoCategory.MODULY,
          length: 3.5,
          icon: 'texture',
          title: "Evidence",
          updated: "30.04.2020",
          url: "https://www.youtube.com/watch?v=Uuy0pECa3Zg&list=PLIciwDP4vVR_f3zxTg9jIPyIzH7OBA362&index=10&t=0s"},
      ];
      this.onVideosChanged.next(this.videos);
      return this.videos;
  }
}
