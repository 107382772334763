import { Component, OnDestroy, OnInit,  ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Apollo } from 'apollo-angular';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { resetPassword } from '@nx-monorepo/obce/common/queries';
import { gitVersion } from 'apps/obce/cms/src/environments/git-version';
import { displaySnack } from '@nx-monorepo/cms-base/helpers';
import { IServerResponse } from '@nx-monorepo/obce/common/interfaces';

@Component({
  selector     : 'pk-reset-password-page',
  templateUrl  : './pk-reset-password-page.component.html',
  styleUrls    : ['./pk-reset-password-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class PkResetPasswordComponent implements OnInit, OnDestroy
{
  resetPassForm: FormGroup;
  returnUrl: string;
  loading = false;
  destroyed$ = new Subject<boolean>();
  lastAuthMessage$: Observable<string>;

  username = '';
  errorMessage = null;

  versionInfo: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private store$: Store<MACMSStoreState.State>,
    private _fuseProgressBarService: FuseProgressBarService,
    library: FaIconLibrary,
    private apollo: Apollo,
  )
  {
    this.versionInfo = gitVersion;
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        },
        header: {
          hidden: true
        },
        content:{
          carded: false
        }
      }
    };
    library.addIcons(faExclamationCircle);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    this.resetPassForm = this._formBuilder.group({
      email   : ['', [Validators.required, Validators.email]],
    });

    // navigate to route on login success
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit(){
    const payload = {
      email: this.resetPassForm.get("email").value
    };

    this.executeQuery(resetPassword, payload).toPromise()
      .then((response: {data: {response: IServerResponse}}) => {
        console.log(response);
        if (!response.data.response.isSuccessful) {
          displaySnack(response.data.response.message, this.store$);
        } else {
          displaySnack('Email pro resetování hesla byl odeslán.', this.store$);
        }
        this.router.navigateByUrl('/login');
      })
      .catch(err => {
        displaySnack(err.message || 'Nepodařilo se resetovat heslo, zkuste to prosím později!', this.store$);
      })
  }

  public executeQuery(mutation: any, vars: any) {
    return this.apollo.mutate({
      mutation: mutation,
      variables: vars
    });
  }

  ngOnDestroy() {
    this.loading = false;
    this._fuseProgressBarService.hide();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
