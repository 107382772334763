import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PaginatorProps } from './paginator.props';
import { BaseComponent } from '../base.component';
import { ObecService, ColorUtilsService } from '@nx-monorepo/obce/ng/services';
import { Router } from '@angular/router';
import { stripPathSegments } from '@nx-monorepo/obce/common/utils';

@Component({
  selector: 'macms-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent extends BaseComponent implements OnChanges {
  @Input() settings: PaginatorProps;

  @Input() currentPage;
  @Input() itemsPerPage;
  @Input() totalItems;

  @Output() pageChanged = new EventEmitter<number>();

  totalPages: number;
  showingFrom: number;
  showingTo: number;

  constructor(colorUtilsService: ColorUtilsService, obecService: ObecService, private router: Router) {
    super(colorUtilsService, obecService);
  }

  changePage(newPageNumber: number){
    console.log(this.router.url, stripPathSegments(this.router.url,1));
    this.router.navigate([stripPathSegments(this.router.url,1)],{ queryParams: {page: newPageNumber}});
    this.pageChanged.emit(newPageNumber);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);
    this.primaryColor = this.settings.primaryColor;
    this.generateColors();
    this.showingFrom = 1 + (this.currentPage  - 1) * this.itemsPerPage;
    this.showingTo = this.currentPage * this.itemsPerPage > this.totalItems ? this.totalItems : this.currentPage * this.itemsPerPage
  }
}
