import { IColumnDefinition, IEntityDefinition } from '../../interfaces';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';

//this cannot be interface due to JS not having interfaces - angular will break!
export abstract class ColumnDefinitionProvider{
  abstract getColumnDefinitionsFor(entity: EntityTypes): IColumnDefinition[];
}

export abstract class EntityDefinitionProvider{
  abstract getEntityDefinitionsFor(entity: EntityTypes): IEntityDefinition[];
}
