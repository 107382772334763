//DEPRECATED BECAUSE PACKAGE IS UNMAINTAINED... NEED TO MAKE OUR OWN
//todo create own component instead of using ngx fa icon picker or whatev
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IPkIconFieldProps } from './pk-icon-field.props';

@Component({
  selector: 'pk-icon-field',
  templateUrl: './pk-icon-field.component.html',
  styleUrls: ['./pk-icon-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PkIconFieldComponent implements OnInit {

  @Input()
  settings: IPkIconFieldProps;

  @Input()
  data: any;

  private icon: string;


/*
  constructor(private iconLibrary: FaIconLibrary, faConfig: FaConfig,) {
    iconLibrary.addIconPacks(fas);
    faConfig.defaultPrefix = 'fas';
  }
*/

  onIconPickerSelect(icon: string): void {
    this.settings.form.get(this.settings.formControlName).setValue(icon);
  }

  ngOnInit(): void {

  }

}

