import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { fuseAnimations } from '@fuse/animations';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MACMSStoreState, LayoutStoreActions, LayoutStoreSelector } from '@nx-monorepo/cms-base/store';
import { IAction } from '@nx-monorepo/cms-base/interfaces';


@Component({
  selector: 'macms-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: fuseAnimations, // contains @animate
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * Constructor
   */
  constructor(public store$: Store<MACMSStoreState.State>, private router: Router) {
  }

  pageIcon$: Observable<IAction>;
  pageName$: Observable<string>;
  searchVisibility$: Observable<boolean>;
  destroy$: Subject<boolean> = new Subject<boolean>();


  // view references with setter due to NgIf
  @ViewChild('filter') set filter(filter: ElementRef) {
    if (filter) {
      fromEvent(filter.nativeElement, 'keyup')
        .pipe(
          takeUntil(this.destroy$),
          debounceTime(200),
          distinctUntilChanged()
        ).subscribe(() => {
        const searchValue = filter.nativeElement.value;
        this.store$.dispatch(new LayoutStoreActions.SetLayoutSearch({ search: searchValue }));
      });
    }
  };

  iconClick(url){
    this.router.navigate([url])
  }

  ngOnInit() {
    this.pageName$ = this.store$.select(LayoutStoreSelector.selectLayoutPageName);
    this.pageIcon$ = this.store$.select(LayoutStoreSelector.selectLayoutPageIcon);
    this.searchVisibility$ = this.store$.select(LayoutStoreSelector.selectLayoutSearchVisibility);
  }

  ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
