import { Component, Input, OnInit } from '@angular/core';
import { IPkFormDialogCompanion } from '../pk-dialog/pk-dialog-data';

@Component({
  selector: 'pk-modulelist',
  templateUrl: './pk-modulelist.component.html',
  styleUrls: ['./pk-modulelist.component.scss']
})
export class PkModulelistComponent implements OnInit {
  public header: string;

  @Input() headerInput: string;
  @Input() itemList: Array<PkModuleItem>;

  ngOnInit(): void {
    if (this.headerInput == null) {
      this.header = 'Moduly';
    }
  }

  dragStartHandler(ev: DragEvent, item: PkModuleItem): void {
    ev.dataTransfer.setData('text/plain', JSON.stringify(item));
    ev.dataTransfer.dropEffect = 'copy';
  }
}

export interface PkModuleItem {
  title: string;
  description: string;
  icon: string;
  type: GridsterInputComponent | GridsterFrontendComponent;
  formCompanion?: IPkFormDialogCompanion;
}

export declare type GridsterFrontendComponent =
  'ItemNavComponent' |
  'WysiwygComponent' |
  'KolekceComponent' |
  'EntitaComponent' |
  'FotogalerieComponent'|
  'FooterComponent' |
  'HeaderComponent' |
  'NewsletterComponent'|
  'UredniDeskyComponent' |
  'UdalostiComponent' |
  'PrispevkyComponent' |
  'CompactUredniDeskyComponent' |
  'TwoColumnUredniDeskyComponent' |
  'CalendarComponent' |
  'FotogalerieComponent';


export declare type GridsterInputComponent =
  'PkGridsterItemTextfieldComponent' |
  'PkGridsterItemSelectComponent' |
  'PkGridsterItemDateComponent' |
  'PkGridsterItemMultipleSelectComponent' |
  'PkGridsterItemCheckboxComponent' |
  'PkGridsterInputWysiwygComponent';
