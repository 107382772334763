import { Validators } from '@angular/forms';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { IPkHiddenFieldProps, IPkColorPickerProps } from '@nx-monorepo/cms-base/components';

export const kategorieSettingsDefinition: IEntityDefinition[] = [
  {
    componentName: 'PkHiddenFieldComponent',
    settings: {
      type: 'number',
      formControlName: 'id',
      defaultValue: 0
    } as IPkHiddenFieldProps,
    data: null,
    validators: [],
    cols: 0,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      isTextArea: false,
      formControlName: 'nazev',
      type: 'text',
      nazev: 'Název kategorie',
      povinnost: true,
      defaultValue: ''
    },
    data: null,
    validators: [Validators.required],
    cols: 4,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkColorPickerFieldComponent',
    settings: {
      formControlName: 'color',
      nazev: 'Barva kategorie',
      povinnost: true,
      showAlways: true,
      defaultValue: '#000000'
    } as IPkColorPickerProps,
    data: null,
    validators: [Validators.required],
    cols: 4,
    x: 0,
    y: 1
  }
];
