export interface NavPol {
  id: number,
  children: NavPol[],
  parent: NavPol,
  nazev: string,
  url: string,
  ikonka?: string,
  parent_id: number,
  order: number,
}

export const getChildrenInTreeRepresentation = (children: NavPol[], tree = {}) => {
  const missingParents = [];

  //add new child to the map of all nodes
  if (children) {
    children.forEach(child => {
      child.children = [];
      tree[child.id] = child;
      if (child.parent_id != null) {
        if(tree[child.parent_id] == null){
          missingParents.push(child);
        } else {
          tree[child.parent_id].children.push(child);
        }
      }
    });
    if(missingParents.length > 0){
      tree = getChildrenInTreeRepresentation(missingParents, tree);
    }

    // find root level (no parents)
    let root = [];
    Object.keys(tree).forEach(key => {
      if (tree[key].parent_id == null) {
        root.push(tree[key]);
      }
    });
    root = sortByOrder(root);
    return root;
  }
};

const sortByOrder = (nodes: NavPol[]) => {
  nodes.sort((a, b) => (a.order > b.order) ? 1 : -1);
  for (const node of nodes) {
    if ('children' in node) {
      sortByOrder(node.children);
    }
  }
  return nodes;
}
