import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { HeaderComponent } from './header.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MacmsModule } from '@nx-monorepo/cms-base/components/macms.module';

@NgModule({
    declarations: [HeaderComponent],
    imports: [
        FuseSharedModule,
        MacmsModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule
    ],
    exports: [HeaderComponent]
})
export class HeaderModule {}
