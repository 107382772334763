import {
  getDefaultDialogButtons,
  getNapovedaSettings,
  getNazevSettings,
  getPovinnostSettings
} from '../common-settings-objects';
import { IPkInputDateFieldProps } from '@nx-monorepo/cms-base/components';
import { IPkFormDialogCompanion, IPkDialogData } from '@nx-monorepo/cms-base/components/pk-dialog/pk-dialog-data';

export class PkGridsterItemDateDialogCompanion implements IPkFormDialogCompanion {
  getDialogData(settings: IPkInputDateFieldProps, data: {}, emitters: {dialogSubmit, dialogClose}) {
    const dialogData: IPkDialogData = {
      title: 'Upravit vstupní pole',
      components: [
        getNazevSettings(settings.nazev),
        getPovinnostSettings(settings.povinnost),
        getNapovedaSettings(settings.napoveda),
      ],
      buttons: getDefaultDialogButtons(emitters)
    };

    return dialogData;
  }
}
