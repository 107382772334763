<section [style.background]="settings.sectionBackground">
  <nav class="list" fxLayout="row" fxLayoutAlign="start top" [contrastForeground]>
    <ng-container *ngFor="let item of navigacePolozky; let i = index">
      <div fxLayout="column" class="nav-column">
        <macms-heading
          [settings]="headingProps"
          [text]="item.nazev"></macms-heading>
        <ng-container *ngFor="let item2 of item.children">
          <ng-container *ngIf="!item2.children || item2.children.length == 0">
            <ng-container *ngTemplateOutlet="item2.url ? outsideLink : insideLink; context: {item: item2}">
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </nav>
</section>

<ng-template #insideLink let-item="item">
  <a [routerLink]="(item.stranka ? item.stranka.slug : null) | DetailUrl:'stranky'"
     [style.fontFamily]="obecSettings.paragraph_font"
     macmsColor
     [mc_underTransparentColor]="settings.isInverted ? settings.primaryColor : '#ffffff'"
     [mc_wantedPrimary]='obecSettings.primary_color'>
    {{item.nazev}}
  </a>
</ng-template>
<ng-template #outsideLink let-item="item">
  <a [href]="item.url"
     [style.fontFamily]="obecSettings.paragraph_font"
     macmsColor
     [mc_underTransparentColor]="settings.isInverted ? settings.primaryColor : '#ffffff'"
     [mc_wantedPrimary]='obecSettings.primary_color' >
    {{item.nazev}}
  </a>
</ng-template>
