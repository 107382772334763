<ng-container>
  <div *ngIf="(!needsCategory || (activeCategory !== null && activeCategory.id > 0)); else noCategorySelected" fxLayout="row">
    <div fxLayout="column" fxFlex="25%">
      <pk-modulelist [itemList]="getItemList()"></pk-modulelist>
    </div>

    <div fxLayout="column" fxFlex="75%">
      <pk-gridster #gridster [form]="gridsterForm" [showPreview]="showPreview" style="height: 600px" (deleteItem)="itemDeleted($event)"></pk-gridster>
    </div>
  </div>
  <ng-template #noCategorySelected>
    <pk-no-category></pk-no-category>
  </ng-template>
</ng-container>


