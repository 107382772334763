<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="navbar-toggle-button"
              (click)="toggleSidebarOpen('navbar')" fxHide.gt-sm>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
        <div
            class="p-0 m-0 h-100-p"
            fxFlex="1 0 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <pk-category-tabs>
            </pk-category-tabs>
        </div>

        <div
            class=""
            fxFlex="0 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <pk-account-menu>
            </pk-account-menu>
        </div>
    </div>
</mat-toolbar>
