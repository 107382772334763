import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';


export class PkValidator {
   static validatorRCFactory(): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            if(control.value){
                if (control.value.includes("/")) {
                    return {"pkerror": "Rodné číslo zadejte bez lomítka!"}
                } else if (control.value.length !== 10) {
                    return {"pkerror": "Rodné číslo nemá správnou délku!"}
                } else if (control.value%11!==0) {
                    return {"pkerror": "Rodné číslo není správné!"}
                } else {
                    return null;
                }
            } else {
                return {"pkerror": "Rodné číslo musí být vyplněno!"}
            }
        }
    }

    static validatorDeliveryTimeFactory(): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            if(control.value) {
                //ak zadana hodnota je vacsia nez dnes+3 dni
                if(control.value > (new Date(Date.now() + 3 * 86400000))) {
                    return {"pkerror": "Není možné zadat větší datum než 3 dni dopředu!"}
                } else {
                    return null;
                }
            }
        }
    }

    static validatorBirthDateFactory(): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            if (control.value) {
                if (moment(control.value).isBefore(moment(new Date(1900, 1, 1))) || moment(control.value).isAfter(moment(new Date()).add('1 day'))) {
                    return {"pkerror": "Zadaný datum je mimo povolený rozsah!"}
                } else {
                    return null;
                }
            }
        }
    }

    //default validator doesnt provide meaningful error message
    static validatorLengthFactory(length: number, minMax: "min"|"max"): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            if (control.value) {
                if (minMax === 'min' ? control.value.length < length : control.value.length > length) {
                    return {"pkerror": `Zadaný text je ${minMax === 'min' ? 'kratší' : 'delší'} než povolený rozsah!`}
                } else {
                    return null;
                }
            }
        }
    }

    static validatorForChipEmail(): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            if (control.value) {
                const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
                for (let i = 0; i < control.value.length; i++) {
                    if (control.value[i].nazev.match(regex) === null) {
                        return {"pkerror": "Emaily nejsou ve správném formátu!"};
                    }
                }
            }
            return null;
        }
    }

    static validatorForChipPhone(): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            if (control.value) {
                const regex = /^(\+420|\+421)?[0-9]{9}$/;
                for (let i = 0; i < control.value.length; i++) {
                    if (control.value[i].nazev.match(regex) === null) {
                        return {"pkerror": "Telefonní čísla nejsou ve správném formátu!"};
                    }
                }
            }
            return null;
        }
    }
}
 