import gql from 'graphql-tag';

export const deleteKategorie = gql`
  mutation deleteKategorie($ids: [Int!]!) {
    response: deleteKategorie(ids: $ids) {
      description
      userMessage
      error
    }
  }
`;
