import { Action } from '@ngrx/store';
import { MatSnackBarConfig } from '@angular/material/snack-bar';


export enum SnackbarActionTypes {
  SNACKBAR_OPEN = '[Snackbar] Open',
  SNACKBAR_CLOSE = '[Snackbar] Close'
}

export class SnackbarActionOpen implements Action {
  readonly type = SnackbarActionTypes.SNACKBAR_OPEN;

  constructor(public payload: {
    message: string,
    action?: string,
    config?: MatSnackBarConfig
  }) { }
}

export class SnackbarActionClose implements Action {
  readonly type = SnackbarActionTypes.SNACKBAR_CLOSE;

  constructor() {}
}

export type SnackbarActions =
  | SnackbarActionOpen
  | SnackbarActionClose;
