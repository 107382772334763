<div class="paginator" [style.fontFamily]="obecSettings.title_font">
  <button [disabled]="currentPage == 1" class="item" aria-hidden="false" aria-label="Předchozí stránka" (click)="changePage(currentPage - 1)" [style.color]="primaryColor"><</button>
  <ng-container *ngIf="currentPage - 2 > 0">
    <button class="item" *ngIf="currentPage - 2 == 1;else backTemplate" (click)="changePage(1)" [style.color]="primaryColor">1</button>
  </ng-container>
  <button class="item" *ngIf="currentPage - 1 > 0" (click)="changePage(currentPage - 1)" [style.color]="primaryColor">{{currentPage - 1}}</button>
  <button disabled class="item" [style.backgroundColor]="primaryColor" [style.color]="onPrimaryColor">{{currentPage}}</button>
  <button class="item" *ngIf="currentPage + 1 <= totalPages" (click)="changePage(currentPage + 1)" [style.color]="primaryColor">{{currentPage + 1}}</button>
  <ng-container *ngIf="currentPage + 2 <= totalPages">
    <button class="item" *ngIf="currentPage + 2 == totalPages;else forwardTemplate" (click)="changePage(currentPage + 2)" [style.color]="primaryColor">{{currentPage + 2}}</button>
  </ng-container>
  <ng-template #forwardTemplate>
    <button disabled class="item" [style.color]="primaryColor">...</button>
    <button class="item" (click)="changePage(totalPages)" [style.color]="primaryColor">{{totalPages}}</button>
  </ng-template>
  <ng-template #backTemplate>
    <button class="item" (click)="changePage(1)" [style.color]="primaryColor">1</button>
    <button disabled class="item" [style.color]="primaryColor">...</button>
  </ng-template>
  <button  [disabled]="currentPage == totalPages" class="item" aria-hidden="false" aria-label="Následující stránka" (click)="changePage(currentPage + 1)" [style.color]="primaryColor">></button>
</div>
<div class="info" [style.color]="obecSettings.primary_color" [style.fontFamily]="obecSettings.paragraph_font">
  Zobrazuji {{ showingFrom }} - {{showingTo}} z celkem {{totalItems}} záznamů
</div>
