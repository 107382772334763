import { NgModule } from '@angular/core';
import { ForegroundColorDirective } from './foreground-color.directive';
import { FixBackgroundUrlPipe } from './fix-background-url.pipe';
import { BorderDefinitionDirective } from '../../../../ng-shared/src/lib/utils/borders/border-definition.directive';
import { MarginDefinitionDirective } from './margin-definition.directive';
import { PaddingDefinitionDirective } from './padding-definition.directive';
import { GapDefinitionDirective } from './gap-definition.directive';
import { MacmsColorDirective } from './macms-color.directive';
import { DetailUrlPipe } from './detail-url.pipe';
import { StripUrlPipe } from './strip-url-segment.pipe';
import { StartsWithPipe } from './starts-with.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TimesPipe } from './times.pipe';
import { ResizeObserverDirective } from './resize-observer.directive';
import { SplitPipe } from './split.pipe';
import { CustomCurrencyPipe } from './currency.pipe';
import { CurrencyDirective } from './currency.directive';
import { ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@NgModule({
  declarations: [ResizeObserverDirective, ForegroundColorDirective, TimesPipe, SafeHtmlPipe, FixBackgroundUrlPipe,StartsWithPipe, BorderDefinitionDirective, DetailUrlPipe, StripUrlPipe, MarginDefinitionDirective, PaddingDefinitionDirective, GapDefinitionDirective, MacmsColorDirective, SplitPipe, CustomCurrencyPipe, CurrencyDirective],
  providers: [ColorUtilsService,ResizeObserverDirective, ForegroundColorDirective, BorderDefinitionDirective, MarginDefinitionDirective, PaddingDefinitionDirective, GapDefinitionDirective, MacmsColorDirective, CurrencyDirective],
  exports: [ResizeObserverDirective, ForegroundColorDirective,TimesPipe, SafeHtmlPipe, FixBackgroundUrlPipe, StartsWithPipe, BorderDefinitionDirective, MarginDefinitionDirective, StripUrlPipe, PaddingDefinitionDirective, GapDefinitionDirective, MacmsColorDirective, DetailUrlPipe, SplitPipe, CustomCurrencyPipe, CurrencyDirective]
})
export class UtilsModule {}

