import { Validators } from '@angular/forms';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { KATEGORIE_SERVICE_TOKEN } from '@nx-monorepo/obce/ng/services';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { IPkHiddenFieldProps, 
        IPkInputTextFieldProps, 
        IPkSelectFieldProps, 
        IPkInputDateFieldProps, 
        IPkInputEntityFieldProps
} from '@nx-monorepo/cms-base/components';

export const urednideskaEntityDefinition: IEntityDefinition[] = [
  {
    componentName: 'PkHiddenFieldComponent',
    settings: {
      type: 'number',
      formControlName: 'id',
      defaultValue: 0
    } as IPkHiddenFieldProps,
    data: null,
    validators: [],
    cols: 0,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Název',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Napoveda lorem ipsum'
      },
      isTextArea: false,
      formControlName: 'nazev',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 2,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Popis',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Napoveda lorem ipsum'
      },
      isTextArea: false,
      formControlName: 'popis',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 2,
    x: 2,
    y: 0
  },
  {
    componentName: 'PkSelectFieldComponent',
    settings: {
      nazev: 'Kategorie',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Napoveda lorem ipsum'
      },
      formControlName: 'kategorie_id',
      isMultiple: false,
      service: {
        token: KATEGORIE_SERVICE_TOKEN,
        fetchAllArgs: {
          types: [EntityTypes.UredniDeska]
        },
        fetchSingleArgs: {}
      },
    } as IPkSelectFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 2,
    x: 0,
    y: 1
  },
  {
    componentName: 'PkInputDateFieldComponent',
    settings: {
      nazev: 'Začátek',
      povinnost: true,
      napoveda: {
        text: 'Napoveda lorem ipsum',
        isIcon: true
      },
      formControlName: 'datum_od',
      defaultValue: new Date()
    } as IPkInputDateFieldProps,
    validators: [Validators.required],
    data: null,
    cols: 2,
    x: 0,
    y: 2
  },
  {
    componentName: 'PkInputDateFieldComponent',
    settings: {
      nazev: 'Konec',
      povinnost: false,
      napoveda: {
        text: 'Napoveda lorem ipsum',
        isIcon: true
      },
      formControlName: 'datum_do',
      defaultValue: null
    } as IPkInputDateFieldProps,
    data: null,
    validators: [],
    cols: 2,
    x: 2,
    y: 2
  },
  {
    componentName: 'PkInputEntityFieldComponent',
    settings: {
      nazev: 'Přidat přílohy',
      povinnost: false,
      napoveda: {
        isIcon: false,
        text: 'Přílohy k úřední desce'
      },
      isMultiple: true,
      withPreview: true,
      entityType: EntityTypes.Media,
      formControlName: 'media',
      defaultValue: null,
    } as IPkInputEntityFieldProps,
    data: null,
    validators: [],
    cols: 4,
    x: 0,
    y: 3
  },
];
