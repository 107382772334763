<div class="section-wrapper info-component-wrapper"
     [style.font-family]="obecSettings.title_font"
     macmsColor
     [mc_isInverted]="settings.isInverted"
     [mc_wantedPrimary]="settings.primaryColor">
   <macms-heading
      *ngIf="settings.showHeading"
      [settings]="settings.headingProps"
      [text]="settings.nazev || 'O Obci'">
    </macms-heading>
  <section id="info">
    <div class="inforow">
      <div class="left-col col">
        <span class="number">{{obecSettings.info_settings.pocet_obyvatel}}</span>
        <span class="text">POČET<br>OBYVATEL</span>
        <!--Need to add stuff to info_settings and show it here...-->
        <!--Aby zde byly informacec k dispozici, musí se taky přidat do obce.service (obcePromise)-->
      </div>
      <div class="right-col col">
        <p>{{obecSettings.info_settings.o_obci}}</p>
      </div>
    </div>
    <div class="inforow">
      <div class="left-col col">
        <!--Need to add stuff to info_settings and show it here...-->
        <!--Aby zde byly informacec k dispozici, musí se taky přidat do obce.service (obcePromise)-->
        <!--<h4>{{obecSettings.info_settings.nazev}}</h4>-->
        <span class="number">ZNAK</span>
        <img [src]="obecSettings.info_settings.logo_url" alt="logo obce"/>
      </div>
      <div class="right-col col">
        <p>{{obecSettings.info_settings.o_logu}}</p>
      </div>
    </div>

  </section>
</div>
