import gql from 'graphql-tag';

export const addNavigace = gql`
  mutation addNavigace($input: [NavigaceInput!]!) {
    response: addNavigace(inputNavigace: $input) {
      id
      nazev
      id
      children{
        id
        nazev
        type
        url
        stranka_id
        children{
          id
          nazev
          type
          url
          stranka_id
          children{
            id
            type
            nazev
            url
            stranka_id
            children{
              id
              type
              nazev
              url
              stranka_id
            }
          }
        }
        
      }
    }
  }
`;
