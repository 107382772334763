import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject, of as observableOf } from 'rxjs';
import { PkDialogComponent } from '../pk-dialog/pk-dialog.component';
import { IPkDialogData } from '../pk-dialog/pk-dialog-data';
import { startWith, takeUntil } from 'rxjs/operators';
import { MACMSStoreState, CategoryTabsStoreSelector, LayoutStoreSelector, CategoryTabsActions } from '@nx-monorepo/cms-base/store';
import { IAction } from '@nx-monorepo/cms-base/interfaces';
import { kategorieSettingsDefinition } from 'apps/obce/cms/src/app/entity-definitions';

@Component({
  selector: 'pk-category-tabs',
  templateUrl: './pk-category-tabs.component.html',
  styleUrls: ['./pk-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PkCategoryTabsComponent implements OnInit, OnDestroy {
  @Output()
  dialogClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  dialogSubmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(public dialog: MatDialog, private store$: Store<MACMSStoreState.State>) {

  }

  private dialogRef: MatDialogRef<PkDialogComponent, string>;
  categories$: Observable<IAction[]>;
  activeCategory$: Observable<IAction>;
  hasAddButton$: Observable<boolean>;
  hideCategories$: Observable<boolean>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnInit() {
    this.subscribeToDialogClose();
    this.subscribeToDialogSubmit();

    this.categories$ = this.store$.select(CategoryTabsStoreSelector.selectCategories);
    this.activeCategory$ = this.store$.select(CategoryTabsStoreSelector.selectActiveCategory);
    this.hasAddButton$ = this.store$.select(LayoutStoreSelector.selectWithAddButton);
    this.hideCategories$ = this.store$.select(LayoutStoreSelector.selectWithCategories).pipe(
      startWith(true)
    );
  }

  private subscribeToDialogClose() {
    this.dialogClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  private subscribeToDialogSubmit() {
    this.dialogSubmit.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      const { nazev, color } = result;
      if (nazev) {
        this.store$.dispatch(new CategoryTabsActions.CreateCategoryAction({ category_title: nazev, category_color: color }));
      }
      this.dialogRef.close();

    });
  }

  tabClicked(idx: number, name: string): void {
    this.store$.dispatch(new CategoryTabsActions.SetActiveCategoryAction({ category_title: name }));
  }

  createClicked(): void {
    if (this.dialogRef == null) {
      // create dialog data
      const dialogData: IPkDialogData = {
        title: 'Přidat novou kategorii',
        components: kategorieSettingsDefinition,
        buttons: [
          {
            color: 'warn',
            text: 'Ne, díky',
            action: this.dialogClose
          },
          {
            color: 'primary',
            text: 'Vytvořit',
            action: this.dialogSubmit
          }
        ]
      };

      // create dialog config and pass dialog data to it
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = dialogData;

      // open dialog and subscribe to its afterClosed event to remove the reference
      this.dialogRef = this.dialog.open(PkDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.dialogRef = null;
      });
    }
  }

  ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}

