import gql from 'graphql-tag';

export const resetPassword = gql`
    query resetPassword($email: String!) {
        response: resetPassword(
            email: $email
        ) {
            message
            isSuccessful
        }
    }`