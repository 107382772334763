import gql from 'graphql-tag';

export const readAllKontaktQuery = gql`
  query ReadAllKontakt($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $categories: [Int!]) {
    response: readAllKontakt(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      categories: $categories
    ) {
      total
      page
      take
      hasMore
      items {
        id
        nazev
        ico
        dic
        ulice
        psc
        
      }
    }
  }
`;
