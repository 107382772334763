import gql from 'graphql-tag';

export const readSelectedUdalostQuery = gql`
  query ReadSelectedUdalost($ids: [Int!], $slug: String) {
    response: readSelectedUdalost(
      ids: $ids,
      slug: $slug
    ) {
      id
      nazev
      popis
      kategorie{
        nazev
      }
      obsah
      slug
      featured
      media{
        id
        mime
        url
      }
      datum_zacatek
      datum_konec
      kategorie_id
      autor_id
    }
  }
`;
