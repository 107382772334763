import { Component, Input, OnInit } from '@angular/core';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { PrispevkyBarrel } from '@nx-monorepo/node-frontend';
import { faCalendar, faDownload } from '@fortawesome/free-solid-svg-icons';
import { ObecService, ColorUtilsService } from '@nx-monorepo/obce/ng/services';
import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { NextButtonProps } from '../../next-button/next-button.props';
import { BaseComponent } from '../../base.component';
import { Router } from '@angular/router';


@Component({
  selector: 'macms-image-prispevky',
  templateUrl: './image-prispevky.component.html',
  styleUrls: ['./image-prispevky.component.scss']
})
export class ImagePrispevkyComponent extends BaseComponent implements IDynamicComponent, OnInit {
  @Input() data: {items: any[], nazev};
  @Input() settings: PrispevkyBarrel.ImagePrispevkyProps;

  //todo Type (create Barrel)
  nextButtonSettings: NextButtonProps;

  constructor(public colorUtilsService: ColorUtilsService, protected obecService: ObecService, private router: Router) {
    super(colorUtilsService, obecService);
  }

  downloadIcon = faDownload;
  calendarIcon = faCalendar;

  ngOnInit(): void {
    registerLocaleData(localeCs, 'cs-CZ');
    this.primaryColor = this.settings.primaryColor;
    this.generateColors();

    this.nextButtonSettings = {
      primaryColor: this.primaryColor,
      fontTitle: this.obecSettings.title_font,
      bottomPaddingEm: 2,
      topPaddingEm: 2,
    };
    
    this.downloadIcon = faDownload;
  }

  getComponentName() {
    return PrispevkyBarrel.name_image;
  }
}
