import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError, BehaviorSubject, from } from 'rxjs';
import { catchError, filter, take, switchMap, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthService) {
    console.log("INTERCEPTOR ALIVE");
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.body && (request.body.operationName === "Refresh" || request.body.operationName === "Login" || request.body.operationName === 'setPassword'|| request.body.operationName === 'resetPassword')){
      return next.handle(request);
    }
    //console.log("DOING SMTH", request);
    // first have a look at our current token, do we have it? is it not expired?
    if (this.authService.isLoggedIn()) {
      // we have token and is not expired
      request = this.addToken(request, this.authService.getToken());
      //console.log("logged in");
    } else {
      // either we do not have token, or it is expired, check if we have refresh
      if (this.authService.hasValidRefreshToken()) {
        console.log("not logged in but good refresh token");
        return this.tryRefreshing(request, next);
      } else {
        // we do not have anything, no point in spamming api

        // find reason of 401 (timeout / never logged in)
        if (this.authService.getToken()) {
          console.log("everything is old, timeout");
          return throwError(
            new HttpErrorResponse({
              error: 'Odhlášen z důvodu neaktivity',
              status: 401
            }));
        } else {
          console.log("never logged in");
          return throwError(
            new HttpErrorResponse({
              error: 'Nejste přihlášen',
              status: 401
            }));
        }
      }
    }

    // we should have everything and there should not be any error, but for safety sake, let's do shit again
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if (this.authService.hasValidRefreshToken()) {
          return this.tryRefreshing(request, next);
        } else {
          return throwError(error);
        }
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }


  private tryRefreshing(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      //Im sorry unreadable code follows
      //convert promise to observable
      console.log("refresh start");
      return from(this.authService.refresh(this.authService.getToken(), this.authService.getRefreshToken()))
        .pipe(
          // switchMap the result from original promise
          switchMap((is_successful: boolean) => {
            console.log("REFRESH DONE WITH RESULT",is_successful);
            if (is_successful) {
              // is successful, so append the new token to our requests
              const new_token = this.authService.getToken();
              // stop refreshing
              this.isRefreshing = false;
              this.refreshTokenSubject.next(new_token);
              return next.handle(this.addToken(request, new_token));
            } else {
              // not successful so
              return throwError(
                new HttpErrorResponse({
                  error: 'Odhlášen z důvodu neaktivity',
                  status: 401
                }));
            }
          }));
    } else {
      // we are already refreshing, hold the request and just subscribe to the refreshToken so when it completes, add the jwt and send it
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        // not sure why we are taking the 1 here, since we cannot do more than one refresh, but this is copied from smarter guys so whatevs
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
