import { IFrontendRoutingDefinition } from './i-frontend-routing-definition';
import { IFrontendRoute } from './i-frontend-route';

export class FrontendRouteBuilder {
  private routes: IFrontendRoute[] = [];

  public getRoutes(): IFrontendRoute[] {
    return this.routes;
  }

  public buildRoutesfromDefinitions(definitions: IFrontendRoutingDefinition[]): IFrontendRoute[] {

    definitions.forEach(definition => {
      // create route for detailPage
      this.routes.push({
        path: definition.basePath,
        data: { nazev: definition.nazev, innerComponent: definition.listComponent, entityType: definition.entityType, isCategorized: definition.isCategorized },
        component: definition.layoutComponent
      } as IFrontendRoute);
      if (definition.isCategorized) {

        this.routes.push({
          path: definition.basePath + '/:kategorie',
          data: { nazev: definition.nazev, innerComponent: definition.listComponent, entityType: definition.entityType, isCategorized: definition.isCategorized },
          component: definition.layoutComponent
        } as IFrontendRoute);

        this.routes.push({
          path: definition.basePath + '/:kategorie/:identifier',
          data: { nazev: definition.nazev, innerComponent: definition.detailComponent, entityType: definition.entityType, isCategorized: definition.isCategorized },
          component: definition.layoutComponent
        } as IFrontendRoute);
      } else {
        this.routes.push({
          path: definition.basePath + '/:identifier',
          data: { nazev: definition.nazev, innerComponent: definition.detailComponent, entityType: definition.entityType, isCategorized: definition.isCategorized },
          component: definition.layoutComponent
        } as IFrontendRoute);
      }
    });

    return this.routes;
  }
}
