import {EntityTypes} from '@nx-monorepo/obce/common/enums';
import {
  readAllEvidenceQuery,
  readAllFormularQuery,
  readAllGalerieQuery,
  readAllMediaQuery,
  readAllNavigaceQuery, 
  readAllPlatebniPrikazQuery,
  readAllPrispevekQuery,
  readAllStrankaQuery,
  readAllUdalostQuery,
  readAllKontaktQuery,
  readAllUredniDeskaQuery,
  readAllUzivatelQuery,
  readSelectedEvidenceQuery,
  readSelectedFormularQuery,
  readSelectedGalerieQuery,
  readSelectedNavigaceQuery,
  readSelectedPrispevekQuery,
  readSelectedUdalostQuery,
  readSelectedUredniDeskaQuery,
  readSelectedUzivatelQuery,
  readSelectedStrankaQuery,
  readSelectedKontaktQuery, 
  readSelectedPlatebniPrikazQuery, 
  readAllEmailQuery,
  readSelectedTransakceQuery, 
  readAllTransakceQuery, 
  readAllSmsQuery, 
  readSelectedSmsQuery, 
  readSelectedEmailQuery
} from '@nx-monorepo/obce/common/queries';
import {Injectable, ɵConsole} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {
  addEvidence,
  addFormular,
  addGalerie,
  addHistorie,
  addKontakt,
  addMedia,
  addNavigace,
  addPlatebniPrikaz,
  addPrispevek,
  addUdalost,
  addUrednideska,
  addUzivatel,
  deleteGalerie,
  deleteKategorie,
  deletePrispevky,
  deleteUdalosti,
  deleteUredniDesky,
  addStranka,
  deleteStranky,
  deleteStrankaObsah,
  deleteMedia,
  addEmail,
  addSms,
  addTransakce,
  deleteNavigace
} from '@nx-monorepo/obce/common/mutations';
import {catchError, map} from 'rxjs/operators';
import {FuseProgressBarService} from "@fuse/components/progress-bar/progress-bar.service";
import {throwError} from "rxjs";
import { replaceSpecialChar } from '@nx-monorepo/obce/common/utils';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class KolekceService {
  constructor(private apollo: Apollo, private _fuseProgressBarService: FuseProgressBarService, private router: Router,
  ) {
  }

  /* SELECT */
  private getDataQueryForType(entityType: EntityTypes, selected: boolean) {
    switch (entityType) {
      case EntityTypes.Uzivatel:
        return selected ? readSelectedUzivatelQuery : readAllUzivatelQuery;
      case EntityTypes.Prispevek:
        return selected ? readSelectedPrispevekQuery : readAllPrispevekQuery;
      case EntityTypes.Udalost:
        return selected ? readSelectedUdalostQuery : readAllUdalostQuery;
      case EntityTypes.Stranka:
        return selected ? readSelectedStrankaQuery : readAllStrankaQuery;
      case EntityTypes.Evidence:
        return selected ? readSelectedEvidenceQuery : readAllEvidenceQuery;
      case EntityTypes.Formular:
        return selected ? readSelectedFormularQuery : readAllFormularQuery;
      case EntityTypes.UredniDeska:
        return selected ? readSelectedUredniDeskaQuery : readAllUredniDeskaQuery;
      case EntityTypes.Media:
        return selected ? null : readAllMediaQuery;
      case EntityTypes.Navigace:
        return selected ? readSelectedNavigaceQuery : readAllNavigaceQuery;
      case EntityTypes.Galerie:
        return selected ? readSelectedGalerieQuery : readAllGalerieQuery;
      case EntityTypes.Kontakt:
        return selected ? readSelectedKontaktQuery : readAllKontaktQuery;
      case EntityTypes.PlatebniPrikaz:
        return selected ? readSelectedPlatebniPrikazQuery : readAllPlatebniPrikazQuery;
      case EntityTypes.Email:
        return selected ? readSelectedEmailQuery : readAllEmailQuery;
      case EntityTypes.Sms:
        return selected ? readSelectedSmsQuery : readAllSmsQuery;
      case EntityTypes.Transakce:
        return selected ? readSelectedTransakceQuery : readAllTransakceQuery;
      default:
        // fixme
        return readAllPrispevekQuery;
    }
  }

  /* UPSERT */
  private getAddMutationFor(entityType: EntityTypes) {
    switch (entityType) {
      case EntityTypes.Prispevek:
        return addPrispevek;
      case EntityTypes.Udalost:
        return addUdalost;
      case EntityTypes.UredniDeska:
        return addUrednideska;
      case EntityTypes.Navigace:
        return addNavigace;
      case EntityTypes.Stranka:
        return addStranka;
      case EntityTypes.Uzivatel:
        return addUzivatel;
      case EntityTypes.Formular:
        return addFormular;
      case EntityTypes.Evidence:
        return addEvidence;
      case EntityTypes.Media:
        return addMedia;
      case EntityTypes.Galerie:
        return addGalerie;
      case EntityTypes.Kontakt:
        return addKontakt;
      case EntityTypes.Historie:
        return addHistorie;
      case EntityTypes.PlatebniPrikaz:
        return addPlatebniPrikaz;
      case EntityTypes.Email:
        return addEmail;
      case EntityTypes.Sms:
        return addSms;
      case EntityTypes.Transakce:
        return addTransakce;
      default:
        return;
    }
  }

  /* DELETE */
  private getDeleteMutationFor(entityType: EntityTypes) {
    switch (entityType) {
      case EntityTypes.Prispevek:
        return deletePrispevky;
      case EntityTypes.Galerie:
        return deleteGalerie;
      case EntityTypes.Udalost:
        return deleteUdalosti;
      case EntityTypes.UredniDeska:
        return deleteUredniDesky;
      case EntityTypes.Stranka:
        return deleteStranky;
      case EntityTypes.Navigace:
        return deleteNavigace;
      case EntityTypes.StrankaObsah:
        return deleteStrankaObsah;
      case EntityTypes.Media:
        return deleteMedia;
      case EntityTypes.Kategorie:
        return deleteKategorie;
      default:
        return;
    }
  }

  public getSingleQuery(entityType: EntityTypes) {
    return this.getDataQueryForType(entityType, true);
  }

  public fetchAll(
    entityType: EntityTypes,
    search?: string,
    page?: number,
    take?: number,
    sortColumn?,
    sortDirection?,
    where?: {},
    categories?: number[]
  ) {
    const variables = {
      search: search,
      page: page,
      take: take,
      where: where,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      categories: categories
    };
    //console.log("Fetching all", entityType, variables);
    this._fuseProgressBarService.show();

    const query = this.apollo.query({
      query: this.getDataQueryForType(entityType, false),
      variables: variables
    }).pipe(map(val => {
      this._fuseProgressBarService.hide();
      return val;
    }));
    //console.log(query, query.toPromise().then(resp => console.log("RESP", resp)));
    return query;
  }

  public getResponseFromItemsPromise(itemsPromise: Promise<any>): Promise<any> {
    return itemsPromise.then(obj => obj.data.response);
  }

  public fetchSingle(entityType: EntityTypes, id: number, redirect404: boolean = true) {
    this._fuseProgressBarService.show();

    return this.apollo.query<{ response: any[] }>({
      query: this.getDataQueryForType(entityType, true),
      variables: {
        ids: [id]
      }
    }).pipe(map((value) => {
      this._fuseProgressBarService.hide();
      if (value.data.response.length > 0) {
        return value.data.response[0];
      } else {
        // if (redirect404) {
        //   this.router.navigateByUrl('/404');
        // } else {
        //   return null;
        // }
      }
    }));
  }

  public fetchSelected(entityType: EntityTypes, ids: number[]) {
    this._fuseProgressBarService.show();
    return this.apollo.query<{ response: any[] }>({
      query: this.getDataQueryForType(entityType, true),
      variables: {
        ids: ids
      }
    }).pipe(map(value => {
      this._fuseProgressBarService.hide();
      return value.data.response
    }));
  }

  public save(entityType: EntityTypes, vars: any) {
    this._fuseProgressBarService.show();
    const transformFn = this.getTransformFunction(entityType);
    return this.apollo.mutate({
      mutation: this.getAddMutationFor(entityType),
      variables: transformFn ? transformFn(vars) : vars
    }).pipe(
      map(val => {
        this._fuseProgressBarService.hide();
        return val;
      }),
      catchError(err => {
        this._fuseProgressBarService.hide();
        return throwError(err);
      })
    )
  }

  public executeMutation(mutation: any, vars: any) {
    return this.apollo.mutate({
      mutation: mutation,
      variables: vars
    });
  }

  public deleteData(entityType: EntityTypes, ids: number[]) {
    return this.apollo.mutate({
      mutation: this.getDeleteMutationFor(entityType),
      variables: {
        ids: ids
      }
    });
  }

  private getTransformFunction(entityType: EntityTypes){
    // email ma dodatecne i normalni prijemce - tato funkce je normalizuje
    if(entityType === EntityTypes.Email){
      const transformFn = (rows) => {
        console.log(rows);
        const newInput = rows.input.map(row => {
          let newprijemci = [];
          if (row.prijemci && Array.isArray(row.prijemci)) {
              row.prijemci.map(prijemce => {
              if('email' in prijemce && prijemce.email){
                //pokud je to objekt a ma nastaveny email
                newprijemci.push(prijemce.email);
              } else if(typeof prijemce === "string") {
                newprijemci.push(prijemce);
              } else {
                //objekt co nema nastaveny email
                console.log("Neplatný záznam - nemá email");
              }
              });
          }
          if(row.dodatecniPrijemci && Array.isArray(row.dodatecniPrijemci)){
            row.dodatecniPrijemci.map(dodPrij =>
              newprijemci = newprijemci.concat(dodPrij.nazev)
            )
          }
          row.dodatecniPrijemci = undefined;
          row.prijemci = newprijemci.join(",");
          return row;
        });
        return {input: newInput};
      }
      return transformFn;
    }
    // SMS ma dodatecne i normalni prijemce - tato funkce je normalizuje
    if (entityType === EntityTypes.Sms) {
      const transformFn = (rows) => {
        const newInput = rows.input.map(row => {
          let newprijemci = [];
          const newText = replaceSpecialChar(row.text);
          if (row.prijemci) {
          row.prijemci.map(prijemce => {
            if('telefon' in prijemce && prijemce.telefon){
              //pokud je to objekt a ma nastaveny telefon
              newprijemci.push(prijemce.telefon.replace(' ', ''));
            } else if(typeof prijemce === "string") {
              newprijemci.push(prijemce.replace(' ', ''));
            } else {
              console.log("Neplatný záznam - uživatel bez telefonu")
            }
          });
        }
          if(row.dodatecniPrijemci){
            row.dodatecniPrijemci.map(dodPrij =>
              newprijemci = newprijemci.concat(dodPrij.nazev.replace(' ', ''))
            )
          }
          row.dodatecniPrijemci = undefined;
          row.prijemci = Array.from(new Set(newprijemci)).join(",");
          row.text = newText;
          return row;
        });
        return {input: newInput};
      }
      return transformFn;
    }
  }

}
