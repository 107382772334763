import { AuthState } from './auth.state';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

export const getLastAuthMessage = (state: AuthState) => state.last_auth_message;
export const getUsername = (state: AuthState) => state.username;
export const getUserId = (state: AuthState) => state.user_id;

export const selectAuthState: MemoizedSelector<object, AuthState> = createFeatureSelector<AuthState>('auth');

export const selectLastAuthMessage: MemoizedSelector<object, string> = createSelector(
  selectAuthState,
  getLastAuthMessage
);

export const selectUsername: MemoizedSelector<object, string> = createSelector(
  selectAuthState,
  getUsername
);

export const selectUserId: MemoizedSelector<object, number> = createSelector(
  selectAuthState,
  getUserId
);
