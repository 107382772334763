import { Validators } from '@angular/forms';
import { PkValidator } from '@nx-monorepo/ng-shared';
import { ROLE_SERVICE_TOKEN } from '@nx-monorepo/obce/ng/services';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { IPkHiddenFieldProps, IPkInputTextFieldProps, IPkInputDateFieldProps, IPkSelectFieldProps } from '@nx-monorepo/cms-base/components';

export const uzivatelEntityDefinition: IEntityDefinition[] = [
  {
    componentName: 'PkHiddenFieldComponent',
    settings: {
      type: 'number',
      formControlName: 'id',
      defaultValue: 0
    } as IPkHiddenFieldProps,
    data: null,
    validators: [],
    cols: 0,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Titul',
      povinnost: false,
      isTextArea: false,
      formControlName: 'titul',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [],
    cols: 1,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Jméno',
      povinnost: true,
      isTextArea: false,
      formControlName: 'jmeno',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 2,
    x: 0,
    y: 1
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Příjmení',
      povinnost: true,
      isTextArea: false,
      formControlName: 'prijmeni',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 2,
    x: 2,
    y: 1
  },
  {
    componentName: 'PkInputDateFieldComponent',
    settings: {
      nazev: 'Datum narození',
      povinnost: true,
      napoveda: {
        text: 'Zadejte datum narození ve formátu dd.mm.yyyy',
        isIcon: true
      },
      formControlName: 'birthday'
    } as IPkInputDateFieldProps,
    validators: [Validators.required, PkValidator.validatorBirthDateFactory()],
    data: null,
    cols: 2,
    x: 0,
    y: 2
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Rodné číslo',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Zadejte rodné číslo (bez lomítka)'
      },
      isTextArea: false,
      formControlName: 'rodnecislo',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    validators: [PkValidator.validatorRCFactory()],
    data: null,
    cols: 2,
    x: 2,
    y: 2
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Ulice',
      povinnost: true,
      isTextArea: false,
      formControlName: 'ulice',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 2,
    x: 0,
    y: 3
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'PSČ',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Zadejte PSČ (bez mezery)'
      },
      isTextArea: false,
      formControlName: 'psc',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required, Validators.pattern('\\d{3}\\d{2}')],
    cols: 1,
    x: 2,
    y: 3
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Město',
      povinnost: true,
      isTextArea: false,
      formControlName: 'mesto',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 1,
    x: 3,
    y: 3
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Telefon',
      povinnost: false,
      napoveda: {
        isIcon: true,
        text: 'Zadejte telefonní číslo bez mezer'
      },
      isTextArea: false,
      formControlName: 'telefon',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.pattern('^[+]?[()/0-9. -]{9,}$')],
    cols: 1,
    x: 0,
    y: 4
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Email',
      povinnost: false,
      napoveda: {
        isIcon: true,
        text: 'Slouží pro přihlášení do systému'
      },
      isTextArea: false,
      formControlName: 'email',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.pattern('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])')],
    cols: 1,
    x: 1,
    y: 4
  },
  {
    componentName: 'PkSelectFieldComponent',
    settings: {
      nazev: 'Role',
      povinnost: true,
      formControlName: 'uzivatel_role_id',
      isMultiple: false,
      service: {
        token: ROLE_SERVICE_TOKEN,
        fetchAllArgs: {},
        fetchSingleArgs: {}
      }
    } as IPkSelectFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 2,
    x: 2,
    y: 0
  }
];
