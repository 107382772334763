import { Validators } from '@angular/forms';
import { PkValidator } from '@nx-monorepo/ng-shared';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { uzivateleEmailColumnDefinitions} from '../column-definitions';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { IPkHiddenFieldProps, IPkInputTextFieldProps, IPkInputWysiwygProps, IPkInputEntityFieldProps, IPkInputChipsFieldProps } from '@nx-monorepo/cms-base/components';

export const EmailEntityDefinition: IEntityDefinition[] = [
    {
        componentName: 'PkHiddenFieldComponent',
        settings: {
          type: 'number',
          formControlName: 'id',
          defaultValue: 0
        } as IPkHiddenFieldProps,
        data: null,
        validators: [],
        cols: 0,
        x: 0,
        y: 0
      },
    {
        componentName: 'PkInputTextFieldComponent',
        settings: {
            nazev: 'Předmět emailu',
            povinnost: true,
            isTextArea: false,
            formControlName: 'predmet',
            defaultValue: '',
            type: 'text',
            maxLength: 74,
        } as IPkInputTextFieldProps,
        data: null,
        validators: [Validators.required, PkValidator.validatorLengthFactory(78, 'max')],
        cols: 4,
        x: 0,
        y: 0,
    },
    {
        componentName: 'PkInputWysiwygComponent',
        settings: {
            nazev: 'Text emailu',
            povinnost: true,
            formControlName: 'text',
            defaultValue: '',
            type: 'text'
        } as IPkInputWysiwygProps,
        data: null,
        validators: [Validators.required],
        cols: 4,
        x: 0,
        y: 1
    },
    {
        componentName: 'PkInputEntityFieldComponent',
        settings: {
            nazev: 'Příjemci',
            povinnost: false,
            napoveda: {
                isIcon: false,
                text: 'Vyberte příjemce'
            },
            overrideColumnDefinitions: uzivateleEmailColumnDefinitions,
            isMultiple: true,
            entityType: EntityTypes.Uzivatel,
            formControlName: 'prijemci',
            defaultValue: '',
        } as IPkInputEntityFieldProps,
        data: [],
        validators: [],
        cols: 4,
        x: 0,
        y: 2
    },
    {
        componentName: 'PkInputChipsFieldComponent',
        settings: {
            nazev: 'Dodateční příjemci',
            povinnost: true,
            formControlName: 'dodatecniPrijemci',
            napoveda: {
            isIcon: true,
            text: 'Zadejte emailovú adresu'
            }
        } as IPkInputChipsFieldProps,
        data: [],
        validators: [PkValidator.validatorForChipEmail()],
        cols: 4,
        x: 0,
        y: 3,
    },
]
