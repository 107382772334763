export * from "./change-password-page/pk-change-password-page.component";
export * from "./pk-category-settings-page/pk-category-settings-page.component";
export * from "./pk-dashboard-page/pk-dashboard-page.component";
export * from './pk-data-table-page/pk-data-table-page.component';
export * from './pk-gridster-page/pk-form-gridster-page/pk-form-gridster-page.component';
export * from './pk-gridster-page/pk-frontend-gridster-page/pk-frontend-gridster.component';
export * from './pk-help-owner-page/pk-owner-settings-page.component';
export * from './pk-help-owner-video-page/pk-video-help-page.component';
export * from './pk-help-user-page/pk-help-user-page.component';
export * from './pk-media-page/pk-media-page.component';
export * from './pk-navigation-page/pk-navigation-page.component';
export * from './pk-new-entity-page/pk-new-entity-page.component';
export * from './pk-new-formular-page/pk-new-formular.component';
export * from './pk-new-kontakt-page/pk-new-kontakt-page.component';
export * from './pk-new-platebni-prikazy-page/pk-new-platebni-prikazy-page.component';
export * from './pk-owner-settings-page/pk-owner-settings-page.component';
export * from './pk-platebni-prikazy-nastaveni-page/pk-platebni-prikazy-nastaveni-page.component';
export * from './pk-preview-platebni-prikazy-page/pk-preview-platebni-prikazy-page.component';
export * from './pk-upload-page/pk-upload-page.component';
export * from './pk-vstupni-pole-form/pk-vstupni-pole-form.component';
export * from './pk-vyuctovani-page/pk-vyuctovani.component';
export * from './pk-change-profile-page/pk-change-profile-page.component';
export * from './pk-help-helpdesk-page/pk-help-helpdesk-page.component';
export * from './page.module';

export * from './pk-new-kontakt-page/pk-new-kontakt-page.component';
export * from './pk-new-entity-page/pk-new-entity-page.component';
export * from './pk-platebni-prikazy-nastaveni-page/pk-platebni-prikazy-nastaveni-page.component';
export * from './base-page.component';
//export * from '../../../helpers/routing/page-settings.provider';
