import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule, GridModule } from '@angular/flex-layout';
import { MomentModule } from 'ngx-moment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HeadingModule } from '../heading/heading.module';
import { NextButtonModule } from '../next-button/next-button.module';
import { TwoColumnUredniDeskyComponent } from './two-column/two-column.component';
import { CompactUredniDeskyComponent } from './compact/compact.component';
import { UtilsModule } from '../../utils/utils.module';
import { RouterModule } from '@angular/router';
import { ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@NgModule({
  imports: [CommonModule, GridModule, MomentModule, FlexModule, FontAwesomeModule, HeadingModule, NextButtonModule, UtilsModule, RouterModule],
  exports: [CompactUredniDeskyComponent, TwoColumnUredniDeskyComponent],
  entryComponents: [CompactUredniDeskyComponent, TwoColumnUredniDeskyComponent],
  providers: [ColorUtilsService],
  declarations: [CompactUredniDeskyComponent, TwoColumnUredniDeskyComponent]
})
export class UredniDeskyModule {}
