import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ColorUtilsService } from '@nx-monorepo/obce/ng/services/color-utils.service';

@Directive({
  selector: '[macmsColor]'
})
export class MacmsColorDirective implements AfterViewInit, OnChanges{

  @Input() mc_isInverted = false;
  @Input() mc_useTransparent = true;
  @Input() mc_underTransparentColor = '#fff';
  @Input() mc_lighten: number;
  @Input() mc_lightnessThreshold = 0.7;
  @Input() mc_contrastThreshold = 7;
  @Input() mc_wantedPrimary: string;
  @Input() mc_otherColors: string[] = [];


  constructor(private el: ElementRef, private colorUtils: ColorUtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.mc_wantedPrimary){
      this.calculateNewColors();
    }
  }

  calculateNewColors(){
    if(this.mc_isInverted){

      this.el.nativeElement.style.backgroundColor = this.mc_wantedPrimary;
      this.el.nativeElement.style.color = this.colorUtils.decideForegroundColor(this.mc_wantedPrimary, this.mc_contrastThreshold, ...this.mc_otherColors);

    } else {

      // find some bright color
      let brightColor = '#fff';

      if(this.mc_useTransparent){
        if(this.mc_underTransparentColor) {
          brightColor = this.mc_underTransparentColor;
        }
      }

      if(this.mc_lighten && !this.mc_useTransparent){
        brightColor = this.colorUtils.setLightness(this.mc_wantedPrimary, this.mc_lighten);
      }

      if(!this.mc_useTransparent) {
        this.mc_otherColors.forEach(color => {
          if (color) {

            if (this.colorUtils.decideLuminosity(color) > this.mc_lightnessThreshold) {
              brightColor = color;
              return false;
            }
          }
        });
      }

      if(this.mc_useTransparent){
        this.el.nativeElement.style.backgroundColor = '#ffffff00';
      } else {
        this.el.nativeElement.style.backgroundColor = brightColor;
      }

      this.el.nativeElement.style.color = this.colorUtils.decideForegroundColor(brightColor, this.mc_contrastThreshold, this.mc_wantedPrimary);
    }
  }

  ngAfterViewInit(): void {
    this.calculateNewColors();
  }
}
