<!--Header-->
<section [style.background]="settings.sectionBackground | fixBackgroundUrl"
         [ngClass]="{limited: settings.isWrapperLimited}">
    <div [ngClass]="{headerWrapper: true, narrow: settings.isNarrow}"
         [style.background]="settings.wrapperBackground | fixBackgroundUrl"
         [style.minHeight]="settings.headerMinHeight"
    >
        <!--LEFT BAR-->
        <ng-container *ngIf="settings.leftSlot as left">
            <div [ngClass]="{leftWrapper: true, absolute: left.isAbsolute, bottomBoxExpanded: left.takeAllSpace }">
                <div
                        [ngClass]="{
          left: true,
          expandHeight: left.takeAllSpace,
          contentCentered: left.centerContent,
          sharp: left.after === 'sharp',
          round: left.after === 'round',
           absolute: left.isAbsolute,
           horizontal: settings.leftSlot.direction === 'row'
         }" [style.background]="settings.leftSlot.background | fixBackgroundUrl"
                >
                    <ng-container class="search-wrap" *ngIf="true">
                        <macms-searchbar></macms-searchbar>
                    </ng-container>

                    <div *ngIf="settings.leftSlot.logo && settings.leftSlot.isLogoFirst" class="img-wrapper">
                        <img [src]="settings.leftSlot.logo" alt="{{settings?.leftSlot.subtitle}}">
                    </div>
                        <div
                                [routerLink]="'/'"
                                class="clickable"
                                macmsColor
                                [mc_underTransparentColor]="left.underTransparentColor ? left.underTransparentColor : left.background">
                            <h1 [style.fontFamily]="obecSettings.title_font"
                                [style.fontSize]="settings.leftSlot.headerFontSize">{{settings?.leftSlot.title}}</h1>
                            <p [style.fontFamily]="obecSettings.title_font"
                               [style.fontSize]="settings.leftSlot.paragraphFontSize">{{settings?.leftSlot.subtitle}}</p>
                        </div>
                        <div [routerLink]="'/'" *ngIf="settings.leftSlot.logo && !settings.leftSlot.isLogoFirst" class="img-wrapper clickable">
                            <img [src]="settings.leftSlot.logo" alt="{{settings?.leftSlot.subtitle}}">
                        </div>
                        <ng-container class="searchWrapper" *ngIf="settings?.leftSlot.searchBarPosition === 'end'">
                            <macms-searchbar></macms-searchbar>
                        </ng-container>
                </div>
            </div>
        </ng-container>
        <div class="right">
            <ng-container *ngIf="settings.topSlot as top">
                <div class="top"
                     [style.background]="top.background | fixBackgroundUrl" [borderDefinition]="top.borders">
                    <ng-container
                            *ngTemplateOutlet="horizontalBar;context:{bardef: top, settings: topBarNavSettings, data: topNavigaceData}"></ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="settings.middleSlot as middle">
                <div class="middle" [style.maxHeight]="middle.maxHeight" [style.minHeight]="middle.minHeight">
                    <div class="narrow contentWrapper">
                        <div class="content">
                            <macms-kontakt
                                    [settings]="middle.kontakt"
                            ></macms-kontakt>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="settings.bottomSlot as bottom">
                <div class="bottom"
                     [style.background]="bottom.background | fixBackgroundUrl" [borderDefinition]="bottom.borders">
                    <ng-container
                            *ngTemplateOutlet="horizontalBar;context:{bardef: bottom, settings: bottomBarNavSettings, data: bottomNavigaceData}"></ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<ng-template #horizontalBar let-bardef="bardef" let-settings="settings" let-data="data">
    <div class="narrow contentWrapper">
        <div class="content" [style.justifyContent]="bardef.flexAlign">
            <div class="partialWrapper">
                <ng-container [ngSwitch]="bardef.type">
                    <ng-container *ngSwitchCase="'custom'">
                        <div class="customBar" [style.background]="bardef.background | fixBackgroundUrl"
                             [borderDefinition]="bardef.borders"></div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'holiday'">
                        <macms-svatek [settings]="bardef.svatek">

                        </macms-svatek>
                    </ng-container>
                    <ng-container *ngSwitchCase="'nav'">
                        <ng-container *ngIf="data && settings">
                            <macms-header-nav [data]="data" [settings]="settings"></macms-header-nav>
                        </ng-container>
                    </ng-container>

                </ng-container>
            </div>
            <div class="partialWrapper" *ngIf="bardef.with_searchbar">
                <macms-searchbar></macms-searchbar>
            </div>
        </div>
    </div>
</ng-template>

