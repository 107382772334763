import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
// noinspection TypeScriptPreferShortImport
import { BasePageComponent } from '../base-page.component';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, ActionsSubject, createAction, Store } from '@ngrx/store';
import { EntityTypes, PlatbaStav, TransakceStav } from '@nx-monorepo/obce/common/enums';
import { Token } from '@nx-monorepo/api-base/lib/interfaces/i-token';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ObecService, KolekceService } from '@nx-monorepo/obce/ng/services';
import { displaySnack } from '@nx-monorepo/cms-base/helpers';
import { MACMSStoreState, LayoutStoreActions } from '@nx-monorepo/cms-base/store';
import { IPkInputDateFieldProps, IPkInputTextFieldProps, IPkSelectFieldProps } from '@nx-monorepo/cms-base/components';
import { PkDialogComponent } from '@nx-monorepo/cms-base/components/pk-dialog/pk-dialog.component';
import { AuthService } from '@nx-monorepo/cms-base/services';
import { IPkDialogData } from '@nx-monorepo/cms-base/components/pk-dialog/pk-dialog-data';
import { Apollo } from 'apollo-angular';
import { checkPaymentStatusQuery } from '@nx-monorepo/obce/common/queries';
import { addTransakce } from '@nx-monorepo/obce/common/mutations';
import * as moment from 'moment';

const REVERSE_ACTION = createAction('[PlatebniPrikaz] Reverse', (payload: {}) => ({payload }))({});
const ODVOLAT_ACTION = createAction('[PlatebniPrikaz] Odvolat', (payload: {}) => ({ payload }))({});
const DOKONCIT_ACTION = createAction('[PlatebniPrikaz] Dokoncit', (payload: {}) => ({ payload }))({});
const ZAPLATIT_ACTION = createAction('[PlatebniPrikaz] Zaplatit', (payload: {}) => ({ payload }))({});
const CHECK_STATUS_ACTION = createAction('[PlatebniPrikaz] Overit', (payload: {}) => ({ payload }))({});

@Component({
  selector: 'pk-preview-platebni-prikazy-page',
  templateUrl: './pk-preview-platebni-prikazy-page.component.html',
  styleUrls: ['./pk-preview-platebni-prikazy-page.component.scss'],
  animations: fuseAnimations
})
export class PkPreviewPlatebniPrikazyPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly userToken: Token;
  public platebniPrikaz$: Subject<{castka: number, variabilni_symbol: string}> = new Subject();
  public obecInfo$: Promise<any>;
  public qrData$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private dialogRef: MatDialogRef<PkDialogComponent, string>;
  private dialogSubmit = new EventEmitter<any>();
  private dialogClose = new EventEmitter<void>();

  public platbaTypes = PlatbaStav;

  constructor(store$: Store<MACMSStoreState.State>,
              currentRoute: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private obecService: ObecService,
              private actionsSubject$: ActionsSubject,
              private kolekceService: KolekceService,
              public dialog: MatDialog,
              private _fuseProgressBarService: FuseProgressBarService,
              private apollo: Apollo) {
    super(store$, currentRoute);
    this.userToken = this.authService.getTokenData();
  }

  ngOnInit(): void {
    const formId = Number(this.currentRoute.snapshot.paramMap.get('id'));
    if (formId) {
      // get obec settings for IBAN
      this.obecInfo$ = this.obecService.getObceSettingsPromise(this.obecService.getObecPromise());

      // get prikaz data
      const prikazPromise = this.kolekceService.fetchSingle(this.routeData.entityType, formId).toPromise();
      prikazPromise
        .then((prikaz: any) => {
          // emit next
          if (prikaz){
            this.platebniPrikaz$.next(prikaz);
          } else {
            displaySnack('Nepodařilo se načíst platební příkaz', this.store$);
          }
        })
        .catch((err: Error) => {
          console.log('Failed to load prikaz', err);
          displaySnack('Nepodařilo se načíst platební příkaz', this.store$);
        });

        this.platebniPrikaz$.subscribe((prikaz) => {
          Promise.all([this.obecInfo$]).then(([obecInfo]) => {
            const payGate = obecInfo.payment_settings ? obecInfo.payment_settings.gate : null;
            this.setupLayoutButtons(prikaz, payGate);
            const qrData = `SPD*ACC:${obecInfo.info_settings.iban}*AM:${prikaz.castka}*CC:CZK*X-VS:${prikaz.variabilni_symbol}`;
            this.qrData$.next(qrData);
            this._fuseProgressBarService.hide();
          })
          .catch(err => displaySnack('Chyba platebního příkazu', this.store$))
        })
    }
  }

  private setupLayoutButtons(prikaz, payGate): void {
    this.subscribeToFormActions(prikaz);
    this.subscribeToDialogSubmit(prikaz);
    this.subscribeToDialogClose();
    const payload = {
      buttons: []
    };
    // if user is obcan, give him some powers
    if (this.userToken.role.pravomoce.canManagePayments) {
      if (prikaz.stav === PlatbaStav.WAITING || prikaz.stav === PlatbaStav.OVERDUE) {
        payload.buttons = [
          { id: 1, nazev: 'Odvolat', action: ODVOLAT_ACTION },
          { id: 2, nazev: 'Dokončit', action: DOKONCIT_ACTION },
        ]; 
      }
      if (payGate) {
        if (prikaz.stav === PlatbaStav.SUCCESS) {
          payload.buttons = [{id: 1, nazev: 'Vrátit platbu', action: REVERSE_ACTION}];
        } else if (prikaz.stav !== PlatbaStav.CANCELED){
          payload.buttons.push({ id: 3, nazev: 'Ověřit stav', action: CHECK_STATUS_ACTION});
        }
      }

    } else {
        if (payGate && prikaz.stav !== PlatbaStav.CANCELED && prikaz.stav !== PlatbaStav.SUCCESS) {
          payload.buttons = [
            {id: 1, nazev: 'Zaplatit', action: ZAPLATIT_ACTION}
          ]
        }
    }
    this.store$.dispatch(new LayoutStoreActions.SetLayoutButtonsAction(payload));
  }

  private subscribeToDialogClose() {
    this.dialogClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  private subscribeToDialogSubmit(prikaz) {
    this.dialogSubmit.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      const {datum_zaplaceni, id_transakce, zpusob_zaplaceni} = result;

      this.apollo.mutate({
        mutation: addTransakce,
        variables: {
                  input: {
                    platebni_prikaz_id: prikaz.id,
                    manual: true,
                    kod_transakce: id_transakce,
                    zpusob_zaplaceni: zpusob_zaplaceni
                  }}
      }).toPromise()
      .then(res => {
        this.dokoncit(prikaz, datum_zaplaceni, zpusob_zaplaceni); 
        this.dialogRef.close()
      })
      .catch(err => {console.log(err);throw Error("Chyba vytvoření transakce.")})
    });
  }

  private openDialogForDate() {
    if (this.dialogRef == null) {
      const dialogData: IPkDialogData = {
        title: 'Vyplňte údaje o zaplacení',
        components: [
          {
            componentName: 'PkInputDateFieldComponent',
            settings: {
              formControlName: 'datum_zaplaceni',
              nazev: 'Datum zaplacení',
              povinnost: true,
              defaultValue: new Date()
            } as IPkInputDateFieldProps,
            data: null,
            validators: [Validators.required],
            cols: 1,
            x: 0,
            y: 0
          },
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
                nazev: 'Identifikátor transakce',
                povinnost: true,
                isTextArea: false,
                formControlName: 'id_transakce',
                defaultValue: '',
                type: 'text',
                napoveda: {
                  isIcon: true,
                  text: "Zadejte např. číslo daňového dokladu"
                }
            } as IPkInputTextFieldProps,
            data: null,
            validators: [Validators.required],
            cols: 1,
            x: 0,
            y: 1,
        },
        {
          componentName: 'PkSelectFieldComponent',
          settings: {
            nazev: 'Způsob zaplacení',
            povinnost: true,
            formControlName: 'zpusob_zaplaceni',
            isMultiple: false,
          } as IPkSelectFieldProps,
          data: [
            {
              id: 'platebni_karta',
              nazev: 'Platební kartou'
            },
            {
              id: 'hotovost',
              nazev: 'Hotovostí'
            },
            {
              id: 'bankovni_prevod',
              nazev: 'Bankovním převodem'
            }
          ],
          validators: [Validators.required],
          cols: 1,
          x: 0,
          y: 2
        },
        ],
        buttons: [
          {
            color: 'warn',
            text: 'Zrušit',
            action: this.dialogClose
          },
          {
            color: 'primary',
            text: 'Potvrdit',
            shouldValidateForm: true,
            action: this.dialogSubmit
          }
        ]
      };

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = dialogData;

      this.dialogRef = this.dialog.open(PkDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.dialogRef = null;
      });
    }
  }


  private subscribeToFormActions(prikaz) {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) =>
        (action === ODVOLAT_ACTION || action === DOKONCIT_ACTION || action === ZAPLATIT_ACTION || action === CHECK_STATUS_ACTION)
      )
    ).subscribe((action: any) => {
      switch (action) {
        case ODVOLAT_ACTION:
          this.odvolatPrikaz(prikaz);
          break;
        case ZAPLATIT_ACTION:
          this.zaplatitPrikaz(prikaz);
          break;
        case REVERSE_ACTION:
          this.reversePrikaz(prikaz);
          break;
        case DOKONCIT_ACTION:
          this.openDialogForDate();
          break;
        case CHECK_STATUS_ACTION:
          this.checkPaymentStatus(prikaz);
      }
    });
  }

  ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();

    this.qrData$.unsubscribe();
    this.platebniPrikaz$.unsubscribe();
  }

  private dokoncit(prikaz: any, datum_zaplaceni, zpusob_zaplaceni: string): void {

    const payload = {
      input: {
        id: prikaz.id,
        zaplaceno_at: datum_zaplaceni,
      }
    };

    this.kolekceService.save(this.routeData.entityType, payload).toPromise()
      .then(_ => this.onDokoncitSuccess(prikaz))
      .catch(err => this.onDokoncitError(err));
  }

  private odvolatPrikaz(prikaz: any): void {
    const odvolano = new Date();

    const payload = {
      input: {
        id: prikaz.id,
        odvolano_at: odvolano
      }
    };

    this.kolekceService.save(this.routeData.entityType, payload).toPromise()
      .then(_ => this.onOdvolatSuccess(prikaz))
      .catch(err => this.onOdvolatError(err));
  }

  private zaplatitPrikaz(prikaz: any): void {
    const payload = {
      input: {
        platebni_prikaz_id: prikaz.id
      }
    }

    this.kolekceService.save(EntityTypes.Transakce, payload).toPromise()
      .then((res: any) => {
        if (res.data.response[0].url) {
          (window as any).location = res.data.response[0].url;
        } else {
          displaySnack('Chyba platebního příkazu: nemožno zaplatit!', this.store$);
        }
      })
      .catch(err => {console.log(err); this.onDokoncitError(err)})
  }

  private reversePrikaz(prikaz: any): void {
    const payload = {
      input: {
        platebni_prikaz_id: prikaz.id,
      }
    }

    this.kolekceService.save(EntityTypes.Transakce, payload).toPromise()
    .then((res: any) => this.onReverseSuccess(res))
    .catch(err => this.onReverseError(err))
  }

  private onOdvolatSuccess(prikaz: any): void {
    // display message
    displaySnack('Platební příkaz byl úspěšně odvolán', this.store$);

    // emit new prikaz data
    const newPrikaz = {
      ...prikaz,
      stav: PlatbaStav.CANCELED
    };

    this.platebniPrikaz$.next(newPrikaz);
  }

  private onDokoncitSuccess(prikaz: any): void {
    // display message
    displaySnack('Platební příkaz byl úspěšně označen za zaplacený', this.store$);

    // emit new prikaz data
    const newPrikaz = {
      ...prikaz,
      stav: PlatbaStav.SUCCESS,
      zaplaceno_at: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    };

    this.platebniPrikaz$.next(newPrikaz);
  }

  private onDokoncitError(err: Error): void {
    // console.log('odvolat failure', err);
    displaySnack('Platební příkaz se nepodařilo označit za zaplacený', this.store$);
  }

  private onOdvolatError(err: Error): void {
    // console.log('odvolat failure', err);
    displaySnack('Platební příkaz se nepodařilo odvolat', this.store$);
  }

  private onReverseSuccess(prikaz: any): void {
    displaySnack('Platební příkaz byl zadán k vrácení peněz', this.store$);
    const newPrikaz = {
      ...prikaz,
      stav: PlatbaStav.CANCELED
    };

    this.platebniPrikaz$.next(newPrikaz);
  }

  private onReverseError(err: Error) {
    displaySnack('Platební příkaz se nepodařilo vrátit', this.store$);
  }

  private checkPaymentStatus(prikaz: any) {
    let stav;
    this.apollo.query<{ response: any[] }>({
      query: checkPaymentStatusQuery,
      variables: {
        plat_prikaz_id: prikaz.id,
      }
    }).toPromise().then(result => {
      if (result.data.response.length > 0) { 
        stav = result.data.response[0].stav === TransakceStav.Založeno || TransakceStav.Zrušeno ? PlatbaStav.WAITING : result.data.response[0].stav;
        
        const newPrikaz = {
          ...prikaz,
          stav: stav
        }
        this.platebniPrikaz$.next(newPrikaz);
      } else {
        displaySnack("Transakce pro tento platební příkaz ještě nebyla založena.", this.store$)
      }
    })
    .catch(err => {displaySnack("Chyba ověření stavu platebního příkazu: " + err, this.store$)})
  }
}
