import { Component, Input, OnInit } from '@angular/core';
import { HeadingProps } from '../heading.props';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';

@Component({
  selector: 'nx-monorepo-test-heading',
  templateUrl: './test-heading.component.html',
  styleUrls: ['./test-heading.component.css']
})
export class TestHeadingComponent implements IDynamicComponent {

  //useless just for dynamicComponentFactory
  @Input() data: Promise<any> | any;
  @Input() settings: Promise<any> | any;


  headingGroups: [{
    title: string,
    popis: string,
    children: [{
      popis: string,
      props: HeadingProps
    }]
  }] = [
    //
    {
      title: 'Křtěnov',
      popis: '1 nadpis pro Křtěnov',
      children: [
        {
          popis: 'Základní komponenta pro Křtěnov',
          props: {
            isSlanted: true,
            isInverted: true,
            primaryColor: '#000F7E',
            boxMargin: { bottom: '1em', left: 'auto', right: 'auto', top: '1em' },
            icon: 'newspaper',
            font: 'Oswald'
          }
        }
      ]
    },
  ];

  getComponentName(): string {
    return 'TestHeadingComponent';
  }



}
