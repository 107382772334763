<div class="headingWrap" [style.background]="settings.wrapperBackground" [borderDefinition]="settings.headingWrapBorder" [marginDefinition]="localSettings.headingWrapMargin" [ngClass]='{limited: localSettings.limitMaxWidthToSection}'>
<div class="container" [marginDefinition]="settings.itemMargin" [ngClass]="{bottomBorder: localSettings.sideDecoration === 'bottom-border'}" [style.borderColor]="settings.primaryColor">
  <div class="box"
       [ngClass]='{box: true, lines: localSettings.sideDecoration === "lines"}'
       [borderDefinition]="localSettings.boxBorder"
       [marginDefinition]="localSettings.boxMargin"
       [paddingDefinition]="localSettings.boxPadding"
       [style.background-image]="boxBackground"
       [style.background]="boxBackground"
       [style.color]="textColor"
       [style.justify-content]="settings.boxContentJustify"
       [style.flexGrow]="settings.boxExpandToFullWidth ? 1 : 0"
       [style.flexDirection]="localSettings.iconPosition === 'start' ? 'row-reverse' : 'row'">
    <div class="title" [style.color]="textColor" [style.fontWeight]="localSettings.fontWeight" [style.fontFamily]="localSettings.font" [style.fontSize]="localSettings.fontSize" >{{text}}</div>
    <fa-icon *ngIf="localSettings.icon" class="icon" icon="{{localSettings.icon}}"></fa-icon>
  </div>
</div>
</div>
