import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { getSvatek } from '@nx-monorepo/obce/common/queries';

@Injectable({
  providedIn: 'root'
})
export class SvatekService {
  constructor(private apollo: Apollo) {}

  public getToday() {
    return this.apollo.query<{response}>({
      query: getSvatek
    });
  }
}
