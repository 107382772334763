<ng-container *ngIf="(activeCategory$ | async) as active">
  <ng-container *ngIf="active.id > 0; else noCategory">

    <ng-container *ngIf="kategorieSettings$ | async as kategorieSettings">
      <ng-container
        [ngTemplateOutlet]="kategorieSettings.hromadna ? hromadna : manualni"
        [ngTemplateOutletContext]="{kategorieSettings: kategorieSettings}">
      </ng-container>
    </ng-container>
  </ng-container>

</ng-container>

<!-- HROMADNY PRIKAZ -->
<ng-template #hromadna let-kategorieSettings="kategorieSettings">
  <form [formGroup]="form">
    <pk-input-date-field [settings]="{
    nazev: 'Datum splatnosti',
    formControlName:'splatnost',
    form: form,
    povinnost: true,
    defaultValue: ''
    }"></pk-input-date-field>

    <mat-table [dataSource]="uzivatele$ | async" formArrayName="platebni_prikazy" matSort>

      <!-- FIELD TYPES -->
      <ng-container
        *ngFor="let definition of kategorieSettings.columnDefinitions;"
        [ngSwitch]="definition.type"
        matColumnDef="{{ definition.key.join('.') }}">

        <!-- STRING COLUMN -->
        <ng-container *ngSwitchCase="'string'">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ definition.title }}
          </mat-header-cell>

          <mat-cell *matCellDef="let item; let rowIndex = index">
            <ng-container *ngIf="definition.controlName">
              <ng-container [formGroupName]="rowIndex">
                <mat-form-field>
                  <input matInput type="number" [formControlName]="definition.controlName"/>
                </mat-form-field>
              </ng-container>

            </ng-container>
            <ng-container *ngIf="!definition.controlName">
              <p class="text-truncate">
                {{ resolveKey(definition.key, item) }}
              </p>
            </ng-container>

          </mat-cell>
        </ng-container>

        <!-- INPUT AMOUNT COLUMN -->
        <ng-container *ngSwitchCase="'inputAmount'">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ definition.title }}
          </mat-header-cell>

          <mat-cell *matCellDef="let item; let rowIndex = index">
            <ng-container [formGroupName]="rowIndex">
              <ng-container formArrayName="polozky">
                <ng-container [formGroupName]="definition.groupName">
                  <mat-form-field>
                    <input matInput type="number" 
                      [formControlName]="definition.controlName" 
                      >
                  </mat-form-field>
                </ng-container>
              </ng-container>
            </ng-container>

          </mat-cell>
        </ng-container>
        <ng-container *ngSwitchCase="'invisible'">
          <!-- <ng-container [formGroupName]="definition.groupName">
             <input type="hidden" [formControlName]="definition.controlName">
           </ng-container>-->
        </ng-container>

        <!-- INPUT MONEY COLUMN -->
        <ng-container *ngSwitchCase="'inputMoney'">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ definition.title }}
          </mat-header-cell>

          <mat-cell *matCellDef="let item; let rowIndex = index">
            <ng-container [formGroupName]="rowIndex">
              <ng-container formArrayName="polozky">
                <ng-container [formGroupName]="definition.groupName">
                  <mat-form-field>
                    <input matInput type="text" 
                      [formControlName]="definition.controlName" 
                      currency
                      >
                  </mat-form-field>
                </ng-container>
              </ng-container>
            </ng-container>

          </mat-cell>
        </ng-container>
        <ng-container *ngSwitchCase="'invisible'">
          <!-- <ng-container [formGroupName]="definition.groupName">
             <input type="hidden" [formControlName]="definition.controlName">
           </ng-container>-->
        </ng-container>

         <!-- MONEY COLUMN -->
         <ng-container *ngSwitchCase="'money'">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ definition.title }}
          </mat-header-cell>

          <mat-cell *matCellDef="let item; let rowIndex = index">
            <ng-container *ngIf="definition.controlName">
              <ng-container [formGroupName]="rowIndex">
                <mat-form-field>
                  <input matInput type="text"
                    [formControlName]="definition.controlName"
                    currency
                    focusable="false"
                  >
                </mat-form-field>
              </ng-container>

            </ng-container>
            <ng-container *ngIf="!definition.controlName">
              <p class="text-truncate">
                {{ resolveKey(definition.key, item) }}
              </p>
            </ng-container>

          </mat-cell>
        </ng-container>

      </ng-container>

      <!-- HEADER ROW -->
      <mat-header-row
        *matHeaderRowDef="kategorieSettings.visibleColumns; sticky: true">
      </mat-header-row>

      <!-- TABLE ROW -->
      <mat-row
        *matRowDef="let item; columns: kategorieSettings.visibleColumns">
      </mat-row>

    </mat-table>
  </form>

  <p>
    Form Status: {{ form.status }}
  </p>
</ng-template>

<ng-template #manualni let-kategorieSettings="kategorieSettings">
  <ng-container *ngFor="let uzivatel of (uzivatele$ | async)">
    {{uzivatel.jmeno}}
  </ng-container>
</ng-template>

<!-- NO CATEGORY SELECTED MESSAGE -->
<ng-template #noCategory>
  <pk-no-category></pk-no-category>
</ng-template>
