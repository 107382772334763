import { Action } from '@ngrx/store';
import { IAction } from '@nx-monorepo/cms-base/interfaces';

export enum CategoryTabsActionTypes {
  FETCH_LAYOUT_CATEGORIES = '[CategoryTabs] Fetch Category Tabs',
  FINISH_FETCH_CATEGORIES_SUCCESS = '[CategoryTabs] Finish Fetch Categories - Success',
  FINISH_FETCH_CATEGORIES_ERROR = '[CategoryTabs] Finish Fetch Categories - Error',
  UPDATE_CATEGORY_AFTER_SAVE = '[CategoryTabs] Update Category After Save',
  SET_ACTIVE_CATEGORY = '[CategoryTabs] Set Active Category',
  SHOULD_REFETCH_CATEGORIES = '[CategoryTabs] ShouldRefetch Categories',
  CREATE_CATEGORY = '[CategoryTabs] Create New Category',
  FINISH_CREATE_CATEGORY_SUCCESS = '[CategoryTabs] Finish Create Categories - Success',
  FINISH_CREATE_CATEGORY_ERROR = '[CategoryTabs] Finish Create Categories - Error',
}

export class FetchCategoriesAction implements Action {
  readonly type = CategoryTabsActionTypes.FETCH_LAYOUT_CATEGORIES;

  constructor(public payload: { entityType: string }) {
  }
}


export class FinishFetchCategoriesSuccessAction implements Action {
  readonly type = CategoryTabsActionTypes.FINISH_FETCH_CATEGORIES_SUCCESS;

  constructor(public payload: { categories: IAction[] }) {
  }
}

export class FinishFetchCategoriesErrorAction implements Action {
  readonly type = CategoryTabsActionTypes.FINISH_FETCH_CATEGORIES_ERROR;

  constructor(public payload: { error: string }) {
  }
}

export class SetActiveCategoryAction implements Action {
  readonly type = CategoryTabsActionTypes.SET_ACTIVE_CATEGORY;

  constructor(public payload: { category_title?: string, category_id?: number }) {
  }
}

export class CreateCategoryAction implements Action {
  readonly type = CategoryTabsActionTypes.CREATE_CATEGORY;

  constructor(public payload: { category_title: string, category_color: string }) {
  }
}

export class FinishCreateCategorySuccessAction implements Action {
  readonly type = CategoryTabsActionTypes.FINISH_CREATE_CATEGORY_SUCCESS;

  constructor(public payload: { new_category: IAction }) {
  }
}

export class FinishCreateCategoryErrorAction implements Action {
  readonly type = CategoryTabsActionTypes.FINISH_CREATE_CATEGORY_ERROR;

  constructor(public payload: { error: string }) {
  }
}

export class ShouldRefetchCategories implements Action {
  readonly type = CategoryTabsActionTypes.SHOULD_REFETCH_CATEGORIES;

  constructor(public payload: { shouldRefetch: boolean }) {
  }
}

export class UpdateCategoryAfterSave implements Action {
  readonly type = CategoryTabsActionTypes.UPDATE_CATEGORY_AFTER_SAVE;

  constructor(public payload: { updatedCategory: IAction }) {
  }
}


export type CategoryTabsActions =
  | FetchCategoriesAction
  | FinishFetchCategoriesSuccessAction
  | FinishFetchCategoriesErrorAction
  | ShouldRefetchCategories
  | UpdateCategoryAfterSave
  | SetActiveCategoryAction
  | CreateCategoryAction
  | FinishCreateCategorySuccessAction
  | FinishCreateCategoryErrorAction;
