import gql from 'graphql-tag';

export const deleteNavigace = gql`
  mutation deleteNavigace($ids: [Int!]!) {
    response: deleteNavigace(ids: $ids) {
      description
      userMessage
      error
    }
  }
`;
