import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsletterComponent } from './newsletter.component';
import { UtilsModule } from '../../utils/utils.module';
import { HeadingModule } from '../heading/heading.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, UtilsModule, HeadingModule, FormsModule],
  exports: [NewsletterComponent],
  entryComponents: [NewsletterComponent],
  declarations: [NewsletterComponent]
})
export class NewsletterModule {}


