import {
  Component,
  OnInit,
} from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { LogoutConfirmed, AuthActionTypes } from '@nx-monorepo/cms-base/store/auth/auth.actions';
import { selectUsername } from '@nx-monorepo/cms-base/store/auth/auth.selectors';

@Component({
  selector: 'pk-account-menu',
  templateUrl: './pk-account-menu.component.html',
  styleUrls: ['./pk-account-menu.component.scss']
})
export class PkAccountMenuComponent implements OnInit{
  menuItems: Array<{ text: string; icon: string; onClick: () => void, url?: string }>;
  username$: Observable<string>;

  constructor(private store$: Store<MACMSStoreState.State>,
              private actionsSubject$: ActionsSubject,
              private router: Router){
    this.menuItems = [
      {
        icon: 'vpn_key',
        text: 'Změnit heslo',
        onClick: () => null,
        url: "change-password",
      },
      {
        icon: 'account_circle',
        text: 'Upravit profil',
        onClick: () => null,
        url: "change-profile"
      },
      {
        icon: 'exit_to_app',
        text: 'Odhlásit se',
        onClick: () => {
          this.store$.dispatch(new LogoutConfirmed())
        }
      }
    ];
  }

  ngOnInit(): void {
    this.username$ = this.store$.select(selectUsername);
    this.username$.subscribe(x => {
        if(!x){

        }
      }
    );

    this.actionsSubject$.subscribe(action => {
      if (action.type === AuthActionTypes.LogoutCompleted) {
        this.router.navigateByUrl("/login");
      }
    });
  }
}
