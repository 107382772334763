<ng-container>
  <ng-container *ngIf="(selectedCategoryId > 0) || (currentFormId != null && currentFormId > 0); else noCategorySelected">
    <form [formGroup]="form">
      <div
        *ngFor="let field of formFieldDefinitions"
        [ngClass]="field.componentName === 'PkHiddenFieldComponent' ? 'hidden-field' : ''"
        [style.grid-row-start]="field.y + 1"
        [style.grid-column-start]="field.x + 1"
        [style.grid-column-end]="field.x + 1 + field.cols">
        <ng-template
          cmsComponentFactory
          [componentName]=field.componentName
          [settings]=field.settings
          [data]="field.data">
        </ng-template>
      </div>
    </form>

    Form Status: {{ form.status }}

  </ng-container>

  <ng-template #noCategorySelected>
    <pk-no-category></pk-no-category>
  </ng-template>
</ng-container>
