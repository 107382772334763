<div id="main">
  <fuse-progress-bar></fuse-progress-bar>
    <div id="container-1" class="container">
        <!-- NAVBAR -->
        <ng-container *ngIf="!fuseConfig.layout.navbar.hidden">
            <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR -->

        <div id="container-2" class="container">
            <!-- TOOLBAR -->
            <ng-container
                *ngIf="fuseConfig.layout.toolbar.position === 'below-fixed'"
            >
                <ng-container *ngTemplateOutlet="toolbar"></ng-container>
            </ng-container>
            <!-- / TOOLBAR -->

            <div
                id="container-3"
                class="container"
                fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{
                    suppressScrollX: true,
                    updateOnRouteChange: true
                }"
            >
              <div [ngClass]="{'carded': fuseConfig.layout.content.carded, 'page-layout fullwidth inner-scroll': true}">
                <!-- TOP BACKGROUND -->
                <div *ngIf="!fuseConfig.layout.header.hidden" class="top-bg fuse-navy-700"></div>
                <!-- / TOP BACKGROUND -->

                <!-- CENTER -->
                <div class="center">
                  <!-- HEADER -->
                  <macms-header *ngIf="!fuseConfig.layout.header.hidden"></macms-header>
                  <!-- / HEADER -->

                  <!-- CONTENT CARD -->
                  <div [ngClass]="{'content-card': fuseConfig.layout.content.carded, 'fullscreen': !fuseConfig.layout.content.carded}">
                    <ng-container *ngIf="(tabs$ | async) as tabs">
                      
                      <ng-container *ngIf="tabs.length > 0">
                    <div class="toolbar px-0 py-0" *ngIf="!fuseConfig.layout.header.hidden">
                      <nav mat-tab-nav-bar backgroundColor="white">
                       
                        <a
                          [routerLink]="tab.url"
                          [active]="tab.nazev === (activeTabName$ | async)"
                          mat-tab-link
                          *ngFor="let tab of tabs"
                        >
                          <!--//[routerLink]="tab.route"
                               routerLinkActive #rla="routerLinkActive"
                               [active]="tab.route === rla.routerLinkActive"-->
                          {{ tab.nazev }}
                        </a>
                      
                      </nav>
                    </div>
                  </ng-container>
                  </ng-container>
                    <div class="content">


                      <!--<ng-template pk-component-factory [component]="activeTab.component" [settings]="settings"> </ng-template>-->
                      <!--
              *ngIf="true" hack is required here for router-outlet to work
              correctly. Otherwise, it won't register the changes on the
              layout and won't update the view.
           -->
                      <router-outlet *ngIf="true"></router-outlet>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
    <toolbar
      *ngIf="!fuseConfig.layout.toolbar.hidden"
        [ngClass]="
            fuseConfig.layout.toolbar.customBackgroundColor === true
                ? fuseConfig.layout.toolbar.position +
                  ' ' +
                  fuseConfig.layout.toolbar.background
                : fuseConfig.layout.toolbar.position
        "
    >
    </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- NAVBAR -->
<ng-template #leftNavbar>
    <fuse-sidebar
        name="navbar"
        class="navbar-fuse-sidebar"
        [folded]="fuseConfig.layout.navbar.folded"
        lockedOpen="gt-sm"

    >
        <navbar
            [variant]="fuseConfig.layout.navbar.variant"
            class="left-navbar"
        ></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / NAVBAR -->
