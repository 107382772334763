import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FotogalerieComponent } from './fotogalerie.component';
import { HeadingModule } from '../heading/heading.module';
import { FlexModule, GridModule } from '@angular/flex-layout';
import { UtilsModule } from '../../utils/utils.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, HeadingModule, FlexModule, GridModule, UtilsModule, RouterModule],
  exports: [FotogalerieComponent],
  entryComponents: [FotogalerieComponent],
  declarations: [FotogalerieComponent]
})
export class FotogalerieModule {}
