import gql from 'graphql-tag';

export const deleteMedia = gql`
  mutation deleteMedia($ids: [Int!]!) {
    response: deleteMedium(ids: $ids) {
      description
      userMessage
      error
    }
  }
`;
