import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvatekComponent } from './svatek.component';
import { MomentModule } from 'ngx-moment';
import { UtilsModule } from '../../utils/utils.module';

@NgModule({
  declarations: [SvatekComponent],
  imports: [
    CommonModule,
    MomentModule,
    UtilsModule
  ],
  exports: [
    SvatekComponent
  ]
})
export class SvatekModule { }
