<div class="uploadfilecontainer" (click)="fileInput.click()" pkDragDrop (fileDropped)="onFilesAdded($event)">
  Zde přetáhněte soubory nebo klikněte pro výběr souborů.
  <input hidden type="file" multiple #fileInput (change)="onFilesAdded($event.target.files)">
</div>

<div class="file-container" *ngFor="let file of files; index as i">
  <!-- FILE INFO -->
  <div class="file-info">
    <!-- FILE NAME -->
    <p> {{ file.name }} </p>

    <!-- KATEGORIE -->
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Zvolte kategorii</mat-label>
      <mat-select [(value)]="fileCategories[i]">
        <mat-option *ngFor="let cat of categories" [value]="cat.id">
          {{cat.nazev}}
        </mat-option>

      </mat-select>
    </mat-form-field>

    <!-- UPLOAD PROGRESS BAR -->
    <mat-progress-bar
      *ngIf="progress"
      mode="determinate"
      [value]="(progress[file.name]?.state | async)?.progress"
    ></mat-progress-bar>
  </div>

  <!-- REMOVE BUTTON -->
  <button mat-icon-button (click)="removeFile(file)">
    <mat-icon>close</mat-icon>
  </button>

  <div class="result" *ngIf="progress && ((progress[file.name]?.state | async)?.progress) === 100">
    <mat-icon>check_circle</mat-icon>
    <h2>Soubor '<strong>{{file.name}}</strong>' byl úspěšně nahrán.</h2>
  </div>
</div>

