import gql from 'graphql-tag';

export const searchPage = gql`
  query searchPage($search: String!) {
    response: searchPage(
      search: $search
    ) {
      title
      content
      url
      kategorie_id
      updated_at
      category
      isOutside
    }
  }`
