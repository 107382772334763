import gql from 'graphql-tag';

export const readSelectedStrankaQuery = gql`
    query ReadSelectedStranka($ids: [Int!], $slug: String) {
        response: readSelectedStranka(
          ids: $ids,
          slug: $slug
        ) {
            id
            nazev
            slug
            obsah {
                coord_x
                coord_y
                id
                nazev
                options
                type
                cols
            }
            autor {
                jmeno
                prijmeni
            }
            autor_id
        }
    }
`;
