import { Component, Input, OnInit } from '@angular/core';
import { PkStatusCellProps, StatusDefintion } from './pkStatusCellProps';
import { AuthService } from '../../../../services/auth.service';
import { FormularStav } from '@nx-monorepo/obce/common/enums';

@Component({
  selector: 'pk-status-cell',
  templateUrl: './pk-status-cell.component.html',
  styleUrls: ['./pk-status-cell.component.scss']
})
export class PkStatusCellComponent implements OnInit {

  @Input()
  private text: string;

  public enumText: string;

  @Input()
  public settings: PkStatusCellProps;

  private currentSettings: StatusDefintion[];

  public currentDefinition: StatusDefintion;

  constructor(public authService: AuthService) {

  }

  ngOnInit(): void {
    this.enumText = FormularStav[this.text] || this.text;
    if (!this.settings) {
      console.error('CANNOT WORK WITHOUT SETTINGS');
    } else {
      console.log("hello from the status cell",this.settings);
      Object.keys(this.settings).every(key => {
        //console.log("Hello from status forEach", key, this.authService.getTokenData().role.pravomoce, this.authService.getTokenData().role.pravomoce[key]);
        if (key === 'default' || this.authService.getTokenData().role.pravomoce[key]) {
          // default should be last item.
          // if there is such privilege and its true -> set currentSettings
          this.currentSettings = this.settings[key];

          // stop forEach
          return false;
        }
        return true;
      });
      if (!this.currentSettings) {
        console.error('CANNOT SHOW STATUS DUE TO BAD PROPS PROVIDED', this.settings);
      } else {
        this.currentDefinition = this.currentSettings.find(val => val.text === this.enumText);
        if (!this.currentDefinition) {
          console.error(`CANNOT FIND CORRECT STATUS - PROVIDED TEXT '${this.enumText}' WAS NOT FOUND IN SETTINGS`, this.currentSettings);
        }
      }
    }
  }

}
