<!-- FORM FIELD -->
<ng-container>
  <mat-form-field appearance="outline" fxFlex [formGroup]="settings.form" (click)="openEntityPicker()">
    <mat-label>{{ settings.nazev }}</mat-label>

    <!-- pocet vybranych -->
    <div *ngIf="settings.form.get(settings.formControlName).value?.length > 0">
      Počet vybraných položek: {{settings.form.get(settings.formControlName).value?.length}}
    </div>

    <input readonly hidden matInput [required]="settings.povinnost" [formControlName]="settings.formControlName" />

    <!-- HINTS -->
    <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
    <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info
    </mat-icon>

    <!-- REMOVE ICON -->
    <button [disabled]="selectedEntityItems.length === 0" matSuffix mat-icon-button (click)="removeAllEntities($event)">
      <mat-icon matTooltip="Odstranit všechny přílohy">close</mat-icon>
    </button>

    <!-- ERROR -->
    <mat-error *ngIf="settings.form.get(settings.formControlName)?.invalid">Toto pole je povinné!</mat-error>

  </mat-form-field>

  <!-- PREVIEW -->
  <ng-container *ngIf="settings.withPreview" [ngTemplateOutlet]="preview"></ng-container>

</ng-container>


<!-- PREVIEW -->
<ng-template class="media-preview" #preview>
  <div class="media-row">
    <div class="media-wrapper" *ngFor="let media of selectedEntityItems">
      <ng-container [ngSwitch]="media.mime | split">
        <ng-container *ngSwitchCase="'image'">
          <img [src]="media.url" />
          <button class="media-delete" mat-icon-button (click)="removeEntity(media)">
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="media-text">{{media.nazev}}</div>
          <button class="media-delete" mat-icon-button (click)="removeEntity(media)">
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>

</ng-template>
