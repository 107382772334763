import { Validators } from '@angular/forms';
import {
  getDefaultDialogButtons,
  getNapovedaSettings,
  getNazevSettings,
  getPovinnostSettings
} from '../common-settings-objects';
import { IPkFormDialogCompanion, IPkDialogData } from '@nx-monorepo/cms-base/components/pk-dialog/pk-dialog-data';
import { IPkSelectFieldProps, IPkInputChipsFieldProps } from '@nx-monorepo/cms-base/components';

export class PkGridsterItemSelectDialogCompanion implements IPkFormDialogCompanion {
  getDialogData(settings: IPkSelectFieldProps, data: {selectFieldData}, emitters: {dialogSubmit, dialogClose}) {
    const dialogData: IPkDialogData = {
      title: 'Upravit vstupní pole',
      components: [
        getNazevSettings(settings.nazev),
        getPovinnostSettings(settings.povinnost),
        getNapovedaSettings(settings.napoveda),
        {
          componentName: 'PkInputChipsFieldComponent',
          settings: {
            povinnost: true,
            nazev: 'Moznosti',
            napoveda: {
              isIcon: true,
              text: 'Napoveda lorem ipsum'
            },
            formControlName: 'options'
          } as IPkInputChipsFieldProps,
          data: data,
          validators: [Validators.required],
          cols: 1,
          x: 0,
          y: 0
        }
      ],
      buttons: getDefaultDialogButtons(emitters)
    };

    return dialogData;
  }
}
