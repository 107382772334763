import {Component, ViewEncapsulation} from '@angular/core';
import {BasePageComponent} from '../base-page.component';
import {Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';

@Component({
  selector: 'pk-dashboard-page',
  templateUrl: './pk-dashboard-page.component.html',
  styleUrls: ['./pk-dashboard-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PkDashboardPage extends BasePageComponent {
  constructor(store$: Store<MACMSStoreState.State>,
              route: ActivatedRoute) {
    super(store$, route);
  }

}
