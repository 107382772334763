import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import { NavPol } from '../../../utils';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'navigace-item',
  templateUrl: './navigace-item.component.html',
  styleUrls: ['./navigace-item.component.scss']
})
export class NavigaceItemComponent implements OnInit {
  @Input() items: NavPol[];
  @Input() itemFont: String;
  @ViewChild('childMenu',{static: true}) public childMenu;

  constructor(public router: Router,private iconLibrary: FaIconLibrary, private faConfig: FaConfig) {
  }

  ngOnInit() {
    this.iconLibrary.addIconPacks(fas, fab);
    this.faConfig.defaultPrefix = 'fas';
  }
}
