import { IColumnDefinition } from '@nx-monorepo/cms-base/interfaces';

export const uzivateleEmailColumnDefinitions: IColumnDefinition[] = [
  {
    title: 'select',
    key: ['select'],
    type: 'select',
    sortable: true,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false
    }
  },
  {
    title: 'ID',
    key: ['id'],
    type: 'string',
    sortable: true,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false
    }
  },
  {
    title: 'Jméno',
    key: ['jmeno'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: true,
      md: false,
      lg: false
    }
  },
  {
    title: 'Příjmení',
    key: ['prijmeni'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false
    }
  },
  {
    title: 'Email',
    key: ['email'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: true,
      md: false,
      lg: false
    }
  }
];
