import { Component, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { animate, style, transition, trigger } from '@angular/animations';


@Component({
  selector     : 'pk-no-category',
  templateUrl  : './no-category.component.html',
  styleUrls    : ['./no-category.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : [...fuseAnimations,
    trigger('rotate', [
      transition('void => *', [
        style({ transform: 'rotate(45deg)', opacity: '0'}),
        animate('200ms 250ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 1 })),
        animate('1000ms 500ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ transform: 'rotate(-45deg)' })),
      ]),

    ])
  ]
})
export class NoCategoryComponent {

  constructor(
    _fuseProgressBarService: FuseProgressBarService
  )
  {
    _fuseProgressBarService.hide();
  }

  /*goBack(){
    this.router.();
  }*/


}
