import { Component, OnInit, Input } from '@angular/core';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { HeaderBarrel, NavigaceBarrel } from '@nx-monorepo/node-frontend';
import { BaseComponent } from '../base.component';
import { ColorUtilsService, ObecService } from '@nx-monorepo/obce/ng/services';

@Component({
  selector: 'macms-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements IDynamicComponent, OnInit {
  @Input() settings: HeaderBarrel.IHeaderGeneralOptions;
  @Input() data: any[];

  topNavigaceData: any;
  bottomNavigaceData: any;

  topBarNavSettings: NavigaceBarrel.HeaderNavProps;
  bottomBarNavSettings: NavigaceBarrel.HeaderNavProps;


  constructor(public colorUtilsService: ColorUtilsService, protected obecService: ObecService) {
    super(colorUtilsService, obecService);
  }

  ngOnInit(): void {
    this.topBarNavSettings = this.settings.topSlot.navigace;
    if (this.topBarNavSettings) {
      this.topNavigaceData = this.getNavigaceWithId(this.topBarNavSettings.id);
    }

    this.bottomBarNavSettings = this.settings.bottomSlot && this.settings.bottomSlot.navigace;
    if (this.bottomBarNavSettings) {
      this.bottomNavigaceData = this.getNavigaceWithId(this.bottomBarNavSettings.id);
    }
  }

  private getNavigaceWithId(id: number): any {
    return this.data.filter(nav => nav.id === id)[0];
  }

  getComponentName(): string {
    return HeaderBarrel.name;
  }
}
