import { Component, Input, OnInit } from '@angular/core';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { UdalostiBarrel } from '@nx-monorepo/node-frontend';
import { BaseComponent } from '../base.component';
import { HeadingProps } from '../heading/heading.props';
import { NextButtonProps } from '../next-button/next-button.props';
import { ColorUtilsService, ObecService } from '@nx-monorepo/obce/ng/services';

@Component({
  selector: 'macms-udalosti',
  templateUrl: './udalosti.component.html',
  styleUrls: ['./udalosti.component.scss']
})
export class UdalostiComponent extends BaseComponent implements IDynamicComponent, OnInit {
  @Input() data: any;
  @Input() settings: UdalostiBarrel.UdalostiProps;

  nextButtonSettings: NextButtonProps;
  headingSettings: HeadingProps;
  primaryColor: string;

  constructor(public colorUtilsService: ColorUtilsService, protected obecService: ObecService) {
    super(colorUtilsService, obecService);
  }

  ngOnInit(): void {
    this.primaryColor = this.settings.primaryColor;
    this.generateColors();
    if(this.settings.headingProps){
      this.headingSettings = this.settings.headingProps;
    } else {
      this.headingSettings = {
        icon: 'newspaper',
        iconPosition: 'start',
        boxMargin: {top: 'auto', bottom: 'auto', left: 'auto', right: 'auto'},
        primaryColor: this.primaryColor,
        font: this.obecSettings.title_font
      };
    }
    this.nextButtonSettings = {
      primaryColor: this.primaryColor,
      fontTitle: this.obecSettings.title_font,
      bottomPaddingEm: 2,
      topPaddingEm: 2,
    };

    if(!this.settings.dateFont){
      this.settings.dateFont = this.obecSettings.title_font;
    }

    if(!this.settings.titleFont){
      this.settings.titleFont = this.obecSettings.title_font;
    }

    if(!this.settings.textFont){
      this.settings.textFont = this.obecSettings.paragraph_font;
    }
  }

  getComponentName() {
    return UdalostiBarrel.udalosti_name;
  }
}
