import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation, ViewRef } from '@angular/core';
import { of as observableOf, Subject } from 'rxjs';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
// noinspection TypeScriptPreferShortImport
import { BasePageComponent } from '../base-page.component';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { FormControl, FormGroup } from '@angular/forms';
import { ApolloQueryResult } from 'apollo-client';
import { HttpClient } from '@angular/common/http';
import { KolekceService } from '@nx-monorepo/obce/ng/services';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { EntityDefinitionProvider } from '@nx-monorepo/cms-base/helpers';
import { SnackbarStoreActions } from '@nx-monorepo/cms-base/store/snackbar';
import { LayoutActionTypes } from '@nx-monorepo/cms-base/store/layout/layout.actions';

@Component({
  selector: 'pk-new-kontakt-page',
  templateUrl: './pk-new-kontakt-page.component.html',
  styleUrls: ['./pk-new-kontakt-page.component.scss'],
  animations: fuseAnimations
})
export class PkNewKontaktPageComponent extends BasePageComponent implements OnInit, OnDestroy {

  @ViewChild('ares',{static: true}) ares: ElementRef;
  // store state


  // inner state
  entityDefinitions: IEntityDefinition[];
  entityForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(store$: Store<MACMSStoreState.State>, currentRoute: ActivatedRoute, private router: Router, private entityDefinitionProvider: EntityDefinitionProvider, private kolekceService: KolekceService, private actionsSubject$: ActionsSubject, private http: HttpClient) {
    super(store$, currentRoute);
  }

  onFindClicked(): void {
    if(this.ares.nativeElement.value){
      this.http.get('/ares/darv_bas.cgi?ico='+this.ares.nativeElement.value, { responseType: 'text' }).subscribe(response => {
        this.processAresXML(response);
      });
    } else {
      console.error("Vyplň IČO do patřičného pole!");
    }
  }

  processAresXML(xml: string){
    const oParser = new DOMParser();
    const oDOM = oParser.parseFromString(xml, "application/xml");
    if(oDOM.documentElement.nodeName === "parsererror" ){

    } else {
      const odpoved = oDOM.documentElement.children[0];
      console.log(odpoved);
      if(odpoved.getElementsByTagName("D:E").length === 1){
        const error = odpoved.getElementsByTagName("D:E")[0].getElementsByTagName("D:ET")[0].innerHTML;
        if(error.startsWith("Chyba 71")){
          console.error("IČO nenalezeno", )
        } else if(error.startsWith("Chyba 31")){
          console.error("Prázdný argument");
        } else {
          console.error("Generická chyba");
        }
      } else if(odpoved.getElementsByTagName("D:VBAS").length === 1){
        const vysledek = odpoved.getElementsByTagName("D:VBAS")[0];
        const lokace = vysledek.getElementsByTagName("D:AA")[0];
        let mesto_text = vysledek.getElementsByTagName("D:N")[0].innerHTML;
        if(vysledek.getElementsByTagName("D:NCO")[0].innerHTML !== mesto_text){
          mesto_text += " - " + vysledek.getElementsByTagName("D:NCO")[0].innerHTML;
        }
        let ulice = "";
        // ma ulici?
        if(lokace.getElementsByTagName("D:NU").length === 1){
          // ma ulici
          ulice += lokace.getElementsByTagName("D:NU")[0].innerHTML + " ";
        } else {
          // nema ulici, pouzij mesto
          ulice += mesto_text + " ";
        }
        if(lokace.getElementsByTagName("D:CO").length === 1){
          // ma cislo orientacni ?
          ulice += lokace.getElementsByTagName("D:CO")[0].innerHTML + "/";
        }
        // cislo popisne by melo byt vzdy
        ulice += lokace.getElementsByTagName("D:CD")[0].innerHTML;
        this.entityForm.patchValue({
          nazev: vysledek.getElementsByTagName("D:OF")[0].innerHTML,
          ico: vysledek.getElementsByTagName("D:ICO")[0].innerHTML,
          dic: vysledek.getElementsByTagName("D:DIC")[0].innerHTML,
          psc: lokace.getElementsByTagName("D:PSC")[0].innerHTML,
          ulice: ulice,
          mesto: mesto_text,
        })
      }
    }
  }

  ngOnInit(): void {
    this.entityForm = new FormGroup({});

    // z cesty ziskat popis entity
    this.entityDefinitions = this.entityDefinitionProvider.getEntityDefinitionsFor(this.routeData.entityType);

    console.log(this.entityDefinitions, this.routeData.entityType);

    // vybuildit form dle popisu entity
    this.generateForm();

    // if we have ID from route, find the entity and fill the form
    const entityId = this.currentRoute.snapshot.paramMap.get('id');
    if (entityId) {
      this.fetchEntityAndFillForm(Number(entityId));
    } else {
      this.entityForm.get('id').disable();
    }

    // subscribe to the action where Save button is pressed
    this.subscribeToSaveButtonAction();
  }

  ngOnDestroy(): void {
    // reset form
    this.entityForm.reset();

    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  private fetchEntityAndFillForm(entityId: number) {
    this.kolekceService.fetchSingle(this.routeData.entityType, entityId).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: ApolloQueryResult<any>) => {
      console.log('patching values', res.data.response);
      this.entityForm.patchValue(res.data.response);
    });
  }

  private generateForm() {
    // pro kazdej field entity
    this.entityDefinitions.forEach((entityDefinition: IEntityDefinition) => {
      // nastavit jim konkretni form
      entityDefinition.settings.form = this.entityForm;

      // pridat do formy control
      this.entityForm.addControl(
        entityDefinition.settings.formControlName,
        new FormControl(entityDefinition.settings.defaultValue || null, entityDefinition.validators)
      );
    });
  }

  private subscribeToSaveButtonAction() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === LayoutActionTypes.SAVE_BUTTON_PRESSED)
    ).subscribe(() => {
      if (this.entityForm.valid) {
        this.saveEntity();
      } else {
        this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({ message: 'Vyplňte prosím všechny položky!', action: 'OK', config: { duration: 2500 } }));
      }
    });
  }

  private saveEntity() {
    this.kolekceService.save(this.routeData.entityType, { input: [this.entityForm.value] }).pipe(
      takeUntil(this.destroy$),
      map(result => {
        this.router.navigateByUrl(this.currentRoute.snapshot.url[0].path || '/');
        this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({ message: 'Úspěšně uloženo!', action: 'OK', config: { duration: 5000 } }));
        return result;
      }),
      catchError((error: Error) => {
        this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({ message: error.message, action: 'OK' }));
        return observableOf(error);
      })
    ).subscribe();
  }
}
