import gql from 'graphql-tag';

export const readSelectedKategorieQuery = gql`
  query readSelectedKategorie($ids: [Int!]!) {
    response: readSelectedKategorie(ids: $ids) {
      id
      nazev
      color
      nastaveni
      uzivatele{
        id
        jmeno
        prijmeni
      }
    }
  }
`;
