import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as categoryTabsActions from './category-tabs.actions';
import * as MACMSStoreState from '../store.state';
import { ApolloQueryResult } from 'apollo-client';
import { KategorieService } from '@nx-monorepo/obce/ng/services';
import { IAction } from '@nx-monorepo/cms-base/interfaces';
import { displaySnack } from '@nx-monorepo/cms-base/helpers';


@Injectable()
export class CategoryTabsEffects {
  constructor(private kategorieService: KategorieService, private actions$: Actions, private store$: Store<MACMSStoreState.State>) {
  }

  @Effect()
  fetchCategoriesEffect$: Observable<Action> = this.actions$.pipe(
    ofType<categoryTabsActions.FetchCategoriesAction>(categoryTabsActions.CategoryTabsActionTypes.FETCH_LAYOUT_CATEGORIES),
    switchMap( action => {
      const entityTypes = action.payload.entityType;
      if (!entityTypes) {
        return observableOf(new categoryTabsActions.FinishFetchCategoriesSuccessAction({
          categories: [{id: 0, nazev: "Vše"}]
        }));
      }
      const vars = { types: entityTypes };
      //stupid, yet working, way to avoid error "GraphQL cannot find category" L.Cs.
      if (!(entityTypes === 'Sms' || entityTypes === 'Email')) {
        return this.kategorieService.fetchAll(vars).pipe(
          map((result: ApolloQueryResult<any>) => {
            return new categoryTabsActions.FinishFetchCategoriesSuccessAction({
              categories: result.data.response.items.map(category => {
                return { id: category.id, nazev: category.nazev, color: category.color } as IAction;
              })
            });
          }),
          catchError(error => observableOf(new categoryTabsActions.FinishFetchCategoriesErrorAction({ error: JSON.stringify(error) })))
        );
      } else {
        //console.log('No categories');
        return [];
      }
    })
  );

  @Effect()
  selectDefaultAfterFetchEffect$: Observable<Action> = this.actions$.pipe(
    ofType<categoryTabsActions.FinishFetchCategoriesSuccessAction>(categoryTabsActions.CategoryTabsActionTypes.FINISH_FETCH_CATEGORIES_SUCCESS),
    withLatestFrom(this.store$),
    map(result => {
        return new categoryTabsActions.SetActiveCategoryAction({
          category_id: 0
        });
      }
    ));

  @Effect()
  createCategoryEffect$ = this.actions$.pipe(
    ofType<categoryTabsActions.CreateCategoryAction>(categoryTabsActions.CategoryTabsActionTypes.CREATE_CATEGORY),
    withLatestFrom(this.store$),
    switchMap(([action, storeState]) => {
      const vars = {
        typ: storeState.categories.entityType,
        nazev: action.payload.category_title,
        color: action.payload.category_color
      };

      //TODO: upravit este na backende
      storeState.categories.categories.forEach(cat => {
        if (cat.nazev === action.payload.category_title) {
          displaySnack("Kategorie s tímhle názvem již existuje!", this.store$);
          throw Error("Kategorie s tímhle názvem již existuje!");
        }
      })

      return this.kategorieService.save({ input: [vars] }).pipe(
        map((result: ApolloQueryResult<any>) => {
          const new_category = result.data.addKategorie[0];
          return new categoryTabsActions.FinishCreateCategorySuccessAction({
            new_category
          });
        }),
        catchError(error => observableOf(new categoryTabsActions.FinishFetchCategoriesErrorAction({ error: JSON.stringify(error) })))
      );
    })
  );
}
