import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store, createAction } from '@ngrx/store';
import { MACMSStoreState } from '..';
import { Observable, of as observableOf } from 'rxjs';
import { withLatestFrom, switchMap, map, catchError } from 'rxjs/operators';
import * as layoutActions from './layout.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PkConfirmDialogComponent } from '../../components/pk-confirm-dialog/pk-confirm-dialog.component';

@Injectable()
export class LayoutStoreEffects {
  constructor(private dialog: MatDialog, private actions$: Actions, private store$: Store<MACMSStoreState.State>) {
  }

  @Effect()
  deleteSelectedEffect$: Observable<layoutActions.SetConfirmDialogReference> = this.actions$.pipe(
    ofType<layoutActions.ShowConfirmDialog>(layoutActions.LayoutActionTypes.SHOW_CONFIRM_DIALOG),
    map((action) => {
        //console.log("EFFECT FIRED", action);
        let dialogRef: MatDialogRef<any>;
        dialogRef = this.dialog.open(PkConfirmDialogComponent, {data: {
          title: action.payload.title,
          content: action.payload.content,
          buttons: [
            { color: 'warn',
            text: 'Potvrdit',
            action: () => {
                const act = createAction(action.payload.onConfirm);
                //console.log(act());
                this.store$.dispatch(act())
                dialogRef.close();
            }},
            { color: 'primary',
            text: 'Zrušit',
            action: () => {
                dialogRef.close();
            }},
            ]
        }});
        return new layoutActions.SetConfirmDialogReference(null);
    })
  );
}
