import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObecService } from '@nx-monorepo/obce/ng/services';
import { IBreadcrumb } from './i-breadcrumb';

@Component({
  selector: 'macms-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit{
/*  obec: String;
  kolekce: String;
  kolekce_url: String;
  kategorie: String;
  kategorie_url: String;
  entita: String;*/
  last: String;

  @Input() crumbs: IBreadcrumb[];
  //TODO: import IOwnerSettings
  obecSettings: any;

  constructor(private router: Router, private route: ActivatedRoute, protected obecService: ObecService) {
    this.obecService.settings$.subscribe(
      next => {this.obecSettings = next;}
    );
  }

  ngOnInit(): void {
/*    this.obec = "Titulní stránka";
    this.kolekce = "Příspěvky";
    this.kolekce_url = 'stranky';
    this.kategorie = 'Aktuality';
    this.kategorie_url = 'aktuality';
    this.entita = 'Nejaka stranka obce krtenov';*/
    if(this.crumbs) {
      //console.log(this.crumbs);
      this.crumbs.forEach(crumb => {
        this.last = crumb.title;
      });
    }
    // this.last = 'kategorie';
  }
}

