<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let child of items">
    <!-- Handle branch node menu items -->
    <span *ngIf="child.children && child.children.length > 0">
      <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu">
        <fa-icon *ngIf="child.ikonka" [icon]="child.ikonka"></fa-icon>
        <span [style.fontFamily]="itemFont">{{child.nazev}}</span>
      </button>
      <navigace-item #menu [items]="child.children" [itemFont]="itemFont"></navigace-item>
    </span>
    <!-- Handle leaf node menu items -->
    <span *ngIf="!child.children || child.children.length === 0">
       <ng-container *ngTemplateOutlet="child.url ? outsideLink : insideLink; context: {polozka: child}">
       </ng-container>
    </span>
  </span>
</mat-menu>

<ng-template #insideLink let-polozka="polozka">
  <a mat-menu-item [href]='polozka.url' [routerLink]="(polozka.stranka ? polozka.stranka.slug : null) | DetailUrl:'stranky'" >
    <fa-icon *ngIf="polozka.ikonka" [icon]="polozka.ikonka"></fa-icon>
    <span [style.fontFamily]="itemFont">{{polozka.nazev}}</span>
  </a>
</ng-template>
<ng-template #outsideLink let-polozka="polozka">
  <a mat-menu-item [href]="polozka.url">
    <fa-icon *ngIf="polozka.ikonka" [icon]="polozka.ikonka"></fa-icon>
    <span [style.fontFamily]="itemFont">{{polozka.nazev}}</span>
  </a>
</ng-template>
