import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'determineCalendarColor',
  pure: false,
})
export class DetermineCalendarColorPipe implements PipeTransform {

  /*
  * value == 0 - primary -> 1 - secondary -> 2 - accent
  */
  transform(currentIndex: number, colors: string[], selectedIndex: number, todayIdx: number): any {
    if(currentIndex === selectedIndex){
      return colors[2%colors.length];
    } else if(currentIndex === todayIdx){
      return colors[1%colors.length];
    }
    return colors[0];
  }

}
