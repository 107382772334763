<div class="gridster-item">
  <div class="base-header">
    <!-- drag handler-->
    <div class="pk-drag-handler">
      <mat-icon>drag_indicator</mat-icon>
    </div>

    <!-- header text -->
    <div class="title">{{ item.moduleItem.title }}</div>

    <!-- menu pro upravit/smazat-->
    <div class="more">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="more">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <!-- UPRAVIT -->
        <button fxLayout="row"
                fxLayoutAlign="start center"
                mat-menu-item
                (click)="onEditClick()">
          <span>Upravit</span>
        </button>

        <!-- SMAZAT -->
        <button
          fxLayout="row"
          fxLayoutAlign="start center"
          mat-menu-item
          (click)="deleteEvent.emit($event)"
        >
          <span>Smazat</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <div *ngIf="!showPreview; else preview" class="gridster-content">
    <div class="title"><mat-icon>{{ item.moduleItem.icon }}</mat-icon>{{item.moduleItem.type}}</div>
    <p class="description">{{item.moduleItem.description}}</p>
  </div>
<ng-template #preview>
  <div class="gridster-content" [ngSwitch]="item.moduleItem.type">
    <pk-gridster-item-textfield
      #gridsterItemComponent
      [dialogRef]="dialogRef"
      [widget]="item"
      [resizeEvent]="resizeEvent"
      *ngSwitchCase="'PkGridsterItemTextfieldComponent'"
    ></pk-gridster-item-textfield>
    <pk-gridster-item-date
      #gridsterItemComponent
      [dialogRef]="dialogRef"
      [widget]="item"
      [resizeEvent]="resizeEvent"
      *ngSwitchCase="'PkGridsterItemDateComponent'"
    ></pk-gridster-item-date>
    <pk-gridster-item-select
      #gridsterItemComponent
      [dialogRef]="dialogRef"
      [widget]="item"
      [resizeEvent]="resizeEvent"
      *ngSwitchCase="'PkGridsterItemSelectComponent'"
    ></pk-gridster-item-select>
    <pk-gridster-item-multiple-select
      #gridsterItemComponent
      [dialogRef]="dialogRef"
      [widget]="item"
      [resizeEvent]="resizeEvent"
      *ngSwitchCase="'PkGridsterItemMultipleSelectComponent'"
    ></pk-gridster-item-multiple-select>
    <pk-gridster-item-wysiwyg
      #gridsterItemComponent
      [dialogRef]="dialogRef"
      [widget]="item"
      [resizeEvent]="resizeEvent"
      *ngSwitchCase="'PkGridsterInputWysiwygComponent'"
    ></pk-gridster-item-wysiwyg>
    <pk-gridster-item-checkbox
      #gridsterItemComponent
      [dialogRef]="dialogRef"
      [widget]="item"
      [resizeEvent]="resizeEvent"
      *ngSwitchCase="'PkGridsterItemCheckboxComponent'"
    ></pk-gridster-item-checkbox>
  </div>
</ng-template>
</div>
