import { Component, Input, OnInit } from '@angular/core';
import { InfoProps } from './info.props';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { BaseComponent } from '../base.component';
import { ColorUtilsService, ObecService } from '@nx-monorepo/obce/ng/services';

@Component({
  selector: 'macms-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent extends BaseComponent implements IDynamicComponent, OnInit {
  @Input() data: any;
  @Input() settings: InfoProps;

  ngOnInit(): void {
    //console.log(this.settings, this.obecSettings, this.data);
  }

  constructor(public colorUtilsService: ColorUtilsService, protected obecService: ObecService) {
    super(colorUtilsService, obecService);

  }

  getComponentName() {
    return InfoComponent.name;
  }
}
