import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { Injectable } from '@angular/core';
import {
  galerieEntityDefinition,
  kontaktEntityDefinition,
  prispevekEntityDefinition,
  strankaEntityDefinition,
  udalostEntityDefinition,
  urednideskaEntityDefinition,
  uzivatelEntityDefinition,
  SmsEntityDefinition
} from './entity-definitions';
import {
  evidenceColumnDefinitions,
  formularColumnDefinitions,
  galerieColumnDefinitions,
  kontaktColumnDefinitions,
  mediaColumnDefinitions,
  navigaceColumnDefinitions, platebniPrikazColumnDefinitions,
  prispevekColumnDefinitions,
  strankaColumnDefinitions,
  udalostColumnDefinitions,
  urednideskyColumnDefinitions,
  uzivatelColumnDefinitions,
  smsOdeslaneColumnDefinitions,
  transakceColumnDefinitions,
  emailOdeslaneColumnDefinitions
} from './column-definitions';
import { EmailEntityDefinition } from './entity-definitions/email-entity-definition';
import { ColumnDefinitionProvider, EntityDefinitionProvider } from '@nx-monorepo/cms-base/helpers';


@Injectable()
export class ObecColumnDefinitionProvider implements ColumnDefinitionProvider {
  getColumnDefinitionsFor(entityType: EntityTypes) {
    switch (entityType) {
      case EntityTypes.Evidence:
        return evidenceColumnDefinitions;
      case EntityTypes.Kontakt:
        return kontaktColumnDefinitions;
      case EntityTypes.Formular:
        return formularColumnDefinitions;
      case EntityTypes.Prispevek:
        return prispevekColumnDefinitions;
      case EntityTypes.Uzivatel:
        return uzivatelColumnDefinitions;
      case EntityTypes.Udalost:
        return udalostColumnDefinitions;
      case EntityTypes.UredniDeska:
        return urednideskyColumnDefinitions;
      case EntityTypes.Stranka:
        return strankaColumnDefinitions;
      case EntityTypes.Media:
        return mediaColumnDefinitions;
      case EntityTypes.Navigace:
        return navigaceColumnDefinitions;
      case EntityTypes.Galerie:
        return galerieColumnDefinitions;
      case EntityTypes.PlatebniPrikaz:
        return platebniPrikazColumnDefinitions;
      case EntityTypes.Sms:
        return smsOdeslaneColumnDefinitions;
      case EntityTypes.Email:
        return emailOdeslaneColumnDefinitions;
      case EntityTypes.Transakce:
        return transakceColumnDefinitions;
      default:
        return uzivatelColumnDefinitions;
    }
  }
}


@Injectable()
export class ObecEntityDefinitionProvider implements EntityDefinitionProvider {
  getEntityDefinitionsFor(entityType: EntityTypes) {
    switch (entityType) {
      case EntityTypes.Kontakt:
        return kontaktEntityDefinition;
      case EntityTypes.Prispevek:
        return prispevekEntityDefinition;
      case EntityTypes.Uzivatel:
        return uzivatelEntityDefinition;
      case EntityTypes.Udalost:
        return udalostEntityDefinition;
      case EntityTypes.UredniDeska:
        return urednideskaEntityDefinition;
      case EntityTypes.Stranka:
        return strankaEntityDefinition;
      case EntityTypes.Galerie:
        return galerieEntityDefinition;
      case EntityTypes.Sms:
        return SmsEntityDefinition;
      case EntityTypes.Email:
        return EmailEntityDefinition;
      default:
        throw Error(`Obec-page-settings-provider.ts received entityType ${entityType} and cannot determine its definition`);
    }
  }
}
