import { IColumnDefinition } from '@nx-monorepo/cms-base/interfaces';
import { PlatbaStav } from '@nx-monorepo/obce/common/enums';

export const platebniPrikazColumnDefinitions: IColumnDefinition[] = [
  {
    title: 'select',
    key: ['select'],
    type: 'select',
    sortable: false,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'ID',
    key: ['id'],
    type: 'string',
    sortable: true,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'Jméno',
    key: ['uzivatel', 'jmeno'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: true,
      md: true,
      lg: true,
    }
  },
  {
    title: 'Příjmení',
    key: ['uzivatel', 'prijmeni'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'Stav',
    key: ['stav'],
    type: 'status',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    },
    settings: {
      default: [
        {
          text: PlatbaStav.WAITING,
          color: 'orange',
        },
        {
          text: PlatbaStav.SUCCESS,
          color: 'green',
        },
        {
          text: PlatbaStav.OVERDUE,
          color: 'red',
        },
        {
          text: PlatbaStav.CANCELED,
          color: 'black',
        }
      ]
    }
  },
  {
    title: 'Částka',
    key: ['castka'],
    type: 'format',
    settings:{
      method: 'money'
    },
    sortable: false,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'Datum splatnosti',
    key: ['splatnost'],
    type: 'date',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
];
