<div id="register" fxLayout="column">

  <div id="register-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="register-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

      <div class="logo">
        <img src="assets/images/logos/restaurus_logo.svg">
      </div>

      <div class="title">Vytvořit účet</div>

      <mat-horizontal-stepper class="mat-elevation-z4" [linear]="true">

        <mat-step [stepControl]="horizontalStepperStep1">

          <form fxLayout="column" [formGroup]="horizontalStepperStep1">

            <ng-template matStepLabel>Základní informace</ng-template>

            <div fxFlex="1 0 auto" fxLayout="column">

              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Jméno</mat-label>
                <input matInput formControlName="firstName" required>
                <mat-error>Jméno je povinné</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Příjmení</mat-label>
                <input matInput formControlName="lastName" required>
                <mat-error>Příjmení je povinné</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Druhé jméno</mat-label>
                <input matInput formControlName="secondName">
              </mat-form-field>

              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
                <mat-form-field appearance="outline" fxFlex="33">
                  <mat-label>Den narození</mat-label>
                  <input type="number" matInput formControlName="dayOfBirth" required>
                  <mat-error>Den narození je povinný!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="34" class="px-8">
                  <mat-label>Měsíc narození</mat-label>
                  <input type="number" matInput formControlName="monthOfBirth" required>
                  <mat-error>Měsíc narození je povinný</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="33">
                  <mat-label>Rok narození</mat-label>
                  <input type="number" matInput formControlName="yearOfBirth" maxlength="4" required>
                  <mat-error>Rok narození je povinný!</mat-error>
                </mat-form-field>
              </div>

            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
              <button mat-raised-button matStepperNext type="button" color="accent">
                Next
              </button>
            </div>

          </form>

        </mat-step>

        <mat-step [stepControl]="horizontalStepperStep2">

          <form fxLayout="column" [formGroup]="horizontalStepperStep2">

            <ng-template matStepLabel>Fill out your address</ng-template>

            <div fxFlex="1 0 auto" fxLayout="row">

              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Address</mat-label>
                <textarea matInput formControlName="address" required>
                                    1600 Amphitheatre Pkwy
                                </textarea>
                <mat-error>Address is required!</mat-error>
              </mat-form-field>

            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
              <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                Previous
              </button>
              <button mat-raised-button matStepperNext type="button" color="accent">
                Next
              </button>
            </div>

          </form>

        </mat-step>

        <mat-step [stepControl]="horizontalStepperStep3">

          <form fxLayout="column" [formGroup]="horizontalStepperStep3">

            <ng-template matStepLabel>Fill out your address</ng-template>

            <div fxFlex="1 0 auto" fxLayout="column">

              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>City</mat-label>
                <input matInput formControlName="city" required>
                <mat-error>City is required!</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>State</mat-label>
                <input matInput formControlName="state" required>
                <mat-error>State is required!</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Postal Code</mat-label>
                <input matInput #postalCode2 formControlName="postalCode"
                       maxlength="5" required>
                <mat-hint align="end">{{postalCode2.value.length}} / 5</mat-hint>
                <mat-error>Postal Code is required!</mat-error>
              </mat-form-field>

            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
              <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                Previous
              </button>
              <button mat-raised-button matStepperNext type="button" color="accent">
                Next
              </button>
            </div>

          </form>

        </mat-step>

        <mat-step>

          <ng-template matStepLabel>Done</ng-template>

          <div class="h2 m-16" fxLayout="row" fxLayoutAlign="center center">
            Thank your for filling out our form.
          </div>

          <div fxLayout="row" fxLayoutAlign="center center">
            <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
              Previous
            </button>
            <button mat-raised-button type="button" color="accent" (click)="finishHorizontalStepper()">
              Finish
            </button>
          </div>

        </mat-step>

      </mat-horizontal-stepper>

      <div class="register" fxLayout="column" fxLayoutAlign="center center">
        <span class="text">Máte už účet?</span>
        <a class="link" [routerLink]="'/login'">Přihlásit se</a>
      </div>

    </div>

  </div>

</div>
