import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoComponent } from './info.component';
import { HeadingModule } from '../heading/heading.module';
import { UtilsModule } from '../../utils/utils.module';


@NgModule({
  imports: [CommonModule, HeadingModule, UtilsModule],
  exports: [InfoComponent],
  entryComponents: [InfoComponent],
  declarations: [InfoComponent]
})
export class InfoModule {}

