import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// noinspection TypeScriptPreferShortImport
import { BasePageComponent } from '../base-page.component';
import { fuseAnimations } from '@fuse/animations';
import {  Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { MACMSStoreState } from 'libs/cms-base/src';

@Component({
  selector: 'pk-data-table-page',
  templateUrl: './pk-data-table-page.component.html',
  styleUrls: ['./pk-data-table-page.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PkDataTablePageComponent extends BasePageComponent {

  constructor(store$: Store<MACMSStoreState.State>, route: ActivatedRoute) {
    super(store$, route);
  }
  public shouldNavigateAfterRowClick = this.routeData.shouldNavigateAfterRowClick ?? true;
}
