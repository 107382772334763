export interface AuthState {
  last_auth_message: string,
  username: string,
  user_id: number,
  owner_id: number

}

export const initialAuthState: AuthState = {
  username: null,
  user_id: 0,
  owner_id: 0,
  last_auth_message: null
};
