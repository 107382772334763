import { Component } from '@angular/core';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { ColorUtilsService, ObecService } from '@nx-monorepo/obce/ng/services';

@Component({
  selector: 'base',
  template: `NO UI TO BE FOUND HERE!`,
  styleUrls: []
})
export class BaseComponent implements IDynamicComponent {
  primaryColor: string;
  secondaryColor: string;
  accentColor: string;

  onPrimaryColor;
  onSecondaryColor;
  onAccentColor;

  subSecondaryColor;
  onSubSecondaryColor;

  wrapperBackgroundColor;

  obecSettings: any;

  data: Promise<any> | any;
  settings: Promise<any> | any;

  getComponentName(): string {
    return 'BASE';
  }

  constructor(public colorUtilsService: ColorUtilsService, protected obecService: ObecService) {
    this.obecService.settings$.subscribe(

      next => {this.obecSettings = next;}
    );
  }

  generateColors() {
    if (this.primaryColor) {
      this.onPrimaryColor = this.colorUtilsService.decideForegroundColor(
        this.settings.primaryColor,
        7,
        this.settings.primaryColor
      );


      this.secondaryColor = this.settings.secondaryColor
        ? this.settings.secondaryColor
        : this.colorUtilsService.setLightness(this.settings.primaryColor, 80);

      this.accentColor = this.settings.accentColor ?
        this.settings.accentColor :
         this.settings.primaryColor;


      this.onAccentColor = this.colorUtilsService.decideForegroundColor(
        this.accentColor,
        6.7,
        this.settings.primaryColor
      );

      this.onSecondaryColor = this.colorUtilsService.decideForegroundColor(
        this.secondaryColor,
        7,
        this.settings.primaryColor
      );

      this.subSecondaryColor = this.colorUtilsService.setLightness(this.secondaryColor, 85);
      this.onSubSecondaryColor = this.colorUtilsService.decideForegroundColor(
        this.subSecondaryColor,
        7,
        this.settings.accentColor,
        this.settings.primaryColor,
        this.settings.secondaryColor
      );
    }
  }

}
