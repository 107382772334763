import {Pipe, PipeTransform} from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({name: 'customCurrency', pure: false})
export class CustomCurrencyPipe implements PipeTransform{
    constructor(private cp: CurrencyPipe) {   
    }
    
    transform(value: string, currency?: 'CZK' | 'EUR'): string {
        let temp:any = value;
        if (typeof temp === 'string') {
            temp = parseFloat(value);
        }
        return this.cp.transform(temp, currency,"Kč",null,'cs-CZ');
      }
}