import { FuseNavigation } from '@fuse/types';

export const userNavigation: FuseNavigation[] = [
  {
    id: 'formuláře',
    title: 'Formuláře',
    // translate: 'NAV.SAMPLE.TITLE',
    type: 'item',
    icon: 'assignment',
    url: '/formulare'
  },
  {
    id: 'platebni-prikaz',
    title: 'Platební příkaz',
    // translate: 'NAV.SAMPLE.TITLE',
    type: 'item',
    icon: 'attach_money',
    url: '/platebni_prikazy'
  },
  {
    id: 'napoveda',
    title: 'Nápověda',
    // translate: 'NAV.SAMPLE.TITLE',
    type: 'item',
    icon: 'help',
    url: '/napoveda/user'
  }
];

export const adminNavigation: FuseNavigation[] = [
    {
        id: 'moduly',
        title: 'Moduly',
        // translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'formuláře',
                title: 'Formuláře',
                // translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'assignment',
                url: '/formulare'
            },
            {
                id: 'evidence',
                title: 'Evidence',
                // translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'home',
                url: '/evidence'
            },
            {
                id: 'platebni-prikaz',
                title: 'Platební příkaz',
                // translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'attach_money',
                url: '/platebni_prikazy'
            }
        ]
    },
    {
        id: 'web',
        title: 'Web',
        // translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'prispevky',
                title: 'Příspěvky',
                // translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'short_text',
                url: '/prispevky'
            },
            {
                id: 'stranky',
                title: 'Stránky',
                // translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'filter_none',
                url: '/stranky'
            },
            {
                id: 'uredni_deska',
                title: 'Úřední deska',
                // translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'assignment_late',
                url: '/uredni_deska'
            },
            {
                id: 'udalosti',
                title: 'Události',
                // translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'calendar_today',
                url: '/udalosti'
            },
            {
                id: 'navigace',
                title: 'Navigace',
                // translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'directions',
                url: '/navigace'
            },
          {
            id: 'galerie',
            title: 'Galerie',
            // translate: 'NAV.SAMPLE.TITLE',
            type: 'item',
            icon: 'photo_album',
            url: '/galerie'
          },
        ]
    },
    {
        id: 'cms',
        title: 'CMS',
        // translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'uzivatele',
                title: 'Uživatelé',
                // translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'people',
                url: '/uzivatele'
            },
            {
                id: 'media',
                title: 'Média a soubory',
                // translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'insert_drive_file',
                url: '/media'
            },
            // {
            //     id: 'nastaveni',
            //     title: 'Nastavení',
            //     // translate: 'NAV.DATATABLES',
            //     type: 'group',
            //     icon: 'settings',
            //     children: [
            //         {
            //             id: 'obec',
            //             title: 'Nastavení obce',
            //             // translate: 'NAV.SAMPLE.TITLE',
            //             type: 'item',
            //             icon: 'location_city',
            //             url: '/current-obec'
            //         }
            //     ]
            // },
          {
            id: 'napoveda',
            title: 'Nápověda',
            // translate: 'NAV.SAMPLE.TITLE',
            type: 'item',
            icon: 'help',
            url: '/napoveda/admin'
          }
        ]
    },
    {
        id: 'kontakt',
        title: 'Kontakt',
        type: 'group',
        children: [{
            id: 'sms',
            title: 'SMS',
            type: 'item',
            icon: 'sms',
            url: '/sms'
        },
        {
            id: 'email',
            title: 'Email',
            type: 'item',
            icon: 'email',
            url: '/email'
        }]
    }

    /*{
        id: 'kiosky',
        title: 'Kiosky',
        // translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'kiosky',
                title: 'Kiosky',
                // translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'device_unknown',
                url: '/kiosky'
            },
            {
                id: 'plany',
                title: 'Plány',
                // translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                icon: 'date_range',
                url: '/kiosky'
            }
        ]
    }*/
];
