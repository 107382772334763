import { Component, Input, OnInit } from '@angular/core';
import { FooterProps } from './footer.props';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { BaseComponent } from '../base.component';
import { ColorUtilsService, ObecService } from '@nx-monorepo/obce/ng/services';
import { FooterNavProps } from '../navigace';
import { KontaktProps } from '../kontakt';


@Component({
  selector: 'macms-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements IDynamicComponent, OnInit {
  @Input() data: any;
  @Input() settings: FooterProps;

  private settingsForNavigation: FooterNavProps;
  public settingsForKontakt: KontaktProps;

  ngOnInit(): void {
    //console.log(`Settings and data of ${this.getComponentName()} component`, this.settings, this.data);
    if(this.settings.navSettings){
      this.settingsForNavigation = this.settings.navSettings;
    } else {
      this.settingsForNavigation = {
        primaryColor: "#ffffff",
        //isInverted: true,
      } as FooterNavProps
    }

    if(this.settings.kontaktSettings){
      this.settingsForKontakt = this.settings.kontaktSettings;
    } else {
      this.settingsForKontakt = {
        headingProps:{
          isInverted: this.settings.isNavInverted,
          primaryColor: this.settings.primaryColor,
          boxMargin: {right: 'auto'},
          boxPadding: {left: '0'},
          fontWeight: 'bold',
        },
        fontFamily: this.obecSettings.paragraph_font,
        showPhone: true,
        showOpenHours: true,
        showPersons: false,
        showHeading: true,
        isConcatenated: false,
        nazev: 'Kontakty',
        isInverted: this.settings.isNavInverted,
        primaryColor: this.obecSettings.primary_color,
        secondaryColor: this.obecSettings.primary_color
      };
      //console.log("aaa",this.settingsForNavigation.linksColor);
    }
  }

  constructor(public colorUtilsService: ColorUtilsService, protected obecService: ObecService) {
    super(colorUtilsService, obecService);
  }

  getComponentName() {
    return FooterComponent.name;
  }
}
