import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as tableActions from './table.actions';
import * as MACMSStoreState from '../store.state';
import { SnackbarStoreActions } from '../snackbar';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { KolekceService } from '@nx-monorepo/obce/ng/services';

@Injectable()
export class TableStoreEffects {
  constructor(private kolekceService: KolekceService, private actions$: Actions, private store$: Store<MACMSStoreState.State>) {
  }

  @Effect()
  deleteSelectedEffect$: Observable<Action> = this.actions$.pipe(
    ofType<tableActions.DeleteSelectedItems>(tableActions.TableActionTypes.DELETE_SELECTED),
    withLatestFrom(this.store$),
    switchMap(([action, storeState]) => {
      console.log(storeState);
      const selectedIds = storeState.tableData.selectedItems.map(item => item.id);
      return this.kolekceService.deleteData(EntityTypes[storeState.categories.entityType], selectedIds).pipe(
        map(result => {
          console.log('Delete Selected Data Result', result);
          this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({
            message: `Záznam byl úspěšně smazán`,
            action: 'OK',
            config: { duration: 5000 }
          }));
          return new tableActions.DeleteSelectedSuccess({ deletedIds: selectedIds });
        }),
        catchError((error) => {
          console.log('Delete Selected Data Error', error);
          return observableOf(new tableActions.DeleteSelectedFailure({ error }));
        })
      );
    })
  );

  @Effect()
  fetchAfterInit$: Observable<Action> = this.actions$.pipe(
    ofType<tableActions.InitTableAction>(tableActions.TableActionTypes.INIT_TABLE),
    map((_) => {
      return new tableActions.FetchTableDataAction();
    })
  );

  @Effect()
  fetchDataEffect$: Observable<Action> = this.actions$.pipe(
    ofType<tableActions.FetchTableDataAction>(tableActions.TableActionTypes.FETCH_TABLE_DATA),
    withLatestFrom(this.store$),
    switchMap(([action, storeState]) =>
      this.kolekceService
        .fetchAll(
          storeState.tableData.currentTableSettings.entityType,
          storeState.tableData.currentTableSettings.search,
          storeState.tableData.currentTableSettings.page,
          storeState.tableData.currentTableSettings.take,
          storeState.tableData.currentTableSettings.sortColumn,
          storeState.tableData.currentTableSettings.sortOrder,
          storeState.tableData.currentTableSettings.where,
          storeState.tableData.currentTableSettings.categories
        )
        .pipe(
          map((result: any) => {
            return new tableActions.SetTableDataSuccessAction({
              data: result.data.response
            });
          }),
          catchError((error) => {
            console.error('Fetch table data error', error);
            return observableOf(new tableActions.SetTableDataErrorAction({ error }));
          })
        )
    )
  );
}
