<div class="wrapper uredni-desky-wrapper" [paddingDefinition]="settings.wrapperPadding" [style.background]="settings.wrapperBackground | fixBackgroundUrl"
     [style.backgroundSize]="'cover'">

  <macms-heading [settings]="headingSettings" [text]="settings.nazev || 'Úřední deska'">
  </macms-heading>

  <section id="uredni-desky" class="uredni-desky"
           [style.maxWidth]="settings.sectionMaxWidth"
           [paddingDefinition]="settings.sectionPadding"
           [marginDefinition]="settings.sectionMargin"
           [style.background]="settings.sectionBackground ? (settings.sectionBackground | fixBackgroundUrl) : colorUtilsService.setLightness(settings.primaryColor,92)">
    <div class="grid">
      <ng-container *ngFor="let polozka of data.items">
        <a
          class="item"
          fxLayout="column"
          [routerLink]="(polozka.slug ? polozka.slug : polozka.id) | DetailUrl:'uredni-deska':polozka.kategorie.nazev"
          [borderDefinition]="settings.itemBorders"
          [style.flexBasis]="settings.itemFlexBasis"
          [marginDefinition]="settings.itemMargin"
        >
          <div class="item-wrap">
            <div class="left"
                 [style.background]="settings.secondaryColor ? settings.secondaryColor : 'white'"
                 [paddingDefinition]="settings.leftColPadding">
              <div [style.background]="accentColor"
                   [contrastForeground]="accentColor"
                   [style.height]="'100%'"
                   [borderDefinition]="settings.leftColBorder" [preferedColors]="[settings.primaryColor]">
                <div class="dates" [style.textAlign]='settings.leftColTextAlign ? settings.leftColTextAlign : "center"'
                     [style.fontFamily]="obecSettings.title_font">
                  <div *ngIf="settings.showCalendarIcon" class="calendar icon-wrapper">
                    <fa-icon [icon]="calendarIcon"></fa-icon>
                  </div>
                  <div class="date">{{polozka.datum_od | amLocale:'cs' | amDateFormat:'DD.MM.YY'}}</div>
                  <ng-container *ngIf="polozka.datum_do">
                    <div class="separator">&nbsp;-&nbsp;</div>
                    <div class="date">{{polozka.datum_do | amLocale:'cs' | amDateFormat:'DD.MM.YY'}}</div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="middle" [marginDefinition]="settings.middleColMargin"
                 [paddingDefinition]="settings.middleColPadding"
                 [style.background]="settings.middleColBackground | fixBackgroundUrl"></div>

            <div class="right" [borderDefinition]="settings.rightColBorder">
              <div class="content" [paddingDefinition]="rightColPadding"
                   [style.background]="settings.secondaryColor ? settings.secondaryColor : 'white'"
                   [contrastForeground]="settings.secondaryColor ? settings.secondaryColor : 'white'"
                   [preferedColors]="[settings.primaryColor]">
                <div class="title" [style.fontFamily]="obecSettings.title_font">{{polozka.nazev}}</div>
                <div class="category" [style.fontFamily]="obecSettings.paragraph_font">
                  {{polozka.kategorie.nazev}}
                </div>
              </div>
              <div class="icon">
                <div class="download icon-wrapper" fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 0"
                     [style.backgroundColor]="settings.secondaryColor ? settings.secondaryColor : 'white'"
                     [contrastForeground]="settings.secondaryColor ? settings.secondaryColor : 'white'"
                     [preferedColors]="[settings.primaryColor]">
                  <fa-icon [icon]="downloadIcon"></fa-icon>
                </div>
              </div>
            </div>
          </div>
        </a>
      </ng-container>
    </div>
  </section>
  <macms-next-button href="/uredni-deska" [settings]="nextButtonSettings"
                     text="Další záznamy úřední desky"></macms-next-button>
</div>
