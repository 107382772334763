import { IPkDialogData, IPkFormDialogCompanion } from '../../../pk-dialog/pk-dialog-data';
import { Validators } from '@angular/forms';
import { getDefaultDialogButtons, getNazevSettings } from '../common-settings-objects';
import { IPkInputTextFieldProps } from '../../../pk-input-fields/pk-input-text-field/pk-input-text-field.props';
import { IPkSelectFieldProps } from '../../../pk-input-fields/pk-select-field/pk-select-field.props';
import { IPkInputEntityFieldProps } from '../../../pk-input-fields/pk-input-entity-field/pk-input-entity-field.props';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { UredniDeskyBarrel } from '@nx-monorepo/node-frontend';

export class PkGridsterItemUredniDeskyTwoColumnsDialogCompanion implements IPkFormDialogCompanion {
  getDialogData(settings: UredniDeskyBarrel.TwoColumnUredniDeskyProps, data: {}, emitters: {dialogSubmit, dialogClose}) {
    const dialogData: IPkDialogData = {
      title: 'Upravit úřední desky',
      components: [
        getNazevSettings(settings.nazev),
        {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'sectionMaxWidth',
              type: 'text',
              nazev: 'Maximální šířka sekce',
              povinnost: false,
              defaultValue: settings.sectionMaxWidth ? settings.sectionMaxWidth : ''
            } as IPkInputTextFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 0
          },
        //   {
        //     leftColBorder?: BoxSides<Border>
        //   }
        {
            componentName: 'PkSelectFieldComponent',
            settings: {
              povinnost: false,
              nazev: 'Zarovnání levého stloupce',
              isMultiple: false,
              formControlName: 'leftColTextAlign',
              defaultValue: settings.leftColTextAlign ? settings.leftColTextAlign : 'left',
            } as IPkSelectFieldProps,
            data: [
              {
                id: 'start',
                nazev: 'Začátek'
              },
              {
                id: 'center',
                nazev: 'Střed'
              },
              {
                id: 'end',
                nazev: 'Konec'
              }
            ],
            validators: [],
            cols: 1,
            x: 0,
            y: 2,
          },
          {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Odsazení levého stloupce',
              formControlName: 'leftColPadding',
              povinnost: false,
              defaultValue: settings.leftColPadding ? settings.leftColPadding : null,
            },
            data: null,
            validators: [Validators.pattern(/^\d+$/)],
            cols: 4,
            x: 0,
            y: 3,
          },
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'itemFlexBasis',
              type: 'text',
              nazev: 'Roztažení položky',
              povinnost: false,
              defaultValue: settings.itemFlexBasis ? settings.itemFlexBasis : ''
            } as IPkInputTextFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 4
          },
          {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Odsazení položky',
              formControlName: 'itemMargin',
              povinnost: false,
              defaultValue: settings.itemMargin ? settings.itemMargin : null,
            },
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 5,
          },
          {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Padding středného stloupce',
              formControlName: 'middleColPadding',
              povinnost: false,
              defaultValue: settings.middleColPadding ? settings.middleColPadding : null,
            },
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 6,
          },
          {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Margin středného stloupce',
              formControlName: 'middleColMargin',
              povinnost: false,
              defaultValue: settings.middleColMargin ? settings.middleColMargin : null,
            },
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 7,
          },
          {
            componentName: 'PkInputEntityFieldComponent',
            settings: {
              nazev: 'Pozadí středného stloupce',
              povinnost: false,
              isMultiple: false,
              withPreview: true,
              entityType: EntityTypes.Media,
              formControlName: 'middleColBackground',
              defaultValue: settings.middleColBackground ? settings.middleColBackground : null,
            } as IPkInputEntityFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 8
          },
        //   {
        //     rightColBorder?: BoxSides<Border>;
        //   }
        // {
        //     itemBorders?: BoxSides<Border>;
        // }
        {
          componentName: 'PkInputEntityFieldComponent',
          settings: {
            nazev: 'Pozadí položky',
            povinnost: false,
            isMultiple: false,
            withPreview: true,
            entityType: EntityTypes.Media,
            formControlName: 'itemBackground',
            defaultValue: settings.itemBackground ? settings.itemBackground : null,
          } as IPkInputEntityFieldProps,
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 9
        },
          {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Padding wrapperu',
              formControlName: 'wrapperPadding',
              povinnost: false,
              defaultValue: settings.wrapperPadding ? settings.wrapperPadding : null,
            },
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 10,
          },
          {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Padding sekce',
              formControlName: 'sectionPadding',
              povinnost: false,
              defaultValue: settings.sectionPadding ? settings.sectionPadding : null,
            },
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 11,
          },
          {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Margin sekce',
              formControlName: 'sectionMargin',
              povinnost: false,
              defaultValue: settings.sectionMargin ? settings.sectionMargin : null,
            },
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 12,
          }
      ],
      buttons: getDefaultDialogButtons(emitters)
    };
    return dialogData;
  }
}
