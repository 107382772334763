import { Component, Input, OnInit } from '@angular/core';
import { ObecService } from '@nx-monorepo/obce/ng/services';
import { KontaktProps } from './kontakt.props';
import { HeadingProps } from '../heading/heading.props';
import * as moment from 'moment';

@Component({
  selector: 'macms-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss']
})
export class KontaktComponent implements OnInit {
  @Input() settings: KontaktProps;
  obecSettings$;
  year: string = moment().format('YYYY');
  headingProps: HeadingProps;

  constructor(private obecService: ObecService) { }

  ngOnInit() {
    this.obecSettings$ = this.obecService.settings$;
    if(!this.settings){
      this.settings = {
        showHeading: false,
        nazev: "Kontakty",
        primaryColor: 'white'
      }
    }
    if(this.settings.headingProps){
      this.headingProps = this.settings.headingProps;
    } else {
      this.headingProps = {
        isInverted: this.settings.isInverted,
        primaryColor: 'white'
      }
    }
  }

}
