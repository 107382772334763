<div class="container" fxLayout="row" fxLayout.xs="column">
  <!-- TABLE -->
  <pk-data-table [shouldNavigateAfterRowClick]="false" [canSelectMultiple]="canSelectMultiple" [currentEntity]="mediaEntity" #dataTable [fxFlex]="selectedItem ? '80%' : '0'" (rowItemClicked)="itemClicked($event)"></pk-data-table>

  <!-- RIGHT STUFF -->
  <div *ngIf="selectedItem" [fxFlex]="selectedItem ? '20%' : '0'">
    <div class="file-details">
      <mat-menu #appMenu="matMenu">
        <button mat-menu-item (click)="copyToClipboard()">Kopírovat odkaz do schránky</button>
        <button mat-menu-item (click)="openDialogForEdit()">Upravit</button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>more_vert</mat-icon>
      </button>

      <div class="preview file-icon" fxLayout="row" fxLayoutAlign="center center">

        <!-- IMAGE -->
        <img *ngIf="getType() === 'image'; else video" [src]="selectedItem.url" (click)="isImageDetail = true" class="small-image"/>

        <!-- VIDEO -->
        <ng-template #video>
          <mat-icon *ngIf="getType() === 'video'; else document"> play_arrow </mat-icon>
        </ng-template>

        <!-- DOCUMENT -->
        <ng-template #document>
          <mat-icon > insert_drive_file </mat-icon>
        </ng-template>

      </div>

      <table>
        <tr class="name">
          <th>Název</th>
          <td>{{selectedItem.nazev}}</td>
        </tr>

        <tr class="type">
          <th>Typ</th>
          <td>{{selectedItem.mime}}</td>
        </tr>

        <tr class="created">
          <th>Vytvořeno</th>
          <td>{{selectedItem.created_at | date: 'dd.MM.yyyy hh:mm' }}</td>
        </tr>

      </table>
    </div>

  </div>
</div>

<div class="image-detail-container" *ngIf="isImageDetail">
  <div class="close-button" (click)="isImageDetail = false">
    <mat-icon>close</mat-icon>
  </div>

  <img alt="Detail obrázku" [src]="selectedItem.url" class="image-detail"/>
</div>
