import { Validators } from '@angular/forms';
import { getDefaultDialogButtons, getNazevSettings } from '../common-settings-objects';
import { UdalostiBarrel } from '@nx-monorepo/node-frontend';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { IPkInputCheckboxFieldProps, 
          IPkInputTextFieldProps, 
          IPkColorPickerProps, 
          IPkInputEntityFieldProps, 
          IPkSelectFieldProps 
} from '@nx-monorepo/cms-base/components';
import { IPkFormDialogCompanion, IPkDialogData } from '@nx-monorepo/cms-base/components/pk-dialog/pk-dialog-data';

export class PkGridsterItemUdalostiDialogCompanion implements IPkFormDialogCompanion {
  getDialogData(settings: UdalostiBarrel.UdalostiProps, data: {}, emitters: {dialogSubmit, dialogClose}) {
    const dialogData: IPkDialogData = {
      title: 'Upravit události',
      components: [
        getNazevSettings(settings.nazev),
        // {
        //   headingProps
        //  button? na PkGridsterItemHeaderDialogCompanion
        // }
        {
            componentName: 'PkInputCheckboxFieldComponent',
            settings: {
                povinnost: true,
                nazev: 'Zobrazit kategorii',
                formControlName: 'showCategory',
                defaultValue: settings.showCategory ? settings.showCategory : false,
            } as IPkInputCheckboxFieldProps,
            data: null,
            validators: [Validators.required],
            cols: 2,
            x: 0,
            y: 1,
        }, 
        {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'maxColumns',
              type: 'number',
              nazev: 'Maximální počet událostí',
              povinnost: true,
              defaultValue: settings.maxColumns ? settings.maxColumns : ''
            } as IPkInputTextFieldProps,
            data: null,
            validators: [Validators.required],
            cols: 4,
            x: 0,
            y: 2
          },
          {
            componentName: 'PkColorPickerFieldComponent',
            settings: {
              defaultValue: settings.secondaryColor ? settings.secondaryColor : '',
              showAlways: false,
              povinnost: false,
              type: 'text',
              nazev: 'Sekundární barva',
              formControlName: 'secondaryColor',
            } as IPkColorPickerProps,
            data: null,
            validators: [],
            cols: 2,
            x: 0,
            y: 3,
          },
          {
            componentName: 'PkColorPickerFieldComponent',
            settings: {
              showAlways: false,
              povinnost: false,
              type: 'text',
              nazev: 'Accent color',
              formControlName: 'accentColor',
              defaultValue: settings.accentColor ? settings.accentColor : '',
            } as IPkColorPickerProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 4,
          },
          {
            componentName: 'PkInputEntityFieldComponent',
            settings: {
              nazev: 'Pozadí levého stloupku',
              povinnost: false,
              isMultiple: false,
              withPreview: true,
              entityType: EntityTypes.Media,
              formControlName: 'leftColBackground',
              defaultValue: settings.leftColBackground ? settings.leftColBackground : null,
            } as IPkInputEntityFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 5,
          },
          {
            componentName: 'PkSelectFieldComponent',
            settings: {
              povinnost: true,
              nazev: 'Zarovnání textu levého stloupce',
              isMultiple: false,
              formControlName: 'leftColTextAlign',
              defaultValue: settings.leftColTextAlign ? settings.leftColTextAlign : 'start',
            } as IPkSelectFieldProps,
            data: [
              {
                id: 'start',
                nazev: 'Začátek'
              },
              {
                id: 'center',
                nazev: 'Střed'
              },
              {
                id: 'end',
                nazev: 'end'
              }
            ],
            validators: [Validators.required],
            cols: 1,
            x: 0,
            y: 6,
          },
          {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Padding levého stloupce',
              formControlName: 'leftColPadding',
              povinnost: true,
              napoveda: {
                isIcon: false,
                text: 'Zadejte celé číslo',
              },
              defaultValue: settings.leftColPadding ? settings.leftColPadding : null,
            },
            data: null,
            validators: [Validators.pattern(/^\d+$/), Validators.required],
            cols: 4,
            x: 0,
            y: 7,
          },
          {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Padding středového stloupce',
              formControlName: 'middleColPadding',
              povinnost: false,
              napoveda: {
                isIcon: false,
                text: 'Zadejte celé číslo',
              },
              defaultValue: settings.middleColPadding ? settings.middleColPadding : null,
            },
            data: null,
            validators: [Validators.pattern(/^\d+$/)],
            cols: 4,
            x: 0,
            y: 8,
          },
          {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Marging středového stloupce',
              formControlName: 'middleColMargin',
              povinnost: false,
              napoveda: {
                isIcon: false,
                text: 'Zadejte celé číslo',
              },
              defaultValue: settings.middleColMargin ? settings.middleColMargin : null,
            },
            data: null,
            validators: [Validators.pattern(/^\d+$/)],
            cols: 4,
            x: 0,
            y: 12,
          },
          {
            componentName: 'PkInputEntityFieldComponent',
            settings: {
              nazev: 'Pozadí středového stloupce',
              povinnost: false,
              isMultiple: false,
              withPreview: true,
              entityType: EntityTypes.Media,
              formControlName: 'middleColBackground',
              defaultValue: settings.middleColBackground ? settings.middleColBackground : null,
            } as IPkInputEntityFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 8
          },
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'itemFlexBasis',
              type: 'text',
              nazev: 'Nastavení šířky prvku',
              povinnost: false,
              defaultValue: settings.itemFlexBasis ? settings.itemFlexBasis : ''
            } as IPkInputTextFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 14
          },
          {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Nastavení paddingu prvku',
              formControlName: 'itemPadding',
              povinnost: false,
              defaultValue: settings.itemPadding ? settings.itemPadding : null,
            },
            data: null,
            validators: [Validators.pattern(/^\d+$/)],
            cols: 4,
            x: 0,
            y: 15,
          },
        //   {
        //    itemBorders: BoxSides<Border>;
        //   },
        {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Nastavení margin prvku',
              formControlName: 'itemMargin',
              povinnost: false,
              defaultValue: settings.itemMargin ? settings.itemMargin : null,
            },
            data: null,
            validators: [Validators.pattern(/^\d+$/)],
            cols: 4,
            x: 0,
            y: 16,
          },
          {
            componentName: 'PkInputBoxFieldComponent',
            settings: {
              nazev: 'Margin pravého stloupce',
              formControlName: 'rightColPadding',
              povinnost: true,
              defaultValue: settings.rightColPadding ? settings.rightColPadding : null,
            },
            data: null,
            validators: [Validators.pattern(/^\d+$/), Validators.required],
            cols: 4,
            x: 0,
            y: 17,
          },
          {
            componentName: 'PkInputEntityFieldComponent',
            settings: {
              nazev: 'Pozadí pravého stloupce',
              povinnost: false,
              isMultiple: false,
              withPreview: true,
              entityType: EntityTypes.Media,
              formControlName: 'rightColBackground',
              defaultValue: settings.rightColBackground ? settings.rightColBackground : null,
            } as IPkInputEntityFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 18
          },
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'dateFont',
              type: 'text',
              nazev: 'Font datumu',
              povinnost: false,
              defaultValue: settings.dateFont ? settings.dateFont : ''
            } as IPkInputTextFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 19
          },
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'titleFont',
              type: 'text',
              nazev: 'Font titulky',
              povinnost: false,
              defaultValue: settings.titleFont ? settings.titleFont : ''
            } as IPkInputTextFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 20
          },
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'textFont',
              type: 'text',
              nazev: 'Font textu',
              povinnost: false,
              defaultValue: settings.textFont ? settings.textFont : ''
            } as IPkInputTextFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 21
          }
      ],
      buttons: getDefaultDialogButtons(emitters)
    };
    return dialogData;
  }
}
