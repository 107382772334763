<ng-container>
  <form [formGroup]="navigaceForm">
    <pk-input-text-field [settings]="nazev_settings"></pk-input-text-field>
  </form>
  <div class="nav-tree-wrapper">
    <div class="text-empty" *ngIf="!nodes.length">
      <p>Začněte přidáním složky nebo stránky</p>
    </div>

    <tree-root (activate)="onNodeActivate($event)" #tree [nodes]="nodes" [options]="options">
      <ng-template #treeNodeWrapperTemplate let-node let-index="index">
        <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
          <div (click)="node.toggleExpanded()" [ngClass]="!node.hasChildren ? 'hidden-icon' : ''">
            <mat-icon *ngIf="node?.isCollapsed">keyboard_arrow_right</mat-icon>
            <mat-icon *ngIf="node?.isExpanded">keyboard_arrow_down</mat-icon>
          </div>

          <div class="node-content-wrapper"
               [class.node-content-wrapper-active]="node.isActive"
               [class.node-content-wrapper-focused]="node.isFocused"
               (treeDrop)="node.onDrop($event)"
               [treeAllowDrop]="node.allowDrop"
               [treeDrag]="node"
               [treeDragEnabled]="node.allowDrag()"
               (click)="node.mouseAction('click', $event)">

            <!-- ICON -->
            <mat-icon>{{node.data.type === 'Slozka' ? 'folder' : 'insert_drive_file'}}</mat-icon>

            <!-- NAZEV -->
            <span class="node-text">{{node.data.nazev}}</span>
            <ng-container *ngIf="node.data.type === 'Stranka'">
              <small>({{node.data.page? (node.data.page.nazev ? node.data.page.nazev : "url:"+node.data.page) : ""}})</small>
            </ng-container>

            <!-- VERT MENU FOR EDIT AND DELETE -->
            <button mat-icon-button [matMenuTriggerFor]="appMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #appMenu="matMenu">
              <button mat-menu-item (click)="editNode(node)">Upravit</button>
              <button mat-menu-item (click)="deleteNode(node)">Smazat</button>
            </mat-menu>


          </div>
        </div>
      </ng-template>
    </tree-root>
  </div>
</ng-container>
