<div class="wrapper newsletter-wrapper" [style.background]="settings.wrapperBackground">
  <section [style.background]="settings.sectionBackground | fixBackgroundUrl" >
  <macms-heading
    [settings]="headingSettings"
    [text]="settings.nazev || 'Newsletter'">
  </macms-heading>

  <div class="newsletter">
    <p [style.font-family]="paragraphFont">Vyžádejte si bezplatné zasílání novinek do e-mailové schránky.</p>
    <label [style.font-family]="paragraphFont" for="email">Email</label>
    <div *ngIf="submitClass" [class]="submitClass" [style.font-family]="paragraphFont">
      {{submitMessage}}
    </div>
    <div class="wrapper" [style.font-family]="paragraphFont">
      <form ngForm (ngSubmit)="onSubmit()">
        <input #email type="email" name="email" id="email" placeholder="adresa@domena.cz" />
        <input [style.font-family]="titleFont" type="submit" value="Odeslat" />
      </form>
    </div>
    <p [style.font-family]="paragraphFont">odesíláním souhlasíte se zpracováním údajů</p>
  </div>
</section>
</div>
