import gql from 'graphql-tag';

export const readAllUredniDeskaQuery = gql`
  query ReadAllUredniDeska($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $categories: [Int!]) {
    response: readAllUrednideska(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      categories: $categories
    ) {
      total
      page
      take
      hasMore
      items {
        id
        kategorie {
          id
          nazev
        }
        autor {
          jmeno
          prijmeni
        }
        nazev
        popis
        datum_od
        slug
        datum_do
        media {
          id
          nazev
          mime
          url
        }
      }
    }
  }
`;
