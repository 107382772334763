// auth.guard.ts
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (!this.authService.isLoggedIn()) {
      // neni zalogovany ale ma validni refresh token
      if(this.authService.hasValidRefreshToken()) {
        return from(this.authService.refresh(this.authService.getToken(), this.authService.getRefreshToken()))
          .pipe(
            // switchMap the result from original promise
            map((is_successful: boolean) => {
              if(!is_successful){
                this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
                return false;
              }
              return is_successful;
            }));
      } else {
        this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return false;
      }
    } else {
      return true;
    }
  }

}
