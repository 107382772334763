import { createFeatureSelector, createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { TableState } from './table.state';

const getData = (state: TableState<any>): any => state.data;
const getSelectedData = (state: TableState<any>): any[] => state.selectedItems;
const getLoading = (state: TableState<any>): boolean => state.isLoading;
const getError = (state: TableState<any>): string => state.error;
export const isAllSelected = (state: TableState<any>): boolean => {
  // projdeme vsechnt itemy na strance, pokud se alespon jeden nenachazi v arrayi selectnutych, tak nejsou selectnute vsechny na strance
  for (let i = 0; i < state.data.items.length; i++) {
    if (!state.selectedItems.find(item => item.id === state.data.items[i].id)) {
      return false;
    }
  }
  return true;
};

export const selectTableFeature: Selector<object, TableState<any>> = createFeatureSelector<TableState<any>>(
  'tableData'
);

export const selectTableData: Selector<object, any> = createSelector(
  selectTableFeature,
  getData
);

export const getTableItemById = createSelector(
  selectTableFeature,
  (state: TableState<any>, props: any) => {
    return state.data.items.find(item => item.id === props.id);
  }
);

export const selectSelectedItems: Selector<object, any[]> = createSelector(
  selectTableFeature,
  getSelectedData
);

export const selectTableDataLoading: Selector<object, boolean> = createSelector(
  selectTableFeature,
  getLoading
);

export const selectTableDataError: Selector<object, string> = createSelector(
  selectTableFeature,
  getError
);

export const selectIsAllSelected: Selector<object, boolean> = createSelector(
  selectTableFeature,
  isAllSelected
);
