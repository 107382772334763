<mat-form-field appearance="outline" fxFlex [formGroup]="settings.form">
  <mat-label>{{ settings.nazev }}</mat-label>
  <ng-container [ngSwitch]="settings.isTextArea">
    <!-- POKUD JE TO DOPICE ARRAY VECI -->
    <ng-container *ngIf="!settings.formArray">
      <input matInput *ngSwitchCase="false" [disabled]="disabled" [type]="settings.type" [required]="settings.povinnost" [formControlName]="settings.formControlName"/>
      <textarea matInput *ngSwitchCase="true" [disabled]="disabled" [type]="settings.type" [required]="settings.povinnost"
                [formControlName]="settings.formControlName"></textarea>
    </ng-container>

    <!-- POKUD TO DOPICE NENI ARRAY -->
    <ng-container [formArrayName]="settings.formArray.name" *ngIf="settings.formArray">
      <ng-container [formGroupName]="settings.formArray.index">
        <input matInput *ngSwitchCase="false" [disabled]="disabled" [type]="settings.type" [required]="settings.povinnost" [formControlName]="settings.formControlName"/>
        <textarea matInput *ngSwitchCase="true" [disabled]="disabled" [type]="settings.type" [required]="settings.povinnost"
                  [formControlName]="settings.formControlName"></textarea>
      </ng-container>
    </ng-container>
  </ng-container>


  <!-- HINTS -->
  <mat-hint align="end" *ngIf="settings.maxLength && settings.maxLength > 0">{{formControl.value?.length ? formControl.value?.length : 0}}/{{settings.maxLength}}</mat-hint>
  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info</mat-icon>

  <!-- ERROR -->
  <mat-error *ngIf="!settings.form.get(this.settings.formControlName)?.valid">
    {{firstError}}
  </mat-error>

  <!-- PASSWORD VISIBILITY -->
  <button *ngIf="isPassword" mat-icon-button matSuffix (click)="hideText()" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="isHidden">
    <mat-icon>{{isHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
  </button>

  <!-- PASSWORD GENERATOR -->
  <button *ngIf="settings.type === 'newpassword'" matTooltip="Vygenerovat náhodné heslo" mat-icon-button matSuffix (click)="genPassword()"
          [attr.aria-label]="'Generate password'">
    <mat-icon>vpn_key</mat-icon>
  </button>

</mat-form-field>

