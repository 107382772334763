<div id="error-404" fxLayout="column" fxLayoutAlign="center center">

  <div class="content" fxLayout="column" fxLayoutAlign="center center">

    <div class="error-code">404</div>

    <div class="message">Omlouváme se, ale požadovaná stránka nebyla nalezena. </div>

    <!--<a class="back-link" (click)="goBack()">Přejít zpátky</a>-->
    <hr/>
    <a class="back-link" [routerLink]="'/'">Přejít na hlavní stranu</a>
    <img alt="logo echopix cms" class="logo-small" src="assets/images/logos/echopix_cms_logo_rect.svg" />
  </div>

</div>
