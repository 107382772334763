import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { categoryTabsReducer } from './category-tabs.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CategoryTabsEffects } from './category-tabs.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('categories', categoryTabsReducer),
    EffectsModule.forFeature([CategoryTabsEffects])
  ]
})
export class CategoryTabsModule {
  constructor(){}
}
