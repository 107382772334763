import gql from 'graphql-tag';

export const deleteUredniDesky = gql`
  mutation deleteUredniDesky($ids: [Int!]!) {
    response: deleteUrednideska(ids: $ids) {
      description
      userMessage
      error
    }
  }
`;
