import gql from 'graphql-tag';

export const readAllFormularQuery = gql`
  query ReadAllFormular($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $categories: [Int!]) {
    response: readAllFormular(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      categories: $categories
    ) {
      total
      page
      take
      hasMore
      items {
        id
        kategorie {
          id
          nazev
        }
        autor {
          jmeno
          prijmeni
        }
        pole{
          data
          vstupni_pole{
            id
            nazev
          }
        }
        stav
        pdf_uuid
        created_at
        updated_at
      }
    }
  }
`;
