<ng-container>

  <table mat-table
    [dataSource]="(data$ | async)?.items"
    matSort
    [@animateStagger]="{ value: '50' }"
    fusePerfectScrollbar
  >
    <ng-container
      *ngFor="let definition of columnDefinitions"
      [ngSwitch]="definition.type"
      matColumnDef="{{ definition.key.join('.') }}"
    >
      <!-- SELECT COLUMN -->
      <ng-container *ngSwitchCase="'select'">
        <!-- the async container -->
        <ng-container *ngIf="{items: selectedItems$ | async, isAllSelected: isAllSelected$ | async} as selection">
          <!-- MASTER CHECKBOX CELL -->
          <th mat-header-cell *matHeaderCellDef
                           [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']">
            <mat-checkbox
              *ngIf="canSelectMultiple"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.items.length > 0 && selection.isAllSelected"
              [indeterminate]="selection.items.length > 0 && !selection.isAllSelected"
            >
            </mat-checkbox>
          </th>
          <!-- ITEM CHECKBOX CELL -->
          <td mat-cell *matCellDef="let row"
                    [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="toggleSelected(row)"
              [checked]="isChecked(selection.items, row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>
      </ng-container>

      <!-- STRING COLUMN -->
      <ng-container *ngSwitchCase="'string'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
                         [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']"
                         [disabled]="!definition.sortable"
                         [fxHide.lt-sm]="definition.fxHide.sm"
                         [fxHide.lt-md]="definition.fxHide.md"
                         [fxHide.lt-lg]="definition.fxHide.lg">
          {{ definition.title }}
      </th>

        <td mat-cell *matCellDef="let item"
                  [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']"
                  [fxHide.lt-sm]="definition.fxHide.sm"
                  [fxHide.lt-md]="definition.fxHide.md"
                  [fxHide.lt-lg]="definition.fxHide.lg">
          <p class="text-truncate">
            {{ resolveKeyOrShowDefault(definition.key, item, definition.nullValue) }}
          </p>
        </td>
      </ng-container>

      <!-- DATE COLUMN -->
      <ng-container *ngSwitchCase="'date'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
                         [disabled]="!definition.sortable"
                         [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']"
                         [fxHide.lt-sm]="definition.fxHide.sm"
                         [fxHide.lt-md]="definition.fxHide.md"
                         [fxHide.lt-lg]="definition.fxHide.lg">
          {{ definition.title }}
      </th>

        <td mat-cell *matCellDef="let item"
                  [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']"
                  [fxHide.lt-sm]="definition.fxHide.sm"
                  [fxHide.lt-md]="definition.fxHide.md"
                  [fxHide.lt-lg]="definition.fxHide.lg">
          <p class="text-truncate">
            {{ resolveKeyOrShowDefault(definition.key, item, definition.nullValue)  | date: 'dd.MM.yyyy' }}
          </p>
        </td>
      </ng-container>

      <!-- DATETIME COLUMN -->
      <ng-container *ngSwitchCase="'date-time'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
                         [disabled]="!definition.sortable"
                         [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']"
                         [fxHide.lt-sm]="definition.fxHide.sm"
                         [fxHide.lt-md]="definition.fxHide.md"
                         [fxHide.lt-lg]="definition.fxHide.lg">
          {{ definition.title }}
      </th>

        <td mat-cell *matCellDef="let item"
                  [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']"
                  [fxHide.lt-sm]="definition.fxHide.sm"
                  [fxHide.lt-md]="definition.fxHide.md"
                  [fxHide.lt-lg]="definition.fxHide.lg">
          <p class="text-truncate">
            {{ resolveKeyOrShowDefault(definition.key, item, definition.nullValue)  | date: 'dd.MM.yyyy HH:mm' }}
          </p>
        </td>
      </ng-container>


      <!-- FORMAT COLUMN -->
      <ng-container *ngSwitchCase="'format'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
                         [disabled]="!definition.sortable"
                         [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']"
                         [fxHide.lt-sm]="definition.fxHide.sm"
                         [fxHide.lt-md]="definition.fxHide.md"
                         [fxHide.lt-lg]="definition.fxHide.lg">
          {{ definition.title }}
      </th>

        <td mat-cell *matCellDef="let item"
                  [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']"
                  [fxHide.lt-sm]="definition.fxHide.sm"
                  [fxHide.lt-md]="definition.fxHide.md"
                  [fxHide.lt-lg]="definition.fxHide.lg">
          <p class="text-truncate">
            {{ resolveKeyOrShowDefault(definition.key, item, definition.nullValue) | format:definition.settings }}
          </p>
        </td>
      </ng-container>

      <!-- STATUS COLUMN -->
      <ng-container *ngSwitchCase="'status'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
                         [disabled]="!definition.sortable"
                         [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']"
                         [fxHide.lt-sm]="definition.fxHide.sm"
                         [fxHide.lt-md]="definition.fxHide.md"
                         [fxHide.lt-lg]="definition.fxHide.lg">
          {{ definition.title }}
        </th>

        <td mat-cell *matCellDef="let item"
                  [ngClass]="['column-' + definition.align, definition.shrink ? 'column-shrink' : '']"
                  [fxHide.lt-sm]="definition.fxHide.sm"
                  [fxHide.lt-md]="definition.fxHide.md"
                  [fxHide.lt-lg]="definition.fxHide.lg">
          <pk-status-cell
            [settings]="definition.settings"
            [text]="resolveKeyOrShowDefault(definition.key, item, definition.nullValue) ">

          </pk-status-cell>
        </td>
      </ng-container>

    </ng-container>

    <!-- HEADER ROW -->
    <tr mat-header-row
      *matHeaderRowDef="visibleColumns; sticky: true"
    ></tr>

    <!-- TABLE ROW -->
    <tr mat-row
      *matRowDef="let item; columns: visibleColumns"
      class="entity-row"
      [ngClass]="{'selected' : item.id == selected?.id}"
      (click)="onRowClick(item)"
    ></tr>

  </table>
  <div class="no-data" *ngIf="(data$ | async)?.items.length === 0">
    Žádná data k zobrazení
  </div>
  <mat-paginator
    #paginator
    [length]="(data$ | async).total"
    [pageIndex]="(data$ | async).page"
    [pageSize]="(data$ | async).take"
    [pageSizeOptions]="pageSizeOptions"
  >
  </mat-paginator>
  <!--  <p>Počet zvolených položek: {{(selectedItemIDs$ | async)?.length}}</p>-->

</ng-container>
