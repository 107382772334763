import gql from 'graphql-tag';

export const readSelectedUredniDeskaQuery = gql`
  query ReadSelectedUredniDeska($ids: [Int!], $slug: String) {
    response: readSelectedUrednideska(
      ids: $ids,
      slug: $slug
    ) {
      id
      nazev
      popis
      slug
      media{
        id
        nazev
        mime
        url
        kategorie_id
      }
      kategorie{
        nazev
      }
      datum_od
      datum_do
      kategorie_id
      autor_id
    }
  }
`;
