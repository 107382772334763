import { Component, Inject } from "@angular/core";
import { IPkConfirmDialog } from './pk-confirm-dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './pk-confirm-dialog.component.html',
    styleUrls: ['./pk-confirm-dialog.component.scss'],
})

export class PkConfirmDialogComponent{

    constructor( 
        @Inject(MAT_DIALOG_DATA) public data: IPkConfirmDialog
        ){
        //console.log("ON INIT", this.data);
    }
}
