import { Validators } from '@angular/forms';
import { KATEGORIE_SERVICE_TOKEN } from '@nx-monorepo/obce/ng/services';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { IPkHiddenFieldProps, 
        IPkInputEntityFieldProps, 
        IPkInputTextFieldProps, 
        IPkSelectFieldProps, 
        IPkInputCheckboxFieldProps, 
        IPkInputDateFieldProps, 
        IPkInputWysiwygProps } from '@nx-monorepo/cms-base/components';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';

export const udalostEntityDefinition: IEntityDefinition[] = [
  {
    componentName: 'PkHiddenFieldComponent',
    settings: {
      type: 'number',
      formControlName: 'id',
      defaultValue: 0
    } as IPkHiddenFieldProps,
    data: null,
    validators: [],
    cols: 0,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputEntityFieldComponent',
    settings: {
      nazev: 'Hlavní obrázek',
      povinnost: false,
      napoveda: {
        isIcon: false,
        text: 'Hlavní obrázek příspěvku'
      },
      isMultiple: false,
      withPreview: true,
      entityType: EntityTypes.Media,
      formControlName: 'media',
      defaultValue: '',
    } as IPkInputEntityFieldProps,
    data: null,
    validators: [],
    cols: 4,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Název',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Zadejte název události'
      },
      isTextArea: false,
      formControlName: 'nazev',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 2,
    x: 0,
    y: 1
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Popis',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Zadejte popisek události'
      },
      isTextArea: false,
      formControlName: 'popis',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 2,
    x: 2,
    y: 1
  },
  {
    componentName: 'PkSelectFieldComponent',
    settings: {
      nazev: 'Kategorie',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Vyberte kategorii události'
      },
      formControlName: 'kategorie_id',
      isMultiple: false,
      service: {
        token: KATEGORIE_SERVICE_TOKEN,
        fetchAllArgs: {
          types: [EntityTypes.Udalost]
        },
        fetchSingleArgs: {}
      },
    } as IPkSelectFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 2,
    x: 0,
    y: 2
  },
  {
    componentName: 'PkInputCheckboxFieldComponent',
    settings: {
      nazev: 'Upozornit odběratele',
      formControlName: 'featured',
      defaultValue: false,
    } as IPkInputCheckboxFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 3,
    x: 2,
    y: 2
  },
  {
    componentName: 'PkInputDateTimeFieldComponent',
    settings: {
      nazev: 'Začátek',
      povinnost: true,
      napoveda: {
        text: 'Zadejte datum začátku události',
        isIcon: true
      },
      formControlName: 'datum_zacatek',
      defaultValue: new Date()
    } as IPkInputDateFieldProps,
    validators: [Validators.required],
    data: null,
    cols: 2,
    x: 0,
    y: 3
  },
  {
    componentName: 'PkInputDateTimeFieldComponent',
    settings: {
      nazev: 'Konec',
      povinnost: false,
      napoveda: {
        text: 'Zadejte datum konce události',
        isIcon: true
      },
      formControlName: 'datum_konec',
      defaultValue: null
    } as IPkInputDateFieldProps,
    data: null,
    validators: [],
    cols: 2,
    x: 2,
    y: 3
  },
  {
    componentName: 'PkInputWysiwygComponent',
    settings: {
      nazev: 'Obsah',
      povinnost: true,
      formControlName: 'obsah',
      defaultValue: null
    } as IPkInputWysiwygProps,
    data: null,
    validators: [],
    cols: 4,
    x: 0,
    y: 4
  }
];
