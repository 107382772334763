import gql from 'graphql-tag';

export const readSelectedKontaktQuery = gql`
    query ReadSelectedKontakt($ids: [Int!]!) {
        response: readSelectedKontakt(
            ids: $ids
        ) {
            id
            nazev
            ulice
            psc
            dic
            ico
        }
    }
`;
