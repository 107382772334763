import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup,Validators} from '@angular/forms';
import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from "@angular/router";

import {Store} from "@ngrx/store";
import {State} from "../../../store/store.state";
import { KolekceService } from '@nx-monorepo/obce/ng/services';
import { setPassword } from '@nx-monorepo/obce/common/mutations';
import { displaySnack } from '@nx-monorepo/cms-base/helpers';
import { IServerResponse } from '@nx-monorepo/obce/common/interfaces';

@Component({
  selector: 'pk-set-password-page',
  templateUrl: './pk-set-password-page.component.html',
  styleUrls: ['./pk-set-password-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class PkSetPasswordPageComponent implements OnInit {
  // data
  loginForm: FormGroup;
  password = null;
  errorMessage = null;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private currentRoute: ActivatedRoute,
    private kolekceService: KolekceService,
    protected store$: Store<State>,
    protected router: Router,
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        },
        header: {
          hidden: true
        },
        content: {
          carded: false
        }
      }
    };

  }

  ngOnInit(): void {

    this.loginForm = this._formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  onSubmit(){
    const {token} = this.currentRoute.snapshot.params;

    const payload = {
      token: token,
      password: this.loginForm.value.password
    };

    this.kolekceService.executeMutation(setPassword, payload).toPromise()
      .then((mutationResponse: {data: {response: IServerResponse}}) => {
        if (!mutationResponse.data.response.isSuccessful) {
          displaySnack(mutationResponse.data.response.message, this.store$);
        } else {
          displaySnack('Heslo bylo úspěšně nastaveno. Můžete se přihlásit.', this.store$);
        }
        this.router.navigateByUrl('/login');
      })
      .catch(err => {
        displaySnack(err.message || 'Nepodařilo se nastavit heslo, zkuste to prosím později!', this.store$);
      })

  }

}


