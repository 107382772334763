<section [style.background]="settings.sectionBackground">
  <nav class="hover-list" [mc_isInverted]="settings.isInverted" [mc_wantedPrimary]="settings.primaryColor" macmsColor >
    <ng-container *ngFor="let item of navigacePolozky; let i = index">
      <span class='nav-span' *ngIf="item.children && item.children.length > 0">

        <a mat-button [matMenuTriggerFor]="menu.childMenu" >
          <fa-icon [icon]='item.ikonka' *ngIf="item.ikonka"></fa-icon>
          <span [style.fontFamily]="obecSettings.title_font">{{item.nazev}}</span>
        </a>
        <navigace-item #menu [items]="item.children" [itemFont]="obecSettings.paragraph_font"></navigace-item>
    </span>
      <!-- Leaf node buttons here -->
      <span class='nav-span' *ngIf="!item.children || item.children.length === 0">
      <ng-container *ngTemplateOutlet="item.url ? outsideLink : insideLink; context: {item: item}">
      </ng-container>
    </span>
    </ng-container>
  </nav>
</section>

<ng-template #insideLink let-item="item">
  <a mat-button color="primary" [routerLink]="(item.stranka ? item.stranka.slug : null) | DetailUrl:'stranky'"
     [style.fontFamily]="obecSettings.title_font">
    {{item.nazev}}
  </a>
</ng-template>
<ng-template #outsideLink let-item="item">
  <a mat-button color="primary" [href]="item.url" [style.fontFamily]="obecSettings.title_font">
    {{item.nazev}}
  </a>
</ng-template>
