import gql from 'graphql-tag';

export const readAllStrankaQuery = gql`
  query ReadAllStranka($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $categories: [Int!]) {
    response: readAllStranka(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      categories: $categories
    ) {
      total
      page
      take
      hasMore
      items {
        id
        is_edit_locked
        nazev
        slug
        autor {
          jmeno
          owner {
            nazev
          }
        }
      }
    }
  }
`;
