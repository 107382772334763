import { NgModule } from '@angular/core';
import { DynamicCMSComponentFactoryDirective } from './dynamic-component-factory.directive';

@NgModule({
  declarations: [DynamicCMSComponentFactoryDirective],
  exports: [DynamicCMSComponentFactoryDirective],
})
export class DynamicRendererModule{

}
