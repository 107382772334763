import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  CompactType,
  DisplayGrid,
  GridsterComponent,
  GridsterConfig,
  GridsterItem,
  GridType
} from 'angular-gridster2';
import { PkModuleItem } from '../pk-modulelist/pk-modulelist.component';
import { PkGridsterItem } from '../pk-gridster-items/pk-gridster-item';
import { PkGridsterItemDefinitionService } from '../../layout/components/pk-page-contents/pk-gridster-page/pk-gridster-item-definition.service';
import { isObservable } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'pk-gridster',
  templateUrl: './pk-gridster.component.html',
  styleUrls: ['./pk-gridster.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class PkGridsterComponent implements OnInit {


  constructor(private itemDefinitionService: PkGridsterItemDefinitionService) {
  }

  @Output()
  deleteItem: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('gridster', { static: true })
  gridster: GridsterComponent;

  @Input() showPreview;

  @Input() form: FormGroup;

  gridsterOptions: GridsterConfig;
  gridsterItems: Array<PkGridsterItem> = [];
  resizeEvent: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    //fixme FUCK GRIDSTER
    this.gridsterOptions = {
      gridType: GridType.ScrollVertical,
      displayGrid: DisplayGrid.Always,
      enableEmptyCellDrop: true,
      compactType: CompactType.CompactLeftAndUp,
      emptyCellDropCallback: this.onItemDrop.bind(this),
      itemChangeCallback: this.gridItemChanged.bind(this),
      itemResizeCallback: item => {
        // send the update to widgets
        this.resizeEvent.emit(item);
      },
      minCols: 4,
      maxCols: 4,
      pushItems: true,
      pushDirections: {
        south: true,
        east: true,
        north: false,
        west: true
      },
      draggable: {
        enabled: true,
        ignoreContent: true,
        dragHandleClass: 'pk-drag-handler'
        // dropOverItems: true
      },
      pushResizeItems: true,
      resizable: {
        enabled: true,
        handles: {
          s: false,
          e: true,
          n: false,
          w: true,
          se: false,
          ne: false,
          sw: false,
          nw: false
        }
      }

    };

  }

  setItems(items: PkGridsterItem[]) {
    this.gridsterItems = [];
    items.sort((a, b) => {
      if (a.x > b.x) return 1;
      else if (a.x < b.x) return -1;
      else if (a.y > b.y) return 1;
      else if (a.y < b.y) return -1;
      else return 0;
    });
    items.forEach(item => {
     this.fillItemData(item)
    })
    this.gridsterItems.push(...items);
    this.resizeGrid();
  }

  fillItemData(item: PkGridsterItem) {
    const possibleData = this.itemDefinitionService.getItemDataByType(item.moduleItem.type);
    // this can be observable, or empty object
    if (isObservable(possibleData)) {
      possibleData.subscribe(data => {
        item.data = data;
        console.log('setting data', data);
      });
    } else {
      item.data = possibleData;
    }
  }

  onItemDrop(event: DragEvent, item: PkGridsterItem): void {
    // get data from drop source (JSON)
    const jsonItem = JSON.parse(event.dataTransfer.getData('text/plain'));

    // set defaults and data again
    item.moduleItem = this.itemDefinitionService.findItemByType(jsonItem.type) as PkModuleItem;
    item.resizeEvent = this.resizeEvent;

    const formControlName = `generated_${item.moduleItem.title}_${Math.random()}`;
    this.form.addControl(formControlName, new FormControl());
    item.settings = { form: this.form, formControlName: formControlName };
    this.fillItemData(item)
    // push item to gridster
    this.gridsterItems.push(item);
    this.resizeGrid();
  }

  gridItemChanged(): void {
    this.resizeGrid();
  }

  private getSouthestItem(): number {
    let maxY = -1;
    this.gridsterItems.forEach((item: GridsterItem) => {
      if (item.y > maxY) {
        maxY = item.y;
      }
    });
    return maxY;
  }

  /**
   * Always resize the grid so the number of rows is one greater than the current southest item
   * setTimeout is 'hack' to prevent item overlap when resizing. this way the resize will happen first
   * and then recalculation of the gridster
   */
  resizeGrid(): void {
    setTimeout(() => {
      const maxY = this.getSouthestItem();
      this.gridsterOptions.minRows = maxY + 2;
      this.gridsterOptions.api.optionsChanged();
    });
  }

  removeItem($event: MouseEvent, item: PkGridsterItem): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.gridsterItems.splice(this.gridsterItems.indexOf(item), 1);
    this.resizeGrid();

    this.deleteItem.emit(item.id);
  }
}
