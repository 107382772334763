import {Injectable} from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import {
  HttpClient,
} from '@angular/common/http';
import {Subject, Observable} from 'rxjs';
import {ManagedUpload} from 'aws-sdk/clients/s3';
import {AuthService} from './auth.service';
import {PKApolloQueryResult} from "@nx-monorepo/api-base/lib";
import { MediaService } from '@nx-monorepo/obce/ng/services';

@Injectable()
export class UploadService {
  //todo in future make generic owner and put it in base libs
  constructor(private http: HttpClient, private authService: AuthService, private mediaService: MediaService) {
  }

  /*  public createFolder(name: string): Observable<HttpEvent<{}>> {
      const formData: FormData = new FormData();
      formData.append('folder_name', name);

      const req = new HttpRequest('POST', '/create-folder', formData);
      return this.http.request(req);
    }*/

  uploadFileToS3(file, owner, filename, callback: (err, data) => void): ManagedUpload {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: 'AKIASJHKWZN6VO2UJFLW',
        secretAccessKey: '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',
        region: 'eu-central-1'
      }
    );
    const params = {
      Bucket: 'obce',
      Key: btoa(owner) + '/' + filename,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };
    return bucket.upload(params, callback);
//for upload progress
    /*bucket.upload(params).on('httpUploadProgress', function (evt) {
              console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
          }).send(function (err, data) {
              if (err) {
                  console.log('There was an error uploading your file: ', err);
                  return false;
              }
              console.log('Successfully uploaded file.', data);
              return true;
          });*/
  }

  public upload(payload: { files: Array<File>, fileCategories: Array<number> }): ProgressResult {
    // this will be the our resulting map
    const status: ProgressResult = {};
    const {files, fileCategories} = payload;


    files.forEach((file, index) => {

      // create a new progress-subject for every file
      const uploadState = new Subject<MediaUploadState>();
      let mediumId = null;

      //create upload callback
      const callback = async (err, data) => {
        if (err) {
          console.error('There was an error uploading your file: ', err);
          return false;
        }

        // save the medium to DB
        mediumId = await this.mediaService.save({
          input: {
            kategorie_id: fileCategories[index],
            mime: file.type,
            nazev: file.name,
            url: data.Location
          }
        })
          .toPromise().then((res: PKApolloQueryResult) => res.data.response[0].id);

        // last emit
        uploadState.next({progress: 100, mediaId: mediumId})

        // complete the observable
        uploadState.complete();
      };

      // upload to S3 bucket
      this.uploadFileToS3(file, this.authService.decodeToken().owner_id, Math.random().toString(36).substr(2) + "_" + file.name, callback).on('httpUploadProgress', event => {
        const percentDone = Math.round((100 * event.loaded) / event.total);
        uploadState.next({progress: percentDone, mediaId: null});
      });

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        state: uploadState.asObservable()
      };
    });

    // return the map of progress.observables
    return status;
  }
}

export interface ProgressResult {
  // filename
  [key: string]: {
    state: Observable<MediaUploadState>; // observer with progress and mediaId
  }
}

export interface MediaUploadState {
  progress: number;
  mediaId?: number;
}
