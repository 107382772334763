import { IAction } from '@nx-monorepo/cms-base/interfaces';

export interface CategoryTabsState {
  categories: IAction[];
  activeCategory: IAction;
  error: string;
  entityType: string;
  shouldRefetch: boolean;
}

export const initialCategoryTabsState: CategoryTabsState = {
  categories: [],
  activeCategory: {
    id: 0,
    nazev: 'Vše',
  },
  error: "",
  entityType: "",
  shouldRefetch: true
};
