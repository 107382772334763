import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { IPkComponentWithFormDialog, PkGridsterItem } from '../pk-gridster-item';
import { IPkDialogData } from '../../pk-dialog/pk-dialog-data';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { PkDialogComponent } from '../../pk-dialog/pk-dialog.component';
import { PkGridsterItemTextfieldDialogCompanion } from '../companions/form-items/pk-gridster-item-textfield-dialog-companion';
import { IPkInputTextFieldProps } from '../..';
import { PkGridsterItemDefinitionService } from '@nx-monorepo/cms-base/layout/components/pk-page-contents/pk-gridster-page/pk-gridster-item-definition.service';

@Component({
  selector: 'pk-gridster-item-textfield',
  templateUrl: './pk-gridster-item-textfield.component.html',
  styleUrls: ['./pk-gridster-item-textfield.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PkGridsterItemTextfieldComponent implements OnInit, OnDestroy, IPkComponentWithFormDialog {


  @Input()
  widget: PkGridsterItem;

  @Input()
  resizeEvent: EventEmitter<any>;

  @Input()
  dialogRef: MatDialogRef<PkDialogComponent, string>;

  @Output()
  dialogClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  dialogSubmit: EventEmitter<any> = new EventEmitter<any>();

  private resizeSub: Subscription;
  public settings: IPkInputTextFieldProps;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  formDialogCompanion = new PkGridsterItemTextfieldDialogCompanion();

  constructor(private itemDefinitionService: PkGridsterItemDefinitionService, private changeDetection: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.subscribeToDialogClose();
    this.subscribeToDialogSubmit();

    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your item, chart, map , etc.
      }
    });

    this.settings = this.itemDefinitionService.fillItemSettingsByType('PkGridsterItemTextfieldComponent', this.widget.settings, {});
    this.widget.settings = this.settings;
  }


  private subscribeToDialogClose() {
    this.dialogClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  getDialogData(): IPkDialogData {
    return this.formDialogCompanion.getDialogData(this.settings, null, {
      dialogSubmit: this.dialogSubmit,
      dialogClose: this.dialogClose
    });
  }

  private subscribeToDialogSubmit() {
    this.dialogSubmit.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      result.povinnost = result.povinnost === '1';
      result.isTextArea = result.isTextArea === '1';

      console.log('wtf', result);
      // set new props
      this.settings = this.itemDefinitionService.fillItemSettingsByType('PkGridsterItemTextfieldComponent', result, this.settings);
      this.widget.settings = this.settings;

      // gridster is fucked up and doesnt push changes, we need to call it manually
      this.changeDetection.markForCheck();

      // close the dialogp
      this.dialogRef.close();
    });
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
