import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NewsletterBarrel } from '@nx-monorepo/node-frontend';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { BaseComponent } from '../base.component';
import { HeadingProps } from '../heading/heading.props';
import { NewsletterService } from './newsletter.service';
import { ObecService, ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@Component({
  selector: 'macms-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent extends BaseComponent implements IDynamicComponent, OnInit {
  @Input() data: any;
  @Input() settings: NewsletterBarrel.NewsletterProps;

  submitClass = null;
  submitMessage = null;

  @ViewChild('email',{static: true})
  email: ElementRef;

  headingSettings: HeadingProps;
  titleFont;
  paragraphFont;

  ngOnInit(): void {
    // console.log("NEWS",this.settings, null , this.data);
    this.titleFont = this.obecSettings.title_font;
    this.paragraphFont = this.obecSettings.paragraph_font;

    if(this.settings.headingProps){
      this.headingSettings = this.settings.headingProps;
    } else {
      this.headingSettings = {
        icon: 'newspaper',
        iconPosition: 'start',
        boxMargin: {top: 'auto', bottom: 'auto', left: 'auto', right: 'auto'},
        primaryColor: this.primaryColor,
        font: this.obecSettings.title_font
      };
    }
  }

  onSubmit(){
    const regexEmail = RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
    if(regexEmail.test(this.email.nativeElement.value)) {
      this.newsletterService.subscribe(this.email.nativeElement.value).toPromise().then(res => {
        if (res.data.subscribeFromHomePage.isSuccessful == false) {
          throw Error(res.data.subscribeFromHomePage.message);
        } else {
          this.submitClass = "success";
          this.submitMessage = res.data.subscribeFromHomePage.message;
        }
      }).catch(err => {
          this.submitClass = "error";
          this.submitMessage = err.message;
      });
    } else {
      this.submitClass = "error";
      this.submitMessage = 'Prosím zkontrolujte platnost zadané adresy.';
    }
    return false;
  }


  constructor(colorUtilsService: ColorUtilsService, obecService: ObecService, private newsletterService: NewsletterService) {
    super(colorUtilsService, obecService);
  }

  getComponentName() {
    return NewsletterBarrel.name;
  }
}
