import gql from 'graphql-tag';

export const readSelectedNavigaceQuery = gql`
  query ReadSelectedNavigace($ids: [Int!]!) {
    response: readSelectedNavigace(
      ids: $ids
    ) {
      id
      nazev
      children{
        id
        nazev
        type
        barva
        ikonka
        popis
        url
        stranka_id
        order
        stranka {
          slug
        }
        parent_id
      }
    }
  }
`;
