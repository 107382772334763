import { Injectable, InjectionToken } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { FetchResult } from 'apollo-link';
import { addKontakt } from '@nx-monorepo/obce/common/mutations';
import { IPkService } from './i-pk-service';
import { readAllKontaktQuery } from '@nx-monorepo/obce/common/queries';

export const KONTAKT_SERVICE_TOKEN = new InjectionToken<IPkService>('KontaktService');

@Injectable({
  providedIn: 'root'
})
export class KontaktService implements IPkService {

  constructor(private apollo: Apollo) {
  }

  fetchAll(vars: object) {
    return this.apollo.query({
      query: readAllKontaktQuery,
      variables: vars
    });
  }

  createAll(vars: object): Observable<FetchResult<any>> {
    return undefined;
  }

  save(vars: object): Observable<FetchResult<any>> {
    return this.apollo.mutate({
      mutation: addKontakt,
      variables: vars
    });
  }

  fetchSelected(vars: number[]): Observable<any[]> {
    return undefined;
  }

  fetchSingle(vars: number): Observable<any> {
    return undefined;
  }


}
