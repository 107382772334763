import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { tableReducer } from './table.reducer';
import { EffectsModule } from '@ngrx/effects';
import { TableStoreEffects } from './table.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('tableData', tableReducer),
        EffectsModule.forFeature([TableStoreEffects])
    ]
})
export class TableStoreModule {}
