import { Component, Input, OnInit } from '@angular/core';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faCalendar, faAirFreshener } from '@fortawesome/free-solid-svg-icons';
import { UredniDeskyBarrel } from '@nx-monorepo/node-frontend';
import { BaseComponent } from '../../base.component';
import { NextButtonProps } from '../../next-button/next-button.props';
import { HeadingProps } from '../../heading/heading.props';
import { ObecService, ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@Component({
  selector: 'macms-compact-uredni-desky',
  templateUrl: './compact.component.html',
  styleUrls: ['./compact.component.scss']
})
export class CompactUredniDeskyComponent extends BaseComponent implements IDynamicComponent, OnInit {
  @Input() data: any;
  @Input() settings: UredniDeskyBarrel.CompactUredniDeskyProps;

  primaryColor;

  nextButtonSettings: NextButtonProps;
  headingSettings: HeadingProps;

  constructor(public colorUtilsService: ColorUtilsService, protected obecService: ObecService) {
    super(colorUtilsService, obecService);
  }

  downloadIcon = faDownload;
  calendarIcon = faCalendar;


  ngOnInit(): void {
    this.primaryColor = this.settings.primaryColor;
    if(this.settings.headingProps){
      this.headingSettings = this.settings.headingProps;
    } else {
      this.headingSettings = {
        icon: 'newspaper',
        iconPosition: 'start',
        boxMargin: {top: 'auto', bottom: 'auto', left: 'auto', right: 'auto'},
        primaryColor: this.primaryColor,
        font: this.obecSettings.title_font
      };
    }
    this.nextButtonSettings = {
      primaryColor: this.primaryColor,
      fontTitle: this.obecSettings.title_font,
      bottomPaddingEm: 2,
      topPaddingEm: 2,
    };
    this.generateColors();
    if(this.settings.wrapperBackground){
      this.wrapperBackgroundColor = this.settings.wrapperBackground;
    } else if(this.settings.automaticWrapperColor) {
      this.wrapperBackgroundColor = this.colorUtilsService.setLightness(this.settings.primaryColor,92);
    }
    if (this.settings.icon === 'faDownload') {
      this.downloadIcon = faDownload;
    } else if (this.settings.icon === 'faAirFreshener') {
      this.downloadIcon = faAirFreshener;
    }
  }



  getComponentName(): string {
    return UredniDeskyBarrel.name_compact;
  }
}
