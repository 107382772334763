import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { IPkComponentWithFormDialog, PkGridsterItem } from '../pk-gridster-item';
import { IPkInputWysiwygProps } from '../../pk-input-fields/pk-input-wysiwyg/pk-input-wysiwyg.props';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { PkDialogComponent } from '../../pk-dialog/pk-dialog.component';
import { PkGridsterItemWysiwygDialogCompanion } from '../companions/frontend-items/pk-gridster-item-wysiwyg-dialog-companion';


@Component({
  selector: 'pk-gridster-item-wysiwyg',
  templateUrl: './pk-gridster-item-wysiwyg.component.html',
  styleUrls: ['./pk-gridster-item-wysiwyg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PkGridsterItemWysiwygComponent implements OnInit, OnDestroy, IPkComponentWithFormDialog {
  @Input()
  widget: PkGridsterItem;

  @Input()
  resizeEvent: EventEmitter<any>;

  @Input()
  dialogRef: MatDialogRef<PkDialogComponent, string>;

  @Output()
  dialogClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  dialogSubmit: EventEmitter<any> = new EventEmitter<any>();

  private resizeSub: Subscription;
  public settings: IPkInputWysiwygProps;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  formDialogCompanion = new PkGridsterItemWysiwygDialogCompanion();

  constructor(private changeDetection: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.subscribeToDialogClose();
    this.subscribeToDialogSubmit();
    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your item, chart, map , etc.
      }
    });

    let settings: IPkInputWysiwygProps = null;
    // for other component reasons widget settings can also be empty object
    if(this.widget.settings && Object.keys(this.widget.settings).length){
      settings = this.widget.settings;
    }

    if (settings) {
      this.setSettings(settings.nazev, settings.defaultValue);
    } else {
      this.setSettings('', '');
    }

  }

  private setSettings(nazev: string, defaultValue: string) {
    this.settings = {
      povinnost: true,
      nazev: nazev,
      defaultValue: defaultValue,
      form: undefined,
      formControlName: undefined
    };

    this.widget.settings = this.settings;
  }

  private subscribeToDialogClose() {
    this.dialogClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  private subscribeToDialogSubmit() {
    this.dialogSubmit.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      // set new props
      this.setSettings(result.nazev, result.defaultValue);

      // gridster is fucked up and doesnt push changes, we need to call it manually
      this.changeDetection.markForCheck();

      // close the dialog
      this.dialogRef.close();
    });
  }

  getDialogData() {
    return this.formDialogCompanion.getDialogData(this.settings, null,
      {dialogSubmit: this.dialogSubmit, dialogClose: this.dialogClose})
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
