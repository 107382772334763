import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from '../base-page.component';
import { Store } from '@ngrx/store';
import {ActivatedRoute, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { IAction } from '@nx-monorepo/cms-base/interfaces';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { selectFilteredCategories } from '@nx-monorepo/cms-base/store/category-tabs/category-tabs.selectors';

@Component({
  selector: 'pk-upload-page',
  templateUrl: './pk-upload-page.component.html',
  styleUrls: ['./pk-upload-page.component.scss']
})
export class PkUploadPageComponent extends BasePageComponent implements OnInit{
  public categories$: Observable<IAction[]>;

  constructor(store$: Store<MACMSStoreState.State>, currentRoute: ActivatedRoute, private router: Router,) {
    super(store$, currentRoute);
  }

  ngOnInit(): void {
    this.categories$ = this.store$.select(selectFilteredCategories);
  }

  filesUploaded(ids: number[]) {
    console.log('files uploaded! ids are:', ids);
    // this.router.navigateByUrl(this.currentRoute.snapshot.url[0].path || '/');
  }

}

