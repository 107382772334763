import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BasePageComponent } from '../base-page.component';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { VideosService } from './videos.service';
import { fuseAnimations } from '@fuse/animations';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';


@Component({
  selector: 'pk-video-help-page',
  templateUrl: './pk-video-help-page.component.html',
  styleUrls: ['./pk-video-help-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class PkVideoHelpPageComponent extends BasePageComponent implements OnInit, OnDestroy, AfterViewInit {

  categories: any[];
  courses: any[];
  coursesFilteredByCategory: any[];
  filteredCourses: any[];
  currentCategory: string;
  searchTerm: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(store$: Store<MACMSStoreState.State>,
              route: ActivatedRoute,
              private _fuseProgressBarService: FuseProgressBarService,
              private _videosService: VideosService) {
    super(store$, route);

    this.currentCategory = 'all';
    this.searchTerm = '';
  }

  ngOnInit(): void
  {
    // Subscribe to categories
    this._videosService.onCategoriesChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(categories => {
        this.categories = categories;
      });

    // Subscribe to courses
    this._videosService.onVideosChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(courses => {
        console.log(courses);
        this.filteredCourses = this.coursesFilteredByCategory = this.courses = courses;
      });
  }

  ngAfterViewInit(): void {
    this._fuseProgressBarService.hide();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Filter courses by category
   */
  filterCoursesByCategory(): void
  {
    // Filter
    if ( this.currentCategory === 'all' )
    {
      this.coursesFilteredByCategory = this.courses;
      this.filteredCourses = this.courses;
    }
    else
    {
      this.coursesFilteredByCategory = this.courses.filter((course) => {
        return course.category === this.currentCategory;
      });

      this.filteredCourses = [...this.coursesFilteredByCategory];
    }

    // Re-filter by search term
    this.filterCoursesByTerm();
  }

  /**
   * Filter courses by term
   */
  filterCoursesByTerm(): void
  {
    const searchTerm = this.searchTerm.toLowerCase();

    // Search
    if ( searchTerm === '' )
    {
      this.filteredCourses = this.coursesFilteredByCategory;
    }
    else
    {
      this.filteredCourses = this.coursesFilteredByCategory.filter((course) => {
        return course.title.toLowerCase().includes(searchTerm);
      });
    }
  }

}
