import { Component, Input, OnInit } from '@angular/core';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { PrispevkyBarrel } from '@nx-monorepo/node-frontend';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { ObecService, ColorUtilsService } from '@nx-monorepo/obce/ng/services';
import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { BaseComponent } from '../../base.component';
import { NextButtonProps } from '../../next-button/next-button.props';
import { HeadingProps } from '../../heading/heading.props';

@Component({
  selector: 'macms-classic-prispevky',
  templateUrl: './classic-prispevky.component.html',
  styleUrls: ['./classic-prispevky.component.scss']
})
export class ClassicPrispevkyComponent extends BaseComponent implements IDynamicComponent, OnInit {
  @Input() data: {items: []};
  @Input() settings: PrispevkyBarrel.ClassicPrispevkyProps;

  headingProps: HeadingProps;
  //todo Type (create Barrel)
  nextButtonSettings: NextButtonProps;

  constructor(public colorUtilsService: ColorUtilsService, protected obecService: ObecService) {
    super(colorUtilsService, obecService);
  }

  calendarIcon = faCalendar;

  ngOnInit(): void {
    registerLocaleData(localeCs, 'cs-CZ');
    this.primaryColor = this.settings.primaryColor;
    this.generateColors();
    if(!this.settings.headingProps){
      this.headingProps = {
        font: this.obecSettings.title_font,
        primaryColor: this.settings.primaryColor
      }
    } else {
      this.headingProps = this.settings.headingProps;
    }
    this.nextButtonSettings = {
      primaryColor: this.primaryColor,
      fontTitle: this.obecSettings.title_font,
      bottomPaddingEm: 2,
      topPaddingEm: 2,
    };
  }

  getComponentName() {
    return PrispevkyBarrel.name_classic;
  }
}
