import gql from 'graphql-tag';

export const readSelectedSmsQuery = gql`
  query ReadSelectedSmsQuery($ids: [Int!]!) {
    response: readSelectedSms(
      ids: $ids
    ) {
      id
      text
      prijemci
      datum_odeslani
      count
      autor {
              id
              jmeno
              prijmeni
            }
    }
  }
`;
