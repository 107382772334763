import { Action } from '@ngrx/store';

export enum MediaActionTypes {
  UPLOAD_FILE = '[Media] Upload File',
  CREATE_FOLDER = '[Media] Create Folder',
  UPLOAD_SUCCESSFUL = '[Media] Upload Successful'
}

export class UploadFileAction implements Action {
  readonly type = MediaActionTypes.UPLOAD_FILE;

  constructor() {}
}

export class CreateFolderAction implements Action {
  readonly type = MediaActionTypes.CREATE_FOLDER;

  constructor() {}
}

export class UploadSuccessfulAction implements Action {
  readonly type = MediaActionTypes.UPLOAD_SUCCESSFUL;

  constructor(public payload: { item: any }) {}
}

export type MediaActions =
  | CreateFolderAction
  | UploadFileAction
  | UploadSuccessfulAction;
