import * as AngularNavigaceBarrel from './lib/components/navigace';
import * as AngularFooterBarrel from './lib/components/footer';
import * as AngularTabsBarrel from './lib/components/tabs';
import * as AngularInfoBarrel from './lib/components/info';
import * as AngularHeaderBarrel from './lib/components/header';
import * as AngularUdalostiBarrel from './lib/components/udalosti';
import * as AngularUredniDeskaBarrel from './lib/components/uredni-desky';
import * as AngularFotogalerieBarrel from './lib/components/fotogalerie';
import * as AngularPrispevkyBarrel from './lib/components/prispevky';
import * as AngularNewsletterBarrel from './lib/components/newsletter';
import * as AngularKontaktBarrel from './lib/components/kontakt/';


 // named barrel exports
 // if you add here please put it to correct spot
 //export * from "./node_index"
 export {
   AngularFooterBarrel,
   AngularNavigaceBarrel,
   AngularTabsBarrel,
   AngularHeaderBarrel,
   AngularInfoBarrel ,
   AngularUredniDeskaBarrel,
 };

import * as AngularWysiwygBarrel from './lib/components/wysiwyg';
export * from './lib/components/wysiwyg/';
export {ObceComponentsModule} from './lib/obce-components.module';
export * from './lib/utils';
export * from './lib/routing';
export * from './lib/components/heading';
export * from './lib/components/breadcrumbs';
export * from './lib/components/footer/angular_api';
export * from './lib/components/paginator';
export * from './lib/components/tabs/public_api';
export * from './lib/components';

export const obceTypes = () => {
  let types = {};

  // I hope I have everysing
  types[AngularFooterBarrel.name] = AngularFooterBarrel.FooterComponent;
  types[AngularNavigaceBarrel.name_item] = AngularNavigaceBarrel.ItemNavComponent;
  types[AngularHeaderBarrel.name] = AngularHeaderBarrel.HeaderComponent;
  types[AngularUdalostiBarrel.name_calendar] = AngularUdalostiBarrel.CalendarComponent;
  types[AngularUdalostiBarrel.udalosti_name] = AngularUdalostiBarrel.UdalostiComponent;
  types[AngularInfoBarrel.name] = AngularInfoBarrel.InfoComponent;
  types[AngularUredniDeskaBarrel.name_compact] = AngularUredniDeskaBarrel.CompactUredniDeskyComponent;
  types[AngularUredniDeskaBarrel.name_two_column] = AngularUredniDeskaBarrel.TwoColumnUredniDeskyComponent;
  types[AngularFotogalerieBarrel.name] = AngularFotogalerieBarrel.FotogalerieComponent;
  types[AngularPrispevkyBarrel.name_classic] = AngularPrispevkyBarrel.ClassicPrispevkyComponent;
  types[AngularPrispevkyBarrel.name_image] = AngularPrispevkyBarrel.ImagePrispevkyComponent;
  types[AngularNewsletterBarrel.name] = AngularNewsletterBarrel.NewsletterComponent;
  types[AngularNewsletterBarrel.name] = AngularNewsletterBarrel.NewsletterComponent;
  types[AngularWysiwygBarrel.name] = AngularWysiwygBarrel.WysiwygComponent;
  types[AngularKontaktBarrel.name] = AngularKontaktBarrel.KontaktComponent;
  return types;

};

