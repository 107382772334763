<form [formGroup]="urlForm">
  <div class="url-list" formArrayName="urls" *ngFor="let url of formData.controls; index as i">

    <!-- NAZEV -->
    <pk-input-text-field [settings]="fieldSettings[i].nazevSettings"></pk-input-text-field>

    <!-- URL -->
    <pk-input-text-field [settings]="fieldSettings[i].urlSettings"></pk-input-text-field>

    <!-- KATEGORIE -->
    <pk-select-field [data]="categories" [settings]="fieldSettings[i].kategorieSettings"></pk-select-field>

    <!-- REMOVE BUTTON -->
    <button mat-icon-button (click)="removeUrl(i)">
      <mat-icon>close</mat-icon>
    </button>


  </div>

</form>
