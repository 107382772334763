import {IPkDialogData,IPkFormDialogCompanion} from '../../../pk-dialog/pk-dialog-data';
import {Validators} from '@angular/forms';
import {getDefaultDialogButtons,getNazevSettings} from '../common-settings-objects';
import {IPkInputTextFieldProps} from '../../../pk-input-fields/pk-input-text-field/pk-input-text-field.props';
import {CalendarProps} from 'libs/frontend-components/src/lib/components/udalosti';
import {IPkInputCheckboxFieldProps} from '../../../pk-input-fields/pk-input-checkbox-field/pk-input-checkbox-field.props';
import { IPkColorPickerProps } from '../../../pk-input-fields/pk-color-picker-field/pk-color-picker-field.props';

export class PkGridsterItemCalendarDialogCompanion implements IPkFormDialogCompanion {
  getDialogData(settings: CalendarProps, data: {}, emitters: {
    dialogSubmit,
    dialogClose
  }) {
    const dialogData: IPkDialogData = {
      title: 'Upravit úřední desky',
      components: [
        getNazevSettings(settings.nazev),
        // {
        //     headingProps
        // },
        {
          componentName: 'PkColorPickerFieldComponent',
          settings: {
            defaultValue: settings.secondaryColor ? settings.secondaryColor : '',
            showAlways: false,
            povinnost: false,
            type: 'text',
            nazev: 'Sekundární barva',
            formControlName: 'secondaryColor',
          } as IPkColorPickerProps,
          data: null,
          validators: [],
          cols: 2,
          x: 0,
          y: 0,
        },
        {
          componentName: 'PkColorPickerFieldComponent',
          settings: {
            showAlways: false,
            povinnost: false,
            type: 'text',
            nazev: 'Accent color',
            formControlName: 'accentColor',
            defaultValue: settings.accentColor ? settings.accentColor : '',
          } as IPkColorPickerProps,
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 1,
        },
        {
          componentName: 'PkInputCheckboxFieldComponent',
          settings: {
            povinnost: false,
            nazev: 'Zobraz popis',
            formControlName: 'showPopis',
            defaultValue: settings.showPopis ? settings.showPopis : false,
          } as IPkInputCheckboxFieldProps,
          data: null,
          validators: [],
          cols: 2,
          x: 0,
          y: 2,
        },
        {
          componentName: 'PkInputTextFieldComponent',
          settings: {
            isTextArea: false,
            povinnost: false,
            type: 'text',
            nazev: 'Odsazení kalendáře',
            formControlName: 'calendarSpacing',
            defaultValue: settings.calendarSpacing ? settings.calendarSpacing : '',
          } as IPkInputTextFieldProps,
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 3,
        },
        //   {
        //     dayBorder?: BoxSides<Border>;
        //     eventBorder?: BoxSides<Border>;
        //   }
        {
          componentName: 'PkInputCheckboxFieldComponent',
          settings: {
            povinnost: false,
            nazev: 'Jsou dni kalendára invertované?',
            formControlName: 'areCalendarDaysInverted',
            defaultValue: settings.areCalendarDaysInverted ? settings.areCalendarDaysInverted : false,
          } as IPkInputCheckboxFieldProps,
          data: null,
          validators: [],
          cols: 2,
          x: 0,
          y: 4,
        },
        {
          componentName: 'PkInputCheckboxFieldComponent',
          settings: {
            povinnost: false,
            nazev: 'Jsou události invertované?',
            formControlName: 'areEventsInverted',
            defaultValue: settings.areEventsInverted ? settings.areEventsInverted : false,
          } as IPkInputCheckboxFieldProps,
          data: null,
          validators: [],
          cols: 2,
          x: 0,
          y: 5,
        },
        {
          componentName: 'PkInputCheckboxFieldComponent',
          settings: {
            povinnost: false,
            nazev: 'Jsou události invertované?',
            formControlName: 'areEventsTransparent',
            defaultValue: settings.areEventsTransparent ? settings.areEventsTransparent : false,
          } as IPkInputCheckboxFieldProps,
          data: null,
          validators: [],
          cols: 2,
          x: 0,
          y: 6,
        },
        {
          componentName: 'PkInputCheckboxFieldComponent',
          settings: {
            povinnost: true,
            nazev: 'Invertuj současný den',
            formControlName: 'invertCurrentDay',
            defaultValue: settings.invertCurrentDay ? settings.invertCurrentDay : false,
          } as IPkInputCheckboxFieldProps,
          data: null,
          validators: [Validators.required],
          cols: 2,
          x: 0,
          y: 7,
        },
        {
          componentName: 'PkInputCheckboxFieldComponent',
          settings: {
            povinnost: false,
            nazev: 'Zobraz nadcházející události',
            formControlName: 'showIncomingEvents',
            defaultValue: settings.showIncomingEvents ? settings.showIncomingEvents : false,
          } as IPkInputCheckboxFieldProps,
          data: null,
          validators: [],
          cols: 2,
          x: 0,
          y: 7,
        },
      ],
      buttons: getDefaultDialogButtons(emitters)
    };
    return dialogData;
  }
}
