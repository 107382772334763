import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators, Form} from '@angular/forms';
import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {Router, ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {State} from "../../../../store/store.state";
import { BasePageComponent } from '../base-page.component';
import { KolekceService } from '@nx-monorepo/obce/ng/services';
import { changePassword } from '@nx-monorepo/obce/common/mutations';
import { displaySnack } from '@nx-monorepo/cms-base/helpers';


@Component({
  selector: 'pk-change-password-page',
  templateUrl: './pk-change-password-page.component.html',
  styleUrls: ['./pk-change-password-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class PkChangePasswordPageComponent extends BasePageComponent implements OnInit {
  // data
  passChangeForm: FormGroup;
  passwordCurrent = null;
  passwordNew = null;
  passwordConfirm = null;
  errorMessage = null;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    protected store$: Store<State>,
    protected router: Router,
    private kolekceService: KolekceService,
    protected route: ActivatedRoute
  ) {
    super(store$, route);
  }

  ngOnInit(): void {
    this.passChangeForm = this._formBuilder.group({
      passwordCurrent: ['', [Validators.required, Validators.minLength(6)]],
      passwordNew: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirm: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  onSubmit(){
    const payload = {
      passwordNew: this.passChangeForm.value.passwordNew,
      passwordCurrent: this.passChangeForm.value.passwordCurrent
    }

    this.kolekceService.executeMutation(changePassword, payload).toPromise()
      .then(response => {
        const graphqlResponse = response.data as {response: {message, isSuccessful}}
        displaySnack(graphqlResponse.response.message, this.store$);
        this.router.navigateByUrl('/dashboard');
      }).catch(err => {
        console.log(err);
        displaySnack(err.message, this.store$);
      })
  }
}