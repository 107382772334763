import {Component, OnInit} from '@angular/core';
// noinspection TypeScriptPreferShortImport
import {fuseAnimations} from '@fuse/animations';
import {EntityDefinitionProvider, displaySnack} from '@nx-monorepo/cms-base/helpers';
import {Action, ActionsSubject, createAction, Store} from "@ngrx/store";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {filter, takeUntil} from "rxjs/operators";
import { Apollo } from 'apollo-angular';
import { KolekceService } from '@nx-monorepo/obce/ng/services';
import { resendActivationEmail } from '@nx-monorepo/obce/common/queries';
import { LayoutActionTypes } from '@nx-monorepo/cms-base/store/layout/layout.actions';
import { PkNewEntityPageComponent } from '@nx-monorepo/cms-base/layout/components/pk-page-contents';
import { MACMSStoreState, LayoutStoreActions } from '@nx-monorepo/cms-base/store';
import { deactivateUzivatel } from '@nx-monorepo/obce/common/mutations';

const DEACTIVATE_ACCOUNT = createAction('[NewUzivatel] Deactivate account', (payload: {}) => ({payload}))({});
const RESET_PASSWORD_TOKEN = createAction('[NewUzivatel] Resend password token', (payload: {}) => ({payload}))({});

@Component({
  selector: 'pk-new-uzivatel-page',
  templateUrl: 'pk-new-uzivatel-page.component.html',
  styleUrls: ['pk-new-uzivatel-page.component.scss'],
  animations: fuseAnimations
})
export class PkNewUzivatelPageComponent extends PkNewEntityPageComponent implements OnInit {
  constructor(store$: Store<MACMSStoreState.State>,
              currentRoute: ActivatedRoute,
              router: Router,
              entityDefinitionProvider: EntityDefinitionProvider,
              kolekceService: KolekceService,
              actionsSubject$: ActionsSubject,
              formBuilder: FormBuilder,
              private apollo: Apollo) {
    super(store$, currentRoute, router, entityDefinitionProvider, kolekceService, actionsSubject$, formBuilder);
  }

  protected setLayoutButtons() {
    if (!this.entityId) {
      return
    }

    const payload = {
      buttons: []
    }

    if (!this.entity.hasPassword && this.entity.email) {
      payload.buttons.push({id: 2, nazev: 'Znovu odeslat email pro nastavení hesla', action: RESET_PASSWORD_TOKEN})
    }

    // nema nastavene heslo, nebudeme deaktivovat
    if (this.entity.hasPassword) {
      payload.buttons.push({id: 3, nazev: 'Deaktivovat', action: DEACTIVATE_ACCOUNT})
    }

    // ulozit je vzdy
    payload.buttons.push({id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()});

    this.store$.dispatch(new LayoutStoreActions.SetLayoutButtonsAction(payload));
  }

  protected subscribeToLayoutButtons() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) =>
        action.type === LayoutActionTypes.SAVE_BUTTON_PRESSED ||
        action.type === DEACTIVATE_ACCOUNT.type ||
        action.type === RESET_PASSWORD_TOKEN.type
      )
    ).subscribe((action) => {
      switch (action.type) {
        case LayoutActionTypes.SAVE_BUTTON_PRESSED:
          this.saveEntity();
          break;
        case DEACTIVATE_ACCOUNT.type:
          this.deactivateAccount()
          break;
        case RESET_PASSWORD_TOKEN.type:
          this.resendActivationEmail();
          break;
      }
    });
  }

  private deactivateAccount() {
    this.kolekceService.executeMutation(deactivateUzivatel, {input: this.entityId})
      .toPromise()
      .then(() => {
        this.router.navigateByUrl(this.currentRoute.snapshot.url[0].path || '/');
        displaySnack('Účet byl úspěšně deaktivován!', this.store$);
      })
      .catch(err => {
        displaySnack(err.message || 'Účet se nepodařilo deaktivovat.', this.store$);
      })
  }

  private resendActivationEmail() {
    const payload = {
      email: this.entity.email,
    }

    this.executeQuery(resendActivationEmail, payload).toPromise()
      .then((response: {data: {response: {isSuccessful, message}}}) => {
        if (response.data.response.isSuccessful === "false") {
            throw Error(response.data.response.message);
        }
        displaySnack(response.data.response.message, this.store$);
      })
      .catch(err => {
        displaySnack(err.message || 'Nepodařilo se odeslat email, zkuste to prosím později!', this.store$);
      })
  }

  private executeQuery(mutation: any, vars: any) {
    return this.apollo.mutate({
      mutation: mutation,
      variables: vars
    });
  }
  
}
