import { Component, OnDestroy, OnInit, Output, EventEmitter, Directive } from '@angular/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, first, map, startWith, take, takeUntil } from 'rxjs/operators';
// noinspection TypeScriptPreferShortImport
import { BasePageComponent } from '../base-page.component';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { EntityDefinitionProvider, displaySnack } from '@nx-monorepo/cms-base/helpers';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KategorieService, KolekceService } from '@nx-monorepo/obce/ng/services';
import { ApolloQueryResult } from 'apollo-client';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { resolveKey } from '@nx-monorepo/ng-shared';
import * as moment from 'moment';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { PkEntityDialogComponent } from '@nx-monorepo/cms-base/components/media-module/pk-entity-dialog/pk-entity-dialog.component';
import { IAction } from '@nx-monorepo/cms-base/interfaces';
import { selectActiveCategory } from '@nx-monorepo/cms-base/store/category-tabs/category-tabs.selectors';
import { selectSelectedItems } from '@nx-monorepo/cms-base/store/table/table.selectors';
import { ClearAllSelected, SetSelectedItems } from '@nx-monorepo/cms-base/store/table/table.actions';
import { LayoutActionTypes } from '@nx-monorepo/cms-base/store/layout/layout.actions';
import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';

@Component({
  selector: 'pk-new-platebni-prikazy-page',
  templateUrl: './pk-new-platebni-prikazy-page.component.html',
  styleUrls: ['./pk-new-platebni-prikazy-page.component.scss'],
  animations: fuseAnimations
})

export class PkNewPlatebniPrikazyPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  private entityDialogRef: MatDialogRef<PkEntityDialogComponent, string>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private uzivateleKategorieCombined: Subscription;
  private platebniPrikazy: FormArray;
  private splatnost: FormControl;

  public form: FormGroup;
  public resolveKey = resolveKey;

  public kategorieSettings$: Observable<any>;
  public activeCategory$: Observable<IAction>;
  public uzivatele$: Observable<any>;

  constructor(store$: Store<MACMSStoreState.State>,
              currentRoute: ActivatedRoute,
              private router: Router,
              private entityDefinitionProvider: EntityDefinitionProvider,
              private kategorieService: KategorieService,
              private actionsSubject$: ActionsSubject,
              public dialog: MatDialog,
              private formBuilder: FormBuilder,
              private kolekceService: KolekceService,
              private _fuseProgressBarService: FuseProgressBarService) {
    super(store$, currentRoute);
  }

  ngOnInit(): void {
    registerLocaleData(localeCs, 'cs-CZ');
    this.activeCategory$ = this.store$.select(selectActiveCategory);

    this.uzivatele$ = this.store$.select(selectSelectedItems);

    this.subscribeCategoryChanges();

    this.subscribeToAddUsersAction();

    this.subscribeToSaveButtonAction();

    this.initForm();
  }

  ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();

    this.form.reset();

    this.store$.dispatch(new ClearAllSelected());
  }

  private initForm() {
    this.platebniPrikazy = this.formBuilder.array([]);
    this.splatnost = this.formBuilder.control({ value: this.getDefaultSplatnost(), disabled: false }, Validators.required);

    this.form = this.formBuilder.group({
      splatnost: this.splatnost,
      platebni_prikazy: this.platebniPrikazy
    });
  }

  private getDefaultSplatnost(daysFromNow: number = 30) {
    const date = new Date();
    date.setDate(date.getDate() + daysFromNow);
    return moment(date);
  }

  private subscribeCategoryChanges() {
    // subscribe na kategorii - vytahnotu settings a urcit column definition
    this.activeCategory$.pipe(
      takeUntil(this.destroy$),
      filter(category => category.id > 0)
    ).subscribe((activeCategory: IAction) => {
      // vytahneme dalsi data o kategorii
      this.kategorieSettings$ = this.getKategorieSettings(activeCategory.id);

      // pokazde kdyz se zmeni uzivatele, vybuildime formu
      if (!this.uzivateleKategorieCombined) {
        this.uzivateleKategorieCombined = this.rebuildFormOnUzivateleOrKategorieChanges(this.uzivatele$, this.kategorieSettings$);
      }

    });
  }

  private getKategorieSettings(categoryId: number): Observable<any> {
    return this.kategorieService.fetchSingle(categoryId)
      .pipe(
        first(),
        map((result: { uzivatele, nastaveni }) => {
          // vytahnout data z responsu
          const { uzivatele, nastaveni } = result;
          const parsedNastaveni = JSON.parse(nastaveni) || {};

          // emit next for our subject
          this.store$.dispatch(new SetSelectedItems({ selectedItems: uzivatele }));

          // vytvorit column definitions
          const columnDefinitions = this.buildColumnDefinition(parsedNastaveni);

          return {
            id: categoryId,
            hromadna: parsedNastaveni.hromadna || false,
            fields: parsedNastaveni.fields,
            columnDefinitions: columnDefinitions,
            visibleColumns: columnDefinitions.map(col => {
              if (col.type !== 'invisible') {
                return col.key.join('.');
              }
            })
          };
        })
      );
  }

  private rebuildFormOnUzivateleOrKategorieChanges(uzivatele$: Observable<any>, kategorieSettings$: Observable<any>): Subscription {
    return combineLatest(uzivatele$, kategorieSettings$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(([uzivatele, kategorieSettings]) => {

        // prve odstranime uzivatele, ktere nezvolili
        for (let i = 0; i < this.platebniPrikazy.length; i++) {
          const prikazUzivatelId = this.platebniPrikazy.at(i).get('uzivatel_id').value;
          const shouldDeleteFromGroup = !uzivatele.some((uzivatel: any) => uzivatel.id === prikazUzivatelId);
          if (shouldDeleteFromGroup) {
            this.platebniPrikazy.removeAt(i);
          }
        }

        // pridame uzivatele
        uzivatele.forEach((uzivatel: any) => {
          const uzivatelInPlatebniPrikazy = this.platebniPrikazy.controls.find(control => control.get('uzivatel_id').value === uzivatel.id);

          // pokud tam jeste uzivatel neni
          if (!uzivatelInPlatebniPrikazy) {
            const polozkyUzivatele = this.formBuilder.array([]);
            const platebniPrikazUzivatele = this.formBuilder.group({
              kategorie_id: kategorieSettings.id,
              uzivatel_id: uzivatel.id,
              polozky: polozkyUzivatele,
              suma: new FormControl({ value: 0, disabled: true })
            });
            // dle vytvorene definice strcime uzivateli veci, co ma zaplatit
            kategorieSettings.fields.forEach(field => {
              // vytvorime form item
              const platbaItem = this.createPlatbaItem(field);

              // push
              polozkyUzivatele.push(platbaItem);

              // subscribneme na nej, at muzeme menit součty dle nastavene vychozi ceny za jednotku
              this.subscribeToPlatbaItem(platebniPrikazUzivatele, platbaItem, field);
            });

            // platebniPrikazUzivatele.setControl('polozky',polozkyUzivatele);

            // klic je uzivatel_id, value je array veci, co ma zaplatit
            this.platebniPrikazy.push(platebniPrikazUzivatele);
          }
         
        });
        this._fuseProgressBarService.hide();
      });
  }

  private createPlatbaItem(field: any) {
    return this.formBuilder.group({
      mnozstvi: [1, Validators.required],
      cena: [parseInt(field.vychozi_cena, 10), Validators.required],
      nazev: [field.nazev, Validators.required],
      total: new FormControl({ value: parseInt(field.vychozi_cena, 10), disabled: true })
    });
  }

  private subscribeToPlatbaItem(parent: FormGroup, platbaItem: FormGroup, fieldDefinition: any): void {
    // hookneme se na zmenu mnozstvi i zmenu ceny
    combineLatest(platbaItem.get('mnozstvi').valueChanges.pipe(startWith(1)), platbaItem.get('cena').valueChanges.pipe(startWith(fieldDefinition.vychozi_cena)))
      .pipe(takeUntil(this.destroy$))
      .subscribe(([mnozstvi, cena]) => {
        // nastavime novou hodnotu
        platbaItem.get('total').setValue(mnozstvi * cena, { emitEvent: false });
        let sum = 0;
        (parent.get('polozky') as FormArray).controls.forEach(control => {
          sum += control.get('total').value;
        });
        parent.get('suma').setValue(sum);
        //console.log(parent, sum);
      });
  }

  private buildColumnDefinition(nastaveni: { [key: string]: any }) {
    return [
      {
        title: 'ID',
        key: ['id'],
        type: 'string'
      },
      {
        title: 'Jméno',
        key: ['jmeno'],
        type: 'string'
      },
      {
        title: 'Příjmení',
        key: ['prijmeni'],
        type: 'string'
      },
      // READABILITY
      ...this.reduceDefinitionFields(nastaveni.fields),
      {
        title: 'Suma',
        key: ['suma'],
        type: 'money',
        controlName: 'suma'
      }
    ];
  }

  private reduceDefinitionFields(fields: [{ [p: string]: any }]): [] {
    return fields.reduce((acc: { array: any[], increment: number }, field) => {
      acc.increment += 1;
      acc.array = acc.array.concat([
        {
          title: `${field.nazev} (${field.jednotka})`,
          type: 'inputAmount',
          key: [field.nazev],
          controlName: 'mnozstvi',
          groupName: acc.increment
        },
        {
          title: `Cena (${field.nazev})`,
          type: 'inputMoney',
          key: [`cena_${field.nazev}`],
          controlName: 'cena',
          groupName: acc.increment
        }
        /*{
          title: `Součet (${field.nazev})`,
          type: 'invisible',
          key: [`total_${field.nazev}`],
          controlName: 'total',
          groupName: acc.increment
        }*/]
      );
      return acc;
    }, { array: [], increment: -1 }).array;
  }

  private subscribeToSaveButtonAction() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === LayoutActionTypes.SAVE_BUTTON_PRESSED)
    ).subscribe(() => {
      if (this.form.valid) {
        this.save();
      } else {
        displaySnack('Vyplňte prosím všechny položky!', this.store$);
      }
    });
  }

  private save(): void {
    const prikazy = this.form.value.platebni_prikazy.map(prikaz => ({...prikaz, splatnost: this.splatnost.value}));
    prikazy.forEach(prikaz => {
      prikaz.polozky.forEach(polozka => {
        polozka.cena = parseInt(polozka.cena, 10);
        polozka.mnozstvi = parseInt(polozka.mnozstvi, 10);
      })
    });
    const payload = {
      input: prikazy
    };

    this.kolekceService.save(EntityTypes.PlatebniPrikaz, payload).toPromise()
      .then((res: ApolloQueryResult<any>) => this.onSaveSuccess(res))
      .catch((err: Error) => this.onSaveError(err));
  }

  private onSaveSuccess(res: ApolloQueryResult<any>): void {
    displaySnack('Úspěšně uloženo!', this.store$);
    // console.log('save success', res);
    this.router.navigateByUrl(this.currentRoute.snapshot.url[0].path || '/');
  }

  private onSaveError(error: Error): void {
    displaySnack(error.message, this.store$);
  }

  private subscribeToAddUsersAction() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === '[PlatebniPrikazy] Add Users')
    ).subscribe(() => {
      this.openEntityPicker();
    });
  }

  openEntityPicker() {
    if (this.entityDialogRef == null) {
      // create dialog config and pass dialog data to it
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        isMultiple: true,
        entityType: EntityTypes.Uzivatel
      };

      // open dialog and subscribe to its afterClosed event to remove the reference
      this.entityDialogRef = this.dialog.open(PkEntityDialogComponent, dialogConfig);

      // close
      this.entityDialogRef.afterClosed().pipe(take(1)).subscribe(() => {
        this.entityDialogRef = null;
      });

      // on potvrdit
      this.entityDialogRef.componentInstance.entitySelected.subscribe((selectedUzivatele) => {
        this.entityDialogRef.close();
      });
    }
  }
}
