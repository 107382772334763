<mat-form-field appearance="outline" fxFlex [formGroup]="settings.form">
  <mat-label>{{ settings.nazev }}</mat-label>
  <!-- ICON PREVIEW -->
  <mat-icon matPrefix fontSet="fa" [fontIcon]="settings.form.get(settings.formControlName).value"></mat-icon>

  <!--<input
    matInput
    readonly
    [required]="settings.povinnost"
    [formControlName]="settings.formControlName"
    [iconPicker]="icon"
    [ipPosition]="'bottom'"
    [ipWidth]="'250px'"
    [ipIconPack]="['fa']"
    [ipPlaceHolder]="'Choose an icon'"
    (iconPickerSelect)="onIconPickerSelect($event)"/>-->

  <!-- HINTS -->
  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info</mat-icon>

  <!-- ERROR -->
  <mat-error *ngIf="settings.form.get(settings.formControlName)?.invalid">Toto pole je povinné!</mat-error>

</mat-form-field>
