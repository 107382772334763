<div id="login" fxLayout="column">
  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="login-form" [@animate]="{value:'*',params:{duration:'600ms',y:'500px'}}" class="fuse-card">
      <a routerLink="/login"> ← Přejít zpět</a>
      <div class="logo">
        <img src="assets/images/logos/echopix_cms_logo_rect.svg">
      </div>

      <div class="title">Zapomenuté heslo</div>
      <div class="my-16 fuse-card warn-100 mx-auto" *ngIf="errorMessage">
        <div class="p-16" fxLayout="row" fxLayoutAlign="start center">
          <fa-icon size="2x" icon="exclamation-circle"></fa-icon>
          <div class="ml-16">
            <div class="text-bold">Neúspěšné obnovení hesla</div>
            <div class="h5 text-bold secondary-text text-left">{{errorMessage}}</div>
          </div>
        </div>
      </div>
      <form name="loginForm" (ngSubmit)="onSubmit()" [formGroup]="resetPassForm" novalidate>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input [value]="username" matInput formControlName="email" [ngModel]="username">
          <mat-icon matSuffix class="secondary-text">mail</mat-icon>
          <mat-error *ngIf="resetPassForm.get('email').hasError('required')">
            Email je vyžadován
          </mat-error>
          <mat-error *ngIf="!resetPassForm.get('email').hasError('required') &&
          resetPassForm.get('email').hasError('email')">
            Prosím vyplňte validní email.
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color="warn" class="submit-button" type="submit" aria-label="LOG IN"
        [disabled]="resetPassForm.get('email').hasError('required') || resetPassForm.get('email').hasError('email')">
          Resetovat heslo
        </button>
      </form>
    </div>

  </div>
  <div class="info">#{{versionInfo.commit}}@{{versionInfo.branch}} | v{{versionInfo.version}}(#{{versionInfo.count}}) |
    <span>{{versionInfo.date | amDateFormat:'DD.MM.YYYY HH:mm'}}</span> | © webyobci.cz </div>
</div>
