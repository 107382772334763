import { Component, Input, OnInit } from '@angular/core';
import { IDynamicComponent, IDynamicTheme } from '@nx-monorepo/ng-shared';
import { NavigaceBarrel } from '@nx-monorepo/node-frontend';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ColorUtilsService, ObecService } from '@nx-monorepo/obce/ng/services';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { BaseComponent } from '../../base.component';
import { getChildrenInTreeRepresentation, NavPol } from '../../../utils';
import { HeadingProps } from '../../heading/heading.props';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'macms-item-nav',
  templateUrl: './item-nav.component.html',
  styleUrls: ['./item-nav.component.scss']
})
export class ItemNavComponent extends BaseComponent implements IDynamicComponent, IDynamicTheme, OnInit {
  @Input() data: any;
  @Input() settings: NavigaceBarrel.ItemNavProps;
  @Input() primaryColor: string;

  headingSettings: HeadingProps;

  navigacePolozky: NavPol[];

  constructor(public colorUtilsService: ColorUtilsService, private iconLibrary: FaIconLibrary, private faConfig: FaConfig, protected obecService: ObecService) {
    super(colorUtilsService, obecService);

  }

  getComponentName() {
    return NavigaceBarrel.name_item;
  }

  ngOnInit(): void {
    this.generateColors();
    this.iconLibrary.addIconPacks(fas, fab);
    this.faConfig.defaultPrefix = 'fas';
    this.navigacePolozky = getChildrenInTreeRepresentation(this.data.children);
    if(this.settings.headingProps){
      this.headingSettings = this.settings.headingProps;
    } else if(this.settings.showHeading){
      this.headingSettings = {
        isInverted: true,
        primaryColor: this.settings.primaryColor,
      } as HeadingProps;
    }
    if(!this.settings.itemGap){
      // nová proměnná až po nastavení seederu
      this.settings.itemGap = '1rem';
    }
  }
}
