import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { IPkService } from './i-pk-service';
import { Observable } from 'rxjs';
import { FetchResult } from 'apollo-link';
import { addMedia } from '@nx-monorepo/obce/common/mutations';
import { readSelectedMediumQuery } from '@nx-monorepo/obce/common/queries';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MediaService implements IPkService {

  constructor(private apollo: Apollo) {
  }

  fetchAll(vars: object) {
    return undefined;
  }

  fetchSingle(vars: number) {
    return this.apollo.query<any[]>({
      query: readSelectedMediumQuery,
      variables: {ids: vars}
    }).pipe(map(response => response.data[0]));
  }

  fetchSelected(vars: object) {
    return this.apollo.query<any[]>({
      query: readSelectedMediumQuery,
      variables: vars
    }).pipe(map(response => response.data));
  }

  save(vars: object): Observable<FetchResult<any>> {
    return this.apollo.mutate({
      mutation: addMedia,
      variables: vars
    });
  }

  delete(vars: object): Observable<FetchResult<any>> {
    return undefined;
  }
}
