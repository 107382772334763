import gql from 'graphql-tag';

export const readAllGalerieQuery = gql`
  query readAllGalerie($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $categories: [Int!]) {
    response: readAllGalerie(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      categories: $categories
    ) {
      total
      page
      take
      hasMore
      items {
        id
        nazev
        slug
        color
        media{
          nazev
          mime
          url
        }
        created_at
        updated_at
      }
    }
  }
`;
