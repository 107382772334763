<div *ngIf="(svatek$ | async) as svatek"
     macmsColor
     [mc_isInverted]="settings.isInverted"
     [mc_wantedPrimary]="settings.primaryColor"
     [mc_otherColors]="[settings.accentColor]"
     [style.fontFamily]="settings.fontFamily"
>
  <span class="dnesUvod">Dnes je </span>
  <span class="dnesDatum">{{svatek.date | amDateFormat:"DD.MM.YYYY"}}</span>
  <span class="dnesDen" *ngIf="svatek.holiday"> - {{svatek.holiday}}</span>
  <span class="dnesKonec">. </span>
  <ng-container *ngIf="svatek.nameday">
    <span class="svatekUvod">Svátek má </span>
    <span class="svatekJmeno">{{svatek.nameday}}.</span>
  </ng-container>
</div>
