
export function resolveKey(key: string[], row) {
  return key.reduce((acc: string[], current: string) => {
    if(acc){
      return acc[current];
    }
  }, row);
}

export function resolveKeyOrShowDefault(key: string[], row, defaultValue: string){
  const result = resolveKey(key, row);
  if(!result && defaultValue){
    return defaultValue;
  } else {
    return result;
  }
}




