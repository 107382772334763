<mat-form-field appearance="outline" fxFlex [formGroup]="settings.form">
  <mat-label>{{ settings.nazev }}</mat-label>
  <mat-select [formControlName]="settings.formControlName" [required]="settings.povinnost" [multiple]="settings.isMultiple">
    <mat-option *ngFor="let item of data" [value]="item.id" >
      {{ item.nazev }}
    </mat-option>
  </mat-select>
  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info</mat-icon>
</mat-form-field>
