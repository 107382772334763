<div class="wrapperUdalosti" [style.background]="settings.wrapperBackground">
  <macms-heading
    *ngIf="settings.showHeading"
    [settings]="headingSettings"
    [text]="data.nazev || 'Události'"
  >
  </macms-heading>

  <section
    [style.background]="settings.sectionBackground ? (settings.sectionBackground | fixBackgroundUrl) : colorUtilsService.setLightness(settings.primaryColor,92)"
    id="udalosti"
  >
    <ng-template *ngIf="settings.uvod" [ngTemplateOutlet]="popis">
    </ng-template>
    <div class="grid">
        <ng-container *ngFor="let udalost of data?.items">
            <a
              class="item"
              [routerLink]="(udalost.slug) | DetailUrl:'udalosti':udalost.kategorie.nazev"
              fxLayout="column"
              [style.borderColor]="settings.primaryColor"
              [style.color]="settings.primaryColor"
              [borderDefinition]="settings.itemBorders"
              [contrastForeground]="settings.leftColBackground ? settings.leftColBackground : accentColor"
              [preferedColors]="[settings.primaryColor]"
              [paddingDefinition]="settings.itemPadding"
              [style.flexBasis]="settings.itemFlexBasis"
              [marginDefinition]="settings.itemMargin"
            >
              <div class="item-wrap">
              <div
                class="date leftcol"
                [style.textAlign]="settings.leftColTextAlign"
                [paddingDefinition]="settings.leftColPadding"
                [style.fontFamily]="settings.dateFont"
                [style.background]="settings.leftColBackground ? settings.leftColBackground : accentColor"
              >
                <!-- {{udalost.datum_zacatek}} -->
                <span
                  class="day"
                >
        {{ udalost.datum_zacatek | amLocale: 'cs' | amDateFormat: 'DD' }}
      </span>
                <span
                  class="month"
                >
        {{ udalost.datum_zacatek | amLocale: 'cs' | amDateFormat: 'MMMM' }}
      </span>
              </div>
              <div class="middle"
                   [marginDefinition]="settings.middleColMargin"
                   [paddingDefinition]="settings.middleColPadding"
                   [style.background]="settings.middleColBackground | fixBackgroundUrl"
              ></div>
              <div class="content rightcol" [paddingDefinition]="settings.rightColPadding"
                   [style.background]="settings.rightColBackground ? settings.rightColBackground : accentColor"
                   [style.color]="settings.primaryColor"
              >
                <div class="left">
                  <h3
                    style="margin-top: 0; margin-bottom: 0.5rem;"
                    [style.fontFamily]="settings.titleFont ? settings.titleFont : obecSettings.title_font"
                  >
                    {{ udalost.nazev }}
                  </h3>
                  <p
                    [style.fontFamily]="settings.textFont ? settings.textFont : obecSettings.paragraph_font"
                  >
                    {{ udalost.popis }}
                  </p>
                  <ng-template [ngTemplateOutlet]="category"
                               [ngTemplateOutletContext]="{udalost:udalost}"></ng-template>
                </div>
                <div class="right" *ngIf="udalost.media">
                  <img src="{{ udalost.media.url }}"/>
                </div>
              </div>
              </div>
            </a>
        </ng-container>
      </div>
  <macms-next-button
    href="/udalosti"
    [settings]="nextButtonSettings"
    text="Další události"
  ></macms-next-button>
</section>
</div>

<ng-template #category let-udalost="udalost">
  <div style="width:100%; text-align:left;" class="category">
    <p [style.color]="settings.primaryColor" [style.fontFamily]="obecSettings.paragraph_font" ><small>Kategorie: {{ udalost.kategorie.nazev }}</small></p>
  </div>
</ng-template>

<ng-template #popis>
  <div [style.color]="settings.primaryColor" [style.fontFamily]="obecSettings.paragraph_font" style="width:100%;" class="intro-text">
    <p>{{ settings.uvod }}</p>
  </div>
</ng-template>
