import gql from 'graphql-tag';

export const readAllEmailQuery = gql`
    query ReadAllEmailQuery($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $categories: [Int!]) {
        response: readAllEmail(search: $search
            take: $take
            page: $page
            sortColumn: $sortColumn
            sortDirection: $sortDirection,
            categories: $categories
            ) {
                total
                page
                take
                hasMore
                items {
                    id
                    predmet
                    prijemci
                    text
                    count
                    datum_odeslani
                    autor {
                        id
                        jmeno
                        prijmeni
                    }
                }
            }
    }
`
