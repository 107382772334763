<h1 mat-dialog-title>Vyberte entity</h1>

<div mat-dialog-content [ngSwitch]="data.entityType">
  <ng-container *ngSwitchCase="'Media'" [ngTemplateOutlet]="mediaList"></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="entityList"></ng-container>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onPotvrditClick()">
    Potvrdit
  </button>
</div>

<!-- MEDIA PICKER -->
<ng-template #mediaList>
  <mat-tab-group>
    <mat-tab label="Vybrat z galerie">
      <pk-media-list [canSelectMultiple]="data.isMultiple"></pk-media-list>
    </mat-tab>

    <mat-tab label="Nahrát soubor">
      <pk-upload [categories]="categories$ | async"></pk-upload>
      <button mat-button (click)="dispatchUploadFile()">Zahájit nahrávání</button>
<!--      <button mat-button (click)="dispatchUploadAndSelectFile()">Nahrát a vybrat</button>-->
    </mat-tab>

    <mat-tab label="Nahrát z URL">
      <pk-upload-url (uploaded)="onNewItems($event)" [categories]="categories$ | async"></pk-upload-url>
      <button mat-button (click)="dispatchAddUrl()">Přidat URL</button>
      <button mat-button (click)="dispatchUploadUrl()">Zahájit nahrávání</button>
    </mat-tab>
  </mat-tab-group>
</ng-template>

<!-- OTHER ENTITY PICKER -->
<ng-template #entityList>
  <pk-data-table [shouldNavigateAfterRowClick]="false"
                 [canSelectMultiple]="data.isMultiple"
                 [currentEntity]="data.entityType"
                 [overrideColumnDefinitions]="data.overrideColumnDefinitions"></pk-data-table>
</ng-template>
