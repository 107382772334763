import { Injectable, InjectionToken } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { FetchResult } from 'apollo-link';
import { readAllKategorieQuery, readSelectedKategorieQuery } from '@nx-monorepo/obce/common/queries';
import { addKategorieMutation } from '@nx-monorepo/obce/common/mutations';
import { IPkService } from './i-pk-service';
import { map } from 'rxjs/operators';

export const KATEGORIE_SERVICE_TOKEN = new InjectionToken<IPkService>('KategorieService');

@Injectable({
  providedIn: 'root'
})
export class KategorieService implements IPkService {

  constructor(private apollo: Apollo) {
  }

  fetchAll(vars: object) {
    return this.apollo.query({
      query: readAllKategorieQuery,
      variables: vars
    });
  }

  fetchSelected(vars: number[]) {
    return this.apollo.query<[]>({
      query: readAllKategorieQuery,
      variables: vars
    }).pipe(map(res => res.data));
  }

  fetchSingle(id: number) {
    return this.apollo.query<{response: any[]}>({
      query: readSelectedKategorieQuery,
      variables: {ids: [id]}
    }).pipe(map((response) => {
      //console.log(response);
      if (response.data.response.length > 0) {
        return response.data.response[0];
      } else {
        return null;
      }
    }));
  }

  save(vars: object): Observable<FetchResult<any>> {
    return this.apollo.mutate({
      mutation: addKategorieMutation,
      variables: vars
    });
  }


}
