import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { displaySnack, EntityDefinitionProvider} from '@nx-monorepo/cms-base/helpers';
import { of as observableOf, Subject, Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { PkGridsterItemDefinitionService } from '../pk-gridster-item-definition.service';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { KolekceService } from '@nx-monorepo/obce/ng/services';
import { PkGridsterItem } from '@nx-monorepo/cms-base/components/pk-gridster-items/pk-gridster-item';
import { LayoutActionTypes } from '@nx-monorepo/cms-base/store/layout/layout.actions';
import { SnackbarStoreActions } from '@nx-monorepo/cms-base/store/snackbar';
import { BasePageComponent } from '../../base-page.component';
import { PkGridsterComponent } from '@nx-monorepo/cms-base/components/pk-gridster/pk-gridster.component';
import { GridsterFrontendComponent } from '@nx-monorepo/cms-base/components/pk-modulelist/pk-modulelist.component';

@Component({
  selector: 'pk-frontend-gridster',
  templateUrl: './pk-frontend-gridster.component.html',
  styleUrls: ['./pk-frontend-gridster.component.scss']
})
export class PkFrontendGridsterComponent extends BasePageComponent implements OnDestroy, OnInit {

  @ViewChild('gridster') gridster: PkGridsterComponent;

  @Input() showPreview = false; //someday maybe... probably not...just, false.. ok?

  destroy$: Subject<boolean> = new Subject<boolean>();
  saveAction$: Subscription;
  private idsToDelete: number[] = [];
  entityDefinitions: IEntityDefinition[];
  gridsterForm: FormGroup = new FormGroup({});



  constructor(store$: Store<MACMSStoreState.State>,
              currentRoute: ActivatedRoute,
              private router: Router,
              private kolekceService: KolekceService,
              private entityDefinitionProvider: EntityDefinitionProvider,
              private actionsSubject$: ActionsSubject,
              private itemDefinitionService: PkGridsterItemDefinitionService) {
    super(store$, currentRoute);
  }

  ngOnInit(): void {
    // get the curent selected category
    this.entityDefinitions = this.entityDefinitionProvider.getEntityDefinitionsFor(this.routeData.entityType);
    this.generateForm();

    // subscribe to the action where Save button is pressed

    const entityId = this.currentRoute.snapshot.paramMap.get('id');
    if (entityId) {
      this.fetchEntityAndFillForm(Number(entityId));
    } else {
      this.gridsterForm.get('id').disable();
    }
    this.subscribeToSaveButtonAction();
  }

  getItemList() {
    return this.itemDefinitionService.getFrontendItems();
  }

  public itemDeleted(id: number) {
    if (id) {
      this.idsToDelete.push(id);
    }
  }

  private getDataForType(type: GridsterFrontendComponent, options) {
    switch (type) {
      case 'ItemNavComponent':
        return this.kolekceService.fetchAll(EntityTypes.Navigace);
    }
  }

  private findDataInOptions(options) {
    const parsed = JSON.parse(options);
    let result = null;
    for (const key in parsed) {
      if (key === 'data') {
        result = parsed[key];
      }
    }
    return result;
  }

  private parseGridsterItemsFromResponse(response) {
    const items = response.obsah.map(item => {
      const gridItem = {
        id: item.id,
        cols: item.cols,
        rows: 1,
        x: item.coord_x,
        y: item.coord_y,
        settings: JSON.parse(item.options),
        data: this.getDataForType(item.type, item.options),
        moduleItem: this.itemDefinitionService.findItemByType(item.type),
        resizeEvent: this.gridster.resizeEvent
      } as PkGridsterItem;
      return gridItem;
    });
    this.gridster.setItems(items);
  }


  private fetchEntityAndFillForm(entityId: number) {
    this.kolekceService.fetchSingle(this.routeData.entityType, entityId).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: any) => {
      this.gridsterForm.patchValue(res);
      this.parseGridsterItemsFromResponse(res);
    });
  }

  private generateForm() {
    // pro kazdej field entity
    this.entityDefinitions.forEach((entityDefinition: IEntityDefinition) => {
      // nastavit jim konkretni form
      entityDefinition.settings.form = this.gridsterForm;

      // pridat do formy control
      this.gridsterForm.addControl(
        entityDefinition.settings.formControlName,
        new FormControl(entityDefinition.settings.defaultValue || '', entityDefinition.validators)
      );
    });
  }

  private subscribeToSaveButtonAction() {
    if (this.saveAction$) {
      this.saveAction$.unsubscribe();
    }

    this.saveAction$ = this.actionsSubject$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(async (action) => {
      if (action.type === LayoutActionTypes.SAVE_BUTTON_PRESSED) {
        // todo validace
        if (this.gridsterForm.valid) {
          await this.deleteVstupniPole();
          const obsah = this.transformGridsterItemsToPageContent(this.gridster.gridsterItems);
          this.saveEntity(obsah);
        } else {
          displaySnack('Vyplňte prosím všechny položky!', this.store$);
        }
      }
    });
  }

  private saveEntity(obsah) {
    const parentStranka = this.gridsterForm.value;
    parentStranka.obsah = obsah;

    // ignore generated keys in form
    Object.keys(parentStranka).forEach(key => {
      if(key.includes('generated_')){
        parentStranka[key] = undefined;
      }
    });

    this.kolekceService.save(this.routeData.entityType, { input: [parentStranka] }).pipe(
      takeUntil(this.destroy$),
      map(result => {
        this.router.navigateByUrl(this.currentRoute.snapshot.url[0].path || '/');
        this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({ message: 'Úspěšně uloženo!', action: 'OK', config: { duration: 5000 } }));
        return result;
      }),
      catchError((error) => {
        // todo zobrazit error
        console.error(error);
        this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({ message: error, action: 'OK', config: { duration: 5000 } }));
        return observableOf(error);
      })
    ).subscribe();
  }


  private transformGridsterItemsToPageContent(gridsterItems: PkGridsterItem[]) {
    return gridsterItems.map((pole) => {
      console.log(pole);
      const strankaObsahInput = {
        id: pole.id,
        cols: pole.cols,
        coord_x: pole.x,
        coord_y: pole.y,
        type: pole.moduleItem.type,
        nazev: pole.settings.nazev,
        options: JSON.stringify({ ...pole.settings, form: undefined, formControlName: undefined })
      } as any;
      return strankaObsahInput;
    });
  }

  private async deleteVstupniPole() {
    if (this.idsToDelete.length > 0) {
      return this.kolekceService.deleteData(EntityTypes.StrankaObsah, this.idsToDelete).pipe(
        take(1),
        catchError((error) => {
          // todo zobrazit error
          console.error(error);
          return observableOf(error);
        })
      ).toPromise();
    }
  }

  ngOnDestroy(): void {
    // reset form
    this.gridsterForm.reset();

    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

}
