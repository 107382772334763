<div class="group">
    <p class="title">{{settings.nazev}}</p>
    <p class="row">
        <mat-form-field appearance="outline" fxFlex [formGroup]="settings.form" class="left">  
            <mat-label>Vlevo</mat-label>
            <input
                matInput
                [formControlName]="settings.formControlName"
                [type]="settings.type" 
                [required]="settings.povinnost" 
                [formControlName]="settings.formControlName"
            />
        </mat-form-field>
        
        <mat-form-field appearance="outline" [formGroup]="settings.form" class="right">
            <mat-label>Vpravo</mat-label>
            <input
                matInput
                [formControlName]="settings.formControlName"
                [type]="settings.type" 
                [required]="settings.povinnost" 
                [formControlName]="settings.formControlName"
            />
        </mat-form-field>
    </p>

    <p class="row">
        <mat-form-field appearance="outline" fxFlex [formGroup]="settings.form" class="left">
            <mat-label>Nahoře</mat-label>
            <input
                matInput
                [formControlName]="settings.formControlName"
                [type]="settings.type" 
                [required]="settings.povinnost" 
                [formControlName]="settings.formControlName"
            />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex [formGroup]="settings.form" class="right">
            <mat-label>Dole</mat-label>
            <input
                matInput
                [formControlName]="settings.formControlName"
                [type]="settings.type" 
                [required]="settings.povinnost" 
                [formControlName]="settings.formControlName"
            />
        </mat-form-field>
    </p>
   
    <!-- ERROR -->
    <mat-error *ngIf="firstError">
        {{firstError}}
    </mat-error>
</div>