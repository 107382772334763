<ng-container>
  <form [formGroup]="entityForm">
    <div
      *ngFor="let field of entityDefinitions"
      [ngClass]="field.componentName === 'PkHiddenFieldComponent' ? 'hidden-field' : ''"
      [style.grid-row-start]="field.y + 1"
      [style.grid-column-start]="field.x + 1"
      [style.grid-column-end]="field.x + 1 + field.cols"
    >
      <!-- zobrazujeme form field pokud NEMA showFunction nebo dle vysledku showFunction -->
      <ng-container
        *ngIf="!shouldDisplay$.hasOwnProperty(field.settings.formControlName) || (shouldDisplay$[field.settings.formControlName] | async)"
        cmsComponentFactory
        [componentName]=field.componentName
        [settings]=field.settings
        [data]="field.data">
      </ng-container>
    </div>
  </form>

  <p>
    Form Status: {{ entityForm.status }}
  </p>
</ng-container>
