import {AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, Subject, combineLatest, BehaviorSubject} from 'rxjs';
import {pairwise, takeUntil, filter, map, combineAll} from 'rxjs/operators';
import {FuseConfigService} from '@fuse/services/config.service';
import {Store} from '@ngrx/store';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
  RoutesRecognized,
} from '@angular/router';
import {ClearAllSelected} from '../store/table/table.actions';
import {AuthService} from '../services/auth.service';
import {FuseProgressBarService} from '@fuse/components/progress-bar/progress-bar.service';
import {Token} from '@nx-monorepo/api-base/lib/interfaces/i-token';
import { IAction } from '../interfaces';
import { MACMSStoreState, CategoryTabsActions, LayoutStoreSelector } from '../..';

@Component({
  selector: 'macms-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  fuseConfig: any;
  navigation: any;

  // Private
  private _userToken: Token;
  private _unsubscribeAll: Subject<any>;

  //observables
  activeTabName$: Observable<string>;
  tabs$: BehaviorSubject<IAction[]> = new BehaviorSubject([]);

  constructor(private store$: Store<MACMSStoreState.State>, private _fuseConfigService: FuseConfigService, protected router: Router, private authService: AuthService,
              private _fuseProgressBarService: FuseProgressBarService) {
    
    // Set the defaults
    // this.navigation = adminNavigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.router.events
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        const previousPath = events[0].urlAfterRedirects.split('/');
        const currentPath = events[1].urlAfterRedirects.split('/');
        if (previousPath.length > 1 && currentPath.length > 1) {
          const previousRoot = previousPath[1];
          const currentRoot = currentPath[1];

          // menime cestu na jinou entitu
          this.store$.dispatch(new CategoryTabsActions.ShouldRefetchCategories({shouldRefetch: previousRoot !== currentRoot}));
          if (previousRoot !== currentRoot) {
            this.store$.dispatch(new ClearAllSelected());
          }

        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    const token$ = this.authService.getTokenObservable().asObservable();
    //if(this.authService.isLoggedIn()) {
    //pipe will only pass tabs that can be shown to user according to his privileges
    const tabChange$ = this.store$.select(LayoutStoreSelector.selectLayoutTabs); 
    combineLatest([token$,tabChange$]).pipe(
      takeUntil(this._unsubscribeAll),
      map(([token,actions]) => {
        if (this.authService.isLoggedIn()) {
          const newArray = [];
          actions.forEach(action => {
            //default is false
            let show = false;
            if (!action.showFor) {
              //if there are no showFors specified than show for everybody
              show = true;
            } else {
              //show only if you have the right privilege
              action.showFor.every(privilege => {
                if (Object.keys(token?.role.pravomoce || {}).includes(privilege)) {
                  // it might be set to a false tho
                  if (token.role.pravomoce[privilege]) {
                    show = true;
                  }
                  return false;
                } else {
                  return true;
                }
              });
            }
            if (show) {
              newArray.push(action);
            }
          this.tabs$.next(newArray);
          return newArray;
        })}}))
      .subscribe();

    this.activeTabName$ = this.store$.select(LayoutStoreSelector.selectLayoutActiveTabName);

    this.router.events.subscribe((event: RouterEvent) => {
      // console.log('event', event);
      if (event instanceof NavigationStart) {
        this._fuseProgressBarService.show();
      } else if (event instanceof NavigationEnd) {
        this._fuseProgressBarService.hide();
      }
    });

    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.fuseConfig = config;
      });

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void {

  }
}
