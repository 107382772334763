import {Action} from '@ngrx/store';
import {ITableSettings, PaginatedResponse} from './table.state';

export enum TableActionTypes {
    INIT_TABLE = '[Table] Init Table',
    FETCH_TABLE_DATA = '[Table] Fetch Table Data',
    SET_TABLE_DATA_SUCCESS = '[Table] Set Data Success',
    SET_TABLE_DATA_ERROR = '[Table] Set Data Error',
    TOGGLE_SELECTED_ITEM = '[Table] Toggle Selected Item',
    SET_SELECTED_ITEMS = '[Table] Set Selected Items',
    CLEAR_ALL_SELECTED = '[Table] Clear All Selected',
    TOGGLE_MASTER_SELECT = '[Table] Toggle Master Select',
    DELETE_SELECTED = '[Table] Delete Selected',
    DELETE_SUCCESS = '[Table] Delete Success',
    DELETE_FAILURE = '[Table] Delete Failure',
}

export class InitTableAction implements Action {
    readonly type = TableActionTypes.INIT_TABLE;

    constructor(
        public payload: ITableSettings
    ) {

    }
}
export class FetchTableDataAction implements Action {
  readonly type = TableActionTypes.FETCH_TABLE_DATA;

  constructor()
  {
  }
}

export class SetTableDataSuccessAction<T> implements Action {
    readonly type = TableActionTypes.SET_TABLE_DATA_SUCCESS;

    constructor(public payload: { data: PaginatedResponse<T> }) {
    }
}

export class SetTableDataErrorAction implements Action {
    readonly type = TableActionTypes.SET_TABLE_DATA_ERROR;

    constructor(public payload: { error: string }) {
    }
}

export class ToggleSelectedItem<T> implements Action {
    readonly type = TableActionTypes.TOGGLE_SELECTED_ITEM;

    constructor(public payload: { selectedItem: any, canSelectMultiple: boolean }) {
    }
}

export class SetSelectedItems<T> implements Action {
    readonly type = TableActionTypes.SET_SELECTED_ITEMS;

    constructor(public payload: { selectedItems: T[] }) {
    }
}

export class ClearAllSelected implements Action {
    readonly type = TableActionTypes.CLEAR_ALL_SELECTED;

    constructor() {
    }
}

export class ToggleMasterSelect implements Action {
    readonly type = TableActionTypes.TOGGLE_MASTER_SELECT;

    constructor() {
    }
}

export class DeleteSelectedItems implements Action {
    readonly type = TableActionTypes.DELETE_SELECTED;

    constructor() {
    }
}

export class DeleteSelectedSuccess implements Action {
    readonly type = TableActionTypes.DELETE_SUCCESS;

    constructor(public payload: { deletedIds: number[] }) {
    }
}

export class DeleteSelectedFailure implements Action {
    readonly type = TableActionTypes.DELETE_FAILURE;

    constructor(public payload: { error: string }) {
    }
}

export type TableActions<T> =
    | InitTableAction
    | FetchTableDataAction
    | SetTableDataSuccessAction<T>
    | SetTableDataErrorAction
    | ToggleMasterSelect
    | DeleteSelectedItems
    | ClearAllSelected
    | DeleteSelectedSuccess
    | DeleteSelectedFailure
    | SetSelectedItems<T>
    | ToggleSelectedItem<T>;
