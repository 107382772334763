import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadingModule } from '../heading/heading.module';
import { FlexModule, GridModule } from '@angular/flex-layout';
import { UtilsModule } from '../../utils/utils.module';
import { WysiwygComponent } from './wysiwyg.component';

@NgModule({
  imports: [CommonModule, HeadingModule, FlexModule, GridModule, UtilsModule],
  exports: [WysiwygComponent],
  entryComponents: [WysiwygComponent],
  declarations: [WysiwygComponent]
})
export class WysiwygModule {}


