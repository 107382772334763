import { Component, Input, OnInit } from '@angular/core';
import { SvatekProps } from './svatek.props';
import { ObecService, SvatekService } from '@nx-monorepo/obce/ng/services';

@Component({
  selector: 'macms-svatek',
  templateUrl: './svatek.component.html',
  styleUrls: ['./svatek.component.scss']
})
export class SvatekComponent implements OnInit {

  @Input()
  settings: SvatekProps;

  constructor(private obecService: ObecService, private svatekService: SvatekService) { }

  svatek$;

  ngOnInit() {

    this.obecService.settings$.subscribe(
      (next: any) => {
        const obceSettings = {
          isInverted: false,
          primaryColor: next.primary_color,
          fontFamily: next.paragraph_font,
          accentColor: next.secondary_color,
        };
        this.settings = {
          ...obceSettings,
          ...this.settings,
        }
      }
    );


    this.svatek$ = this.svatekService.getToday().toPromise().then(result => {
      return result.data.response;
    });
  }

}
