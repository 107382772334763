<div>
  <section id="uredni-desky"
           [style.background]="wrapperBackgroundColor">
    <macms-heading
      [settings]="headingSettings"
      [text]="data.nazev || 'Úřední deska'">
    </macms-heading>
    <ng-template *ngIf="settings.uvod" [ngTemplateOutlet]="popis">
    </ng-template>
    <div class="grid">
      <ng-container *ngFor="let polozka of data.items">
        <a
          class="item"
          fxLayout="column"
          [routerLink]="(polozka.slug ? polozka.slug : polozka.id) | DetailUrl:'uredni-deska':polozka.kategorie.nazev"
          [paddingDefinition]="settings.itemPadding"
          [style.flexBasis]="settings.itemFlexBasis"
          [style.flexGrow]="'1'">
          <div class="top-row" fxLayout="row" fxLayoutAlign="center center">
            <ng-template [ngTemplateOutlet]="state"
                         [ngTemplateOutletContext]="{polozka:polozka}"></ng-template>
            <ng-template
              [ngTemplateOutlet]="dates"
              [ngTemplateOutletContext]="{polozka:polozka}"></ng-template>
          </div>
          <ng-template [ngTemplateOutlet]="title"
                       [ngTemplateOutletContext]="{polozka:polozka}"></ng-template>
          <div class="bottom" fxLayout="row" [style.color]="onSubSecondaryColor" [style.background]="secondaryColor">
            <ng-template [ngTemplateOutlet]="downloadList"
                         [ngTemplateOutletContext]="{polozka:polozka}"></ng-template>
          </div>
        </a>
      </ng-container>
    </div>
    <macms-next-button href="/uredni-deska" [settings]="nextButtonSettings"
                       text="Další záznamy úřední desky"></macms-next-button>
  </section>
</div>


<ng-template #state let-polozka="polozka">
  <div class="state" fxLayout="row" fxLayoutAlign="center center"
       [style.background]="settings.primaryColor ? settings.primaryColor : accentColor"
       [contrastForeground]="settings.primaryColor ? settings.primaryColor : accentColor"
       [preferedColors]="[accentColor, primaryColor, secondaryColor]"
       [style.fontFamily]="obecSettings.title_font">
    {{polozka.kategorie.nazev}}
  </div>
</ng-template>

<ng-template #dates let-polozka="polozka">
  <div class="dates"
       [style.background]="subSecondaryColor"
       [style.color]="onSubSecondaryColor"
       [style.fontFamily]="obecSettings.title_font">
    <div *ngIf="settings.showCalendarIcon" class="calendar icon-wrapper">
      <fa-icon [icon]="calendarIcon"></fa-icon>
    </div>
    <div class="date"
         [style.color]="onSubSecondaryColor">{{polozka.datum_od | amLocale:'cs'  | amDateFormat:'DD.MM.YY'}}</div>
    <ng-container *ngIf="polozka.datum_do">
      <div class="separator" [style.color]="onSubSecondaryColor">&nbsp;-&nbsp;</div>
      <div class="date"
           [style.color]="onSubSecondaryColor">{{polozka.datum_do | amLocale:'cs' | amDateFormat:'DD.MM.YY'}}</div>
    </ng-container>
  </div>
</ng-template>

<!--Seznam -->
<ng-template #downloadList let-polozka="polozka">
  <div [style.background]="subSecondaryColor" fxLayoutAlign="center center" class="file">
    <div class="download icon-wrapper" fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 0"
         [style.background]="accentColor"
         [contrastForeground]="accentColor"
         [preferedColors]="[primaryColor]"
    >
      <fa-icon [icon]="downloadIcon"></fa-icon>
    </div>
    <div class="category"
         [style.color]="onSubSecondaryColor"
         [style.fontFamily]="obecSettings.paragraph_font">
      Počet příloh: {{polozka.media.length}}
    </div>
  </div>
</ng-template>

<ng-template #title let-polozka="polozka">
  <div class="title ud-title"
       [style.background]="secondaryColor"
       [style.color]="onSecondaryColor"
       [style.fontFamily]="obecSettings.title_font"
  >{{polozka.nazev}}</div>
</ng-template>

<ng-template #horizontalLine>
  <div class="horizontal-line"></div>
</ng-template>

<ng-template #popis>
  <div [style.color]="settings.primaryColor" [style.fontFamily]="obecSettings.paragraph_font" style="width:100%;"
       class="intro-text">
    <p>{{ settings.uvod }}</p>
  </div>
</ng-template>
