<mat-form-field appearance="outline" fxFlex [formGroup]="settings.form">
  <mat-label>{{ settings.nazev }}</mat-label>

  <mat-chip-list #chipList>
    <mat-chip *ngFor="let item of innerData" (removed)="remove(item)">
      {{ item.nazev }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input placeholder="Nová položka..."
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="true"
           (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>

  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info</mat-icon>
  <!-- MAT-ERROR NOT WORKING WITH MAT CHIP LIST SO WE HAVE OUR OWN -->
  <div class="customError" *ngIf="firstError">
    {{firstError}}
  </div>
</mat-form-field>
