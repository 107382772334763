import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output, ViewChild
} from '@angular/core';

import { PkGridsterItem } from '../pk-gridster-item';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PkDialogComponent } from '../../pk-dialog/pk-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'pk-gridster-base-item',
  templateUrl: './pk-gridster-base-item.component.html',
  styleUrls: ['./pk-gridster-base-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class PkGridsterBaseItemComponent implements OnInit, OnDestroy {
  @Input()
  item: PkGridsterItem;

  @Input()
  resizeEvent;

  @Input()
  showPreview;

  @Output()
  deleteEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();


  @ViewChild('gridsterItemComponent') gridsterItemComponent;


  private dialogRef: MatDialogRef<PkDialogComponent, string>;
  private destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  public onEditClick() {
    if (this.dialogRef == null) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = '50%';

      if(this.gridsterItemComponent){
        console.log(this.gridsterItemComponent);

        // create dialog config and pass dialog data to it
        dialogConfig.data = this.gridsterItemComponent.getDialogData();
      } else {
        // in case of some components, we do not have a preview now, so we will use components companion object to get data

        // we will create our own emitters
        const dialogClose = new EventEmitter<void>();
        const dialogSubmit = new EventEmitter<any>();

        dialogClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.dialogRef.close();
        });

        dialogSubmit.pipe(takeUntil(this.destroy$)).subscribe((result) => {
          // overwrite settings
          this.item.settings = result;
          console.log(result);
          this.dialogRef.close();
        });

        dialogConfig.data = this.item.moduleItem.formCompanion.getDialogData(
          this.item.settings,
          this.item.data,
          {dialogClose, dialogSubmit}
        );
      }

      // open dialog and subscribe to its afterClosed event to remove the reference
      this.dialogRef = this.dialog.open(PkDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.dialogRef = null;
      });
    }
  }

  ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}
