<div id="login" fxLayout="column">
  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="login-form" [@animate]="{value:'*',params:{duration:'600ms',y:'500px'}}" class="fuse-card">

      <div class="logo">
        <img src="assets/images/logos/echopix_cms_logo_rect.svg">
      </div>

      <div class="title">Nastavte si heslo</div>
      <div class="my-16 fuse-card warn-100 mx-auto" *ngIf="errorMessage">
        <div class="p-16" fxLayout="row" fxLayoutAlign="start center">
          <fa-icon size="2x" icon="exclamation-circle"></fa-icon>
        </div>
      </div>
      <form name="loginForm" (ngSubmit)="onSubmit()" [formGroup]="loginForm" novalidate >

        <mat-form-field appearance="outline">
          <mat-label>Heslo</mat-label>
          <input [value]="password" matInput type="password" formControlName="password" [ngModel]="password">
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
          <mat-error>
            Heslo je vyžadováno a musí mít alespoň 6 znaků.
          </mat-error>
        </mat-form-field>


        <button mat-raised-button color="accent" class="submit-button" type="submit" aria-label="Odeslat"
                [disabled]="loginForm.invalid">
          Odeslat
        </button>

      </form>
    </div>

  </div>
</div>
