import {NgModule} from "@angular/core";
import {PkNewUzivatelPageComponent} from "./pk-new-uzivatel-page/pk-new-uzivatel-page.component";
import {DynamicRendererModule} from "../../../../../../libs/cms-base/src/lib/dynamic-renderer/dynamic-renderer.module";
import {ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [
    PkNewUzivatelPageComponent
  ],
  imports: [
    DynamicRendererModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule
  ]
})
export class PagesModule {

}
