<section [style.background]="settings.sectionBackground | fixBackgroundUrl">
  <div class="section-subnav" [paddingDefinition]="settings.sectionPadding" [style.fontFamily]="obecSettings.paragraph_font">
    <macms-heading *ngIf="settings.showHeading" [settings]="headingSettings"
                   [text]="settings.nazev || 'Užitečné odkazy'">

    </macms-heading>
    <div class="wrapper">
      <nav class="grid" [style.marginLeft]="'-'+settings.itemGap" [style.marginRight]="'-'+settings.itemGap">
        <ng-container *ngFor="let polozka of navigacePolozky">
          <ng-container *ngTemplateOutlet="polozka.url ? outsideLink : insideLink; context: {polozka: polozka}">

          </ng-container>
        </ng-container>
      </nav>
    </div>
  </div>
</section>

<ng-template #chevron>
  <span [paddingDefinition]="settings.iconPadding" *ngIf='settings.otherIcon === "chevron"; else emptyicon'
        [style.background]="'rgba(0,0,0,0.2)'" class="right icon">
    <fa-icon icon="chevron-right"></fa-icon>
  </span>
</ng-template>

<ng-template #emptyicon>
  <span class="icon" [paddingDefinition]="settings.iconPadding"></span>
  <!--<fa-icon></fa-icon>-->
</ng-template>

<ng-template #inside let-polozka="polozka">
  <div macmsColor
       class="item-header"
       [paddingDefinition]="settings.itemPadding"
       [borderDefinition]="settings.itemBorder"
       [mc_wantedPrimary]="settings.useNavItemsColors && polozka.barva ? polozka.barva : settings.primaryColor"
       [mc_otherColors]="[settings.secondaryColor]"
       [mc_contrastThreshold]="4.5"
       [mc_isInverted]="settings.isInverted">
    <ng-container *ngIf="settings.iconPosition == 'left'">
                <span class="left icon" macmsColor
                      [paddingDefinition]="settings.iconPadding"
                      [mc_wantedPrimary]="settings.useNavItemsColors && polozka.barva ? polozka.barva : settings.secondaryColor ? settings.secondaryColor : settings.primaryColor"
                      [mc_otherColors]="[settings.primaryColor, settings.secondaryColor]"
                      [mc_contrastThreshold]="4.5"
                      [mc_isInverted]="settings.isIconInverted">
                  <ng-container *ngIf='polozka.ikonka'>

                    <fa-icon icon="{{polozka.ikonka}}"></fa-icon>
                  </ng-container>
                </span>
    </ng-container>

    <div class="title" [style.textAlign]="settings.textAlign"
         [style.fontFamily]="obecSettings.title_font">{{polozka.nazev}}</div>
    <ng-container *ngIf="settings.iconPosition == 'right'; else chevron">
                <span class="right icon" macmsColor
                      [paddingDefinition]="settings.iconPadding"
                      [mc_wantedPrimary]="settings.useNavItemsColors && polozka.barva ? polozka.barva : settings.primaryColor"
                      [mc_contrastThreshold]="6"
                      [mc_isInverted]="settings.isIconInverted"
                >
                  <ng-container *ngIf='polozka.ikonka'>
                    <fa-icon icon="{{polozka.ikonka}}"></fa-icon>
                  </ng-container>
                </span>
    </ng-container>


  </div>
  <div *ngIf='settings.showPopis' class="popis">{{polozka.popis}}</div>
</ng-template>
<ng-template #insideLink let-polozka="polozka">
  <a [href]='polozka.url' [routerLink]="(polozka.stranka ? polozka.stranka.slug : null) | DetailUrl:'stranky'"
     class="item" [style.padding]="settings.itemGap"
     [style.flexBasis]="settings.itemFlexBasis ? settings.itemFlexBasis : '320px'">
    <ng-container [ngTemplateOutlet]="inside" [ngTemplateOutletContext]="{polozka: polozka}"></ng-container>
  </a>
</ng-template>
<ng-template #outsideLink let-polozka="polozka">
  <a [href]="polozka.url" class="item" [style.padding]="settings.itemGap"
     [style.flexBasis]="settings.itemFlexBasis ? settings.itemFlexBasis : '320px'">
    <ng-container [ngTemplateOutlet]="inside" [ngTemplateOutletContext]="{polozka: polozka}"></ng-container>
  </a>
</ng-template>
