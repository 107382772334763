export enum VideoCategory {
  WEB = "WEB",
  CMS = "CMS",
  MODULY = "MODULY"
}

export type Videos = {
  category: VideoCategory,
  length: number,
  updated: string,
  title: string,
  url: string,
  icon: string,
}
