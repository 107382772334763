import { Component, OnInit } from "@angular/core";
import { BasePageComponent } from '../base-page.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {Store} from "@ngrx/store";
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import * as moment from 'moment';
import { State } from '@nx-monorepo/cms-base/store/store.state';
import { countItems } from '@nx-monorepo/obce/common/queries';
import { displaySnack } from '@nx-monorepo/cms-base/helpers';

interface CasovyUsek {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'pk-vuctovani-page',
  templateUrl: './pk-vyuctovani.component.html',
  styleUrls: ['./pk-vyuctovani.component.scss']
})

export class PkVyuctovaniPageComponent extends BasePageComponent implements OnInit {
  constructor(
    protected route: ActivatedRoute,
    protected store$: Store <State> ,
    private apollo: Apollo,
    private _formBuilder: FormBuilder,
  ) {
    super(store$, route);
  }

  entityForm: FormGroup;
  itemRanged: Observable < {
    data: {
      response: [{
        pocet ? ,
        rok ? ,
        mesic ? ,
        den ?
      }]
    }
  } > ;
  itemVse: Observable < {
    data: {
      response: [{
        pocet ? ,
        rok ? ,
        mesic ? ,
        den ?
      }]
    }
  } > ;
  dateFrom: Date;
  dateTo: Date;
  colors: string[] = ['#55efc4', '#81ecec', '#74b9ff', '#a29bfe', '#a29bfe', '#dfe6e9', '#00b894', '#00cec9', '#0984e3', '#6c5ce7', '#b2bec3', '#ffeaa7', '#fab1a0'];
  selectedValue: string;
  vyuctovaniForm: FormGroup;
  chart: Chart;

  vybranyUsekSoucet = 0;
  celkemSoucet = 0;
  vybranyUsekCena = 0;
  celkemCena = 0;
  usekDateFrom = '';
  usekDateTo = '';

  casovyUsek: CasovyUsek[] = [/*{
      value: 'Vse',
      viewValue: 'Vše'
    },*/
    {
      value: 'Rok',
      viewValue: 'Rok'
    },
    {
      value: 'Mesic',
      viewValue: 'Měsíc'
    },
    {
      value: 'Den',
      viewValue: 'Den'
    }
  ];

  barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        }
      }]
    }
  }
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = false;
  barChartData: ChartDataSets[] = [{
    data: [],
    label: 'Počet položek',
    backgroundColor: [],
    hoverBackgroundColor: 'rgb(88,88,88)'
  }]

  ngOnInit(): void {
    moment.locale('cs'); //pre nazvy mesiacov
    //default - when user open page, he will have results for current month
    this.vyuctovaniForm = this._formBuilder.group({
      obdobi: ['Mesic', [Validators.required]],
      dateFrom: [moment().startOf('month').format("YYYY-MM-DD HH:mm:ss")],
      dateTo: [moment().endOf('month').format("YYYY-MM-DD HH:mm:ss")],
    });

    this.count();
  }

  //randomly select color from array and do not repeat
  numberGenerator(arr) {
    if (arr.length >= 12) return;
    const newNumber = Math.floor(Math.random() * 12 + 1);
    if (arr.indexOf(newNumber) < 0) {
      arr.push(newNumber);
    }
    this.numberGenerator(arr);
  };

  count() {
    if (!this.vyuctovaniForm.get('obdobi').value) {
      displaySnack('Vyplňte období!', this.store$);
      throw new Error('Vyplňte období!');
    }
    this.usekDateFrom = moment(this.vyuctovaniForm.get('dateFrom').value).format("DD.MM.YYYY");
    this.usekDateTo = moment(this.vyuctovaniForm.get('dateTo').value).format("DD.MM.YYYY");
   
    this.barChartData[0].data = [];
    this.barChartLabels = [];
    
    //todo: sql is messing with timezones and setting time -2 hours
    this.itemRanged = this.apollo.query({
      query: countItems,
      variables: {
        entity_type: this.routeData.entityType.toLowerCase(),
        casovy_usek: this.vyuctovaniForm.get('obdobi').value,
        datum_od: this.vyuctovaniForm.get('dateFrom').value 
                    ? moment(this.vyuctovaniForm.get('dateFrom').value).format("YYYY-MM-DD") 
                    : moment("1990-01-01").format("YYYY-MM-DD"),
        datum_do: this.vyuctovaniForm.get('dateTo').value 
                    ? moment(this.vyuctovaniForm.get('dateTo').value).add(1,'days').format("YYYY-MM-DD") 
                    : moment().add(1,'days').format("YYYY-MM-DD"),
      }
    });

    this.itemRanged.subscribe(res => {
      this.vybranyUsekSoucet = 0;
      if (this.chart) {
        this.chart.destroy();
      }

      const num = [];
      this.numberGenerator(num);
      const colorArr = [];
      res.data.response.forEach((item, i) => {
        colorArr.push(this.colors[num[i]]);
        this.barChartLabels.push((item.den ? item.den + " " : "") + (item.mesic ? moment.months(item.mesic - 1) : "") + (item.rok ? " " + item.rok : ""));
        this.barChartData[0].data.push(item.pocet);
        this.vybranyUsekSoucet += item.pocet;
      });
      this.vybranyUsekCena = Math.ceil(this.vybranyUsekSoucet*(this.routeData.entityType.toString() === 'Sms' ? 0.75 : 0.1));
      this.barChartData[0].backgroundColor = colorArr;
      return res;
    });

    this.itemVse = this.apollo.query({
      query: countItems,
      variables: {
        entity_type: this.routeData.entityType.toLowerCase(),
        casovy_usek: 'Vse',
        datum_od: moment("1990-01-01").format("YYYY-MM-DD"),
        datum_do: moment().add(1,'day').format("YYYY-MM-DD"),
        nezaplacene: true,
      }
    });

    this.itemVse.subscribe(res => {
      this.celkemSoucet = 0;
      res.data.response.forEach((item, i) => {
        this.celkemSoucet += item.pocet;
      });
      this.celkemCena = Math.ceil(this.celkemSoucet*(this.routeData.entityType.toString() === 'Sms' ? 0.75 : 0.1));
    });

    //reset input values
    this.vyuctovaniForm = this._formBuilder.group({
      obdobi: [this.vyuctovaniForm.get('obdobi').value, [Validators.required]],
      dateFrom: [moment(this.vyuctovaniForm.get('dateFrom').value).format("YYYY-MM-DD HH:mm:ss")],
      dateTo: [moment(this.vyuctovaniForm.get('dateTo').value).add(1, 'day').format("YYYY-MM-DD HH:mm:ss")],
    });
  }
}
