// noinspection TypeScriptPreferShortImport
import { MacmsModule } from '../../../components/macms.module';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { TreeModule } from 'angular-tree-component';
import { PkMediaPageComponent } from './pk-media-page/pk-media-page.component';
import { BasePageComponent } from './base-page.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PkDataTablePageComponent } from './pk-data-table-page/pk-data-table-page.component';
import { PkNewEntityPageComponent } from './pk-new-entity-page/pk-new-entity-page.component';
import { NgSharedModule } from '@nx-monorepo/ng-shared';
import { RouterModule } from '@angular/router';
import { PkNewFormularComponent } from './pk-new-formular-page/pk-new-formular.component';
import { PkUploadPageComponent } from './pk-upload-page/pk-upload-page.component';
import { PkNavigationPageComponent } from './pk-navigation-page/pk-navigation-page.component';
import { PkFrontendGridsterComponent } from './pk-gridster-page/pk-frontend-gridster-page/pk-frontend-gridster.component';
import { PkFormGridsterPageComponent } from './pk-gridster-page/pk-form-gridster-page/pk-form-gridster-page.component';
import { PkVstupniPoleFormComponent } from './pk-vstupni-pole-form/pk-vstupni-pole-form.component';
import { PkNewKontaktPageComponent } from './pk-new-kontakt-page/pk-new-kontakt-page.component';
import { PkDataTableModule } from '../../../components/pk-data-table/pk-data-table.module';
import { HttpClientModule } from '@angular/common/http';
import { PkPlatebniPrikazyNastaveniPageComponent } from './pk-platebni-prikazy-nastaveni-page/pk-platebni-prikazy-nastaveni-page.component';
import { PkNewPlatebniPrikazyPageComponent } from './pk-new-platebni-prikazy-page/pk-new-platebni-prikazy-page.component';
import { PkOwnerSettingsPageComponent } from './pk-owner-settings-page/pk-owner-settings-page.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { MatListModule } from '@angular/material/list';
import { PkCategorySettingsPageComponent } from './pk-category-settings-page/pk-category-settings-page.component';
import { DynamicRendererModule } from '../../../dynamic-renderer/dynamic-renderer.module';
import { PkPreviewPlatebniPrikazyPageComponent } from './pk-preview-platebni-prikazy-page/pk-preview-platebni-prikazy-page.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DisplayPagesModule } from '../../../displays/display-pages.module';
import { PkHelpOwnerPage } from './pk-help-owner-page/pk-owner-settings-page.component';
import { MomentModule } from 'ngx-moment';
import { PkVideoHelpPageComponent } from './pk-help-owner-video-page/pk-video-help-page.component';
import { PkChangePasswordPageComponent } from './change-password-page/pk-change-password-page.component';
import { ChartsModule } from 'ng2-charts';
import { FuseWidgetModule } from '@fuse/components';
import { PkVyuctovaniPageComponent } from './pk-vyuctovani-page/pk-vyuctovani.component';
import { UtilsModule } from '@nx-monorepo/frontend-components';
import { CurrencyPipe } from '@angular/common';
import { PkChangeProfilePage } from './pk-change-profile-page/pk-change-profile-page.component';
import { PkHelpHelpdeskPage } from './pk-help-helpdesk-page/pk-help-helpdesk-page.component';

@NgModule({
  declarations: [
    PkHelpOwnerPage,
    PkHelpHelpdeskPage,
    BasePageComponent,
    PkOwnerSettingsPageComponent,
    PkVideoHelpPageComponent,
    PkMediaPageComponent,
    PkDataTablePageComponent,
    PkNewEntityPageComponent,
    PkPlatebniPrikazyNastaveniPageComponent,
    PkNewKontaktPageComponent,
    PkFormGridsterPageComponent,
    PkFrontendGridsterComponent,
    PkNewFormularComponent,
    PkUploadPageComponent,
    PkNavigationPageComponent,
    PkVstupniPoleFormComponent,
    PkNewPlatebniPrikazyPageComponent,
    PkCategorySettingsPageComponent,
    PkPreviewPlatebniPrikazyPageComponent,
    PkChangePasswordPageComponent,
    PkVyuctovaniPageComponent,
    PkChangeProfilePage
  ],
  imports: [
    FuseWidgetModule,
    TranslateModule,
    FuseSharedModule,
    MatIconModule,
    MatProgressBarModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    NgSharedModule,
    RouterModule,
    MacmsModule,
    MatMenuModule,
    MatSelectModule,
    HttpClientModule,
    MatButtonModule,
    TreeModule.forRoot(),
    PkDataTableModule,
    MatInputModule,
    MatTooltipModule,
    NgJsonEditorModule,
    MatListModule,
    DynamicRendererModule,
    QRCodeModule,
    MatCardModule,
    MatProgressSpinnerModule,
    DisplayPagesModule,
    MomentModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    FuseSharedModule,
    MatCardModule,
    MomentModule,
    ChartsModule,
    UtilsModule
  ],
  exports: [
    BasePageComponent,
    PkVideoHelpPageComponent,
    PkFormGridsterPageComponent,
    PkMediaPageComponent,
    PkNewEntityPageComponent,
    PkFrontendGridsterComponent,
    PkNewFormularComponent,
    PkOwnerSettingsPageComponent,
    PkCategorySettingsPageComponent,
    PkUploadPageComponent,
    PkNavigationPageComponent,
    PkVstupniPoleFormComponent,
    PkPlatebniPrikazyNastaveniPageComponent,
    PkNewPlatebniPrikazyPageComponent,
    PkCategorySettingsPageComponent,
    PkPreviewPlatebniPrikazyPageComponent,
    PkChangePasswordPageComponent,
    PkVyuctovaniPageComponent,
    PkChangeProfilePage
  ],
  entryComponents: [
    PkFormGridsterPageComponent,
    PkMediaPageComponent,
    PkDataTablePageComponent,
    PkNewEntityPageComponent,
    PkFrontendGridsterComponent,
    PkNewFormularComponent,
    PkUploadPageComponent,
    PkNavigationPageComponent,
    PkVstupniPoleFormComponent,
    PkPlatebniPrikazyNastaveniPageComponent,
    PkNewPlatebniPrikazyPageComponent,
  ],
  providers: [
    CurrencyPipe
  ]
})
export class PageModule {
}


