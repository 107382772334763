import { IPkDialogData, IPkFormDialogCompanion } from '../../../pk-dialog/pk-dialog-data';
import { Validators } from '@angular/forms';
import { IPkInputWysiwygProps } from '../../../pk-input-fields/pk-input-wysiwyg/pk-input-wysiwyg.props';
import { getDefaultDialogButtons, getNazevSettings } from '../common-settings-objects';

export class PkGridsterItemWysiwygDialogCompanion implements IPkFormDialogCompanion {
  getDialogData(settings: IPkInputWysiwygProps, data: {}, emitters: {dialogSubmit, dialogClose}) {
    const dialogData: IPkDialogData = {
      title: 'Upravit vstupní pole',
      components: [
        getNazevSettings(settings.nazev),
        {
          componentName: 'PkInputWysiwygComponent',
          settings: {
            formControlName: 'defaultValue',
            povinnost: true,
            nazev: 'Obsah',
            napoveda: {
              isIcon: false,
              text: 'Zadejte obsah bloku.'
            },
            defaultValue: settings.defaultValue
          },
          data: null,
          validators: [Validators.required],
          cols: 1,
          x: 0,
          y: 0
        },
      ],
      buttons: getDefaultDialogButtons(emitters)
    };
    return dialogData;
  }
}
