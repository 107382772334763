import { MatDialogRef } from '@angular/material/dialog';
import { IAction } from '@nx-monorepo/cms-base/interfaces';

export interface LayoutState {
  buttons: IAction[];
  tabs: IAction[];
  activeTabName: string;

  error: string;
  pageName: string;
  pageIcon: IAction; //todo typings
  withSearch: boolean;
  search: string;
  withAddButton: boolean;
  hideCategories: boolean;
  confirmDialogRef?: MatDialogRef<any>;
}

export const initialLayoutState: LayoutState = {
  buttons: [],
  tabs: [],
  activeTabName: '',

  error: '',
  pageName: 'default',
  pageIcon: { id: 0, nazev: 'assignment' },
  withSearch: true,
  search: '',
  withAddButton: true,
  hideCategories: false,
  confirmDialogRef: null,
};
