<mat-list>
  <h3 mat-subheader>{{ header }}</h3>
  <mat-list-item
    *ngFor="let item of itemList"
    draggable="true"
    (dragstart)="dragStartHandler($event, item)"
  >
    <mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
    <h4 mat-line>{{ item.title }}</h4>
    <p mat-line>{{ item.description }}</p>
  </mat-list-item>
</mat-list>
