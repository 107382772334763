import { Validators } from '@angular/forms';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { IPkHiddenFieldProps, IPkInputTextFieldProps } from '@nx-monorepo/cms-base/components';

export const kontaktEntityDefinition: IEntityDefinition[] = [
  {
    componentName: 'PkHiddenFieldComponent',
    settings: {
      type: 'number',
      formControlName: 'id',
      defaultValue: 0
    } as IPkHiddenFieldProps,
    data: null,
    validators: [],
    cols: 0,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Název',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Název osoby'
      },
      isTextArea: false,
      formControlName: 'nazev',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 4,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Ulice',
      povinnost: true,
      napoveda: {
        text: 'Ulice/Adresa osoby (Název + ČP nebo ČO/ČP)',
        isIcon: true
      },
      isTextArea: false,
      formControlName: 'ulice',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    validators: [Validators.required],
    data: null,
    cols: 4,
    x: 0,
    y: 1
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'PSČ',
      povinnost: true,
      napoveda: {
        text: 'Poštovní Směrovací Číslo',
        isIcon: true
      },
      isTextArea: false,
      formControlName: 'psc',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    validators: [Validators.required],
    data: null,
    cols:1,
    x: 0,
    y: 2
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Město',
      povinnost: true,
      napoveda: {
        text: 'Město',
        isIcon: true
      },
      isTextArea: false,
      formControlName: 'mesto',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    validators: [Validators.required],
    data: null,
    cols:3,
    x: 1,
    y: 2
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'DIČ',
      povinnost: false,
      napoveda: {
        text: 'Daňové Identifikační Číslo',
        isIcon: true
      },
      isTextArea: false,
      formControlName: 'dic',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    validators: [],
    data: null,
    cols:2,
    x: 0,
    y: 3
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'IČO',
      povinnost: false,
      napoveda: {
        text: 'Identifikační Číslo Občana',
        isIcon: true
      },
      isTextArea: false,
      formControlName: 'ico',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    validators: [],
    data: null,
    cols: 2,
    x: 2,
    y: 3
  },
];
