import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import ClassicEditor from '@markays/ckeditor5-build-classic';
import { IPkInputWysiwygProps } from './pk-input-wysiwyg.props';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { PkEntityDialogComponent } from '../../media-module/pk-entity-dialog/pk-entity-dialog.component';
// import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';
import { take } from 'rxjs/operators';
import { KolekceService } from '@nx-monorepo/obce/ng/services';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';

@Component({
  selector: 'pk-input-wysiwyg-field',
  templateUrl: './pk-input-wysiwyg.component.html',
  styleUrls: ['./pk-input-wysiwyg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PkInputWysiwygComponent implements OnInit {
  private mediaDialogRef: MatDialogRef<PkEntityDialogComponent, string>;

  @Input()
  settings: IPkInputWysiwygProps;

  @Input()
  data: any;

  public editor = ClassicEditor;

  private editorInstance = null;

  public config = {};

  constructor(public dialog: MatDialog, public kolekceService: KolekceService) {
  }


  ngOnInit(): void {

    if(this.settings.plainTextOnly){
      this.config = {toolbar:['bold','italic','underline']};
    }
  }

  private openMediaDialog() {
    if (this.mediaDialogRef == null) {
      // create dialog config and pass dialog data to it
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        isMultiple: false,
        entityType: EntityTypes.Media
      };

      // open dialog and subscribe to its afterClosed event to remove the reference
      this.mediaDialogRef = this.dialog.open(PkEntityDialogComponent, dialogConfig);
      this.mediaDialogRef.afterClosed().pipe(take(1)).subscribe(() => {
        this.mediaDialogRef = null;
      });

      this.mediaDialogRef.componentInstance.entitySelected.subscribe(results => {
        console.log('parent res', results);
        results.forEach(result => {
          this.editorInstance.commands.execute('imageInsert', { source: result.url });
        });
        this.mediaDialogRef.close();
      });
    }
  }


  /*public italicAsEmPlugin( editor ) {
    editor.conversion.for( 'downcast' ).attributeToElement( {
      model: 'italic',
      view: 'em',
      converterPriority: 'high'
    } );
  }
*/
  public onReady(editor) {
    this.editorInstance = editor;
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    editor.commands.get('openExternalModal').on('execute', (evt, propertyName) => {
      this.openMediaDialog();
    });
  }
}

