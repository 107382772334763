import gql from 'graphql-tag';

export const deleteUdalosti = gql`
  mutation deleteUdalosti($ids: [Int!]!) {
    response: deleteUdalost(ids: $ids) {
      description
      userMessage
      error
    }
  }
`;
