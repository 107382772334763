import gql from 'graphql-tag';

export const readAllPlatebniPrikazQuery = gql`
  query ReadAllPlatebniPrikaz($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $categories: [Int!]) {
    response: readAllPlatebniprikaz(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      categories: $categories
    ) {
      total
      page
      take
      hasMore
      items {
        id
        castka
        splatnost
        zaplaceno_at
        odvolano_at
        stav
        uzivatel {
          jmeno
          prijmeni
        }
        polozky{
          mnozstvi
          cena
          nazev
        }
      }
    }
  }
`;
