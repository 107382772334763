import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formArray'
})
export class FormArrayPipe implements PipeTransform {

  transform(value: any, name: string, index: number): any {
    return {
      ...value,
      formArray: {
        name,
        index
      }
    }
  }

}
