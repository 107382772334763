import gql from 'graphql-tag';

export const addKategorieMutation = gql`
  mutation Add($input: [KategorieInput!]!){
    addKategorie(inputKategorie: $input) {
      id
      nazev
      color
    }
  }
`;
