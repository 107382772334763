import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IPkInputRepeaterFieldProps } from './pk-input-repeater-field.props';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'pk-input-repeater-field',
  templateUrl: './pk-input-repeater-field.component.html',
  styleUrls: ['./pk-input-repeater-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PkInputRepeaterFieldComponent implements OnInit, OnDestroy {
  @Input()
  settings: IPkInputRepeaterFieldProps;

  @Input()
  data: any;

  get formControl() {
    return <FormArray>this.settings.form.get(this.settings.formControlName);
  }

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    console.log('inited repeater | settings: ', this.settings);
    // je treba pridat kazdemu fieldu formular, ktery zname az ted
    this.settings.rowDefinition.forEach(field => field.settings.form = this.settings.form);

/*    const { defaultValue }: { defaultValue?: [] } = this.settings; //array itemu nebo undef

    // pokud je default value, pushujeme je
    if (defaultValue && defaultValue.length > 0) {
      defaultValue.forEach(item => this.pushItem(item));
    } else {
      this.createItem();
    }*/
  }

  public createItem(): void {
    // create item based on rowdefinition
    const item = {};
    this.settings.rowDefinition.forEach(field => item[field.settings.formControlName] = [field.settings.defaultValue || null, field.validators]);

    this.pushItem(item);
  }

  private pushItem(item: {}) {
    // create formgroup item
    const formItem = this.formBuilder.group(item);

    // get the array from form
    const items = this.settings.form.get(this.settings.formControlName) as FormArray;

    // push it
    items.push(formItem);
  }

  public deleteItem(itemIndex: number): void {
    const items = this.settings.form.get(this.settings.formControlName) as FormArray;
    items.removeAt(itemIndex);
  }

  ngOnDestroy(): void {
    const items = this.settings.form.get(this.settings.formControlName) as FormArray;
    items.clear();
  }


}

