import { LayoutActions, LayoutActionTypes } from './layout.actions';
import { initialLayoutState, LayoutState } from './layout.state';

export function layoutReducer(state = initialLayoutState, action: LayoutActions): LayoutState {
  switch (action.type) {
    case LayoutActionTypes.SET_LAYOUT_BUTTONS:
      return {
        ...state,
        buttons: action.payload.buttons
      };
    case LayoutActionTypes.SET_LAYOUT_TABS:
      return {
        ...state,
        tabs: action.payload.tabs
      };

    case LayoutActionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTabName: action.payload.activeTabName
      };
    case LayoutActionTypes.SET_LAYOUT_PAGE_NAME:
      return {
        ...state,
        pageName: action.payload.pageName
      };
    case LayoutActionTypes.SET_LAYOUT_PAGE_ICON:
      return {
        ...state,
        pageIcon: action.payload.pageIcon
      };
    case LayoutActionTypes.SET_LAYOUT_SEARCH_VISIBILITY:
      return {
        ...state,
        withSearch: action.payload.withSearch
      };
    case LayoutActionTypes.SET_LAYOUT_SEARCH:
      return {
        ...state,
        search: action.payload.search
      };
    case LayoutActionTypes.SET_WITH_ADD_CATEGORY:
      return {
        ...state,
        withAddButton: action.payload.withAddButton
      };
    case LayoutActionTypes.SET_CONFIRM_DIALOG_REF:
      return {
        ...state,
        confirmDialogRef: action.payload
      };
    case LayoutActionTypes.SET_LAYOUT_CATEGORIES:
      return {
        ...state,
        hideCategories: action.payload.hideCategories
      }
    default: {
      return state;
    }
  }
}
