import { Validators } from '@angular/forms';
import {
  getDefaultDialogButtons,
  getNapovedaSettings,
  getNazevSettings,
  getPovinnostSettings
} from '../common-settings-objects';
import { IPkInputTextFieldProps, IPkSelectFieldProps } from '@nx-monorepo/cms-base/components';
import { IPkDialogData, IPkFormDialogCompanion } from '@nx-monorepo/cms-base/components/pk-dialog/pk-dialog-data';

export class PkGridsterItemTextfieldDialogCompanion implements IPkFormDialogCompanion {
  getDialogData(settings: IPkInputTextFieldProps, data: {}, emitters: {dialogSubmit, dialogClose}) {
    const dialogData: IPkDialogData = {
      title: 'Upravit vstupní pole',
      components: [
        getNazevSettings(settings.nazev),
        getPovinnostSettings(settings.povinnost),
        getNapovedaSettings(settings.napoveda),
        {
          componentName: 'PkSelectFieldComponent',
          settings: {
            povinnost: true,
            nazev: 'Textová oblast',
            napoveda: {
              isIcon: true,
              text: 'Napoveda lorem ipsum'
            },
            isMultiple: false,
            formControlName: 'isTextArea',
            defaultValue: settings.isTextArea ? '1' : '0'
          } as IPkSelectFieldProps,
          data: [
            {
              id: '1',
              nazev: 'Ano'
            },
            {
              id: '0',
              nazev: 'Ne'
            }
          ],
          validators: [Validators.required],
          cols: 1,
          x: 0,
          y: 0
        },
        {
          componentName: 'PkSelectFieldComponent',
          settings: {
            povinnost: true,
            nazev: 'Typ',
            napoveda: {
              isIcon: true,
              text: 'Číselná nebo textová hodnota'
            },
            isMultiple: false,
            formControlName: 'type',
            defaultValue: settings.type
          } as IPkSelectFieldProps,
          data: [
            {
              id: 'text',
              nazev: 'Text'
            },
            {
              id: 'number',
              nazev: 'Číslo'
            }
          ],
          validators: [Validators.required],
          cols: 1,
          x: 0,
          y: 0
        },
      ],
      buttons: getDefaultDialogButtons(emitters)
    };
    return dialogData;
  }
}
