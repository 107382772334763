import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexModule } from '@angular/flex-layout';
import { PkDataTableModule } from '../pk-data-table/pk-data-table.module';
import { PkEntityDialogComponent } from './pk-entity-dialog/pk-entity-dialog.component';
import { PkMediaListComponent } from './pk-media-list/pk-media-list.component';
import { PkUploadComponent } from './pk-upload/pk-upload.component';
import { PkUploadUrlComponent } from './pk-upload-url/pk-upload-url.component';
import { PkDragDrop } from './drag-and-drop.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { PkInputFieldsModule } from '../pk-input-fields/pk-input-fields.module';
import {MatListModule} from "@angular/material/list";

@NgModule({
  declarations: [
    PkEntityDialogComponent,
    PkMediaListComponent,
    PkUploadComponent,
    PkUploadUrlComponent,
    PkDragDrop
  ],
  exports: [
    PkEntityDialogComponent,
    PkMediaListComponent,
    PkUploadComponent,
    PkUploadUrlComponent
  ],
    imports: [
        CommonModule,
        MatMenuModule,
        PkDataTableModule,
        MatIconModule,
        FlexModule,
        MatTabsModule,
        MatDialogModule,
        MatButtonModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        PkInputFieldsModule,
        MatListModule
    ],
  entryComponents:[
    PkEntityDialogComponent
  ]
})
export class PkMediaModule {
}
