import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { IPkSelectFieldProps } from './pk-select-field.props';
import { map, take } from 'rxjs/operators';
import { IPkService } from '@nx-monorepo/obce/ng/services';

@Component({
  selector: 'pk-select-field',
  templateUrl: './pk-select-field.component.html',
  styleUrls: ['./pk-select-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PkSelectFieldComponent implements OnInit {
  @Input()
  settings: IPkSelectFieldProps;

  @Input()
  data: any;


  constructor(private injector: Injector) {}

  ngOnInit(): void {
    if (this.settings.service) {
      const dataService = this.injector.get<IPkService>(this.settings.service.token);
      this.getDataFromService(dataService);
    }
  }

  private getDataFromService(service: IPkService) {
    service.fetchAll(this.settings.service.fetchAllArgs).pipe(
      take(1),
      map(result => {
        return result.data.response.items.filter(item => item.id > 0);
      })
    ).subscribe(result => {
      this.data = result;
    });
  }
}

