import gql from 'graphql-tag';

export const readAllUzivatelQuery = gql`
  query readAllUzivatel($search: String, $page: Int, $take: Int) {
    response: readAllUzivatel(search: $search, take: $take, page: $page) {
      total
      page
      take
      hasMore
      items {
        id
        jmeno
        prijmeni
        email
        telefon
        role{
          nazev
          }
      }
    }
  }
`;
