<div id="academy-courses" class="page-layout simple">

  <!-- HEADER -->
  <div class="header accent p-16 p-sm-24" fxLayout="column" fxLayoutAlign="center center">

    <div class="hero-text">
      <mat-icon class="hero-icon">school</mat-icon>
      <h1 [@animate]="{value:'*',params:{delay:'100ms', y:'25px'}}">
        VIDEOKNIHOVNA
      </h1>
      <h3 [@animate]="{value:'*',params:{delay:'100ms', y:'25px'}}">
        Spolu s textovým návodem udržujeme videoknihovnu návodů k jednotlivým částem systému.
        Náš systém se stále mění, omluvte případné grafické nesrovnalosti.
        Po velké změně funkcionality určitého modulu se pokusíme video co nejrychleji aktualizovat.
      </h3>
    </div>

  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content p-24">


    <div class="filters" fxLayout="column" fxLayoutAlign="center center"
         fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

      <mat-form-field appearance="outline" floatLabel="always" class="course-search">
        <mat-label>Vyhledat</mat-label>
        <input matInput placeholder="Zadejte klíčové slovo"
               [(ngModel)]="searchTerm"
               (input)="filterCoursesByTerm()">
      </mat-form-field>

      <mat-form-field appearance="outline" class="category-selector">
        <mat-label>Kategorie</mat-label>
        <mat-select [(ngModel)]="currentCategory"
                    (selectionChange)="filterCoursesByCategory()">
          <mat-option [value]="'all'">
            Vše
          </mat-option>
          <mat-option *ngFor="let category of categories" [value]="category">
            {{ category }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="courses" [@animateStagger]="{value:'50'}">

      <div class="course" *ngFor="let course of filteredCourses" fxFlex="100" fxFlex.gt-xs="50"
           fxFlex.gt-sm="33" [ngClass]="course.category" [@animate]="{value:'*',params:{y:'100%'}}">

        <div class="course-content" fxLayout="column" fxFlex="1 1 auto">

          <div class="header" fxLayout="row" fxLayoutAlign="center center"
               [ngClass]="course.category + '-bg'">

            <div class="category" fxFlex>
              {{course.category}}
            </div>

            <div class="length" fxLayout="row" fxLayoutAlign="center center">
              <mat-icon class="length-icon s-20">access_time</mat-icon>
              <div class="min">{{course.length}} min</div>
            </div>

          </div>

          <div class="content" fxLayout="column" fxLayoutAlign="center center" fxFlex="1 1 auto">
            <mat-icon class="length-icon s-20">{{course.icon}}</mat-icon>
            <div class="h1">{{course.title}}</div>
            <div class="updated">Aktualizováno {{course.updated}}</div>
          </div>

          <div class="footer" fxLayout="row" fxLayoutAlign="center center">
            <a mat-button color="accent"
                    [href]="course.url"
              target="_blank">
              START
            </a>
          </div>

        </div>

      </div>

      <div class="no-courses" *ngIf="filteredCourses.length === 0">
        Žádné video nenalezeno!
      </div>
    </div>
  </div>
  <!-- / CONTENT -->
</div>

