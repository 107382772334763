import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import gql from 'graphql-tag';
import { ApolloQueryResult } from 'apollo-client';
import { KolekceService } from './kolekce.service';
import { map } from 'rxjs/operators';
import { PrispevkyBarrel, UredniDeskyBarrel } from '@nx-monorepo/node-frontend';
//todo BADIMPORT && CirDep
import { IFrontendProps } from '../../../../../frontend-components/src/lib/components/i-frontend-props';
import { MutationResponse } from '@nx-monorepo/api-base/lib';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { isNumeric } from '@nx-monorepo/obce/common/utils';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ObecService {
  //TODO change currentSettings to IOwnerSettings 
  private currentSettings: {};
  private settingsSubject: Subject<{}> = new BehaviorSubject<{}>(this.currentSettings);


  constructor(
    private apollo: Apollo,
    private kolekceService: KolekceService,
    private router: Router,
  ) {
  }

  get settings$() {
    return this.settingsSubject.asObservable();
  }

  get settings() {
    return this.currentSettings;
  }

  modifySettings(newSettings: {}) {
    this.currentSettings = newSettings;
    this.settingsSubject.next(newSettings);
  }


  updateOwnerSettings(ownerSettings: string): Promise<MutationResponse>{
    return this.apollo.mutate({
      mutation: gql`
        mutation updateCurrentOwnerSettings($settings: String!){
          updateCurrentOwnerSettings(settings: $settings){
            userMessage
            description
          error
          }
        }
      `,
      variables: {settings: ownerSettings}
    }).toPromise().then(res => {
      console.log(res);
      return <MutationResponse>res.data});
  }

  getObecPromise(): Promise<any> {
    const queryResult: Observable<ApolloQueryResult<any>> = this.apollo.query({
      query: gql`
        query {
          readAllOwner {
            items {
              nazev
              settings {
                header
                footer
                primary_color
                title_font
                paragraph_font
                inner_settings{
                  right_navigation_id
                  left_navigation_id
                  headingProps
                  use_compact_header
                  use_compact_footer
                }
                info_settings{
                  nazev
                  nazev_urad
                  psc_line
                  tel1
                  tel2
                  email
                  ulice_line
                  uredni_hodiny
                  iban
                  cislo_banky
                  cislo_uctu
                  datovka
                  faviconHref
                  osoby{
                    email
                    hodiny
                    jmeno
                    nazev
                    tel1
                    tel2
                  }
                  ico
                  domain
                  logo_url
                  o_logu
                  o_obci
                  pocet_obyvatel
                }
                payment_settings{
                  merchant_id
                  secret
                  gate
                }
                social_settings{
                  rajce_url
                  facebook_url
                  youtube_url
                  twitter_url
                  instagram_url
                }
              }
            }
          }
        }
      `
    });

    return queryResult.toPromise().then(res => res.data.readAllOwner.items[0]);
  }

  getObceSettingsPromise(obecPromise: Promise<any>) {
    return obecPromise.then(res => (res.settings));
  }

  getHeaderSettingsPromise(obecPromise: Promise<any>) {
    return obecPromise.then(res => JSON.parse(res.settings.header));
  }

  getHeaderDataPromise(obecPromise: Promise<any>): Promise<any[]> {
    return obecPromise.then(res => {
      const ids: number[] = [];
      const headerJson = JSON.parse(res.settings.header);

      //todo nejak inteligentneji otypovat na IHeader_____Options
      const headerOptions = headerJson.options;

      //todo taky nejak inteligentneji, funkce, JSON key recursive search
      const bottom_navigace_id =
        headerOptions.bottom_slot && headerOptions.bottom_slot.navigace && headerOptions.bottom_slot.navigace.id;
      if (bottom_navigace_id) {
        ids.push(bottom_navigace_id);
      }

      const top_navigace_id = headerOptions.top_slot.navigace && headerOptions.top_slot.navigace.id;
      if (top_navigace_id) {
        ids.push(top_navigace_id);
      }

      return this.kolekceService.fetchSelected(EntityTypes.Navigace, ids).toPromise();
    });
  }

  getMainPagePromise(): Promise<any> {
    const queryResult: Observable<ApolloQueryResult<any>> = this.apollo.query({
      query: gql`
        query {
          readMainPage {
            nazev
            id
            obsah {
              nazev
              options
              cols
              coord_x
              coord_y
              id
              type
            }
          }
        }
      `
    });

    return queryResult.toPromise().then(({ data }) => {
      //get the main page
      const _mainPage = data.readMainPage;

      //parse options of all obsah to object
      _mainPage.obsah = _mainPage.obsah.map(this.fetchData);

      return _mainPage;
    });
  }

  private fetchData = (obsah) => {
    {
      obsah.options = JSON.parse(obsah.options);
      obsah['data'] = this.getDataPromiseFor(obsah.type, obsah.options);
      return obsah;
    }
  };

  public fetchSingleByUrlOrId(entityType: EntityTypes, identifier: any) {
    const singleQuery = this.kolekceService.getSingleQuery(entityType);
    let query;
    if (!isNumeric(identifier)) {
      query = this.apollo.query({
        query: singleQuery,
        variables: {
          slug: identifier
        }
      });
    } else {
      query = this.apollo.query({
        query: singleQuery,
        variables: {
          ids: [Number(identifier)]
        }
      });
    }
    return query.toPromise().then((response) => {
      const responseItems = response.data.response;
      if (responseItems && responseItems.length === 1) {
        const item = responseItems[0];
        if (entityType === EntityTypes.Stranka) {
          item.obsah.forEach(this.fetchData);
        }
        return item;
      } else {
        //this.router.navigate(["404"],{state: {nazev: identifier}});
      }
           
    }).catch(err => {this.router.navigate(["500"])});
  }


  private getDataPromiseFor(obsahType: string, obsahOptions: { defaultValue } & { maxColumns } & IFrontendProps): Promise<any> {
    switch (obsahType) {
      case 'UdalostiComponent':
        return this.kolekceService.getResponseFromItemsPromise(
          this.kolekceService.fetchAll(
            EntityTypes.Udalost,
            undefined,
            0,
            obsahOptions.maxItemCount ? obsahOptions.maxItemCount : 4,
            'datum_zacatek',
            'ASC'
          ).toPromise());
      case UredniDeskyBarrel.name_two_column:
      case UredniDeskyBarrel.name_compact:
        return this.kolekceService.getResponseFromItemsPromise(this.kolekceService.fetchAll(
          EntityTypes.UredniDeska,
          undefined,
          0,
          obsahOptions.maxItemCount ? obsahOptions.maxItemCount : 4,
          'datum_od',
          'DESC'
        ).toPromise());
      case "ItemNavComponent":
      case "HeaderNavComponent":
      case "FooterNavComponent":
      case 'FooterComponent':
        // @ts-ignore
        return this.kolekceService.fetchSingle(EntityTypes.Navigace, obsahOptions.id).toPromise();
      case 'FotogalerieComponent':
        return this.kolekceService.getResponseFromItemsPromise(this.kolekceService.fetchAll(
          EntityTypes.Galerie,
          undefined,
          0,
          obsahOptions.maxItemCount ? obsahOptions.maxItemCount : 6
        ).toPromise());
      case 'WysiwygComponent':
        return Promise.resolve(obsahOptions.defaultValue);
      case PrispevkyBarrel.name_image:
      case PrispevkyBarrel.name_classic:
        // @ts-ignore
        return this.kolekceService.getResponseFromItemsPromise(this.kolekceService.fetchAll(EntityTypes.Prispevek, '', 0, obsahOptions.maxItemCount ? obsahOptions.maxItemCount : 3, 'datum_zverejneni', 'DESC').toPromise());
      default:
        return this.getObecPromise();
    }
  }
}
