import { Action, createAction, Store, ActionsSubject } from '@ngrx/store';
import { LayoutActionTypes } from '@nx-monorepo/cms-base/store/layout/layout.actions';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { PkNewEntityPageComponent } from '..';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityDefinitionProvider, IRouteData, displaySnack } from '@nx-monorepo/cms-base/helpers';
import { KolekceService } from '@nx-monorepo/obce/ng/services';
import { FormBuilder } from '@angular/forms';
import { takeUntil, filter, take } from 'rxjs/operators';
import { MACMSStoreState, LayoutStoreActions } from '@nx-monorepo/cms-base/store';
import { AuthService } from '@nx-monorepo/cms-base/services';
import { SnackbarStoreActions } from '@nx-monorepo/cms-base/store/snackbar';

const ULOZIT_ACTION = createAction('[ChangeProfile] Confirm', (payload: {}) => ({ payload }))({});
const SAVE_BUTTON = createAction('[ChangeProfile] Save', (payload: {}) => ({payload}))({});

@Component({
    selector: 'pk-change-profile-page',
    templateUrl: 'pk-change-profile-page.component.html',
    styleUrls: ['pk-change-profile-page.component.scss'],
    animations: fuseAnimations
  })
export class PkChangeProfilePage extends PkNewEntityPageComponent implements OnInit, AfterViewInit {
    user: any;
    entityDefinitions:IEntityDefinition[];
    public routeData: IRouteData;
    forbiddenFields: string[] = ['birthday','id', 'jmeno', 'prijmeni', 'mesto', 'psc', 'rodnecislo', 'titul', 'ulice', 'uzivatel_role_id'];

    constructor(
        store$: Store<MACMSStoreState.State>,
        currentRoute: ActivatedRoute,
        router: Router,
        entityDefinitionProvider: EntityDefinitionProvider,
        kolekceService: KolekceService,
        actionsSubject$: ActionsSubject,
        formBuilder: FormBuilder,
        private authSer: AuthService,
    ) {
        super(store$, currentRoute, router, entityDefinitionProvider, kolekceService, actionsSubject$, formBuilder);
        this.entityDefinitions = [...this.entityDefinitionProvider.getEntityDefinitionsFor(this.routeData.entityType)];
        
    }
    ngAfterViewInit(): void {
        const token = this.authSer.decodeToken();
        this.kolekceService.fetchSingle(this.routeData.entityType, token.user_id).toPromise().then(res => {
            for (const field in this.entityForm.controls) {
                if (this.forbiddenFields.includes(field)) {
                    this.entityForm.controls[field].setValue(res[field]);
                    this.entityForm.controls[field].disable();
                }
            }
            this.user = res;
            this.entityForm.controls['telefon'].setValue(res.telefon);
            this.entityForm.controls['email'].setValue(res.email);
        })
    }

    protected setLayoutButtons() {
        const payload = {
            buttons: [{id:0, nazev: 'Uložit', action: SAVE_BUTTON}]
        }
        this.store$.dispatch(new LayoutStoreActions.SetLayoutButtonsAction(payload));
    }

    protected subscribeToLayoutButtons() {
        this.actionsSubject$.pipe(
          takeUntil(this.destroy$),
           filter((action: Action) =>
            action.type === SAVE_BUTTON.type || action.type === ULOZIT_ACTION.type
          ) 
        ).subscribe((action: any) => {
          switch (action.type) {
            case SAVE_BUTTON.type:
                if (this.entityForm.valid) {
                    if (this.entityForm.controls["email"].value === null || this.entityForm.controls["email"].value.length < 1) {
                        this.showModal();
                    } else {
                        this.saveEntity();
                    }
                } else {
                    this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({ message: 'Vyplňte prosím všechny položky!', action: 'OK', config: { duration: 2500 } }));
                }
              break;
            case ULOZIT_ACTION.type:
                console.log('save entity');
                this.saveEntity();
                break;
            default:
                console.error('Neznama akce', action);
          }
        });
    }

    protected showModal() {
        this.store$.dispatch(new LayoutStoreActions.ShowConfirmDialog({
            title: "Smazat email?",
            content: 'Opravdu si přejete smazat email? Přijdete tak o možnost přihlášení do systému!',
            onConfirm: '[ChangeProfile] Confirm'
          }));
    }

    protected saveEntity() {
        const payload = this.entityForm.getRawValue();
        this.kolekceService.save(this.routeData.entityType, { input: [payload] }).pipe(
        take(1)
        ).toPromise().then(_ => {
            this.authSer.refresh(this.authSer.getToken(), this.authSer.getRefreshToken());
            displaySnack('Změna profilu byla úspěšná', this.store$)
        }).catch(err => {
            console.error('Chyba ulozeni zmeny profilu ' + err);
            displaySnack('Změna profilu se nezdařila, zkuste to, prosím, později.', this.store$)
        })
    }
}