import { Validators } from '@angular/forms';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { PkValidator } from '@nx-monorepo/ng-shared';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { IPkHiddenFieldProps, IPkInputTextFieldProps, IPkInputEntityFieldProps, IPkInputChipsFieldProps } from '@nx-monorepo/cms-base/components';
import { uzivateleTelefonColumnDefinitions } from '../column-definitions';

export const SmsEntityDefinition: IEntityDefinition[] = [
    {
        componentName: 'PkHiddenFieldComponent',
        settings: {
          type: 'number',
          formControlName: 'id',
          defaultValue: 0
        } as IPkHiddenFieldProps,
        data: null,
        validators: [],
        cols: 0,
        x: 0,
        y: 0
      },{
        componentName: 'PkInputTextFieldComponent',
        settings: {
            nazev: 'Text SMS',
            povinnost: true,
            isTextArea: true,
            formControlName: 'text',
            defaultValue: '',
            type: 'text',
            maxLength: 160,
        } as IPkInputTextFieldProps,
        data: null,
        validators: [Validators.required, PkValidator.validatorLengthFactory(160, 'max')],
        cols: 4,
        x: 0,
        y: 0
    },
    {
        componentName: 'PkInputEntityFieldComponent',
        settings: {
            nazev: 'Příjemci',
            povinnost: false,
            napoveda: {
                isIcon: false,
                text: 'Vyberte příjemce'
            },
            overrideColumnDefinitions: uzivateleTelefonColumnDefinitions,
            isMultiple: true,
            entityType: EntityTypes.Uzivatel,
            formControlName: 'prijemci',
            defaultValue: '',
        } as IPkInputEntityFieldProps,
        data: null,
        validators: [],
        cols: 4,
        x: 0,
        y: 1
    },
    {
        componentName: 'PkInputChipsFieldComponent',
        settings: {
            nazev: 'Dodateční příjemci',
            formControlName: 'dodatecniPrijemci',
            napoveda: {
              isIcon: true,
              text: 'Zadejte tel. čísla, jednotlivá čísla oddělujte čárkou nebo tlačítkem enter.',
            },
            povinnost: true
        } as IPkInputChipsFieldProps,
        data: [],
        validators: [PkValidator.validatorForChipPhone()],
        cols: 4,
        x: 0,
        y: 2,
    },
    //todo: delivery time
]
