import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { LayoutStoreModule } from './layout';
import { EffectsModule } from '@ngrx/effects';
import { TableStoreModule } from './table';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthStoreModule } from './auth/auth.module';
import { MediaStoreModule } from './media/media.module';
import { SnackbarStoreModule } from './snackbar';
import { MatSnackBarContainer } from '@angular/material/snack-bar';


// https://wesleygrimes.com/angular/2018/05/30/ngrx-best-practices-for-enterprise-angular-applications
@NgModule({
    imports: [
      CommonModule,
      StoreModule.forRoot({},{
        runtimeChecks: {
          // todo set everything to true
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: false,
          strictActionSerializability: true,
        },
      }),
      EffectsModule.forRoot([]),
      StoreDevtoolsModule.instrument({
        maxAge: 25, // Retains last 25 states
      }),
      AuthStoreModule,
      LayoutStoreModule,
      TableStoreModule,
      MediaStoreModule,
      SnackbarStoreModule
    ],
    declarations: [],
})
export class MACMSStoreModule {}
