import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPkDialogData } from './pk-dialog-data';
import { FormBuilder, FormGroup } from '@angular/forms';
import { initEntityForm } from '@nx-monorepo/cms-base/helpers/form/form-utils';

@Component({
  templateUrl: 'pk-dialog.component.html',
  styleUrls: ['pk-dialog.component.scss']
})
export class PkDialogComponent implements OnInit{
  dialogForm: FormGroup = new FormGroup({});

  constructor(@Inject(MAT_DIALOG_DATA) public data: IPkDialogData, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    console.log(this.data);

    this.dialogForm = initEntityForm(this.data.components, this.formBuilder);

    // pro kazdou componentu
/*    this.data.components.forEach((entityDefinition: IEntityDefinition) => {
      // nastavit jim konkretni form
      entityDefinition.settings.form = this.dialogForm;

      // pridat do formy control
      console.log(entityDefinition.settings.formControlName, '->', entityDefinition.settings.defaultValue);
      this.dialogForm.addControl(
        entityDefinition.settings.formControlName,
        new FormControl(entityDefinition.settings.defaultValue || entityDefinition.data, entityDefinition.validators)
      );
    });*/
  }

  public emitAction(action: EventEmitter<any>, shouldValidate: boolean){
    if (shouldValidate && !this.dialogForm.valid){
      // console.log('pk-dialog.component form validity', this.dialogForm.valid);
      return;
    }

    action.emit(this.dialogForm.value);
  }
}
