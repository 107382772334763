import gql from 'graphql-tag';

export const readAllKategorieQuery = gql`
  query readAllKategorieOfType($types: [EntityTypes!]) {
    response: readAllKategorieOfType(types: $types) {
      items {
        id
        nazev
        color
      }
    }
  }
`;
