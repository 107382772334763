<div class="gallerybg" [style.background]="settings.wrapperBackground | fixBackgroundUrl">
  <ng-container [ngSwitch]="settings.type">
    <macms-heading
      [settings]="headingProps"
      [text]="settings.nazev ? settings.nazev : 'Obecní fotogalerie'"
    >
    </macms-heading>
    <section id="galerie" [style.font-family]="obecSettings.title_font" [style.background]="settings.sectionBackground | fixBackgroundUrl">
      <ng-container *ngSwitchCase='"multi"'>
        <div class="gallery-wrap">
          <div *ngIf='settings.uvod' class="left-col" [style.background]="'rgba(255, 255, 255, 0.8)'">
            <p>{{settings.uvod}}</p>
          </div>
          <div class="right-col">
            <div class="grid">
              <ng-container *ngFor="let galerie of data.items">
                <a [routerLink]="(galerie.slug ? galerie.slug : galerie.id) | DetailUrl:'galerie'"
                   [style.font-family]="obecSettings.title_font"
                   [contrastForeground]="galerie.color ? galerie.color : primaryColor" [preferedColors]="[primaryColor]"
                   [style.background]="galerie.color ? galerie.color : primaryColor"
                   [style.flexBasis]='settings.itemFlexBasis ? settings.itemFlexBasis : "200px"'
                   [marginDefinition]="settings.itemMargin"
                >
                  <h3>{{galerie.nazev}}</h3>
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase='"single"'>
        <div class="gallery-single">
          <div class="gallery-wrap">
            <a [style.background]="galerie.color ? galerie.color : primaryColor" *ngFor="let galerie of data.items"
               [routerLink]="(galerie.slug ? galerie.slug : galerie.id) | DetailUrl:'galerie'">
              <img src="{{galerie.media[0].url}}"/>
            </a>
          </div>
        </div>
      </ng-container>
    </section>
  </ng-container>
</div>
