<ng-container *ngIf="(obecSettings$ | async ) as obecSettings">
  <macms-heading
    *ngIf="settings.showHeading"
    [settings]="headingProps"
    [text]="settings.nazev"
  ></macms-heading>
  <div class="wrapper"
       macmsColor
       [mc_isInverted]="settings.isInverted"
       [mc_wantedPrimary]="settings.primaryColor"
       [mc_otherColors]="[settings.secondaryColor]"
       [paddingDefinition]="settings.padding"
       [style.fontFamily]="settings.fontFamily? settings.fontFamily : obecSettings.paragraph_font">

      <ng-container *ngIf="settings.isConcatenated">
        <div [style.fontFamily]="settings.fontFamily? settings.fontFamily : obecSettings.paragraph_font" class="wrapper concatenated">
          <span class="subtitle" *ngIf="settings.useSubtitles">{{obecSettings.info_settings.nazev}}</span>
          <ng-container *ngIf="settings.showCopyright">© {{year}}, </ng-container>
          <div>{{obecSettings.info_settings.nazev}} | {{obecSettings.info_settings.ulice_line}}, {{obecSettings.info_settings.psc_line}}</div>
          <div><ng-container *ngIf="settings.showPhone">{{obecSettings.info_settings.tel1}} {{obecSettings.info_settings.tel2}}</ng-container></div>
          <div><ng-container *ngIf="settings.showOpenHours && obecSettings.info_settings.uredni_hodiny">Úřední hodiny: {{obecSettings.info_settings.uredni_hodiny}}</ng-container></div>
          <ng-container *ngIf="settings.showEmail">email: <a [href]="'mailto:'+obecSettings.info_settings.email">{{obecSettings.info_settings.email}}</a></ng-container>
          <ng-container *ngIf="settings.showCopyright"><div> © Echopix s.r.o. | WebMaster: <a href="mailto:webyobci@echopix.cz">webyobci@echopix.cz</a></div></ng-container>

          <ng-container *ngIf="settings.showPersons && obecSettings.info_settings.osoby.length">
            <div class="osoba" *ngFor="let osoba of obecSettings.info_settings.osoby">
              <ng-container>{{osoba.nazev}}: </ng-container>
              <ng-container>{{osoba.jmeno}} </ng-container>
              <ng-container *ngIf="osoba.tel1">{{osoba.tel1}} </ng-container>
              <ng-container *ngIf="osoba.tel2">{{osoba.tel2}} </ng-container>
              <ng-container *ngIf="osoba.email">email: {{osoba.email}}</ng-container>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!settings.isConcatenated">
        <div class="obecInfo">
        <span class="subtitle" *ngIf="settings.useSubtitles">{{obecSettings.info_settings.nazev}}</span>
        <div><ng-container *ngIf="settings.showCopyright">© {{year}}, </ng-container>{{obecSettings.info_settings.nazev}}</div>
        <div>{{obecSettings.info_settings.ulice_line}}</div>
        <div>{{obecSettings.info_settings.psc_line}}</div>
        <div *ngIf="settings.showPhone">{{obecSettings.info_settings.tel1}}</div>
        <div *ngIf="settings.showPhone && obecSettings.info_settings.tel2">{{obecSettings.info_settings.tel2}}</div>
        <div *ngIf="settings.showEmail">email: <a [href]="'mailto:'+obecSettings.info_settings.email">{{obecSettings.info_settings.email}}</a></div>
        <div *ngIf="settings.showOpenHours && obecSettings.info_settings.uredni_hodiny">Úřední hodiny: {{obecSettings.info_settings.uredni_hodiny}}</div>
        </div>

        <ng-container *ngIf="settings.showPersons && obecSettings.info_settings.osoby.length">
          <div class="osoba" *ngFor="let osoba of obecSettings.info_settings.osoby">
            <div class="subtitle">{{osoba.nazev}}</div>
            <div>{{osoba.jmeno}}</div>
            <div *ngIf="osoba.tel1">{{osoba.tel1}}</div>
            <div *ngIf="osoba.tel2">{{osoba.tel2}}</div>
            <div *ngIf="osoba.email">email: {{osoba.email}}</div>
          </div>
        </ng-container>
        <ng-container *ngIf="settings.showCopyright"><div> © Echopix s.r.o. | WebMaster: <a href="mailto:webyobci@echopix.cz">webyobci@echopix.cz</a> </div></ng-container>
      </ng-container>
  </div>
</ng-container>
