import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { IPkComponentWithFormDialog, PkGridsterItem } from '../pk-gridster-item';
import { PkDialogComponent } from '../../pk-dialog/pk-dialog.component';
import { IPkInputCheckboxFieldProps } from '../..';
import { PkGridsterItemCheckboxDialogCompanion } from '../companions/form-items/pk-gridster-item-checkbox-dialog-companion';
import { PkGridsterItemDefinitionService } from '@nx-monorepo/cms-base/layout/components/pk-page-contents/pk-gridster-page/pk-gridster-item-definition.service';

@Component({
  selector: 'pk-gridster-item-checkbox',
  templateUrl: './pk-gridster-item-checkbox.component.html',
  styleUrls: ['./pk-gridster-item-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PkGridsterItemCheckboxComponent implements OnInit, OnDestroy, IPkComponentWithFormDialog {
  @Input()
  widget: PkGridsterItem;

  @Input()
  resizeEvent: EventEmitter<any>;

  @Input()
  dialogRef: MatDialogRef<PkDialogComponent, string>;

  @Output()
  dialogClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  dialogSubmit: EventEmitter<any> = new EventEmitter<any>();

  private resizeSub: Subscription;
  public settings: IPkInputCheckboxFieldProps;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  formDialogCompanion = new PkGridsterItemCheckboxDialogCompanion();

  constructor(private itemDefinitionService: PkGridsterItemDefinitionService, private changeDetection: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.subscribeToDialogClose();
    this.subscribeToDialogSubmit();

    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your item, chart, map , etc.
      }
    });

    this.settings = this.itemDefinitionService.fillItemSettingsByType('PkGridsterItemCheckboxComponent', this.widget.settings, {});
    this.widget.settings = this.settings;
  }

  private subscribeToDialogClose() {
    this.dialogClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  private subscribeToDialogSubmit() {
    this.dialogSubmit.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      // set new props
      result.povinnost = result.povinnost === '1';

      this.settings = this.itemDefinitionService.fillItemSettingsByType('PkGridsterItemCheckboxComponent', result, this.settings);
      this.widget.settings = this.settings;

      // gridster is fucked up and doesnt push changes, we need to call it manually
      this.changeDetection.markForCheck();

      // close the dialog
      this.dialogRef.close();
    });
  }

  getDialogData() {
    return this.formDialogCompanion.getDialogData(this.settings, null, {
      dialogClose: this.dialogClose,
      dialogSubmit: this.dialogSubmit
    })
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
