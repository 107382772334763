import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { InjectableToolbarDirective } from './toolbar.directive';
import { InjectableToolbarService } from './toolbar.service';
import { ToolbarComponent } from './toolbar.component';
import { MacmsModule } from '@nx-monorepo/cms-base/components/macms.module';

@NgModule({
    declarations: [ToolbarComponent, InjectableToolbarDirective],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatTabsModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        MacmsModule
    ],
    exports: [ToolbarComponent, InjectableToolbarDirective],
    providers: [InjectableToolbarService]
})
export class ToolbarModule {}
