import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { snackbarReducer } from './snackbar.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SnackbarStoreEffects } from './snackbar.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('snackbar', snackbarReducer),
        EffectsModule.forFeature([SnackbarStoreEffects])
    ]
})
export class SnackbarStoreModule {}
