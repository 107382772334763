import {Component, Input, OnInit, AfterViewInit} from '@angular/core';
import {IPkInputDateFieldProps} from './pk-input-date-field.props';
import {getFirstError} from '@nx-monorepo/cms-base/helpers';

@Component({
  selector: 'pk-input-date-field',
  templateUrl: './pk-input-date-field.component.html',
  styleUrls: ['./pk-input-date-field.component.scss']
})
export class PkInputDateFieldComponent implements OnInit, AfterViewInit {
  public firstError ?: string;

  @Input()
  settings: IPkInputDateFieldProps;

  ngOnInit(): void {
    this.firstError = getFirstError(this.settings.formControlName, this.settings.form);
  }

  ngAfterViewInit(): void {
    this.settings.form.get(this.settings.formControlName)?.valueChanges.subscribe((_) => {
      this.firstError = getFirstError(this.settings.formControlName, this.settings.form);
    })
  }
}

