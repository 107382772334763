import { AuthActions, AuthActionTypes } from './auth.actions';
import { AuthState, initialAuthState } from './auth.state';

export function authReducer(state = initialAuthState, action: AuthActions): AuthState {
  switch (action.type) {

    case AuthActionTypes.LogoutConfirmed:
      return initialAuthState;
    case AuthActionTypes.LoginCheckSuccess:
    case AuthActionTypes.LoginComplete:
      return {
        ...state,
        username: action.payload.username
      };


    default:
      return state;
  }
}
