import gql from 'graphql-tag';

export const readSelectedGalerieQuery = gql`
  query ReadSelectedGalerie($ids: [Int!], $slug: String) {
    response: readSelectedGalerie(
      ids: $ids,
      slug: $slug
    ) {
      id
      nazev
      slug
      color
      obsah
      media{
        id
        nazev
        mime
        url
        kategorie_id
      }
    }
  }
`;
