import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule, GridModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchbarComponent } from './searchbar.component';
import { UtilsModule } from '../../utils/utils.module';
import {FormsModule} from "@angular/forms";
import { ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@NgModule({
  imports: [CommonModule, GridModule, FlexModule, FontAwesomeModule, MatMenuModule, RouterModule, MatIconModule, MatButtonModule, BrowserAnimationsModule, UtilsModule, MatInputModule, FormsModule],
  declarations: [SearchbarComponent],
  entryComponents: [SearchbarComponent],
  providers: [ColorUtilsService],
  exports: [SearchbarComponent]
})
export class SearchbarModule {}

