import { ChangeDetectionStrategy, Component, Input, OnInit, } from '@angular/core';
import { IPkInputCheckboxFieldProps } from './pk-input-checkbox-field.props';


@Component({
  selector: 'pk-input-checkbox-field',
  templateUrl: './pk-input-checkbox-field.component.html',
  styleUrls: ['./pk-input-checkbox-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PkInputCheckboxFieldComponent implements OnInit {

  @Input()
  settings: IPkInputCheckboxFieldProps;

  @Input()
  data: any;

  ngOnInit(): void {
    // console.log('checkbox settings', this.settings);
    this.settings.form.get(this.settings.formControlName).setValue(this.settings.defaultValue);
  }


}

