import gql from 'graphql-tag';

export const checkPaymentStatusQuery = gql`
    query checkPaymentStatus($plat_prikaz_id: Float!) {
        response: checkPaymentStatus(
            plat_prikaz_id: $plat_prikaz_id
        ) {
            id
            stav
        }
    }
`