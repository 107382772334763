import {Component, Input} from '@angular/core';
import { SearchbarProps } from './searchbar.props';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ObecService, ColorUtilsService } from '@nx-monorepo/obce/ng/services';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { BaseComponent } from '../base.component';
import { Apollo } from 'apollo-angular';
import {Router} from "@angular/router";

@Component({
  selector: 'macms-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent extends BaseComponent{
  @Input() settings: SearchbarProps;
  @Input() text: string;
  constructor(
    private router: Router,
    public colorUtilsService: ColorUtilsService,
    private iconLibrary: FaIconLibrary,
    faConfig: FaConfig,
    protected obecService: ObecService,
    private apollo: Apollo) {
    super(colorUtilsService, obecService);
    iconLibrary.addIconPacks(fas, fab);
    faConfig.defaultPrefix = 'fas';
  }
  public inputValue: string;

  onClick(event: Event) {
      event.preventDefault();
      event.stopPropagation();
  }

  onSubmit(input: string){
    //todo: some error showing - only whitespaces, too short, too long...
    if (input.trim().length > 1) {
      this.search(input
        .trim()
        .replace(/\s\s+/g, ' ')
        .replace(new RegExp(" ", "g"), " & ")
      ).catch(err => console.error(err));
    }
    this.inputValue = '';
  }

  search(searchVars: string) {
    return this.router.navigate(["/vyhledavani"], {queryParams: { search: searchVars}}).catch(err => console.error(err));
  }
}
