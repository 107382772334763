import { Component, Input, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { IPkInputChipsFieldProps } from './pk-input-chips-field.props';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { getFirstError } from '../../../helpers/form/pk-form-handling';

@Component({
  selector: 'pk-input-chips-field',
  templateUrl: './pk-input-chips-field.component.html',
  styleUrls: ['./pk-input-chips-field.component.scss']
})
export class PkInputChipsFieldComponent implements OnInit, AfterViewInit, OnDestroy {
  public firstError ?: string;
  public formControl ?: FormControl;

  public innerData: {id, nazev}[];

  @Input()
  settings: IPkInputChipsFieldProps;

  @Input()
  data: any;
  @ViewChild('chipList') chipList: MatChipList;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  ngAfterViewInit(): void {
     this.formControl?.valueChanges.subscribe((_) => {
      this.checkForErrors();
    })
  }

  private checkForErrors(){
    this.firstError = getFirstError(this.settings.formControlName, this.settings.form);
    if(this.firstError && this.chipList){
      this.chipList.errorState = true;
    } else if(this.chipList){
      this.chipList.errorState = false;
    }
  }

  ngOnInit(): void {
    this.formControl = this.settings.form.controls[this.settings.formControlName] as FormControl;
    this.checkForErrors();
    this.innerData = [...this.data];
  }

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      const item = {
        nazev: value.trim(),
        id: this.innerData.length.toString()
      };

      this.innerData.push(item);
      this.settings.form.controls[this.settings.formControlName].patchValue(this.innerData);
      this.settings.form.controls[this.settings.formControlName].updateValueAndValidity();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(item: any): void {
    const index = this.innerData.indexOf(item);

    if (index >= 0) {
      this.innerData.splice(index, 1);
    }
    this.settings.form.controls[this.settings.formControlName].patchValue(this.innerData);
    this.settings.form.controls[this.settings.formControlName].updateValueAndValidity();
  }

  ngOnDestroy(): void {
    console.log("DESTROYERINK");
    this.innerData = null;
    this.settings.form.controls[this.settings.formControlName].setValue("");
    this.settings.form.controls[this.settings.formControlName].reset();
    this.settings.form.controls[this.settings.formControlName].updateValueAndValidity();
  }
}

