import gql from 'graphql-tag';

export const readSelectedEmailQuery = gql`
    query ReadSelectedEmailQuery($ids: [Int!]!) {
        response: readSelectedEmail(
            ids: $ids
            ) {
                id
                predmet
                text
                prijemci
                datum_odeslani
                count
                autor {
                        id
                        jmeno
                        prijmeni
                    }
        }
    }
`;
