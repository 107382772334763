import gql from 'graphql-tag';

export const deleteVstupnipole = gql`
    mutation deleteVstupniPole($ids: [Int!]!){
      response: deleteVstupniPole(ids: $ids){
        description
        error
        userMessage
      }
    }

`;
