import { createFeatureSelector, createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { LayoutState } from './layout.state';
import { IAction } from '@nx-monorepo/cms-base/interfaces';

const getButtons = (state: LayoutState): IAction[] => state.buttons;

const getTabs = (state: LayoutState): IAction[] => state.tabs;

const getPageIcon = (state: LayoutState): IAction => state.pageIcon;

const getPageName = (state: LayoutState): string => state.pageName;

const getSearchVisibility = (state: LayoutState): boolean => state.withSearch;

const getSearchText = (state: LayoutState): string => state.search;

const getWithAddButton = (state: LayoutState): boolean => state.withAddButton;

const getActiveTabName = (state: LayoutState): string => state.activeTabName;

const getHideCategories = (state: LayoutState): boolean => state.hideCategories;

export const selectLayoutState: MemoizedSelector<object, LayoutState> = createFeatureSelector<LayoutState>('layout');

export const selectLayoutButtons: MemoizedSelector<object, IAction[]> = createSelector(
  selectLayoutState,
  getButtons
);

export const selectLayoutActiveTabName: MemoizedSelector<object, string> = createSelector(
  selectLayoutState,
  getActiveTabName
);

export const selectLayoutTabs: MemoizedSelector<object, IAction[]> = createSelector(
  selectLayoutState,
  getTabs
);


export const selectLayoutPageName: MemoizedSelector<object, string> = createSelector(
  selectLayoutState,
  getPageName
);

export const selectLayoutPageIcon: MemoizedSelector<object, IAction> = createSelector(
  selectLayoutState,
  getPageIcon
);

export const selectLayoutSearchVisibility: MemoizedSelector<object, boolean> = createSelector(
  selectLayoutState,
  getSearchVisibility
);

export const selectLayoutSearch: Selector<object, string> = createSelector(
  selectLayoutState,
  getSearchText
);

export const selectWithAddButton: Selector<object, boolean> = createSelector(
  selectLayoutState,
  getWithAddButton
);

export const selectWithCategories: Selector<object, boolean> = createSelector(
  selectLayoutState,
  getHideCategories
);
