import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
// noinspection TypeScriptPreferShortImport
import { fuseAnimations } from '@fuse/animations';
import {
  CategoryTabsStoreSelector,
  LayoutStoreSelector,
  MACMSStoreState,
  LayoutStoreActions
} from '@nx-monorepo/cms-base/store';
import { Apollo } from 'apollo-angular';
import { ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { VstupniPoleService } from '@nx-monorepo/obce/ng/services';
import { ApolloQueryResult } from 'apollo-client';
import {
  selectIsAllSelected,
  selectSelectedItems,
  selectTableData,
  selectTableDataError,
  selectTableDataLoading
} from '../../store/table/table.selectors';
import {
  InitTableAction,
  ToggleMasterSelect,
  ToggleSelectedItem,
  TableActionTypes,
  FetchTableDataAction
} from '../../store/table/table.actions';
import { resolveKeyOrShowDefault } from '@nx-monorepo/ng-shared';
import { SnackbarStoreActions } from '../../store/snackbar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { LayoutActionTypes } from '../../store/layout/layout.actions';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { IAction, IColumnDefinition } from '@nx-monorepo/cms-base/interfaces';
import { IRouteData, ColumnDefinitionProvider } from '@nx-monorepo/cms-base/helpers';
// tslint:disable-next-line:nx-enforce-module-boundaries
import VstupniPole from 'apps/obce/api/src/app/entities/vstupni-pole/vstupni-pole';
import {MediaActionTypes} from "@nx-monorepo/cms-base/store/media/media.actions";

@Component({
  selector: 'pk-data-table',
  templateUrl: './pk-data-table.component.html',
  styleUrls: ['./pk-data-table.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PkDataTableComponent implements OnInit, OnDestroy {
  // store state
  loadingData$: Observable<boolean>;
  dataError$: Observable<string>;
  data$: Observable<any>;
  selectedItems$: Observable<any[]>;
  isAllSelected$: Observable<boolean>;
  search$: Observable<string>;
  activeCategory$: Observable<IAction>;

  // inner state
  visibleColumns: string[] = [];
  pageSizeOptions = [10, 25, 50];
  destroy$: Subject<boolean> = new Subject<boolean>();
  searchText: string;
  activeCategoryValue: IAction;
  columnDefinitions: IColumnDefinition[];
  @Output() rowItemClicked: EventEmitter<any> = new EventEmitter();
  selected: any;

  @Input()
  private shouldNavigateAfterRowClick = true;

  routeData: IRouteData;

  @Input()
  currentEntity: EntityTypes;

  @Input()
  canSelectMultiple = true;

  @Input()
  filterObject?: {} = null;

  @Input()
  overrideColumnDefinitions?: IColumnDefinition[] = null;


  // view references
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private resolveKeyOrShowDefault = resolveKeyOrShowDefault;

  constructor(private apollo: Apollo, private store$: Store<MACMSStoreState.State>, private route: ActivatedRoute, private router: Router,
              private _fuseProgressBarService: FuseProgressBarService,
              private columnDefinitionProvider: ColumnDefinitionProvider, private vstupniPoleService: VstupniPoleService, private actionsSubject$: ActionsSubject) {
    this.routeData = route.snapshot.data as IRouteData;
  }


  ngOnInit(): void {
    //console.log("OCD",this.overrideColumnDefinitions);
    if (!this.currentEntity) {
      this.currentEntity = this.routeData.entityType;
    }

    // select active category
    this.activeCategory$ = this.store$.select(CategoryTabsStoreSelector.selectActiveCategory);

    // select a search from LayoutStore
    this.search$ = this.store$.select(LayoutStoreSelector.selectLayoutSearch);

    // setup observables for table
    this.observeTableChanges();

    // data selector + optional transform
    this.data$ = this.store$.select(selectTableData).pipe(
      map(res => {
        // pokud nemame dynamicke sloupecky, neni treba nic transformovat
        if (!this.routeData.dynamicTableColumns) {
          return res;
        }

        // v opacnem pripade transformutejeme
        const newItems = res.items.map(item => {
          const newItem = {
            ...item,
            pole: undefined
          };

          // pokud itemy obsahuji i "pole", provedeme transformaci
          if (item.pole && item.pole.length > 0) {
            item.pole.forEach(pole => {
              newItem[`${pole.vstupni_pole.nazev}_${pole.vstupni_pole.id}`] = pole.data;
            });
          }
          return newItem;
        });
        return {
          ...res,
          items: newItems
        };
      })
    );

    // are we loading?
    this.store$.select(selectTableDataLoading).subscribe((isLoading)=>{
      if(!isLoading) {
        this._fuseProgressBarService.hide();
      } else {
        this._fuseProgressBarService.show();
      }
    });

    // did we encounter error?
    this.dataError$ = this.store$.select(selectTableDataError);

    // get selected data
    this.selectedItems$ = this.store$.select(selectSelectedItems);

    // wether or not is all selected
    this.isAllSelected$ = this.store$.select(selectIsAllSelected);

    // subscribe to DELETE button
    this.subscribeToButtonsAction();
    this.subscribeToUploads();
  }

  isChecked(selectedItems, row): boolean {
    const foundIndex = selectedItems.findIndex(selectItem => selectItem.id === row.id);
    return foundIndex >= 0;
  }


  ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  private subscribeToUploads(): Subscription{
    return this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action) =>
        action.type === MediaActionTypes.UPLOAD_SUCCESSFUL)
    ).subscribe((action) => {
      this.refetch();
    });
  }

  private subscribeToButtonsAction(): Subscription {
    return this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.selectedItems$),
      filter(([action,_]) =>
        action.type === LayoutActionTypes.DELETE_BUTTON_PRESSED)
    ).subscribe(([action,selectedItems]) => {
      switch (action.type) {
        case LayoutActionTypes.DELETE_BUTTON_PRESSED:
          if(selectedItems.length > 0){
            this.showDeleteConfirmDialog(selectedItems.length);
          }
          break;

      }
    });
  }

  private showDeleteConfirmDialog(numberOfItemsToDelete: number){

    let contentText = `Opravdu chcete smazat ${numberOfItemsToDelete} položku ?`;
    if(numberOfItemsToDelete > 4){
      contentText = `Opravdu chcete smazat ${numberOfItemsToDelete} položek ?`;
    } else if(numberOfItemsToDelete > 1){
      contentText = `Opravdu chcete smazat ${numberOfItemsToDelete} položky ?`;
    }

    //TODO customize texts with selected category/entity
    this.store$.dispatch(new LayoutStoreActions.ShowConfirmDialog({
      title: "Smazat položky?",
      content: contentText,
      onConfirm: TableActionTypes.DELETE_SELECTED
    }));
  }

  /**
   * Observer changes for pagination, sort and filter changes to refetch data
   */
  private observeTableChanges() {
    // subscribe for paginator and sort changes
    const changes = [this.paginator.page, this.sort.sortChange];
    merge(...changes)
      .pipe(
        takeUntil(this.destroy$),
        map(() => {
          this._fuseProgressBarService.show();
          this.updateFilterAndFetch();
        })
      ).subscribe();

    // subscribe for search
    this.search$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((searchValue: string) => {
      this.searchText = searchValue;
      this.updateFilterAndFetch();
    });

    // subscribe for active category changes
    this.activeCategory$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((activeCategory: IAction) => {
      // set active category and fetch table data
      this.activeCategoryValue = activeCategory;
      this.updateFilterAndFetch();

      // get columns
      this.columnDefinitions = this.overrideColumnDefinitions ?? this.columnDefinitionProvider.getColumnDefinitionsFor(this.currentEntity);
      this.visibleColumns = this.columnDefinitions.map(col => col.key.join('.'));

      // get dynamic table columns if wanted
      if (this.routeData.dynamicTableColumns) {
        this.subscribeForDynamicTableColumns(this.activeCategoryValue.id);
      }

    });

  }

  private subscribeForDynamicTableColumns(categoryId: number) {
    this.vstupniPoleService.fetchAll({ categories: [categoryId] })
      .pipe(
        take(1),
        map((res: ApolloQueryResult<any>) => res.data.response.items)
      )
      .subscribe((vstupniPole: VstupniPole[]) => {

        const columns: IColumnDefinition[] = vstupniPole.map((pole) => ({
          title: pole.nazev,
          type: 'string',
          key: [`${pole.nazev}_${pole.id}`],
          align: 'left',
          sortable: false,
          fxHide: {
            sm: false,
            md: false,
            lg: false
          }
        }));

        this.columnDefinitions = this.columnDefinitions.concat(columns);
        this.visibleColumns = this.columnDefinitions.map(col => col.key.join('.'));
      });

  }

  public refetch(){
    this.store$.dispatch(
      new FetchTableDataAction());}


  public updateFilterAndFetch() {
    this.store$.dispatch(
      new InitTableAction({
        entityType: this.currentEntity,
        page: this.paginator.pageIndex || 0,
        take: this.paginator.pageSize || this.pageSizeOptions[0],
        sortColumn: this.sort.active || undefined,
        search: this.searchText,
        where: this.filterObject,
        sortOrder: this.sort.direction.toLocaleUpperCase() || undefined,
        categories: (this.activeCategoryValue && this.activeCategoryValue.id > 0) ? [this.activeCategoryValue.id] : undefined
      })
    );
  }

  onRowClick(item) {
    // try to navigate to edit component
    if(!item.is_edit_locked) {
      if (!this.shouldNavigateAfterRowClick) {
        this.selected = item;
        this.rowItemClicked.emit(item);
      } else {
        this.router.navigate(['edit/' + item.id], { relativeTo: this.route }).catch(err => console.error(err))
      }

      // emit the clicked item

    } else {
      this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({ message: 'Tuto položku nelze upravit!', action: 'OK', config: { duration: 5000 } }));
    }
  }

  toggleSelected(item): void {
    this.store$.dispatch(
      new ToggleSelectedItem({
        selectedItem: item,
        canSelectMultiple: this.canSelectMultiple
      })
    );
  }

  masterToggle(): void {
    this.store$.dispatch(new ToggleMasterSelect());
  }

}
