import { createFeatureSelector, createSelector, Selector } from '@ngrx/store';
import { MediaState } from './media.state';

const getLoading = (state: MediaState): any => state.isLoading;


export const selectTableFeature: Selector<object, MediaState> = createFeatureSelector<MediaState>(
  'media'
);

export const selectLoading: Selector<object, any> = createSelector(
  selectTableFeature,
  getLoading
);
