import gql from 'graphql-tag';

export const readSelectedUzivatelQuery = gql`
  query readSelectedUzivatel($ids: [Int!]!) {
    response: readSelectedUzivatel(
      ids: $ids
    ) {
      id
      titul
      uzivatel_role_id
      jmeno
      prijmeni
      rodnecislo
      birthday
      telefon
      email
      mesto
      ulice
      psc
      hashed_password
      hasPassword
    }
  }
`;
