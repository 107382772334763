import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PkGridsterItemTextfieldComponent } from './pk-gridster-item-textfield/pk-gridster-item-textfield.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FlexModule } from '@angular/flex-layout';
import { PkGridsterItemDateComponent } from './pk-gridster-item-date/pk-gridster-item-date.component';
import { PkGridsterItemSelectComponent } from './pk-gridster-item-select/pk-gridster-item-select.component';
import { PkGridsterBaseItemComponent } from './pk-gridster-base-item/pk-gridster-base-item.component';
import { PkInputFieldsModule } from '../pk-input-fields/pk-input-fields.module';
import { PkGridsterItemMultipleSelectComponent } from './pk-gridster-item-multiple-select/pk-gridster-item-multiple-select.component';
import { PkGridsterItemWysiwygComponent } from './pk-gridster-item-wysiwyg/pk-gridster-item-wysiwyg.component';
import { PkGridsterItemCheckboxComponent } from './pk-gridster-item-checkbox/pk-gridster-item-checkbox.component';

@NgModule({
  declarations: [
    PkGridsterBaseItemComponent,
    PkGridsterItemTextfieldComponent,
    PkGridsterItemDateComponent,
    PkGridsterItemSelectComponent,
    PkGridsterItemMultipleSelectComponent,
    PkGridsterItemCheckboxComponent,
    PkGridsterItemWysiwygComponent
  ],
  exports: [PkGridsterBaseItemComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatMenuModule, FlexModule, PkInputFieldsModule]
})
export class MacmsGridsterItemsModule {}
