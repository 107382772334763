import { Component, Input, OnInit } from '@angular/core';
import { IDynamicComponent, IDynamicTheme } from '@nx-monorepo/ng-shared';
import { NavigaceBarrel } from '@nx-monorepo/node-frontend';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { getChildrenInTreeRepresentation, NavPol } from '../../../utils';
import { BaseComponent } from '../../base.component';
import { HeadingProps } from '../../heading/heading.props';
import { ColorUtilsService, ObecService } from '@nx-monorepo/obce/ng/services';

@Component({
  selector: 'macms-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss']
})
export class FooterNavComponent extends BaseComponent implements IDynamicComponent, IDynamicTheme, OnInit {
  @Input() data: any;
  @Input() settings: NavigaceBarrel.FooterNavProps;
  @Input() primaryColor: string;

  headingProps: HeadingProps;
  navigacePolozky: NavPol[];

  constructor(public colorUtilsService: ColorUtilsService, private iconLibrary: FaIconLibrary, faConfig: FaConfig, protected obecService: ObecService) {
    super(colorUtilsService, obecService);
    iconLibrary.addIconPacks(fas, fab);
    faConfig.defaultPrefix = 'fas';
  }


  getComponentName() {
    return NavigaceBarrel.name_footer;
  }

  ngOnInit(): void {
    this.generateColors();
    this.navigacePolozky = getChildrenInTreeRepresentation(this.data.children);
    if(this.settings.headingProps){
      this.headingProps = this.settings.headingProps;
    } else {
      this.headingProps = {
        isInverted: this.settings.isInverted,
        primaryColor: this.settings.primaryColor,
        boxMargin: {right: 'auto'},
        boxPadding: {left: '0'},
        fontWeight: 'bold'
      }
    }
  }
}
