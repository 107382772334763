<div>
  <section id="prispevky"
           [style.background]="settings.sectionBackground"
  >
    <macms-heading
      *ngIf="settings.showHeading"
      [settings]="headingProps"
      [text]="settings.nazev || 'Příspěvky'">
    </macms-heading>
    <ng-template *ngIf="settings.uvod" [ngTemplateOutlet]="popis">
    </ng-template>
    <div class="classic-news grid">
      <!--
      #TODO - idk where to use primary color here ??????
      #TODO - prefferedColors ?????
      -->
      <ng-container *ngFor="let prispevek of data.items">
        <a class="item"
           [routerLink]="(prispevek.slug ? prispevek.slug : prispevek.id) | DetailUrl:'prispevky':prispevek.kategorie.nazev"
           [paddingDefinition]="settings.itemPadding"
           [marginDefinition]="settings.itemMargin"
           [style.color]='settings.fontColor ? settings.fontColor : obecSettings.primary_color'
           [style.flexBasis]="settings.itemFlexBasis"
           [style.background]='settings.itemBackground ? settings.itemBackground : ""'
           [borderDefinition]="settings.itemBorder"
        >
          <div class="content">
            <ng-template *ngIf="settings.showDescriptionInHeader" [ngTemplateOutlet]="info"
                         [ngTemplateOutletContext]="{prispevek: prispevek}">

            </ng-template>
            <div class="header"
                 [paddingDefinition]="settings.headerPadding"
                 [marginDefinition]="settings.headerMargin"
                 [borderDefinition]="settings.headerBorder">
              <h2 [style.fontFamily]="obecSettings.title_font"
                  macmsColor
                  [paddingDefinition]="settings.headerPadding"
                  [mc_isInverted]="settings.isHeaderInverted"
                  [mc_wantedPrimary]="settings.primaryColor"
                  [mc_otherColors]="[settings.secondaryColor, settings.accentColor]"
              >{{prispevek?.nazev}}</h2>
            </div>
            <div class="obsah"
            >
              <p
                [contrastForeground]="settings.sectionBackground ? settings.sectionBackground : (settings.wrapperBackground ? settings.wrapperBackground : '#ffffff')"
                [preferedColors]="[secondaryColor]"
                [style.fontFamily]="obecSettings.paragraph_font"
                [innerHtml]="prispevek.uvod ? prispevek.uvod : prispevek.obsah"></p>
            </div>
            <ng-template *ngIf="!settings.showDescriptionInHeader" [ngTemplateOutlet]="info"
                         [ngTemplateOutletContext]="{prispevek: prispevek}">

            </ng-template>
          </div>
        </a>
      </ng-container>
    </div>


    <macms-next-button href="/prispevky" [settings]="nextButtonSettings" text="Další příspěvky"></macms-next-button>
  </section>
</div>

<ng-template #category let-polozka="polozka">
  <div class="category"
       [style.backgroundColor]="settings.primaryColor"
       [style.color]="onPrimaryColor"
       [style.fontFamily]="obecSettings.title_font">
    Ekonomika
  </div>
</ng-template>

<ng-template #popis>
  <div macmsColor [mc_useTransparent]="true" [mc_wantedPrimary]="settings.primaryColor"
       [style.fontFamily]="obecSettings.paragraph_font" style="width:100%;"
       class="intro-text">
    <p>{{ settings.uvod }}</p>
  </div>
</ng-template>

<ng-template #info let-prispevek="prispevek">
  <div class="prispevekInfo" macmsColor [mc_isInverted]="settings.isDescriptionInverted"
       [mc_wantedPrimary]="settings.accentColor ? settings.accentColor : settings.secondaryColor"
       [mc_otherColors]="[settings.primaryColor]">
    <div class="datum" [style.fontFamily]="obecSettings.paragraph_font">
      <small>{{prispevek.datum_zverejneni | date:'mediumDate':undefined:'cs-CZ' }}</small></div>
    <div class="autor" [style.fontFamily]="obecSettings.paragraph_font">
      <small>{{prispevek?.autor.jmeno}} {{prispevek?.autor.prijmeni}}</small></div>
  </div>
</ng-template>