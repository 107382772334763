<div class="content">
  <form class="inputs" [formGroup]="vyuctovaniForm" class="row">
    <mat-form-field appearance="outline" class="inputFields">
      <mat-label>Zvolte období</mat-label>
      <mat-select [(ngModel)]="selectedValue" formControlName="obdobi">
        <mat-option *ngFor="let choice of casovyUsek" [value]="choice.value">
          {{choice.viewValue}}
        </mat-option>
      </mat-select>
      <mat-error>Období je povinné</mat-error>
    </mat-form-field>

    <pk-input-date-field class="inputFields" [settings]="{
                    nazev: 'Datum od',
                    formControlName:'dateFrom',
                    form: vyuctovaniForm,
                    povinnost: false,
                    defaultValue: ''
                }"></pk-input-date-field>

    <pk-input-date-field class="inputFields" [settings]="{
                    nazev: 'Datum do',
                    formControlName:'dateTo',
                    form: vyuctovaniForm,
                    povinnost: false,
                    defaultValue: ''
                }"></pk-input-date-field>
    <button mat-raised-button class="inputFields" type="button" color="primary" (click)="count()">
      Výpis
    </button>
  </form>

  <ng-container *ngIf="(itemRanged | async) as total" class="row">
    <div class="cards">
      <fuse-widget class="summary">
        <!-- Front -->
        <div class="fuse-widget-front mat-elevation-z2">
          <div class="header">
              <p class="headline">Vybrané období: {{this.usekDateFrom}} - {{this.usekDateTo}}</p>
          </div>

          <div class="card">
            <div class="stat-wrapper">
              <div class="stat">
                <p class="number">{{vybranyUsekSoucet}}</p>
                <p class="label">Počet {{this.routeData.entityType === 'Sms' ? 'SMS' : 'emailů'}}</p>
              </div>
            </div>
            <div class="stat-wrapper">
              <div class="stat">
                <p class="number">{{vybranyUsekCena}} Kč</p>
                <p class="label">Celkem</p>
              </div>
            </div>
          </div>
        </div>
      </fuse-widget>
      <fuse-widget class="summary">
        <!-- Front -->
        <div class="fuse-widget-front mat-elevation-z2">
          <div class="header ">
              <p class="headline">Celkem</p>
          </div>
          <div class="card">
            <div class="stat-wrapper">
              <div class="stat">
                <p class="number">{{celkemSoucet}}</p>
                <p class="label">Nezaplacených {{this.routeData.entityType === 'Sms' ? 'SMS' : 'emailů'}}</p>
              </div>
            </div>
            <div class="stat-wrapper">
              <div class="stat">
                <p class="number">{{celkemCena}} Kč</p>
                <p class="label">Celkem</p>
              </div>
            </div>
          </div>
        </div>
      </fuse-widget>
    </div>

    <div *ngIf="total.data.response.length < 1">
      <p class="noData">Žádná data k zobrazení!</p>
    </div>
    <div *ngIf="total.data.response.length >=1" class="graph">
      <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
        [legend]="barChartLegend" [chartType]="barChartType">
      </canvas>
    </div>

  </ng-container>
</div>