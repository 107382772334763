<!--<ul class="crumbs" [style.font-family]="obecSettings.paragraph_font">
  <li [style.color]='obecSettings.primary_color' [ngClass]="{'last': last == 'obec'}"><a href="/">{{obec}}</a></li>
  <li [style.color]='obecSettings.primary_color' *ngIf="kolekce" [ngClass]="{'last': last == 'kolekce'}"><a [href]="kolekce_url">{{kolekce}}</a></li>
  <li [style.color]='obecSettings.primary_color' *ngIf="kategorie" [ngClass]="{'last': last == 'kategorie'}"><a [href]="kolekce_url+'/'+kategorie_url">{{kategorie}}</a></li>
  <li *ngIf="entita"><a>{{entita}}</a></li>
</ul>-->

<ul class="crumbs" [style.font-family]="obecSettings.paragraph_font">
  <ng-container *ngFor="let crumb of crumbs">
    <li [style.color]='obecSettings.primary_color' [ngClass]="{'last': last == crumb.title}"><small><a [routerLink]="crumb.url">{{crumb.title}}</a></small></li>
  </ng-container>
</ul>
