import gql from 'graphql-tag';

export const deleteGalerie = gql`
  mutation deleteGalerie($ids: [Int!]!) {
    response: deleteGalerie(ids: $ids) {
      description
      userMessage
      error
    }
  }
`;
