import * as fromAuth from './auth.actions';
import { AuthService } from '../../services/auth.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {  map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as MACMSStoreState from '../store.state';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { LoginComplete } from './auth.actions';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store$: Store<MACMSStoreState.State>,
    private _fuseNavigationService: FuseNavigationService,
  ) {
  }

  @Effect()
  login$ = this.actions$.pipe(
    ofType<fromAuth.PerformLogin>(fromAuth.AuthActionTypes.PerformLogin),
    switchMap((action) => {
      return this.authService.login(action.payload.email, action.payload.password).then(()=>{
        return new LoginComplete({username: "TEST"});
        }
      );
    })
  );

  @Effect()
  loginSuccess$ = this.actions$.pipe(
    ofType<fromAuth.LoginSuccess>(fromAuth.AuthActionTypes.LoginSuccess, fromAuth.AuthActionTypes.LoginCheckSuccess),
    map((action) => {
      console.log("LS payload",action.payload);
      if (!action.payload.role.pravomoce.canManageContent) {
        console.log("SETTING OBCAN");
        this._fuseNavigationService.setCurrentNavigation('user');
        //this.router.navigate(['/formulare']);
      } else {
        console.log("SETTING ADMIN NAV");
        this._fuseNavigationService.setCurrentNavigation('admin');
      }
      return new LoginComplete({username: action.payload.username});
    })
  );

  @Effect()
  checkLogin$ = this.actions$.pipe(
    ofType<fromAuth.PerformLoginCheck>(fromAuth.AuthActionTypes.PerformLoginCheck),
    map(() => {
        if(this.authService.isLoggedIn()){
          const token_data = this.authService.loginFromStorage();
          return new fromAuth.LoginCheckSuccess({role: token_data.role as any, username: token_data.email});
        } else {
          return new fromAuth.LoginCheckFailure({error: 'Nejste přihlášen!'})
        }
      }
    ));


  @Effect()
  logout$ = this.actions$.pipe(
    ofType<fromAuth.LogoutConfirmed>(fromAuth.AuthActionTypes.LogoutConfirmed),
    map( () => {
      this.authService.cleanSession();
      return new fromAuth.LogoutCompleted();
    })
  );

  public isExpired(expires_at: moment.Moment) {
    return moment().isBefore(expires_at);
  }
}
