import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { PkGridsterItemUdalostiDialogCompanion } from '@nx-monorepo/cms-base/components/pk-gridster-items/companions/frontend-items/pk-gridster-item-udalosti-dialog-companion';
import { PkGridsterItemUredniDeskyCommonDialogCompanion } from '@nx-monorepo/cms-base/components/pk-gridster-items/companions/frontend-items/pk-gridster-item-uredni-desky-common-dialog-companion';
import { PkGridsterItemUredniDeskyCompactDialogCompanion } from '@nx-monorepo/cms-base/components/pk-gridster-items/companions/frontend-items/pk-gridster-item-uredni-desky-compact-dialog.companion';
import { PkGridsterItemUredniDeskyTwoColumnsDialogCompanion } from '@nx-monorepo/cms-base/components/pk-gridster-items/companions/frontend-items/pk-gridster-item-uredni-desky-two-columns-dialog.companion';
import { PkGridsterItemCalendarDialogCompanion } from '@nx-monorepo/cms-base/components/pk-gridster-items/companions/frontend-items/pk-gridster-item-calendar-dialog-companion';
import { PkGridsterItemGalerieDialogCompanion } from '@nx-monorepo/cms-base/components/pk-gridster-items/companions/frontend-items/pk-gridster-item-galerie-dialog-companion';
import { KolekceService } from '@nx-monorepo/obce/ng/services';
import { PkModuleItem, GridsterInputComponent, GridsterFrontendComponent } from '@nx-monorepo/cms-base/components';
import { PkGridsterItemWysiwygDialogCompanion } from '@nx-monorepo/cms-base/components/pk-gridster-items/companions/frontend-items/pk-gridster-item-wysiwyg-dialog-companion';
import { PkGridsterItemNavigaceDialogCompanion } from '@nx-monorepo/cms-base/components/pk-gridster-items/companions/frontend-items/pk-gridster-item-navigace-dialog-companion';
import { GenericDialogCompanion } from '@nx-monorepo/cms-base/components/pk-gridster-items/companions/frontend-items/generic-frontend-dialog-companion';


@Injectable({
  providedIn: 'root'
})
export class PkGridsterItemDefinitionService {

  constructor(private kolekceService: KolekceService) {
  }

  //fixme maybe move me to normal components instead of layout?

  private frontendItems: Array<PkModuleItem> = [
    /*{
      title: 'Kolekce',
      description: 'Tento modul zobrazí přehled více položek jedné entity (kolekce)',
      icon: 'list',
      type: 'KolekceComponent',
      formCompanion: new GenericDialogCompanion()
    },
    {
      title: 'Entita',
      description: 'Tento modul zobrazí detail konkrétní entity (článku, příspěvku, úřední desky).',
      icon: 'class',
      type: 'EntitaComponent',
      formCompanion: new GenericDialogCompanion()
    },*/
    /*{
      title: 'Navigace',
      description: 'Tento modul zobrazí specifickou navigaci',
      icon: 'call_split',
      type: 'NavigaceComponent',
      formCompanion: new PkGridsterItemNavigaceDialogCompanion()
    },
    {
      title: 'Poslední úřední desky',
      description: 'Tento modul zobrazí specifickou navigaci',
      icon: 'commute',
      type: 'UredniDeskyComponent',
      formCompanion: new GenericDialogCompanion()
    },
    {
      title: 'Poslední příspěvky',
      description: 'Tento modul zobrazí specifickou navigaci',
      icon: 'commute',
      type: 'PrispevkyComponent',
      formCompanion: new GenericDialogCompanion()
    },
    {
      title: 'Poslední události',
      description: 'Tento modul zobrazí specifickou navigaci',
      icon: 'commute',
      type: 'UdalostiComponent',
      formCompanion: new GenericDialogCompanion()
    },
    {
      title: 'Newsletter',
      description: 'Tento modul zobrazí pole pro přihlášení do newsletteru',
      icon: 'commute',
      type: 'NewsletterComponent',
      formCompanion: new GenericDialogCompanion()
    },
    {
      title: 'Poslední galerie',
      description: 'Tento modul zobrazí X posledních galerií',
      icon: 'photo_library',
      type: 'FotogalerieComponent',
      formCompanion: new GenericDialogCompanion()
    },*/
    {
      title: 'Vlastní blok',
      description: 'Tento modul umožňuje vložit formátovaný text, obrázky, tabulky nebo odkazy.',
      icon: 'line_style',
      type: 'WysiwygComponent',
      formCompanion: new PkGridsterItemWysiwygDialogCompanion()
    },
    {
      title: 'Navigace blok',
      description: 'Tento modul umožňuje vložit formátovaný text, obrázky, tabulky nebo odkazy.',
      icon: 'line_style',
      type: 'ItemNavComponent',
      formCompanion: new PkGridsterItemNavigaceDialogCompanion()
    },
    {
      title: 'Události blok',
      description: '',
      icon: 'line_list',
      type: 'UdalostiComponent',
      formCompanion: new PkGridsterItemUdalostiDialogCompanion()      
    },
    {
      //todo: Nejaký popis rozdielov medzi úr. doskami
      title: 'Úřední desky common',
      description: '',
      icon: 'line_list',
      type: 'UredniDeskyComponent',
      formCompanion: new PkGridsterItemUredniDeskyCommonDialogCompanion()
    },
    {
      title: 'Úřední desky compact',
      description: '',
      icon: 'line_list',
      type: 'CompactUredniDeskyComponent',
      formCompanion: new PkGridsterItemUredniDeskyCompactDialogCompanion()
    },
    {
      title: 'Úřední desky - dva stloupce',
      description: '',
      icon: 'line_list',
      type: 'TwoColumnUredniDeskyComponent',
      formCompanion: new PkGridsterItemUredniDeskyTwoColumnsDialogCompanion()
    },
    {
      title: 'Kalendář',
      description: '',
      icon: 'line_list',
      type: 'CalendarComponent',
      formCompanion: new PkGridsterItemCalendarDialogCompanion()
    },
    {
      title: 'Galerie',
      description: '',
      icon: 'line_list',
      type: 'FotogalerieComponent',
      formCompanion: new PkGridsterItemGalerieDialogCompanion()
    }
  ];
  private formItems: Array<PkModuleItem> = [
    {
      title: 'Text',
      description: 'Tento modul slouží k vyplnění textové informace',
      icon: 'short_text',
      type: 'PkGridsterItemTextfieldComponent'
    },
    {
      title: 'Datum',
      description: 'Tento modul slouží k vyplnění data',
      icon: 'date_range',
      type: 'PkGridsterItemDateComponent'
    },
    {
      title: 'Výběr jedné možnosti',
      description: 'Tento modul slouží k možnosti výběru jedné odpovědi z několika možností',
      icon: 'layers',
      type: 'PkGridsterItemSelectComponent'
    },
    {
      title: 'Výběr více možností',
      description: 'Tento modul slouží k možnosti výběru několika odpovědí z několika možností',
      icon: 'layers',
      type: 'PkGridsterItemMultipleSelectComponent'
    },
    {
      title: 'Zaškrtávací pole',
      description: 'Tento modul slouží k přidání zaškrtávacího pole.',
      icon: 'checkbox',
      type: 'PkGridsterItemCheckboxComponent'
    }
  ];

  findItemByType(itemType: string) {
    const resultArray = this.frontendItems.concat(this.formItems).filter(item => {
      return itemType === item.type;
    });
    if (resultArray.length > 0) {
      return resultArray[0];
    } else {
      return {
        title: `UNK - ${itemType}`,
        description: '',
        icon: 'contact_support',
        type: itemType,
        formCompanion: new GenericDialogCompanion()
      };
    }
  }

  fillItemSettingsByType(itemType: GridsterInputComponent | GridsterFrontendComponent, newSettings: any, oldSettings): any {
    const shared = {
      nazev: newSettings.nazev || '',
      povinnost: newSettings.povinnost === undefined ? true : newSettings.povinnost,
      defaultValue: newSettings.defaultValue || '',
      form: newSettings.form ? newSettings.form : oldSettings.form ? oldSettings.form : null,
      napoveda: {
        isIcon: true,
        text: newSettings.napoveda || ''
      },
      formControlName: newSettings.formControlName ? newSettings.formControlName : oldSettings.formControlName ? oldSettings.formControlName : null
    };
    switch (itemType) {
      case 'PkGridsterItemTextfieldComponent':
        return {
          ...shared,
          type: newSettings.type || 'text',
          isTextArea: newSettings.isTextArea === undefined ? false : newSettings.isTextArea
        };
      case 'PkGridsterItemDateComponent':
      case 'PkGridsterItemCheckboxComponent':
        return shared;
      case 'PkGridsterItemMultipleSelectComponent':
        return {
          ...shared,
          service: newSettings.service || null,
          options: newSettings.options || [],
          isMultiple: true
        };
      case 'PkGridsterItemSelectComponent':
        return {
          ...shared,
          options: newSettings.options || [],
          isMultiple: false
        };
    }
  }

  getItemDataByType(itemType: GridsterInputComponent | GridsterFrontendComponent) {
    switch (itemType) {
      case 'ItemNavComponent':
        // @ts-ignore
        return this.kolekceService.fetchAll(EntityTypes.Navigace).pipe(
          map((response: any) => response.data.response.items),
          map(items => {
            return items.map(item => ({...item, description: ''}))
          })
        );
      default:
        return {};
    }
  }

  getFrontendItems() {
    return this.frontendItems;
  }

  getFormItems() {
    return this.formItems;
  }

  getAllItems() {
    return this.formItems.concat(this.frontendItems);
  }
}
