import { Validators } from '@angular/forms';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { IPkHiddenFieldProps, IPkInputTextFieldProps, IPkColorPickerProps, IPkInputEntityFieldProps, IPkInputDateFieldProps } from '@nx-monorepo/cms-base/components';

export const galerieEntityDefinition: IEntityDefinition[] = [
  {
    componentName: 'PkHiddenFieldComponent',
    settings: {
      type: 'number',
      formControlName: 'id',
      defaultValue: 0
    } as IPkHiddenFieldProps,
    data: null,
    validators: [],
    cols: 0,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Název',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Napoveda lorem ipsum'
      },
      isTextArea: false,
      formControlName: 'nazev',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 2,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Název v adresovém řádku',
      povinnost: false,
      napoveda: {
        isIcon: true,
        text: 'Toto pole umožňuje vytvořit vlastní adresu galerie. Pole smí obsahovat pouze malé písmena, číslice, pomlčky a podtržítka. Nejsou povoleny mezery. Pokud pole necháte prázdné, bude URL vygenerována automaticky z názvu.'
      },
      isTextArea: false,
      formControlName: 'slug',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.pattern('^[a-z\\-\\_0-9]+$')],
    cols: 1,
    x: 2,
    y: 0
  },
  {
    componentName: 'PkColorPickerFieldComponent',
    settings: {
      nazev: 'Barva',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Volitelná barva galerie - někdy bývá použita v grafických prvcích.'
      },
      formControlName: 'color',
      defaultValue: '#000000',
    } as IPkColorPickerProps,
    data: null,
    validators: [Validators.required],
    cols: 1,
    x: 3,
    y: 0
  },
  {
    componentName: 'PkInputEntityFieldComponent',
    settings: {
      nazev: 'Média',
      buttonText: 'Přidat média',
      povinnost: true,
      napoveda: {
        isIcon: false,
        text: 'Média této galerie'
      },
      isMultiple: true,
      entityType: EntityTypes.Media,
      withPreview: true,
      formControlName: 'media',
      defaultValue: '',
    } as IPkInputEntityFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 4,
    x: 0,
    y: 2
  },
  {
    componentName: 'PkInputWysiwygComponent',
    settings: {
      nazev: 'Popis galerie',
      formControlName: 'obsah',
      defaultValue: ''
    } as IPkInputDateFieldProps,
    validators: [],
    data: null,
    cols: 4,
    x: 0,
    y: 1
  }

];
