<ng-container>
  <pk-vstupni-pole-form
    #vstupniPoleFormComponent
  [disabled]="!canUserEditForm"></pk-vstupni-pole-form>

  <!-- HISTORIE -->
  <ng-container *ngIf="historie?.length > 0">
    <h2>Historie:</h2>
    <mat-list role="list">
      <mat-list-item role="listitem" *ngFor="let history of historie.slice().reverse()">
        <!-- Ikonka stavu -->
        <ng-container matListIcon [ngSwitch]="history.stav">
          <mat-icon matListIcon *ngSwitchCase="'Rozpracovaný'">create</mat-icon>
          <mat-icon matListIcon *ngSwitchCase="'Podaný'">next_week</mat-icon>
          <mat-icon matListIcon *ngSwitchCase="'Odvolaný'">backspace</mat-icon>
          <mat-icon matListIcon *ngSwitchCase="'Schválený'">check_circle_outline</mat-icon>
          <mat-icon matListIcon *ngSwitchCase="'Vrácený'">undo</mat-icon>
          <mat-icon matListIcon *ngSwitchCase="'Zamítnutý'">highlight_off</mat-icon>
        </ng-container>

        <!-- Datum vzniku -->
        <h3 matLine>{{history.stav}}</h3>

        <!-- text Stavu + text komentare pokud je -->
        <p matLine>
          <span>{{history.created_at | amDateFormat: 'DD.MM.YYYY HH:mm'}}</span>
          <span *ngIf="history.text"> -- {{history.text}} </span>
        </p>
      </mat-list-item>
    </mat-list>
  </ng-container>
</ng-container>
