import gql from 'graphql-tag';

export const deleteStranky = gql`
  mutation deleteStranka($ids: [Int!]!) {
    response: deleteStranka(ids: $ids) {
      description
      userMessage
      error
    }
  }
`;
