import { Component, Input, OnInit } from '@angular/core';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { FotogalerieBarrel } from '@nx-monorepo/node-frontend';
import { BaseComponent } from '../base.component';
import { HeadingProps } from '../heading/heading.props';
import { ObecService, ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'macms-fotogalerie',
  templateUrl: './fotogalerie.component.html',
  styleUrls: ['./fotogalerie.component.scss'],

})
export class FotogalerieComponent extends BaseComponent implements IDynamicComponent, OnInit {
  @Input() data: any;
  @Input() settings: FotogalerieBarrel.FotogalerieProps;

  headingProps: HeadingProps;

  ngOnInit(): void {
    if(!this.settings.headingProps){
       this.headingProps = {
         font: this.obecSettings.title_font,
         primaryColor: this.settings.primaryColor ? this.settings.primaryColor : this.obecSettings.primary_color,
         isInverted: true
       }
    } else {
      this.headingProps = this.settings.headingProps;
    }
  }

  constructor(public colorUtilsService: ColorUtilsService, protected obecService: ObecService) {
    super(colorUtilsService, obecService);
  }

  getComponentName() {
    return FotogalerieBarrel.name;
  }
}
