import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderModule } from './components/header';
import { NavigaceModule } from './components/navigace';
import { PrispevkyModule } from './components/prispevky';
import { UdalostiModule } from './components/udalosti';
import { UredniDeskyModule } from './components/uredni-desky';
import { FotogalerieModule } from './components/fotogalerie';
import { FooterModule } from './components/footer';
import { NewsletterModule } from './components/newsletter';
import { NgSharedModule } from '@nx-monorepo/ng-shared';
import { HeadingModule } from './components/heading/heading.module';
import { WysiwygModule } from './components/wysiwyg/wysiwyg.module';
import { InfoModule } from './components/info';
import { SvatekModule } from './components/svatek/svatek.module';
import { BaseComponent } from './components/base.component';
import { KontaktModule } from './components/kontakt';


@NgModule({
  declarations: [
    BaseComponent
  ],
  imports: [
    CommonModule,
    HeaderModule,
    NavigaceModule,
    KontaktModule,
    HeadingModule,
    InfoModule,
    PrispevkyModule,
    WysiwygModule,
    UdalostiModule,
    UredniDeskyModule,
    FotogalerieModule,
    FooterModule,
    NewsletterModule,
    NgSharedModule,
    SvatekModule
  ],
})
export class ObceComponentsModule {
  constructor() {
    console.log('ObceComponents Compiled');
  }
}
