import { IPkDialogData, IPkFormDialogCompanion } from '../../../pk-dialog/pk-dialog-data';
import { Validators } from '@angular/forms';
import { getDefaultDialogButtons, getNazevSettings } from '../common-settings-objects';

import { IPkInputCheckboxFieldProps } from '../../../pk-input-fields/pk-input-checkbox-field/pk-input-checkbox-field.props';
import { IPkSelectFieldProps } from '../../../pk-input-fields/pk-select-field/pk-select-field.props';
import { IPkInputTextFieldProps } from '../../../pk-input-fields/pk-input-text-field/pk-input-text-field.props';
import { IPkColorPickerProps } from '../../../pk-input-fields/pk-color-picker-field/pk-color-picker-field.props';
import { IPkInputEntityFieldProps } from '../../../pk-input-fields/pk-input-entity-field/pk-input-entity-field.props';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { NavigaceBarrel } from '@nx-monorepo/node-frontend';

export class PkGridsterItemNavigaceDialogCompanion implements IPkFormDialogCompanion {
  getDialogData(settings: NavigaceBarrel.ItemNavProps, data: {}, emitters: {dialogSubmit, dialogClose}) {
    const dialogData: IPkDialogData = {
      title: 'Upravit navigaci',
      components: [
        getNazevSettings(settings.nazev),
        {
          componentName: 'PkSelectFieldComponent',
          settings: {
            formControlName: 'id',
            type: 'number',
            nazev: 'Id',
            isMultiple: false,
            povinnost: true,
            napoveda: {
              isIcon: false,
              text: 'Zadejte ID existující navigace'
            },
            defaultValue: settings.id ? settings.id : ''
          } as IPkSelectFieldProps,
          data: data,
          validators: [Validators.required],
          cols: 4,
          x: 0,
          y: 0
        },
        {
          componentName: 'PkInputTextFieldComponent',
          settings: {
            defaultValue: settings.itemFlexBasis ? settings.itemFlexBasis : '',
            isTextArea: false,
            formControlName: 'itemFlexBasis',
            type: 'text',
            nazev: 'Šířka položky',
            povinnost: false,
            napoveda: {
              isIcon: false,
              text: 'Zadejte šířku položky jako celé číslo'
            },
          } as IPkInputTextFieldProps,
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 1
        },
        {
          componentName: 'PkInputTextFieldComponent',
          settings: {
            defaultValue: settings.itemGap ? settings.itemGap : '',
            isTextArea: false,
            formControlName: 'itemGap',
            type: 'text',
            //todo: tu naozaj neviem názov
            nazev: 'Mezera mezi prvky',
            povinnost: false,
            napoveda: {
              isIcon: false,
              text: 'Zadejte odsazení prvku jako celé číslo'
            },
          } as IPkInputTextFieldProps,
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 2
        },
        {
          componentName: 'PkInputBoxFieldComponent',
          settings: {
            nazev: 'Odsazení prvku',
            formControlName: 'itemPadding',
            povinnost: false,
            napoveda: {
              isIcon: false,
              text: 'Zadejte celé číslo',
            },
            //todo: should be empty object with four vars
            defaultValue: settings.itemPadding ? settings.itemPadding : null,
          },
          data: null,
          validators: [Validators.pattern(/^\d+$/)],
          cols: 4,
          x: 0,
          y: 3,
        },
        {
          componentName: 'PkInputBoxFieldComponent',
          settings: {
            nazev: 'Odsazení ikony',
            formControlName: 'iconPadding',
            povinnost: false,
            napoveda: {
              isIcon: false,
              text: 'Zadejte celé číslo',
            },
            defaultValue: settings.iconPadding ? settings.iconPadding : null,
          },
          data: null,
          validators: [Validators.pattern(/^\d+$/)],
          cols: 4,
          x: 0,
          y: 4,
        },
        {
          componentName: 'PkInputCheckboxFieldComponent',
          settings: {
            povinnost: true,
            nazev: 'Zalomenie',
            formControlName: 'wrapSublinks',
            defaultValue: settings.wrapSublinks ? settings.wrapSublinks : false,
          } as IPkInputCheckboxFieldProps,
          data: null,
          validators: [Validators.required],
          cols: 2,
          x: 0,
          y: 5,
        },
        {
          componentName: 'PkInputCheckboxFieldComponent',
          settings: {
            povinnost: true,
            nazev: 'Ukázat popisek',
            formControlName: 'showPopis',
            defaultValue: settings.showPopis ? settings.showPopis : false,
          } as IPkInputCheckboxFieldProps,
          data: null,
          validators: [Validators.required],
          cols: 2,
          x: 0,
          y: 6,
        },
        {
          componentName: 'PkColorPickerFieldComponent',
          settings: {
            showAlways: false,
            povinnost: false,
            type: 'text',
            nazev: 'Sekundární barva',
            formControlName: 'secondaryColor',
            defaultValue: settings.secondaryColor ? settings.secondaryColor : '',
          } as IPkColorPickerProps,
          data: null,
          validators: [],
          cols: 2,
          x: 0,
          y: 7,
        },
        {
          componentName: 'PkColorPickerFieldComponent',
          settings: {           
            showAlways: false,            
            povinnost: false,
            type: 'text',
            nazev: 'Accent color',
            formControlName: 'accentColor',
            defaultValue: settings.accentColor ? settings.accentColor : '',
          } as IPkColorPickerProps,
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 8,
        },
        {
          componentName: 'PkInputEntityFieldComponent',          
          settings: {
            povinnost: false,
            isMultiple: false,
            withPreview: true,
            entityType: EntityTypes.Media,
            nazev: 'Pozadí obsahu',
            formControlName: 'contentBackground',
            defaultValue: settings.contentBackground ? settings.contentBackground : null,
          } as IPkInputEntityFieldProps,
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 9,
        },
        {
          componentName: 'PkInputEntityFieldComponent',
          settings: {
            nazev: 'Pozadí sekce',
            povinnost: false,
            isMultiple: false,
            withPreview: true,
            entityType: EntityTypes.Media,
            formControlName: 'sectionBackground',
            defaultValue: settings.sectionBackground ? settings.sectionBackground : null,
          } as IPkInputEntityFieldProps,
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 10,
        },
        {
          componentName: 'PkInputCheckboxFieldComponent',
          settings: {
            povinnost: true,
            nazev: 'Použít jinou barvu pro navigaci',
            formControlName: 'useNavItemsColors',
            defaultValue: settings.useNavItemsColors ? settings.useNavItemsColors : false,
          } as IPkInputCheckboxFieldProps,
          data: null,
          validators: [Validators.required],
          cols: 2,
          x: 0,
          y: 11,
        },
        {
          //todo: picker z ikon?
          componentName:'PkInputTextFieldComponent',
          settings: {
            isTextArea: false,
            povinnost: false,
            nazev: 'Přepsat ikonu',
            formControlName: 'overrideIcon',
            defaultValue: settings.overrideIcon ? settings.overrideIcon : '',
            napoveda: {
              isIcon: false,
              text: 'Vyberte ikonu navigace (prázdné pole = žádná ikona)'
            }
          } as IPkInputTextFieldProps,
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 12,
        },
        {
          componentName: 'PkInputBoxFieldComponent',
          settings: {
            nazev: 'Odsazení sekce',
            formControlName: 'sectionPadding',
            povinnost: false,
            napoveda: {
              isIcon: false,
              text: 'Zadejte celé číslo',
            },
            defaultValue: settings.sectionPadding ? settings.sectionPadding : null,
          },
          data: null,
          validators: [Validators.pattern(/^\d+$/)],
          cols: 4,
          x: 0,
          y: 13,
        },
        {
          componentName: 'PkSelectFieldComponent',
          settings: {
            povinnost: true,
            nazev: 'Pozice ikony',
            isMultiple: false,
            formControlName: 'iconPosition',
            defaultValue: settings.iconPosition ? settings.iconPosition : 'left',
          } as IPkSelectFieldProps,
          data: [
            {
              id: 'left',
              nazev: 'Vpravo'
            },
            {
              id: 'right',
              nazev: 'Vlevo'
            }
          ],
          validators: [Validators.required],
          cols: 1,
          x: 0,
          y: 14,
        },
        {
          componentName: 'PkInputTextFieldComponent',
          settings: {
            povinnost: false,
            isTextArea: false,
            formControlName: 'otherIcon',
            type: 'text',
            nazev: 'Další ikona',
            defaultValue: settings.otherIcon ? settings.otherIcon : false,
          } as IPkInputTextFieldProps,
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 15,
        },
        {
          componentName: 'PkInputCheckboxFieldComponent',
          settings: {
            povinnost: false,
            nazev: 'Invertovaná ikona',
            formControlName: 'isIconInverted',
            defaultValue: settings.isIconInverted ? settings.isIconInverted : false,
          } as IPkInputCheckboxFieldProps,
          data: null,
          validators: [],
          cols: 2,
          x: 0,
          y: 16,
        }, 
        // {
        //   itemBorder?: BoxSides<Border>
        // },
        {
          componentName: 'PkInputBoxFieldComponent',
          settings: {
            nazev: 'Odsazení sekce',
            formControlName: 'sectionPadding',
            povinnost: false,
            napoveda: {
              isIcon: false,
              text: 'Zadejte celé číslo',
            },
            defaultValue: settings.sectionPadding ? settings.sectionPadding : null,
          },
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 17,
        },
        {
          componentName: 'PkSelectFieldComponent',
          settings: {
            povinnost: false,
            nazev: 'Zarovnání textu',
            formControlName: 'textAlign',
            isMultiple: false,
            defaultValue: settings.textAlign ? settings.textAlign : 'left'
          } as IPkSelectFieldProps,
          data: [
            {
              id: 'left',
              nazev: 'Vlevo'
            },
            {
              id: 'center',
              nazev: 'Na střed'
            }, 
            {
              id: 'right',
              nazev: 'Vpravo'
            }
          ],
          validators: [],
          cols: 2,
          x: 0,
          y: 18,
        }, 
        // {
        //   headingProps
        //  button? na PkGridsterItemHeaderDialogCompanion
        // }
      ],
      buttons: getDefaultDialogButtons(emitters)
    };
    return dialogData;
  }
}
