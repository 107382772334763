import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { IPkService } from './i-pk-service';
import { Observable } from 'rxjs';
import { FetchResult } from 'apollo-link';
import { readAllVstupnipoleQuery } from '@nx-monorepo/obce/common/queries';
import { deleteVstupnipole, addVstupniPole } from '@nx-monorepo/obce/common/mutations'

@Injectable({
  providedIn: 'root'
})
export class VstupniPoleService implements IPkService {

  constructor(private apollo: Apollo) {
  }

  fetchAll(vars: object) {
    return this.apollo.query({
      query: readAllVstupnipoleQuery,
      variables: vars
    });
  }

  fetchSingle(id: number) {
    return null;
  }

  save(vars: object): Observable<FetchResult<any>> {
    return this.apollo.mutate({
      mutation: addVstupniPole,
      variables: vars
    });
  }


  delete(vars: object): Observable<FetchResult<any>> {
    return this.apollo.mutate({
      mutation: deleteVstupnipole,
      variables: vars
    });
  }

  fetchSelected(vars: number[]): Observable<any[]> {
    return undefined;
  }
}
