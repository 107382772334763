
export enum EntityTypes {
  // Categorized Collections
  Evidence = 'Evidence',
  Formular = 'Formular',
  PlatebniPrikaz = 'PlatebniPrikaz',
  Prispevek = 'Prispevek',
  Udalost = 'Udalost',
  UredniDeska = 'UredniDeska',
  Media = 'Media',
  Navigace = 'Navigace',
  Historie = 'Historie',

  // Generic Collections
  Uzivatel = 'Uzivatel',
  Stranka = 'Stranka',
  StrankaObsah = 'StrankaObsah',
  Galerie = 'Galerie',
  // Uzivatel = 'Uzivatel',

  Faktura = "Faktura",
  Kontakt = "Kontakt",
  Owner = "Owner",
  Kategorie = "Kategorie",

  Sms = 'Sms',
  Email = 'Email',
  Transakce = 'Transakce'
}


