import gql from 'graphql-tag';

export const readAllNavigaceQuery = gql`
  query ReadAllNavigace($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $categories: [Int!]) {
    response: readAllNavigace(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      categories: $categories
    ) {
      total
      page
      take
      hasMore
      items {
        id
        nazev
        
        created_at
      }
    }
  }
`;
