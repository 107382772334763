<div id="login" fxLayout="column">
  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="passChangeForm" [@animate]="{value:'*',params:{duration:'600ms',y:'500px'}}" class="fuse-card">

      <div class="logo">
        <img src="assets/images/logos/echopix_cms_logo_rect.svg">
      </div>

      <div class="title">Nastavte si heslo</div>
      <div class="my-16 fuse-card warn-100 mx-auto" *ngIf="errorMessage">
        <div class="p-16" fxLayout="row" fxLayoutAlign="start center">
          <fa-icon size="2x" icon="exclamation-circle"></fa-icon>
        </div>
      </div>
      <form name="passChangeForm" (ngSubmit)="onSubmit()" [formGroup]="passChangeForm" novalidate >

        <mat-form-field appearance="outline">
          <mat-label>Aktuální heslo</mat-label>
          <input [value]="passwordCurrent" matInput type="password" formControlName="passwordCurrent" [ngModel]="passwordCurrent">
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
          <mat-error>
            Heslo je vyžadováno a musí mít alespoň 6 znaků.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Nové heslo</mat-label>
          <input [value]="passwordNew" matInput type="password" formControlName="passwordNew" [ngModel]="passwordNew">
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
          <mat-error>
            Heslo je vyžadováno a musí mít alespoň 6 znaků.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Potvrzení nového hesla</mat-label>
          <input [value]="passwordConfirm" matInput type="password" formControlName="passwordConfirm" [ngModel]="passwordConfirm">
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
          <mat-error>
            Hesla nejsou totožná!
          </mat-error>
        </mat-form-field>


        <button mat-raised-button color="accent" class="submit-button" type="submit" aria-label="Odeslat" [disabled]="passChangeForm.invalid">
          Odeslat
        </button>

      </form>
    </div>

  </div>
</div>
