import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BasePageComponent } from '../base-page.component';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { KolekceService, ObecService } from '@nx-monorepo/obce/ng/services';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { LayoutActionTypes } from '@nx-monorepo/cms-base/store/layout/layout.actions';
import { displaySnack } from '@nx-monorepo/cms-base/helpers';

@Component({
  selector: 'pk-owner-settings-page',
  templateUrl: './pk-owner-settings-page.component.html',
  styleUrls: ['./pk-owner-settings-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PkOwnerSettingsPageComponent extends BasePageComponent implements OnInit, OnDestroy, AfterViewInit {
  editorOptions: JsonEditorOptions;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('editor',{static: true})  editor: JsonEditorComponent;

  editorData: any;
  originalData: any;
  showData: any;
  settingsPromise: Promise<any>;

  constructor(store$: Store<MACMSStoreState.State>,
              route: ActivatedRoute,
              private kolekceService: KolekceService,
              private fb: FormBuilder,
              private _fuseProgressBarService: FuseProgressBarService,
              private obecService: ObecService,
              private actionsSubject$: ActionsSubject) {
    super(store$, route);
  }

  ngOnInit(): void {
    this.originalData = {};
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.language = 'cs-CZ';
    this.editorOptions.modes = ['tree','code'];
    this.editorData = {loading: 'LOADING'};


    //TODO PRASOKOD, udelano narychlo, bastl, fuj, hnus, Truly Marecek, WOW
    const lastSegment = this.currentRoute.snapshot.url[this.currentRoute.snapshot.url.length-1];

    if(lastSegment.path === 'front'){
      this.obecService.getMainPagePromise().then(mainPage =>{
        this.originalData = mainPage;
        this.editorData = this.originalData.obsah.map(obsah => {
          return obsah.options;
        });
      });
    } else {
      this.settingsPromise = this.obecService.getObceSettingsPromise(this.obecService.getObecPromise()).then(settings =>{
        settings.header = JSON.parse(settings.header);
        settings.footer = JSON.parse(settings.footer);
        this.editorData = settings;
      });
    }

    this.subscribeToSaveButtonAction(lastSegment.path === 'front');

  }

  private subscribeToSaveButtonAction(isFrontPage = false) {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === LayoutActionTypes.SAVE_BUTTON_PRESSED)
    ).subscribe(action => {
      isFrontPage ? this.saveFrontPageSettings() : this.saveOwnerSettings();
    });
  }

  changeLog(event = null) {
    this.showData = this.editor.get();
  }

  saveFrontPageSettings(){
    const editorContents = this.editor.get() as unknown as [];
    const resultStranka = this.originalData;
    editorContents.forEach((val, idx)=>{
      resultStranka.obsah[idx].data = undefined;
      resultStranka.obsah[idx].options = JSON.stringify(val);
    });
    console.log('resultStranka',resultStranka);
    this.kolekceService.save(EntityTypes.Stranka, { input: [resultStranka] }).toPromise().then(resp => {
      console.log(resp);
    })
    .catch(err => console.error("Chyba ukládání nastavení přední strany! " + err));
  }

  saveOwnerSettings(){
    const editorContents: any = this.editor.get();
    // change headerProps to String
    if(editorContents.inner_settings.headingProps && typeof editorContents.inner_settings.headingProps === 'object'){
      editorContents.inner_settings.headingProps = JSON.stringify(editorContents.inner_settings.headingProps);
    }
    this.obecService.updateOwnerSettings(JSON.stringify(editorContents))
    .then(resp => {
      displaySnack("Nastavení uloženo", this.store$)
      console.log(resp);
    })
    .catch(err => console.error("Chyba ukládání nastavení! " + err));
  }

  public ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this._fuseProgressBarService.hide();
  }
}
