<ng-container>
  <form [formGroup]="entityForm">
    <div
      [style.grid-row-start]="1"
      [style.grid-column-start]="1"
      [style.grid-column-end]="4"
    >
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Načtení dle IČO z ARESu</mat-label>
        <input #ares matInput type="text"/>
        <mat-icon matSuffix matTooltip="Systém umí zkusit vyhledat IČO (bez mezer) v ARESu a automaticky vše vyplnit">info</mat-icon>
      </mat-form-field>
    </div>
    <div
      [style.grid-row-start]="1"
      [style.grid-column-start]="4"
      [style.grid-column-end]="5">
      <button mat-flat-button color="accent" (click)="onFindClicked()">Vyhledat</button>
    </div>
    <div
      *ngFor="let field of entityDefinitions"
      [ngClass]="field.componentName === 'PkHiddenFieldComponent' ? 'hidden-field' : ''"
      [style.grid-row-start]="field.y + 2"
      [style.grid-column-start]="field.x + 1"
      [style.grid-column-end]="field.x + 1 + field.cols"
    >
      <ng-template
        cmsComponentFactory
        [componentName]=field.componentName
        [settings]=field.settings
        [data]="field.data">
      </ng-template>
    </div>
  </form>

  <p>
    Form Status: {{ entityForm.status }}
  </p>
</ng-container>
