import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { IPkColorPickerProps } from './pk-color-picker-field.props';


@Component({
  selector: 'pk-color-picker-field',
  templateUrl: './pk-color-picker-field.component.html',
  styleUrls: ['./pk-color-picker-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PkColorPickerFieldComponent implements OnInit {

  @Input()
  settings: IPkColorPickerProps;

  @Input()
  data: any;

  showPicker = false;

  ngOnInit(): void {

  }

  colorChanged({color}): void {
    const { hex } = color;
    console.log('color changed', hex);
    this.settings.form.get(this.settings.formControlName).setValue(hex);
  }
}

