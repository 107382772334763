import { Component, Input, OnInit } from '@angular/core';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faCalendar, faAirFreshener } from '@fortawesome/free-solid-svg-icons';
import { UredniDeskyBarrel } from '@nx-monorepo/node-frontend';
import { TwoColumnUredniDeskyProps } from './two-column.props';
import { BaseComponent } from '../../base.component';
import { HeadingProps } from '../../heading/heading.props';
import { NextButtonProps } from '../../next-button/next-button.props';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { BoxSides } from 'libs/ng-shared/src/lib/utils/borders/border-definition';
import { ObecService, ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@Component({
  selector: 'macms-two-column-uredni-desky',
  templateUrl: './two-column.component.html',
  styleUrls: ['./two-column.component.scss']
})
export class TwoColumnUredniDeskyComponent extends BaseComponent implements IDynamicComponent, OnInit {
  @Input() data: any;
  @Input() settings: TwoColumnUredniDeskyProps;

  rightColPadding: BoxSides<string>;
  leftColPadding: BoxSides<string>;

  primaryColor;

  nextButtonSettings: NextButtonProps;
  headingSettings: HeadingProps;

  constructor(public colorUtilsService: ColorUtilsService, protected obecService: ObecService) {
    super(colorUtilsService, obecService);
  }

  downloadIcon = faDownload;
  calendarIcon = faCalendar;


  ngOnInit(): void {
    this.primaryColor = this.settings.primaryColor;
    if(this.settings.headingProps){
      console.log(this.settings.headingProps);
      this.headingSettings = this.settings.headingProps;
    } else {
      this.headingSettings = {
        icon: 'newspaper',
        iconPosition: 'start',
        isInverted: true,
        boxMargin: {top: 'auto', bottom: 'auto', left: 'auto', right: 'auto'},
        primaryColor: this.primaryColor,
        font: this.obecSettings.title_font
      };
    }

    this.nextButtonSettings = {
      primaryColor: this.primaryColor,
      fontTitle: this.obecSettings.title_font,
      bottomPaddingEm: 2,
      topPaddingEm: 2,
    };
    this.generateColors();
    // todo demo
    if (this.settings.icon === 'faDownload') {
      this.downloadIcon = faDownload;
    } else if (this.settings.icon === 'faAirFreshener') {
      this.downloadIcon = faAirFreshener;
    }



    if (this.settings.rightColPadding){
      this.rightColPadding = this.settings.rightColPadding;
    } else {
      this.rightColPadding = BoxSides.applySideToAll("1rem")
    }

    if (this.settings.leftColPadding){
      this.leftColPadding = this.settings.leftColPadding;
    } else {
      this.leftColPadding = BoxSides.applySideToAll("1rem")
    }
  }



  getComponentName(): string {
    return UredniDeskyBarrel.name_two_column;
  }
}
