import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KontaktComponent } from './kontakt.component';
import { HeadingModule } from '../heading/heading.module';
import { UtilsModule } from '../../utils/utils.module';



@NgModule({
  declarations: [KontaktComponent],
  exports: [KontaktComponent],
  entryComponents: [KontaktComponent],
  imports: [
    CommonModule,
    HeadingModule,
    UtilsModule
  ]
})
export class KontaktModule { }
