<div  class="page-layout carded fullwidth inner-scroll">
    <!-- TOP BACKGROUND -->
    <div class="top-bg fuse-navy-700"> </div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- HEADER -->
        <header
            class="header fuse-navy-700"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="space-between center"
        >
            <!-- APP TITLE -->
            <div
                class="logo mb-24 mb-md-0"
                fxLayout="row"
                fxLayoutAlign="start center"
            >
              <ng-container *ngIf="(pageIcon$ | async).url as url">
                <a (click)="iconClick(url)" >
                  <mat-icon
                    class="logo-icon s-32 mr-16"
                    [@animate]="{
                        value: '*',
                        params: { delay: '50ms', scale: '0.2' }
                    }"
                  >
                    {{ (pageIcon$ | async).nazev }}
                  </mat-icon>
                </a>
              </ng-container>
              <ng-container *ngIf="!(pageIcon$ | async).url">
                <mat-icon
                  class="logo-icon s-32 mr-16"
                  [@animate]="{
                        value: '*',
                        params: { delay: '50ms', scale: '0.2' }
                    }"
                >
                  {{ (pageIcon$ | async).nazev }}
                </mat-icon>
              </ng-container>

                <span
                    class="logo-text h1"
                    [@animate]="{
                        value: '*',
                        params: { delay: '100ms', x: '-25px' }
                    }"
                >
                    {{ pageName$ | async }}
                </span>
            </div>
            <!-- / APP TITLE -->

            <!-- SEARCH -->
            <div *ngIf="searchVisibility$ | async" class="search-wrapper mx-32 mx-md-0 fuse-white-500">
                <div
                    class="search"
                    fxFlex
                    fxLayout="row"
                    fxLayoutAlign="start center"
                >
                    <mat-icon>search</mat-icon>
                    <input #filter placeholder="Vyhledat" />
                </div>
            </div>
            <!-- / SEARCH -->

            <!-- ADD BUTTON -->
            <pk-page-action-buttons>

            </pk-page-action-buttons>
            <!-- / ADD BUTTON -->
        </header>
        <!-- / HEADER -->
    </div>

</div>
