import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import 'core-js/es7/reflect';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { CategoryTabsModule, MACMSStoreModule } from '@nx-monorepo/cms-base/store';
import { ApolloLink } from 'apollo-link';
import { LayoutModule } from './layout/layout.module';
import { MacmsModule } from './components/macms.module';
import { AuthService } from './services/auth.service';
import { UploadService } from './services/upload.service';

@NgModule({
  imports: [
    BrowserModule,
    ApolloModule,
    //StoreDevtoolsModule.instrument({ maxAge: 5 }),
    HttpLinkModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,
    BrowserAnimationsModule,
    // Material
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,


    // Fuse modules
    FuseModule.forRoot({}),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    FuseProgressBarModule,
    MACMSStoreModule,
    // App modules
    CategoryTabsModule,
    LayoutModule,
    MacmsModule
  ],
  exports:[
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    FuseProgressBarModule,
    // App modules
    MACMSStoreModule,
    LayoutModule,
    MacmsModule,
  ],
  providers: [
    AuthService,
    UploadService,
    MatSnackBar,
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        const http = httpLink.create({ uri: '/graphql' });
        const auth = new ApolloLink((operation, forward) => {
          /*operation.setContext({
            headers: {
              authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvYmVjX2lkIjoiMSIsInJvbGUiOlsiQURNSU4iLCJTU1IiXSwiaWF0IjoxNTY1ODU0Mjg4fQ.qb_sCIN4-jeLofSBB_8Ty-5m11etW8NUh9JB925zDfw`
            }
          });*/
          return forward(operation);
        });
        const link = ApolloLink.from([auth, http]);
        return {
          cache: new InMemoryCache({
            addTypename: false
          }),
          link: link,
          defaultOptions: {
            query: {
              fetchPolicy: 'no-cache'
            }
          }
        };
      },
      deps: [HttpLink]
    }
  ],
})
export class CmsModule {
}
