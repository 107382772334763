import { SnackbarStoreActions } from '../store/snackbar';
import { MACMSStoreState } from '../store';
import { Store } from '@ngrx/store';

export const displaySnack = (message: string, store: Store<MACMSStoreState.State>, duration = 2500, action = 'OK') => {
    store.dispatch(new SnackbarStoreActions.SnackbarActionOpen({
      message: message,
      action: action,
      config: { duration: duration }
    }));
  };