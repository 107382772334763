<div [formGroup]="settings.form">
  <h3>{{ settings.nazev }}<span *ngIf="settings.povinnost" class="required"> *</span></h3>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info</mat-icon>
  <p *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</p>
  <div id="editor"></div>
<ckeditor
    [editor]="editor"
    [config]="config"
    [formControlName]="settings.formControlName"
    [data]="data"
    (ready)="onReady($event)"
  >
  </ckeditor>
</div>


