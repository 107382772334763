import { Injectable, InjectionToken } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { FetchResult } from 'apollo-link';
import {readAllAssignableRoleQuery } from '@nx-monorepo/obce/common/queries';
import { IPkService } from './i-pk-service';



export const ROLE_SERVICE_TOKEN = new InjectionToken<IPkService>('RoleService');

@Injectable({
  providedIn: 'root'
})
export class RoleService implements IPkService {

  constructor(private apollo: Apollo) {
  }

  fetchAll(vars: object) {
    return this.apollo.query({
      query: readAllAssignableRoleQuery,
      variables: vars
    });
  }

  fetchSingle(vars: number) {
    return null;
  }

  createAll(vars: object): Observable<FetchResult<any>> {
    return undefined;
  }

  save(vars: object): Observable<FetchResult<any>> {
    return undefined;
  }

  fetchSelected(vars: number[]): Observable<any[]> {
    return undefined;
  }


}
