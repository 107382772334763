<div class="navbar-header fuse-navy-700">

    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/echopix_logo.svg" />
        <span class="logo-text">EchoCMS</span>
    </div>

    <button mat-icon-button class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()" fxHide.lt-md>
        <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()" fxHide.gt-sm>
        <mat-icon>arrow_back</mat-icon>
    </button>

</div>

<div class="navbar-content fuse-navy-700" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
    <fuse-navigation layout="vertical"></fuse-navigation>
</div>
