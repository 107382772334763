import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavigaceModule } from '../navigace';
import { SearchbarModule } from '../searchbar/searchbar.module';
import { UtilsModule } from '../../utils/utils.module';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SvatekModule } from '../svatek/svatek.module';
import { KontaktModule } from '../kontakt';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, NavigaceModule, SearchbarModule, UtilsModule, MatIconModule, RouterModule, SvatekModule, KontaktModule],
  declarations: [HeaderComponent],
  entryComponents: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {}


