<ng-container *ngIf="!(hideCategories$ | async)">
  <nav mat-tab-nav-bar class="pk-navbar-tabs">
      <a
        mat-tab-link
        *ngFor="let tab of categories$ | async; let i = index"
        (click)="tabClicked(i, tab.nazev)"
        [active]="(activeCategory$ | async) === tab"
      >
        {{ tab.nazev }}
      </a>
      <a
        mat-tab-link
        class="add-button"
        *ngIf="(hasAddButton$ | async)"
        (click)="createClicked()"
        ><mat-icon>add</mat-icon></a>
  </nav>
</ng-container>
