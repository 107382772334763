<ng-container *ngFor="let group of headingGroups">
  <h2>{{group.title}}</h2>
  <p>{{group.popis}}</p>
  <ng-container *ngFor="let heading of group.children">
    <p>{{heading.popis}}</p>
    <macms-heading
      [settings]="heading.props"
      [text]="'TEST'">
    </macms-heading>
  </ng-container>
</ng-container>
