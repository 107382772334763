import { Pipe, PipeTransform } from '@angular/core';
import { FormatPipeProps } from './format-pipe.props';
import  * as moment from 'moment';

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  transform(value: any, settings?: FormatPipeProps): any {
    switch (settings.method) {
      case 'date':
        return moment(value).format(settings?.format || 'DD.MM.YYYY HH:mm');
      case 'money':
        return this.makeItRain(value);
      default:
        return value;
    }
  }

  makeItRain(value: string){
    const formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: 'CZK',
    });
    return formatter.format(Number(value)/100.0);
  }

}
