import gql from 'graphql-tag';

export const readAllUdalostQuery = gql`
  query ReadAllUdalost($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $categories: [Int!], $where: [Where!]) {
    response: readAllUdalost(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      categories: $categories,
      where: $where,
    ) {
      total
      page
      take
      hasMore
      items {
        id
        kategorie {
          id
          nazev
        }
        slug
        featured
        autor {
          jmeno
          prijmeni
        }
        media{
          id
          url
          mime
          popis
        }
        nazev
        popis
        datum_konec
        datum_zacatek
      }
    }
  }
`;
