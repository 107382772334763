import { Component, Input } from '@angular/core';
import { NextButtonProps } from './next-button.props';

@Component({
  selector: 'macms-next-button',
  templateUrl: './next-button.component.html',
  styleUrls: ['./next-button.component.scss']
})
export class NextButtonComponent {
  @Input() settings: NextButtonProps;
  @Input() text: string;
  @Input() href: string;
}
