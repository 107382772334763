import { Component, } from '@angular/core';
import { BasePageComponent } from '../base-page.component';
import { Store } from '@ngrx/store';
import { ActivatedRoute} from '@angular/router';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';

@Component({
  selector: 'pk-media-page',
  templateUrl: './pk-media-page.component.html',
  styleUrls: ['./pk-media-page.component.scss']
})
export class PkMediaPageComponent extends BasePageComponent {

  constructor(store$: Store<MACMSStoreState.State>, route: ActivatedRoute) {
    super(store$, route);
  }

}
