import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule, GridModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeadingComponent } from './heading.component';
import { UtilsModule } from '../../utils/utils.module';
import { TestHeadingComponent } from './test-heading/test-heading.component';
import { ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@NgModule({
  imports: [CommonModule, GridModule, FlexModule, FontAwesomeModule, MatMenuModule, RouterModule, MatIconModule, MatButtonModule, BrowserAnimationsModule, UtilsModule],
  declarations: [HeadingComponent, TestHeadingComponent],
  entryComponents: [HeadingComponent, TestHeadingComponent],
  providers: [ColorUtilsService],
  exports: [HeadingComponent]
})
export class HeadingModule {}

