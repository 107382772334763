import gql from 'graphql-tag';

export const readSelectedFormularQuery = gql`
  query ReadSelectedFormular($ids: [Int!]!) {
    response: readSelectedFormular(
      ids: $ids
    ) {
      id
      stav
      pole{
        id
        data
        vstupni_pole_id
      }
      kategorie {
        id
      }
      historie{
        id
        stav
        text
        created_at
      }
      pdf_uuid
      autor_id
    }
  }
`;
