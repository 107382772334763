import { IColumnDefinition } from '@nx-monorepo/cms-base/interfaces';

export const emailOdeslaneColumnDefinitions: IColumnDefinition[] = [
  {
    title: 'ID',
    key: ['id'],
    type: 'string',
    sortable: true,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
      title: 'Předmět',
      key: ['predmet'],
      type: 'string',
      sortable: true,
      align: 'left',
      fxHide: {
          sm: true,
          md: false,
          lg: false,
      }
  },
  {
      title: 'Datum odeslání',
      key: ['datum_odeslani'],
      type: 'date',
      sortable: true,
      align: 'left',
      shrink: true,
      fxHide: {
          sm: true,
          md: false,
          lg: false,
      }
  },
  {
    title: 'Počet adresátů',
    key: ['count'],
    type: 'string',
    sortable: true,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: true,
      md: false,
      lg: false,
    }
  },
  {
    title: 'Autor',
    key: ['autor', 'jmeno'],
    type: 'string',
    sortable: true,
    align: 'left',
    nullValue: 'Systém',
      fxHide: {
        sm: false,
        md: false,
        lg: false,
    }
  }
]
