import gql from 'graphql-tag';

export const readAllPrispevekQuery = gql`
  query ReadAllPrispevek($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $categories: [Int!]) {
    response: readAllPrispevek(
      search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      categories: $categories
    ) {
      total
      page
      take
      hasMore
      items {
        id
        slug
        kategorie {
          id
          nazev
          color
        }
        autor {
          jmeno
          prijmeni
          owner {
            nazev
          }
        }
        nazev
        obsah
        datum_zverejneni
        featured
        media{
          url
          mime
        }
        uvod
        priloha {
          id
          url
          nazev
        }
      }
    }
  }
`;
