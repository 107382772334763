import { FuseConfig } from '@fuse/types';
import { MacmsConfig } from '../../../../../../libs/cms-base/src/lib/helpers/MacmsConfig';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig: MacmsConfig = {
    // Color themes can be defined in src/app/app.theme.scss
    colorTheme: 'theme-restaurus',
    customScrollbars: true,
    layout: {
        style: 'vertical-layout-1',
        width: 'fullwidth',
        navbar: {
            primaryBackground: 'fuse-navy-700',
            secondaryBackground: 'fuse-navy-900',
            folded: true,
            hidden: false,
            position: 'left',
            variant: 'vertical-style-2'
        },
        toolbar: {
            customBackgroundColor: false,
            background: 'fuse-white-500',
            hidden: false,
            position: 'below-fixed'
        },
        footer: {
            customBackgroundColor: true,
            background: 'fuse-navy-900',
            hidden: false,
            position: 'below-fixed'
        },
        sidepanel: {
            hidden: false,
            position: 'right'
        },
      header:{
          hidden: false
      },
      content: {
          carded: true
      }
    }
};
