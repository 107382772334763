import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { mediaReducer } from './media.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MediaStoreEffects } from './media.effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('media', mediaReducer),
        EffectsModule.forFeature([MediaStoreEffects])
    ]
})
export class MediaStoreModule {}
