import { Directive, OnInit, TemplateRef } from '@angular/core';
import { InjectableToolbarService } from './toolbar.service';

@Directive({
    selector: '[injectableToolbar]'
})
export class InjectableToolbarDirective implements OnInit {
    constructor(
        private injectableToolbar: InjectableToolbarService,
        private ref: TemplateRef<any>
    ) {}

    ngOnInit(): void {
        console.log('injectableToolbar inited');
        // this.injectableToolbar.setContents(this.ref);
    }
}
