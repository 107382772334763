import gql from 'graphql-tag';

export const addFormular = gql`
    mutation addFormular($inputFormular: [FormularInput!]!){
      response: addFormular(inputFormular: $inputFormular){
        id
        stav
      }
    }
`;
