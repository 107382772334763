import gql from 'graphql-tag';

export const readAllSmsQuery = gql`
  query ReadAllSmsQuery($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $categories: [Int!]) {
    response: readAllSms(search: $search
      take: $take
      page: $page
      sortColumn: $sortColumn
      sortDirection: $sortDirection,
      categories: $categories
    ) {
      total
      page
      take
      hasMore
      items {
        id
        text
        prijemci
        datum_odeslani
        count
        autor {
          id
          jmeno
          prijmeni
        }
      }
    }
  }
`
