import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PageNotFoundComponent } from './404/page-not-found.component';
import { NoCategoryComponent } from './no-category/no-category.component';


const routes = [
  {
    path     : '*',
    component: PageNotFoundComponent
  },
];

@NgModule({
  declarations: [
    PageNotFoundComponent,
    NoCategoryComponent
  ],
  exports:[
    PageNotFoundComponent,
    NoCategoryComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    FuseSharedModule,
    MatCardModule,
    FontAwesomeModule
  ]
})
export class DisplayPagesModule {}
