import gql from 'graphql-tag';

export const addMedia = gql`
    mutation addMedium($input: [MediumInput!]!){
      response: addMedium(inputMedium: $input){
        id
      }
    }

`;
