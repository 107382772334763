import gql from 'graphql-tag';

export const readAllEventsOfMonth = gql`
  query readAllEventsOfMonth($month: Float!, $year: Float!) {
    response: readAllEventsOfMonth(
      month: $month,
      year: $year
    ) {
      id
      kategorie {
        id
        nazev
        color
      }
      slug
      autor {
        jmeno
        prijmeni
      }
      media{
        id
        url
        mime
        popis
      }
      nazev
      popis
      datum_konec
      datum_zacatek
      }
    }
`;
