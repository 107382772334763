import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation, AfterViewInit} from '@angular/core';
import {IPkInputTextFieldProps} from './pk-input-text-field.props';
import {getFirstError} from '@nx-monorepo/cms-base/helpers';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'pk-input-text-field',
  templateUrl: './pk-input-text-field.component.html',
  styleUrls: ['./pk-input-text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PkInputTextFieldComponent implements OnInit, AfterViewInit {

  public isHidden = true;
  public isPassword = false;
  public firstError ?: string;
  public formControl ?: FormControl;

  @Input()
  settings: IPkInputTextFieldProps;

  @Input()
  public disabled: boolean = false;

  @Input()
  data: any;

  ngAfterViewInit(): void {
    this.formControl?.valueChanges.subscribe((_) => {
      this.firstError = getFirstError(this.settings.formControlName, this.settings.form);
    })
  }

  ngOnInit(): void {
    this.isPassword = this.settings.type === 'password';
    this.firstError = getFirstError(this.settings.formControlName, this.settings.form);
    this.formControl = this.settings.form.get(this.settings.formControlName) as FormControl;  
  }


  private genPassword() {
    const random = (Math.random() + 1).toString(36).substr(2, 5) + (Math.random() + 1).toString(36).substr(2, 5);
    this.settings.form.patchValue({
      heslo: random
    });
  }

  private hideText() {
    this.isHidden = !this.isHidden;
    this.settings.type = this.isHidden ? 'password' : 'text';
  }
}

