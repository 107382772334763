import { CategoryTabsState } from './category-tabs.state';
import { createFeatureSelector, createSelector, MemoizedSelector, Selector } from '@ngrx/store';
import { IAction } from '@nx-monorepo/cms-base/interfaces';

const getCategories = (state: CategoryTabsState): IAction[] => state.categories;
const getFilteredCategories = (state: CategoryTabsState): IAction[] => state.categories.filter(cat => cat.id > 0);
const getActiveCategory = (state: CategoryTabsState): IAction => state.activeCategory;
const getShouldReFetch = (state: CategoryTabsState): boolean => state.shouldRefetch;

export const selectCategoryTabsState: MemoizedSelector<object, CategoryTabsState> = createFeatureSelector<CategoryTabsState>('categories');

export const selectCategories: Selector<object, IAction[]> = createSelector(
  selectCategoryTabsState,
  getCategories
);

export const selectFilteredCategories: Selector<object, IAction[]> = createSelector(
  selectCategoryTabsState,
  getFilteredCategories
);

export const selectActiveCategory: MemoizedSelector<object, IAction> = createSelector(
  selectCategoryTabsState,
  getActiveCategory
);

export const selectShouldRefetchCategories: Selector<object, boolean> = createSelector(
  selectCategoryTabsState,
  getShouldReFetch
);
