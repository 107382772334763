import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { FormControl } from '@angular/forms';
import { IPkInputTextFieldProps } from '../pk-input-text-field/pk-input-text-field.props';
import { getFirstError } from '../../../helpers/form/pk-form-handling';

@Component({
    selector: 'pk-input-box-field',
    templateUrl: './pk-input-box-field.component.html',
    styleUrls: ['./pk-input-box-field.component.scss']
})
export class PkInputBoxFieldComponent implements OnInit, AfterViewInit {
    public firstError ?: string;
    public formControl ?: FormControl;
    
    @Input()
    settings: IPkInputTextFieldProps;

    @Input()
    data: any;

    ngOnInit(): void {
        this.firstError = getFirstError(this.settings.formControlName, this.settings.form);
    }

    ngAfterViewInit(): void {
        this.settings.form.get(this.settings.formControlName)?.valueChanges.subscribe((_) => {
            this.firstError = getFirstError(this.settings.formControlName, this.settings.form);
        })
    }
}