<ng-container>
  <form [formGroup]="gridsterForm">
    <ng-template *ngFor="let field of entityDefinitions"
                 cmsComponentFactory
                 [componentName]=field.componentName
                 [settings]=field.settings
                 [data]="field.data">

    </ng-template>

    <div fxLayout="row">
      <div fxLayout="column" fxFlex="35%">
        <pk-modulelist [itemList]="getItemList()">

        </pk-modulelist>
      </div>

      <div fxLayout="column" fxFlex="65%">
        <pk-gridster #gridster
                     [form]="gridsterForm"
                     [showPreview]="showPreview"
                     (deleteItem)="itemDeleted($event)">

        </pk-gridster>
      </div>
    </div>
  </form>
</ng-container>


