<ng-container>
  <div *ngIf="activeCategory?.id > 0; else noCategorySelected" fxLayout="row">
    <form [formGroup]="categorySettingForm">
      <ng-template *ngFor="let component of categorySettingDefinition" cmsComponentFactory [componentName]="component.componentName" [settings]="component.settings" [data]="component.data"></ng-template>
    </form>
  </div>
  <ng-template #noCategorySelected>
    <pk-no-category></pk-no-category>
  </ng-template>
</ng-container>


