import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@Directive({
  selector: '[contrastForeground]'
})
export class ForegroundColorDirective implements AfterViewInit{

  @Input('contrastForeground') specifiedBackground: string;
  @Input() preferedColors: string[] = [];

  constructor(private el: ElementRef, private colorUtils: ColorUtilsService) {

  }

  ngAfterViewInit(): void {
    if(this.specifiedBackground){
      this.el.nativeElement.style.color = this.colorUtils.decideForegroundColor(this.specifiedBackground,6.7,...this.preferedColors);
    } else {
      this.el.nativeElement.style.color = this.colorUtils.decideForegroundColor(this.specifiedBackground,6.7,...this.preferedColors);
    }
  }
}
