import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { PkAccountMenuComponent } from './pk-accountmenu/pk-account-menu.component';
import { FlexModule } from '@angular/flex-layout';
import { PkModulelistComponent } from './pk-modulelist/pk-modulelist.component';
import { PkGridsterComponent } from './pk-gridster/pk-gridster.component';
import { GridsterModule } from 'angular-gridster2';
import { PkInputFieldsModule } from './pk-input-fields/pk-input-fields.module';
import { MacmsGridsterItemsModule } from './pk-gridster-items/macms-gridster-items.module';
import { PKPageActionButtons } from './pk-page-action-buttons/pk-page-action-buttons.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgModule } from '@angular/core';
import { PkCategoryTabsComponent } from './pk-category-tabs/pk-tabs.component';
import { AuthPageModule } from './auth-pages/auth-page.module';
import { NgSharedModule } from '@nx-monorepo/ng-shared';
import { PkDialogComponent } from './pk-dialog/pk-dialog.component';
import { PkMediaModule } from './media-module/pk-media.module';
import { DynamicRendererModule } from '../dynamic-renderer/dynamic-renderer.module';
import { PkConfirmDialogComponent } from './pk-confirm-dialog/pk-confirm-dialog.component';



@NgModule({
  declarations: [
    PkCategoryTabsComponent,
    PkAccountMenuComponent,
    PkDialogComponent,
    PkModulelistComponent,
    PkGridsterComponent,
    PKPageActionButtons,
    PkConfirmDialogComponent,
  ],
  imports: [
    RouterModule,
    //StoreModule,
    //MACMSStoreModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    AuthPageModule,
    FlexModule,
    MatListModule,
    PkMediaModule,
    GridsterModule,
    MacmsGridsterItemsModule,
    PkInputFieldsModule,
    MatSelectModule,
    MatTooltipModule,
    MatSortModule,
    FuseSharedModule,
    NgSharedModule,
    MatProgressBarModule,
    DynamicRendererModule

  ],
  exports: [
    PkCategoryTabsComponent,
    PkInputFieldsModule,
    MacmsGridsterItemsModule,
    PkAccountMenuComponent,
    PkModulelistComponent,
    PkConfirmDialogComponent,
    PkGridsterComponent,
    PkMediaModule,
    PKPageActionButtons,
  ],
  entryComponents: [
    PkDialogComponent,
    PkConfirmDialogComponent,
  ],
})
export class MacmsModule {}
