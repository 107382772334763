<form (click)="onClick($event)">
  <fa-icon class="form-icon start" aria-label="Vyhledat" icon="search" matPrefix></fa-icon>
  <input class="line" [style.border-color]="'white'" [style.color]="'white'" [(ngModel)]="inputValue" name="inputField">
  <button class="form-icon end"
          type="submit"
          (click)="onSubmit(inputValue)">
      <fa-icon class="form-icon end" aria-label="Vyhledat" icon="arrow-right" matSuffix></fa-icon>
  </button>
</form>

