import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { readNFutureUdalost, readAllEventsOfMonth } from '@nx-monorepo/obce/common/queries';


@Injectable({
  providedIn: 'root'
})
export class UdalostService {
  constructor(private apollo: Apollo) {}

  getSpecificMonth(wantedMonth, wantedYear){
      return this.apollo.query<{response: any[]}>({
        query: readAllEventsOfMonth,
        variables: {
          month: wantedMonth,
          year: wantedYear
        }
      }
    ).pipe(map((value) => {
        return value.data.response;
    }));
  }

  getNextEvents(today: Date, numberOfEvents: number){
    return this.apollo.query<{response: any[]}>({
        query: readNFutureUdalost,
        variables: {
          today: today,
          numberOfEvents: numberOfEvents
        }
      }
    ).pipe(map((value) => {
      return value.data.response;
    }));
  }

}
