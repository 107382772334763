import { IColumnDefinition } from '@nx-monorepo/cms-base/interfaces';

export const evidenceColumnDefinitions: IColumnDefinition[] = [
  {
    title: 'select',
    key: ['select'],
    type: 'select',
    sortable: false,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'ID',
    key: ['id'],
    type: 'string',
    sortable: true,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'Kategorie',
    key: ['kategorie', 'nazev'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
];
