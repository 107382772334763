import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, of as observableOf, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
// noinspection TypeScriptPreferShortImport
import {BasePageComponent} from '../base-page.component';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {Action, ActionsSubject, Store} from '@ngrx/store';
import {MACMSStoreState} from '@nx-monorepo/cms-base/store';
import {displaySnack} from '@nx-monorepo/cms-base/helpers';
import {FormBuilder, FormGroup} from '@angular/forms';
import {LayoutActionTypes} from '../../../../store/layout/layout.actions';
import {EntityDefinitionProvider} from '@nx-monorepo/cms-base/helpers';
import {PKApolloQueryResult} from "@nx-monorepo/api-base/lib";
import { KolekceService } from '@nx-monorepo/obce/ng/services';
import { createFormWatcher, initEntityForm } from '@nx-monorepo/cms-base/helpers/form/form-utils';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';


@Component({
  selector: 'pk-new-entity-page',
  templateUrl: './pk-new-entity-page.component.html',
  styleUrls: ['./pk-new-entity-page.component.scss'],
  animations: fuseAnimations
})
export class PkNewEntityPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  // store state

  // inner state
  entityId: number;
  entity: any;
  entityDefinitions: IEntityDefinition[];
  entityForm: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  /**
   * Objekt, kde klice jsou formControlNames a values jsou Observably na showFunction
   */
  protected shouldDisplay$: { [key: string]: Observable<boolean> };

  constructor(store$: Store<MACMSStoreState.State>,
              currentRoute: ActivatedRoute,
              protected router: Router,
              public entityDefinitionProvider: EntityDefinitionProvider,
              protected kolekceService: KolekceService,
              protected actionsSubject$: ActionsSubject,
              private formBuilder: FormBuilder) {
    super(store$, currentRoute);
  }

  protected getEntityDefinition() {
    this.entityDefinitions = [...this.entityDefinitionProvider.getEntityDefinitionsFor(this.routeData.entityType)];
  }

  async ngOnInit() {
    this.entityId = Number(this.currentRoute.snapshot.paramMap.get('id'));

    // z cesty ziskat popis entity
    this.getEntityDefinition();
    this.entityForm = initEntityForm(this.entityDefinitions, this.formBuilder);
    this.shouldDisplay$ = createFormWatcher(this.entityDefinitions, this.entityForm, this.destroy$);

    // if we have ID from route, find the entity and fill the form
    if (this.entityId) {
      this.entity = await this.fetchEntity();
      console.log('entity', this.entity);
      this.fillForm(this.entity);
    } else {
      this.entityForm.get('id').disable();
    }

    // set layout buttons if wanted
    this.setLayoutButtons();

    // subscribe to the action where Save button is pressed
    this.subscribeToLayoutButtons();

  }

  ngOnDestroy(): void {
    // reset form
    this.entityForm.reset();

    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  protected setLayoutButtons() {
  };

  protected fetchEntity(): Promise<{}> {
    return this.kolekceService.fetchSingle(this.routeData.entityType, this.entityId).toPromise();
  }

  protected fillForm(entity: {}) {
    this.entityForm.patchValue(entity);
  }

  protected subscribeToLayoutButtons() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === LayoutActionTypes.SAVE_BUTTON_PRESSED)
    ).subscribe(() => {
      this.saveEntity();
    });
  }

  protected saveEntity(): void {
    if (!this.entityForm.valid){
      displaySnack('Vyplňte prosím všechny položky!', this.store$);
      return;
    }
    this.kolekceService.save(this.routeData.entityType, {input: [this.entityForm.value]}).toPromise()
      .then((res: PKApolloQueryResult) => {
        if (!(this.routeData.entityType === "Email" || this.routeData.entityType === "Sms")){
          this.router.navigateByUrl(this.currentRoute.snapshot.url[0].path || '/'); 
        } else {
          this.entityForm.reset()
        }
        displaySnack('Úspěšně uloženo!', this.store$);
      })
      .catch((err) => {
        displaySnack(err.message || 'Entitu se nepodařilo uložit!', this.store$);
      })
  }
}
