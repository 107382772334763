import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IPkAutocompleteFieldProps } from './pk-input-autocomplete.props';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
  selector: 'pk-input-autocomplete',
  templateUrl: './pk-input-autocomplete.component.html',
  styleUrls: ['./pk-input-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PkInputAutocompleteComponent implements OnInit {

  @Input()
  settings: IPkAutocompleteFieldProps;

  @Input()
  data: any;

  public filteredData: Observable<any>;

  ngOnInit(): void {
    this.filteredData = this.settings.form.get(this.settings.formControlName).valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nazev),
        map(nazev => nazev ? this._filter(nazev) : this.data.slice())
      );
  }

  public getViewValue(item?: any): string | undefined {
    return item ? item.nazev : undefined;
  }

  private _filter(nazev: string) {
    const filterValue = nazev.toLowerCase();

    return this.data.filter(option => {
      return option.nazev.toLowerCase().includes(filterValue) ||
        option.description.toLowerCase().includes(filterValue);
    });
  }

}

