import { Action } from '@ngrx/store';
import { IAuthTokenGroup } from '../../helpers/auth/i-auth-token-group';

export enum AuthActionTypes {
  PerformLogin = '[Auth] Perform Login',
  LoginComplete = '[Auth] Login Complete',
  LoginSuccess = '[Auth] Login Success',
  LoginFailure = '[Auth] Login Failure',
  PerformLoginCheck = '[Auth] Perform Login Check', // in case of clean store
  LoginCheckSuccess = '[Auth] Login Check Success', // fill store
  LoginCheckFailure = '[Auth] Login Check Failure', // redirect to login page
  PerformConfirmLogout = '[Auth] Confirm Logout',   // show modal for logout
  LogoutCancelled = '[Auth] Logout Cancelled',      // cancel in logout modal - pass
  LogoutConfirmed = '[Auth] Logout Confirmed',      // clean session
  LogoutCompleted = '[Auth] Logout Completed',      // redirect to login page
  PerformRefresh = '[Auth] Perform Refresh',        // if there is valid non-expired refresh token
  RefreshSuccess = '[Auth] Refresh Success',
  RefreshFailure = '[Auth] Refresh Failure',
  RefreshComplete = '[Auth] Refresh Complete',
}

export class PerformLogin implements Action {
  readonly type = AuthActionTypes.PerformLogin;
  constructor(public payload: { email: string, password: string }) {
  }
}

export class LoginComplete implements Action {
  readonly type = AuthActionTypes.LoginComplete;
  constructor(public payload: {username: string}) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;
  constructor(public payload: {role: {nazev,pravomoce}, username: string}) {}
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;
  constructor(public payload: {error: string}) {}
}

export class PerformLoginCheck implements Action {
  readonly type = AuthActionTypes.PerformLoginCheck;
}

export class LoginCheckSuccess implements Action{
  readonly type = AuthActionTypes.LoginCheckSuccess;
  constructor(public payload: {role: {nazev, pravomoce}, username: string}) {}
}

export class LoginCheckFailure implements  Action {
  readonly type = AuthActionTypes.LoginCheckFailure;
  constructor(public payload: {error: string}) {}
}

export class PerformLogout implements Action {
  readonly type = AuthActionTypes.PerformConfirmLogout;
}

export class LogoutConfirmed implements Action {
  readonly type = AuthActionTypes.LogoutConfirmed;
}

export class LogoutCancelled implements Action {
  readonly type = AuthActionTypes.LogoutCancelled;
}

export class LogoutCompleted implements Action {
  readonly type = AuthActionTypes.LogoutCompleted;
}

export class PerformRefresh implements Action {
  readonly type = AuthActionTypes.PerformRefresh;
  constructor(public payload: IAuthTokenGroup){}
}

export class RefreshSuccessful implements Action {
  readonly type = AuthActionTypes.RefreshSuccess;
  constructor(public payload: IAuthTokenGroup) {}
}

export class RefreshFailure implements Action {
  readonly type = AuthActionTypes.RefreshFailure;
  constructor(public payload: {error: string}) {}
}

export class RefreshComplete implements Action {
  readonly type = AuthActionTypes.RefreshComplete;
}

export type AuthActions =
  | PerformLogin
  | LoginComplete
  | LoginSuccess
  | LoginFailure
  | PerformLoginCheck
  | LoginCheckSuccess
  | LoginCheckFailure
  | PerformLogout
  | LogoutCancelled
  | LogoutConfirmed
  | LogoutCompleted
  | PerformRefresh
  | RefreshSuccessful
  | RefreshFailure
  | RefreshComplete;
