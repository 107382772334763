import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { FetchResult } from 'apollo-link';
import gql from 'graphql-tag';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(private apollo: Apollo) {
  }

  subscribe(email): Observable<FetchResult<any>> {
    return this.apollo.mutate({
      mutation: gql`mutation Subscribe($email: String!){
        subscribeFromHomePage(email: $email){
        isSuccessful
        message
        }}`,
      variables: {email: email}
    });
  };
}
