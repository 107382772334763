import gql from 'graphql-tag';

export const readAllTransakceQuery = gql`
query readAllTransakce($search: String, $page: Int, $take: Int, $sortColumn: String, $sortDirection: String, $categories: [Int!]) {
  response: readAllTransakce(
    search: $search
    take: $take
    page: $page
    sortColumn: $sortColumn
    sortDirection: $sortDirection,
    categories: $categories
  ) {
    total
    page
    take
    hasMore
    items {
      id
      created_at
      variabilni_symbol
      stav
    }
  }
}
`;
