<ng-container>
    <div class="alert">
        <mat-icon>error_outline</mat-icon>
        <div class="text">Uživatel může upravovat pouze telefon a email. Pro úpravu ostatních údajů kontaktujte svůj obecní úřad.</div>
      </div>

    <form [formGroup]="entityForm">
        <div
            *ngFor="let field of entityDefinitions"
            [ngClass]="field.componentName === 'PkHiddenFieldComponent' ? 'hidden-field' : ''"
            [style.grid-row-start]="field.y + 1"
            [style.grid-column-start]="field.x + 1"
            [style.grid-column-end]="field.x + 1 + field.cols"
        >
        <ng-container
            *ngIf="!shouldDisplay$.hasOwnProperty(field.settings.formControlName) || (shouldDisplay$[field.settings.formControlName] | async)"
            cmsComponentFactory
            [componentName]=field.componentName
            [settings]=field.settings
            [data]="field.data">
      </ng-container>
        </div>
    </form>
</ng-container>