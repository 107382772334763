import gql from 'graphql-tag';

export const getSvatek = gql`
  query getSvatek($date: DateTime) {
    response: getSvatek(
      date: $date
    ) {
    nameday
    date
    holiday
    is_public_holiday
  }
}
`;
