import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {FuseSharedModule} from '@fuse/shared.module';
import {PkLoginPageComponent} from './login-page/pk-login-page.component';
import {PkRegistrationPageComponent} from './register-page/pk-registration-page.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCardModule} from '@angular/material/card';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MomentModule} from 'ngx-moment';
import {PkSetPasswordPageComponent} from "./set-password-page/pk-set-password-page.component";
import { PkResetPasswordComponent } from './reset-password-page/pk-reset-password-page.component';

const routes = [
  {
    path: 'login',
    component: PkLoginPageComponent
  },
  {
    path: 'register',
    component: PkRegistrationPageComponent
  },
  {
    path: 'login/forgotten-pw',
    component: PkResetPasswordComponent
  },
  {
    path: 'set-password/:token',
    component: PkSetPasswordPageComponent
  }
];

@NgModule({
  declarations: [
    PkLoginPageComponent,
    PkRegistrationPageComponent,
    PkSetPasswordPageComponent,
    PkResetPasswordComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatStepperModule,
    FuseSharedModule,
    MatCardModule,
    FontAwesomeModule,
    MomentModule
  ]
})
export class AuthPageModule {
}
