import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
// noinspection TypeScriptPreferShortImport
import { BasePageComponent } from '../base-page.component';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ApolloQueryResult } from 'apollo-client';
import { EntityDefinitionProvider, displaySnack } from '@nx-monorepo/cms-base/helpers';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { KategorieService } from '@nx-monorepo/obce/ng/services';
import { createFormWatcher, initEntityForm } from '@nx-monorepo/cms-base/helpers/form/form-utils';
import { IEntityDefinition, IAction } from '@nx-monorepo/cms-base/interfaces';
import { selectActiveCategory } from '@nx-monorepo/cms-base/store/category-tabs/category-tabs.selectors';
import { SetSelectedItems } from '@nx-monorepo/cms-base/store/table/table.actions';
import { LayoutActionTypes } from '@nx-monorepo/cms-base/store/layout/layout.actions';
import { platebniPrikazyNastaveniDefinitions } from 'apps/obce/cms/src/app/entity-definitions';

@Component({
  selector: 'pk-platebni-prikazy-nastaveni-page',
  templateUrl: './pk-platebni-prikazy-nastaveni-page.component.html',
  styleUrls: ['./pk-platebni-prikazy-nastaveni-page.component.scss'],
  animations: fuseAnimations
})
export class PkPlatebniPrikazyNastaveniPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  entityDefinitions: IEntityDefinition[] = platebniPrikazyNastaveniDefinitions;
  form: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  /**
   * Objekt, kde klice jsou formControlNames a values jsou Observably na showFunction
   */
  shouldDisplay$: { [key: string]: Subject<boolean> };
  public activeCategory: IAction;

  constructor(store$: Store<MACMSStoreState.State>, currentRoute: ActivatedRoute, private router: Router, private entityDefinitionProvider: EntityDefinitionProvider, private kategorieService: KategorieService, private actionsSubject$: ActionsSubject, private formBuilder: FormBuilder) {
    super(store$, currentRoute);
  }

  private debug() {
    console.log(this.form.value);
  }

  ngOnInit(): void {
    // init formulare
    this.form = initEntityForm(this.entityDefinitions, this.formBuilder);
    this.shouldDisplay$ = createFormWatcher(this.entityDefinitions, this.form, this.destroy$);

    // ziskat aktivni kategorii
    this.fetchCategorySettings();

    // sub na save button
    this.subscribeToSaveButtonAction();
  }

  ngOnDestroy(): void {
    // reset form
    this.form.reset();

    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();

    // unsubscribe na vsechny observably v shouldDisplay objektu
    Object.keys(this.shouldDisplay$).forEach(key => this.shouldDisplay$[key].unsubscribe());
  }

  private fetchCategorySettings() {
    // subscribe na zmenu kategorie
    this.store$.select(selectActiveCategory).pipe(
      takeUntil(this.destroy$)
    ).subscribe((activeCategory: IAction) => {
      // ulozime aktivni kategorii
      this.activeCategory = activeCategory;

      if (this.activeCategory.id > 0) {
        // ziskame nastaveni kategorie a aplikujeme do formy
        this.kategorieService.fetchSingle(activeCategory.id).toPromise()
          .then((result: any) => {
            // destructure data
            const { nastaveni, uzivatele } = result;

            // create form values
            const formPatchValues = {
              ...JSON.parse(nastaveni) || {},
              uzivatele: uzivatele
            };

            // dispatchnout uizivatele do storu
            this.store$.dispatch(new SetSelectedItems({ selectedItems: uzivatele }));

            // updatnout data ve forme
            this.form.patchValue(formPatchValues);

            // updatnout repeater, tedy nastavit default value
            const repeaterField = this.entityDefinitions.find(field => field.componentName === 'PkInputRepeaterFieldComponent');
            if (repeaterField) {
              console.log(repeaterField, formPatchValues);
              this.patchRepeater(repeaterField, formPatchValues);
            }

          })
          .catch(err => console.error(err));
      }
    });
  }

  private patchRepeater(repeaterField: IEntityDefinition, patchValues: any) {
    // zjistit formControlName repeateru
    const repeaterFormName = repeaterField.settings.formControlName;

    // dostat vsechny jeho fieldy a vycistit (pri zmene kategorii nutne)
    const fields = this.form.get(repeaterFormName) as FormArray;
    fields.clear();
    // pushnout vsechnty do form arraye repeateru
    if(patchValues[repeaterFormName]){
      patchValues[repeaterFormName].forEach(field => {
        fields.push(this.formBuilder.group(field));
      });
    }

  }

  private subscribeToSaveButtonAction() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === LayoutActionTypes.SAVE_BUTTON_PRESSED)
    ).subscribe(action => {
      console.log(this.form.value);
      if (this.form.valid) {
        this.save();
      } else {
        displaySnack('Vyplňte prosím všechny položky!', this.store$);
      }
    });
  }

  private save() {
    const payload = {
      id: this.activeCategory.id,
      nazev: this.activeCategory.nazev,
      typ: EntityTypes.PlatebniPrikaz,
      uzivatele: this.form.value.uzivatele.map(uzivatel => ({ id: uzivatel.id })),
      nastaveni: JSON.stringify({ ...this.form.value, uzivatele: undefined })
    };

    console.log('payload', payload);

    this.kategorieService.save({ input: payload }).toPromise()
      .then((res: ApolloQueryResult<any>) => this.onSaveSuccess(res))
      .catch((err: Error) => this.onSaveError(err));
  }

  private onSaveSuccess(result: ApolloQueryResult<any>) {
    this.router.navigateByUrl(this.currentRoute.snapshot.url[0].path || '/');
    displaySnack('Úspěšně uloženo!', this.store$);
  }

  private onSaveError(error: any) {
    console.log(error);
    displaySnack(error.message, this.store$);
  }
}
