import { Component, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { Router } from '@angular/router';


@Component({
  selector     : 'pk-page-not-found',
  templateUrl  : './page-not-found.component.html',
  styleUrls    : ['./page-not-found.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class PageNotFoundComponent {

  constructor(
    private router: Router,
    private _fuseConfigService: FuseConfigService,
    private _fuseProgressBar: FuseProgressBarService,
  )
  {
    // Configure the layout
    this._fuseProgressBar.hide();
    this._fuseConfigService.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        },
        header: {
          hidden: true
        },
        content:{
          carded: false
        }
      }
    };
  }

  /*goBack(){
    this.router.();
  }*/


}
