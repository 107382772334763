import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Action, ActionsSubject, createAction, Store} from '@ngrx/store';
import { MACMSStoreState, TableStoreSelector } from '@nx-monorepo/cms-base/store';
import {Observable, Subject} from 'rxjs';
import {filter, flatMap, reduce, take, takeLast, takeUntil} from 'rxjs/operators';
import { KategorieService } from '@nx-monorepo/obce/ng/services';
import { IAction } from '@nx-monorepo/cms-base/interfaces';
import {MediaActionTypes} from "@nx-monorepo/cms-base/store/media/media.actions";


@Component({
  templateUrl: 'pk-entity-dialog.component.html',
  styleUrls: ['pk-entity-dialog.component.scss']
})
export class PkEntityDialogComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private categories$: Observable<IAction[]>;
  public entitySelected: EventEmitter<any> = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private store$: Store<MACMSStoreState.State>,
              private kategorieService: KategorieService,
              private actionsSubject$: ActionsSubject) {}

  ngOnInit(): void {
    console.log("Data OCD",this.data);
    this.categories$ = this.getEntityCategories();
  }

  private getEntityCategories(): Observable<IAction[]> {
    return this.kategorieService.fetchAll({ types: [this.data.entityType] })
      .pipe(
        flatMap((result: any) => result.data.response.items), // flatten items
        filter((item: any) => item.id > 0), // filter out Vse
        reduce((acc, item) => [...acc, item], []), // merge into array again,
      );
  }

  public onNewItems(ids: number[]) {
    console.log('new items', ids);
  }

  public dispatchAddUrl() {
    this.store$.dispatch(createAction('[Media] Add Url')());
  }

  public dispatchUploadUrl() {
    this.store$.dispatch(createAction('[Media] Upload External File')());
  }

  public dispatchUploadFile() {
    this.store$.dispatch(createAction(MediaActionTypes.UPLOAD_FILE)());
  }

  public dispatchUploadAndSelectFile() {
    this.store$.dispatch(createAction(MediaActionTypes.UPLOAD_FILE)());

    let selectedItem:any;
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) =>
        (action.type === MediaActionTypes.UPLOAD_SUCCESSFUL)
      )
    ).subscribe((action: any) => {
      if (action.type === MediaActionTypes.UPLOAD_SUCCESSFUL) {
        selectedItem = action.payload;

        this.entitySelected.emit(selectedItem);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public onPotvrditClick() {
    //select selected media ids from store and emit it
    this.store$.select(TableStoreSelector.selectSelectedItems).pipe(
      take(1)
    ).subscribe((selectedMediaIds: number[]) => {
      this.entitySelected.emit(selectedMediaIds);
    })
  }
}
