import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { BasePageComponent } from '../base-page.component';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';

@Component({
  selector: 'pk-owner-settings-page',
  templateUrl: './pk-owner-settings-page.component.html',
  styleUrls: ['./pk-owner-settings-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PkHelpOwnerPage extends BasePageComponent implements OnInit, OnDestroy, AfterViewInit {

  private destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(store$: Store<MACMSStoreState.State>,
              route: ActivatedRoute,
              private _fuseProgressBarService: FuseProgressBarService,) {
    super(store$, route);
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this._fuseProgressBarService.hide();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
