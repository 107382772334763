import gql from 'graphql-tag';

export const readSelectedPlatebniPrikazQuery = gql`
    query ReadSelectedPlatebniPrikaz($ids: [Int!]!) {
      response: readSelectedPlatebniprikaz(
        ids: $ids
      ) {
        id
        castka
        polozky{
          cena
          nazev
          mnozstvi
        }
        stav
        splatnost
        zaplaceno_at
        uzivatel_id
        uzivatel{
          id
          jmeno
          prijmeni
        }
        odvolano_at
        variabilni_symbol
      }
    }
`;
