<div id="login" fxLayout="column">
  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="login-form" [@animate]="{value:'*',params:{duration:'600ms',y:'500px'}}" class="fuse-card">

      <div class="logo">
        <img src="assets/images/logos/echopix_cms_logo_rect.svg">
      </div>

      <div class="title">Přihlašte se</div>
      <div class="my-16 fuse-card warn-100 mx-auto" *ngIf="errorMessage">
        <div class="p-16" fxLayout="row" fxLayoutAlign="start center">
          <fa-icon size="2x" icon="exclamation-circle"></fa-icon>
          <div class="ml-16">
            <div class="text-bold">Neúspěšné přihlášení</div>
            <div class="h5 text-bold secondary-text text-left">{{errorMessage}}</div>
          </div>
        </div>
      </div>
      <form name="loginForm" (ngSubmit)="onSubmit()" [formGroup]="loginForm" novalidate >

        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input [value]="username" matInput formControlName="email" [ngModel]="username">
          <mat-icon matSuffix class="secondary-text">mail</mat-icon>
          <mat-error *ngIf="loginForm.get('email').hasError('required')">
            Email je vyžadován
          </mat-error>
          <mat-error
            *ngIf="!loginForm.get('email').hasError('required') &&
                                loginForm.get('email').hasError('email')">
            Prosím vyplňte validní email.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Heslo</mat-label>
          <input [value]="password" matInput type="password" formControlName="password" [ngModel]="password">
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
          <mat-error>
            Heslo je vyžadováno.
          </mat-error>
        </mat-form-field>

        <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
             fxLayoutAlign="space-between center">
          <mat-checkbox class="remember-me" aria-label="Remember Me">
            Zapamatovat
          </mat-checkbox>

         <a disabled class="forgot-password" [routerLink]="'forgotten-pw'">
            Zapomněli jste heslo?
          </a>
        </div>

        <button mat-raised-button color="accent" class="submit-button" type="submit" aria-label="LOG IN"
                [disabled]="loginForm.invalid || loading">
          Přihlásit se
        </button>
        <div class="separator">
          <span class="text">NEBO</span>
        </div>

        <div class="register" fxLayout="column" fxLayoutAlign="center center">
          Manuální registrace pro tento systém jsou vypnuty.<br/>
          Pro přístup do systému se musíte obrátit na obecní úřad Vaší obce.
          <!--<span class="text">Používá Vaše obec tento systém?</span>
          <a disabled class="link">Zaregistrujte se</a>
          &lt;!&ndash;<a class="link" [routerLink]="'/register'">Zaregistrujte se</a>&ndash;&gt;-->
        </div>
      </form>
    </div>

  </div>
  <div class="info">#{{versionInfo.commit}}@{{versionInfo.branch}} | v{{versionInfo.version}}(#{{versionInfo.count}}) | <span>{{versionInfo.date | amDateFormat:'DD.MM.YYYY HH:mm'}}</span> | © webyobci.cz </div>
</div>
