import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArrayPipe } from './pipes/form-array.pipe';
import { FormatPipe } from './pipes/format.pipe';
import { BrowserFaviconsService } from './services/favicon.service';

@NgModule({
  imports: [CommonModule],
  providers: [BrowserFaviconsService],
  exports: [FormArrayPipe, FormatPipe],
  declarations: [FormArrayPipe, FormatPipe]
})
export class NgSharedModule {
}
