import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { Validators } from '@angular/forms';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { IPkInputCheckboxFieldProps, IPkInputEntityFieldProps, IPkInputTextFieldProps, IPkInputRepeaterFieldProps } from '@nx-monorepo/cms-base/components';

export const platebniPrikazyNastaveniDefinitions: IEntityDefinition[] = [
  {
    componentName: 'PkInputCheckboxFieldComponent',
    settings: {
      povinnost: false,
      nazev: 'Hromadná platba',
      formControlName: 'hromadna',
      defaultValue: true
    } as IPkInputCheckboxFieldProps,
    data: null,
    validators: [],
    cols: 4,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputEntityFieldComponent',
    settings: {
      nazev: 'Uživatelé',
      povinnost: true,
      napoveda: {
        isIcon: false,
        text: 'Vyberte uživatele pro hromadnou platbu'
      },
      isMultiple: true,
      entityType: EntityTypes.Uzivatel,
      formControlName: 'uzivatele',
      defaultValue: '',
      showFunction: form => form.get('hromadna').value === true
    } as IPkInputEntityFieldProps,
    data: null,
    validators: [],
    cols: 4,
    x: 0,
    y: 1
  },
  {
    componentName: 'PkInputRepeaterFieldComponent',
    settings: {
      nazev: 'Definice platby',
      povinnost: true,
      napoveda: {
        isIcon: false,
        text: 'Lorem'
      },
      formControlName: 'fields',
      rowDefinition: [
        {
          componentName: 'PkInputTextFieldComponent',
          settings: {
            nazev: 'Název',
            povinnost: true,
            napoveda: {
              isIcon: true,
              text: 'Napoveda lorem ipsum'
            },
            isTextArea: false,
            formControlName: 'nazev',
            defaultValue: '',
            type: 'text'
          } as IPkInputTextFieldProps,
          data: null,
          validators: [Validators.required],
          cols: 4,
          x: 0,
          y: 0
        },
        {
          componentName: 'PkInputTextFieldComponent',
          settings: {
            nazev: 'Jednotka',
            povinnost: true,
            napoveda: {
              isIcon: true,
              text: 'Napoveda lorem ipsum'
            },
            isTextArea: false,
            formControlName: 'jednotka',
            defaultValue: '',
            type: 'text'
          } as IPkInputTextFieldProps,
          data: null,
          validators: [Validators.required],
          cols: 4,
          x: 4,
          y: 0
        },
        {
          componentName: 'PkInputTextFieldComponent',
          settings: {
            nazev: 'Výchozí cena (v h)',
            povinnost: true,
            napoveda: {
              isIcon: true,
              text: 'Zadejte cenu v haléřích'
            },
            isTextArea: false,
            formControlName: 'vychozi_cena',
            defaultValue: '',
            type: 'number'
          } as IPkInputTextFieldProps,
          data: null,
          validators: [Validators.required],
          cols: 4,
          x: 8,
          y: 0
        },
      ],
      showFunction: form => form.get('hromadna').value === true
    } as IPkInputRepeaterFieldProps,
    data: null,
    validators: [],
    cols: 4,
    x: 0,
    y: 2
  }
];
