<mat-form-field appearance="outline" fxFlex [formGroup]="settings.form">
  <mat-label>{{ settings.nazev }}</mat-label>
  <input matInput [matAutocomplete]="auto" [formControlName]="settings.formControlName" [required]="settings.povinnost" type="text">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="getViewValue">
    <mat-option *ngFor="let item of filteredData | async" [value]="item">
      <span>{{ item.nazev }}</span>
      <small>{{ item.description }}</small>
    </mat-option>
  </mat-autocomplete>

  <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
  <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info</mat-icon>

  <mat-error *ngIf="settings.form.get(settings.formControlName)?.invalid">Toto pole je povinné!</mat-error>
</mat-form-field>
