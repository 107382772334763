import { TableActions, TableActionTypes } from './table.actions';
import { initialTableState, TableState } from './table.state';
import { isAllSelected } from './table.selectors';

export function tableReducer<T>(state = initialTableState, action: TableActions<T>): TableState<T> {
  switch (action.type) {
    case TableActionTypes.INIT_TABLE:
      // console.log('FETCH TABLE DATA for ', action.payload.entityType);
      return {
        ...state,
        isLoading: true,
        error: null,
        data: {
          ...initialTableState.data
        },
        currentTableSettings: action.payload
      };
    case TableActionTypes.SET_TABLE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload.data
      };
    case TableActionTypes.SET_TABLE_DATA_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    case TableActionTypes.DELETE_SELECTED:
      // console.log('FETCH TABLE DATA for ', action.payload.entityType);
      return {
        ...state,
        error: null
      };
    case TableActionTypes.DELETE_SUCCESS:
      const { deletedIds } = action.payload;
      const newSelectedItems = [...state.selectedItems].filter(item => !deletedIds.includes(item.id));
      const newData = { ...state.data };
      newData.items = newData.items.filter(item => !deletedIds.includes(item.id));

      return {
        ...state,
        isLoading: false,
        error: null,
        selectedItems: newSelectedItems,
        data: newData
      };
    case TableActionTypes.DELETE_FAILURE:
      // console.error('SET TABLE DATA ERROR', action.payload.error);
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    case TableActionTypes.SET_SELECTED_ITEMS:
      const { selectedItems } = action.payload;
      return {
        ...state,
        selectedItems: selectedItems
      };
    case TableActionTypes.TOGGLE_SELECTED_ITEM:
      // get selected item id from payload
      const { selectedItem, canSelectMultiple } = action.payload;

      // make new state array
      const newSelected = [...state.selectedItems];

      // try to find the selected item
      const foundIndex = newSelected.findIndex(item => item.id === selectedItem.id);

      // if we found it, remove it, otherwise add it
      if (foundIndex >= 0) {
        newSelected.splice(foundIndex, 1);
      } else {
        if (!canSelectMultiple) {
          newSelected.splice(0, newSelected.length);
        }
        newSelected.push(selectedItem);
      }

      // return new state
      return {
        ...state,
        selectedItems: newSelected
      };
    case TableActionTypes.CLEAR_ALL_SELECTED:
      return {
        ...state,
        selectedItems: []
      };
    case TableActionTypes.TOGGLE_MASTER_SELECT:
      const newSelectedItems2 = [...state.selectedItems];
      const currentItems = state.data.items;

      // projdeme vsechny itemy na  strance
      currentItems.forEach(item => {
        // podivame se, jestli uz je selectnuty
        const foundIdx = newSelectedItems2.findIndex(element => element.id === item.id);

        // pokud je vse selectnute, odstranime item po itemu, jinak pridame vsechny, co jeste nejsou
        if (isAllSelected(state)) {
          newSelectedItems2.splice(foundIdx, 1);
        } else if (foundIdx === -1) {
          newSelectedItems2.push(item);
        }
      });

      return {
        ...state,
        selectedItems: newSelectedItems2
      };
    default: {
      return state;
    }
  }
}
