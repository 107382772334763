import { IColumnDefinition } from '@nx-monorepo/cms-base/interfaces';

export const prispevekColumnDefinitions: IColumnDefinition[] = [
  {
    title: 'select',
    key: ['select'],
    type: 'select',
    sortable: false,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false
    }
  },
  {
    title: 'ID',
    key: ['id'],
    type: 'string',
    sortable: true,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false
    }
  },
  {
    title: 'Název',
    key: ['nazev'],
    type: 'string',
    sortable: true,
    align: 'left',

    fxHide: {
      sm: false,
      md: false,
      lg: false
    }
  },
  {
    title: 'Kategorie',
    key: ['kategorie', 'nazev'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false
    }
  },
  {
    title: 'Adresa',
    key: ['slug'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false
    }
  },
  {
    title: 'Autor',
    key: ['autor', 'jmeno'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false
    }
  }
];
