import { IEntityDefinition } from '../../interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export function initEntityForm(entityDefinitions: IEntityDefinition[], formBuilder: FormBuilder): FormGroup {
    const form = createForm(entityDefinitions, formBuilder);
    hookFormToFields(entityDefinitions, form);
    return form;
  }
  
  
  export function createFormWatcher(entityDefinitions: IEntityDefinition[], form: FormGroup, takeUntil$: Subject<boolean>): { [key: string]: Subject<boolean> } {
    const displayObservables: { [key: string]: Subject<boolean> } = {};
  
    entityDefinitions.forEach((entityDefinition: IEntityDefinition) => {
  
      // pokud existuje showFunction, pridame ji do objektu na observing
      if (entityDefinition.settings.hasOwnProperty('showFunction')) {
        const fieldControlName = entityDefinition.settings.formControlName;
        displayObservables[fieldControlName] = new Subject<boolean>();
      }
    });
  
    // kdykoliv se zmeni jakakoliv hodnota ve formulari, projdeme vsechna policka, ktere maji show function a spustime show/hide+enable/disable
    form.valueChanges.pipe(takeUntil(takeUntil$)).subscribe(() => {
      // vyfiltrujeme definice, ktere maji showFunction
      const entitiesWithShowFunction = entityDefinitions.filter(definition => definition.settings.hasOwnProperty('showFunction'));
  
      // pro kazde z nich vyhodnotime vysledek showFunction
      entitiesWithShowFunction.forEach(definition => {
        // spustim funkci, ktera rozhodne, jestli se ma zobrazovat nebo ne
        const shouldShow = definition.settings.showFunction(definition.settings.form);
  
        // emitnu jeji vysledek, at se muze prerenderovat view
        displayObservables[definition.settings.formControlName].next(shouldShow);
  
        // na zaklade vysledku jeste disable/enable control - emitEvent:false je dulezite proti zacykleni
        if (!shouldShow) {
          form.get(definition.settings.formControlName).disable({ emitEvent: false });
        } else {
          form.get(definition.settings.formControlName).enable({ emitEvent: false });
        }
  
      });
    });
  
    // vratim objekt se subjectama, at s nim muze view pracovat
    return displayObservables;
  }
  
  function createForm(entityDefinitions: IEntityDefinition[], formBuilder: FormBuilder): FormGroup {
    const root = {};
  
    entityDefinitions.forEach((entityDefinition: IEntityDefinition) => {
      // todo udelat si na to isArray v nastaveni EntityDefinition
      // todo zaroven muzeme udelat isGroup v nastaveni EntityDefinition a mit pak ultimatni formbuilder podle nasich definic
      if (entityDefinition.componentName !== 'PkInputRepeaterFieldComponent') {
        root[entityDefinition.settings.formControlName] = [entityDefinition.settings.defaultValue || null, entityDefinition.validators];
      } else {
        root[entityDefinition.settings.formControlName] = formBuilder.array([]);
      }
    });
  
    return formBuilder.group(root);
  }
  
  function hookFormToFields(entityDefinitions: IEntityDefinition[], form: FormGroup) {
    entityDefinitions.forEach((entityDefinition: IEntityDefinition) => {
      entityDefinition.settings.form = form;
    });
  }