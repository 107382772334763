<div>
  <button
    *ngFor="let button of buttons$ | async; let i = index"
    mat-raised-button
    [routerLink]="button.url"
    class="add-product-button fuse-white mt-24 mt-md-0 ml-4"
    (click)="performAction(button.action)"
  >
    <span>{{button.nazev}}</span>
  </button>
</div>

