import { IPkFormDialogCompanion, IPkDialogData } from '@nx-monorepo/cms-base/components/pk-dialog/pk-dialog-data';
import { IPkInputCheckboxFieldProps } from '@nx-monorepo/cms-base/components';
import { getNazevSettings, getPovinnostSettings, getDefaultDialogButtons } from '../common-settings-objects';

export class PkGridsterItemCheckboxDialogCompanion implements IPkFormDialogCompanion {
  getDialogData(settings: IPkInputCheckboxFieldProps, data: {}, emitters: {dialogSubmit, dialogClose}) {
    const dialogData: IPkDialogData = {
      title: 'Upravit vstupní pole',
      components: [
        getNazevSettings(settings.nazev),
        getPovinnostSettings(settings.povinnost),
      ],
      buttons: getDefaultDialogButtons(emitters)
    };

    return dialogData;
  }
}
