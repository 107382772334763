import gql from 'graphql-tag';

export const deletePrispevky = gql`
  mutation deletePrispevky($ids: [Int!]!) {
    response: deletePrispevek(ids: $ids) {
      description
      userMessage
      error
    }
  }
`;
