import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule, GridModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeadingModule } from '../heading/heading.module';
import { UtilsModule } from '../../utils/utils.module';
import { FooterNavComponent} from './footer-nav/footer-nav.component';
import { ItemNavComponent} from './item-nav/item-nav.component';
import { HeaderNavComponent} from './header-nav/header-nav.component';
import { NavigaceItemComponent } from './navigace-item/navigace-item.component';
import { ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@NgModule({
  imports: [CommonModule, GridModule, FlexModule, FontAwesomeModule, MatMenuModule, RouterModule, MatIconModule, MatButtonModule, BrowserAnimationsModule, HeadingModule, UtilsModule],
  declarations: [ ItemNavComponent, HeaderNavComponent, FooterNavComponent, NavigaceItemComponent],
  entryComponents: [ItemNavComponent, HeaderNavComponent, FooterNavComponent, NavigaceItemComponent],
  providers: [ColorUtilsService],
  exports: [ItemNavComponent, HeaderNavComponent, FooterNavComponent, NavigaceItemComponent],
})
export class NavigaceModule {}

