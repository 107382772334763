<div *ngIf="platebniPrikaz$ | async as platebniPrikaz">
  <div class="payment-wrapper" [ngClass]="platebniPrikaz?.stav">
    <ng-container [ngSwitch]="platebniPrikaz?.stav">
      <mat-card *ngSwitchCase="platbaTypes.SUCCESS" class="success info card" [@animate]="{value:'*',params:{duration:'400ms',opacity:'0', y:'100%'}}">
        <header>
          <div class="title">Tento platební příkaz byl zaplacen</div>
        </header>
        <div class="content">
          <div class="icon">
            <mat-icon>check</mat-icon>
          </div>
          <div class="text">
            <p>Tento platební příkaz byl zaplacen dne <b>{{platebniPrikaz.zaplaceno_at | amDateFormat:"DD.MM.YYYY"}}</b>.
              Splatnost tohoto příkazu byla/je <b>{{platebniPrikaz.splatnost | amDateFormat:"DD.MM.YYYY"}}</b>.</p>
          </div>
        </div>
      </mat-card>
      <mat-card *ngSwitchCase="platbaTypes.OVERDUE" class="danger info card"
                [@animate]="{value:'*',params:{y:'100%'}}">
        <header>
          <div class="title">Tento platební příkaz je po splatnosti</div>
        </header>
        <div class="content">
          <div class="icon">
            <mat-icon>warning</mat-icon>
          </div>
          <div class="text">
            <p><b>Splatnost tohoto platebního příkazu vypršela dne <u>{{platebniPrikaz.splatnost | amDateFormat:"DD.MM.YYYY"}}</u>.
              Zaplaťte příkaz co nejdříve. <br/>Je možné, že budete za pozdní platbu penalizováni.</b></p>
          </div>
        </div>
      </mat-card>
      <mat-card *ngSwitchCase="platbaTypes.WAITING" class="warning info card" [@animate]="{value:'*',params:{duration:'400ms',opacity:'0', y:'100%'}}">
        <header>
          <div class="title">Tento platební příkaz ještě není zaplacen</div>
        </header>
        <div class="content">
          <div class="icon">
            <mat-icon>alarm</mat-icon>
          </div>
          <div class="text">
            <p>Splatnost tohoto platebního příkazu je <b>{{platebniPrikaz.splatnost | amDateFormat:"DD.MM.YYYY"}}</b>.
            Zaplaťte příkaz co nejdříve. Je možné, že budete za pozdní platbu penalizováni.
            Platba musí být správcem označena jako zaplacená.
              V případě, že jste již platbu zaplatili, musíte počkat, než bude manuálně zpracována.</p>
          </div>
        </div>
      </mat-card>
      <mat-card *ngSwitchCase="platbaTypes.CANCELED" class="grey info card" [@animate]="{value:'*',params:{delay: '100ms', duration:'400ms',opacity:'0', y:'100%'}}">
        <header>
          <div class="title">Tento platební příkaz byl odvolán</div>
        </header>
        <div class="content">
          <div class="icon">
            <mat-icon>not_interested</mat-icon>
          </div>
          <div class="text">
            <p>Tento platební příkaz již není platný, tuto platbu&nbsp;<b> NEPLAŤTE</b>. Pokud jste platbu vykonali, prosím
              kontaktujte neprodleně Váš obecní úřad.</p>
          </div>
        </div>
      </mat-card>
    </ng-container>

    <!-- CARD 1 - payment info -->
    <mat-card class="payment card" [@animate]="{value:'*',params:{delay: '200ms', duration:'400ms',opacity:'0', y:'100%'}}">
      <header class="primary">
        <div class="title">Pošlete peníze přímo na účet</div>
      </header>
      <div class="content">
        <table>
          <tr *ngIf="obecInfo$ | async as obecInfo">
            <td><b>Číslo účtu</b></td>
            <td>{{obecInfo.info_settings.cislo_uctu}}/{{obecInfo.info_settings.cislo_banky}}</td>
          </tr>

          <tr>
            <td><b>Částka</b></td>
            <td>{{platebniPrikaz.castka | format:{ method: 'money' } }}</td>
          </tr>

          <tr>
            <td><b>Variabilní symbol</b></td>
            <td>{{platebniPrikaz.variabilni_symbol}}</td>
          </tr>

          <tr>
            <td><b>Pozn pro příjemce</b></td>
            <!-- We need user info :( -->
            <td>- - -</td>
          </tr>
        </table>
      </div>

    </mat-card>

    <!-- CARD 2 - QR code -->
    <mat-card class="payment card" *ngIf="qrData$ | async as qrData"
              [@animate]="{value:'*',params:{delay: '300ms',y:'100%'}}">
      <header class="primary">
        <div class="title">QR platba</div>
      </header>
      <div class="content">
        <p>QR platba vám usnadní vyplnění platebního příkazu v mobilní bankovní aplikaci, nebo platbomatech.
          Už nebudete muset složitě přepisovat číslo účtu, variabilní symbol a další údaje, když budete chtít zaplatit
          fakturu. Stačí vyfotit QR kód z faktury chytrým telefonem a platební příkaz v bankovní aplikaci se vyplní
          automaticky.</p>
        <qrcode [qrdata]="qrData" [size]="128" [level]="'M'"></qrcode>
      </div>
    </mat-card>

    <mat-card class="items card" [@animate]="{value:'*',params:{delay: '400ms', duration:'400ms',opacity:'0', y:'100%'}}">
      <header class="primary">
        <div class="title">Přehled položek</div>
      </header>
      <div class="content">
        <table>
          <thead class="fuse-navy">
          <tr>
            <th>#</th>
            <th>Množství</th>
            <th>Název</th>
            <th>Cena/j</th>
            <th>Celkem</th>
          </tr>
          </thead>
          <tr *ngFor="let polozka of platebniPrikaz.polozky; let idx = index">
            <td>{{idx + 1}}</td>
            <td>{{polozka.mnozstvi}}</td>
            <td>{{polozka.nazev}}</td>
            <td>{{polozka.cena}}</td>
            <td>{{(polozka.cena * polozka.mnozstvi)| format:{ method: 'money' } }}</td>
          </tr>
          <tr>
            <td colspan="4">Celkem</td>
            <td>{{platebniPrikaz.castka | format:{ method: 'money' } }}</td>
          </tr>
        </table>
      </div>
    </mat-card>


  </div>
</div>
