import { IColumnDefinition } from '@nx-monorepo/cms-base/interfaces';

export const navigaceColumnDefinitions: IColumnDefinition[] = [
  {
    title: 'select',
    key: ['select'],
    type: 'select',
    sortable: false,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'ID',
    key: ['id'],
    type: 'string',
    sortable: true,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'Název',
    key: ['nazev'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'Datum vytvoření',
    key: ['created_at'],
    type: 'date',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: true,
      md: false,
      lg: false,
    }
  }
];
