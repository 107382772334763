import { GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
import { PkModuleItem } from '../pk-modulelist/pk-modulelist.component';
import { EventEmitter } from '@angular/core';
import { IPkDialogData, IPkFormDialogCompanion } from '../pk-dialog/pk-dialog-data';

export class PkGridsterItem implements GridsterItem {
  // interface stuff
  compactEnabled: boolean;
  dragEnabled: boolean;
  initCallback: (
    item: GridsterItem,
    itemComponent: GridsterItemComponentInterface
  ) => void;
  maxItemArea: number;
  maxItemCols: number;
  maxItemRows: number;
  minItemArea: number;
  minItemCols: number;
  minItemRows: number;
  resizeEnabled: boolean;
  rows: number;
  cols: number;
  x: number;
  y: number;

  // pk stuff
  id: number;
  moduleItem: PkModuleItem;
  settings: any;
  data: any;
  resizeEvent: EventEmitter<any>;
}

export interface IPkComponentWithFormDialog {
  formDialogCompanion: IPkFormDialogCompanion;
  getDialogData(): IPkDialogData;
}

