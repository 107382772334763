import gql from 'graphql-tag';

export const readSelectedTransakceQuery = gql`
  query readSelectedTransakce($ids: [Int!]!) {
    response: readSelectedTransakce(
      ids: $ids
    ) {
        id
        created_at
        variabilni_symbol
        stav
    }
  }
`;
