import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../base-page.component';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { KategorieService, KolekceService } from '@nx-monorepo/obce/ng/services';
import { MutationResponse } from '@nx-monorepo/api-base/lib';
import { ApolloQueryResult } from 'apollo-client';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { displaySnack } from '@nx-monorepo/cms-base/helpers';
import { PkDialogComponent } from '@nx-monorepo/cms-base/components/pk-dialog/pk-dialog.component';
import { IAction, IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { MACMSStoreState, CategoryTabsStoreSelector, LayoutStoreActions, CategoryTabsActions } from '@nx-monorepo/cms-base/store';
import { kategorieSettingsDefinition } from 'apps/obce/cms/src/app/entity-definitions';
import { initEntityForm } from '@nx-monorepo/cms-base/helpers/form/form-utils';
import { LayoutActionTypes } from '@nx-monorepo/cms-base/store/layout/layout.actions';
import { IPkDialogData } from '@nx-monorepo/cms-base/components/pk-dialog/pk-dialog-data';

@Component({
  selector: 'pk-category-settings-page',
  templateUrl: './pk-category-settings-page.component.html',
  styleUrls: ['./pk-category-settings-page.component.scss']
})
export class PkCategorySettingsPageComponent extends BasePageComponent implements OnInit, OnDestroy {

  private dialogRef: MatDialogRef<PkDialogComponent, string>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public activeCategory: IAction;
  public categorySettingForm: FormGroup = new FormGroup({});
  public categorySettingDefinition: IEntityDefinition[];

  constructor(store$: Store<MACMSStoreState.State>,
              route: ActivatedRoute, router: Router,
              private dialog: MatDialog,
              private kolekceService: KolekceService,
              private kategorieService: KategorieService,
              private formBuilder: FormBuilder,
              private actionsSubject$: ActionsSubject) {
    super(store$, route);
  }

  ngOnInit(): void {
    this.categorySettingDefinition = kategorieSettingsDefinition;
    this.categorySettingForm = initEntityForm(this.categorySettingDefinition, this.formBuilder);

    this.subscribeToCategoryChanges();

    this.subscribeToButtonsAction();
  }

  private subscribeToCategoryChanges(): Subscription {
    // select from store
    const activeCategory$ = this.store$.select(CategoryTabsStoreSelector.selectActiveCategory).pipe(takeUntil(this.destroy$));

    // subscribe and watch changes. hide buttons when Vse is selected
    return activeCategory$.subscribe((category: IAction) => {
      this.activeCategory = category;
      this.categorySettingForm.patchValue(category);

      if (category.id > 0) {
        this.store$.dispatch(new LayoutStoreActions.SetLayoutButtonsAction({ buttons: this.routeData.buttons }));
      } else {
        this.store$.dispatch(new LayoutStoreActions.SetLayoutButtonsAction({ buttons: [] }));
      }
    });
  }

  private subscribeToButtonsAction(): Subscription {
    return this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) =>
        action.type === LayoutActionTypes.SAVE_BUTTON_PRESSED ||
        action.type === LayoutActionTypes.DELETE_BUTTON_PRESSED)
    ).subscribe((action: Action) => {
      switch (action.type) {
        case LayoutActionTypes.DELETE_BUTTON_PRESSED:
          this.openDeleteDialog();
          break;
        case LayoutActionTypes.SAVE_BUTTON_PRESSED:
          this.saveCategory();
          break;
      }
    });
  }

  private openDeleteDialog(): void {
    if (this.dialogRef == null) {
      // create close event
      const closeEvent = new EventEmitter<void>();
      closeEvent.subscribe(() => this.dialogRef?.close());

      // create positive event
      const confirmEvent = new EventEmitter<void>();
      confirmEvent.subscribe(() => {
        this.dialogRef?.close();
        this.deleteCategory();
      });

      // create dialog data
      const dialogData: IPkDialogData = {
        title: 'Opravdu si přejete smazat tuto kategorii?',
        components: [],
        buttons: [
          {
            color: 'warn',
            text: 'Ne, díky',
            action: closeEvent
          },
          {
            color: 'primary',
            text: 'Ano',
            action: confirmEvent
          }
        ]
      };

      // create dialog config and pass dialog data to it
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = dialogData;

      // open dialog and subscribe to its afterClosed event to remove the reference
      this.dialogRef = this.dialog.open(PkDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().pipe(
        take(1)
      ).subscribe(() => {
        // clear the ref
        this.dialogRef = null;

        // clear events after closing dialog
        closeEvent.unsubscribe();
        confirmEvent.unsubscribe();
      });
    }
  }

  private saveCategory(): void {
    // create payload for api
    const categoryPayload = {
      input: [{
        ...this.categorySettingForm.value,
        typ: this.routeData.entityType
      }]
    };

    this.kategorieService.save(categoryPayload).toPromise()
      .then((result) => {
        //console.log("update kategorie result", result.data);
        this.store$.dispatch(new CategoryTabsActions.UpdateCategoryAfterSave({ updatedCategory: result.data.addKategorie[0] }));
        displaySnack('Kategorie byla úspěšně editována', this.store$);
      })
      .catch((err) => {
        console.error(err);
        displaySnack('Kategorie se nepodařilo editovat', this.store$);
      });
  }

  private deleteCategory(): void {
    this.kolekceService.deleteData(EntityTypes.Kategorie, [this.activeCategory.id])
      .toPromise()
      .then((result: ApolloQueryResult<{ response: MutationResponse }>) => {
        const { response } = result.data;
        displaySnack(response.userMessage, this.store$, 15000);
        if (!response.error) {
          this.store$.dispatch(new CategoryTabsActions.FetchCategoriesAction({ entityType: this.routeData.entityType }));
        }
      });
  }

  public ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }


}
