import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Action, ActionsSubject, createAction, Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import {forkJoin, Subject, Subscription} from 'rxjs';
import { ProgressResult, UploadService, MediaUploadState } from '@nx-monorepo/cms-base/services';
import { IAction } from '@nx-monorepo/cms-base/interfaces';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { SnackbarStoreActions } from '@nx-monorepo/cms-base/store/snackbar';
import {MediaActionTypes, UploadSuccessfulAction} from "@nx-monorepo/cms-base/store/media/media.actions";

@Component({
  selector: 'pk-upload',
  templateUrl: './pk-upload.component.html',
  styleUrls: ['./pk-upload.component.scss']
})
export class PkUploadComponent implements OnInit, OnDestroy {
  private shouldClearFiles = false;
  private progress: ProgressResult;
  public files: Array<File> = [];
  public fileCategories: Array<number> = [];

  private saveAction$: Subscription;
  destroy$: Subject<boolean> = new Subject<boolean>();
  done$: Subject<boolean> = new Subject<boolean>();

  @Output()
  uploaded: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Input()
  categories: IAction[];

  constructor(private store$: Store<MACMSStoreState.State>, private actionsSubject$: ActionsSubject, private uploadService: UploadService) {
  }

  ngOnInit(): void {
    this.subscribeToSaveButtonAction();
  }

  ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();

    this.done$.unsubscribe();
  }


  onFilesAdded(files: FileList) {
    if (this.shouldClearFiles) {
      this.files = [];
      this.shouldClearFiles = false;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      this.files.push(file);

      // based on type try to guess category
      let category_id = null;
      if (file.type.includes('image')) {
        category_id = this.categories.find(item => item.nazev === 'Obrázky').id;
      } /*else if (file.type.includes('video')) {
        category_id = this.categories.find(item => item.nazev === 'Videa').id;
      } */ else {
        category_id = this.categories.find(item => item.nazev === 'Dokumenty').id;
      }
      this.fileCategories.push(category_id);
    }


  }

  removeFile(file: File) {
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.fileCategories.splice(index, 1);
  }

  private subscribeToSaveButtonAction() {
    this.saveAction$ = this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === MediaActionTypes.UPLOAD_FILE),
    ).subscribe(action => {
      if (this.files.length > 0) {
        // set the flag
        this.shouldClearFiles = false;

        // start the upload and save the progress map
        this.progress = this.uploadService.upload({files: this.files, fileCategories: this.fileCategories});

        const allProgressObservables = [];
        Object.keys(this.progress).forEach((fileName: string) => {
          // subscribe to all observables
          // this.progress[fileName].state.subscribe(val => console.log(val.progress));

          // add it to an array, so we can forkjoin for final result
          allProgressObservables.push(this.progress[fileName].state);
        });

        // When all progress-observables are completed...
        forkJoin(allProgressObservables).subscribe((result: MediaUploadState[]) => {
          // set the flag
          this.shouldClearFiles = true;

          // display result
          this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({
            message: 'Všechny soubory byly úspěšně nahrány',
            action: 'OK'
          }));

          // emit ids
          const ids = result.map(item => item.mediaId);
          this.uploaded.emit(ids);

          this.store$.dispatch(new UploadSuccessfulAction({ item: ids }));
        });

      }

    });
  }
}

