import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CmsModule} from '@nx-monorepo/cms-base/cms.module';
import {FuseModule} from '@fuse/fuse.module';
import {LayoutStoreActions, TableStoreActions} from '@nx-monorepo/cms-base/store';
import {fuseConfig} from './fuse-config';
import {AppComponent} from './app.component';
import {KATEGORIE_SERVICE_TOKEN, KategorieService, 
  ROLE_SERVICE_TOKEN, RoleService, 
  KONTAKT_SERVICE_TOKEN, KontaktService} from '@nx-monorepo/obce/ng/services';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ObecColumnDefinitionProvider, ObecEntityDefinitionProvider} from './obce-page-settings-provider';
import {
  PkDataTablePageComponent,
  PkNewEntityPageComponent,
  PkFormGridsterPageComponent,
  PkPlatebniPrikazyNastaveniPageComponent,
  PkFrontendGridsterComponent,
  PkDashboardPage,
  PkNewKontaktPageComponent,
  PkCategorySettingsPageComponent,
  PkChangePasswordPageComponent,
  PkNewFormularComponent,
  PkVstupniPoleFormComponent,
  PkNewPlatebniPrikazyPageComponent,
  PkPreviewPlatebniPrikazyPageComponent,
  PkMediaPageComponent,
  PkUploadPageComponent,
  PkNavigationPageComponent,
  PkOwnerSettingsPageComponent,
  PkHelpOwnerPage,
  PkVideoHelpPageComponent,
  PkHelpUserPage,
  PkVyuctovaniPageComponent,
  PkChangeProfilePage,
  PkHelpHelpdeskPage
} from '@nx-monorepo/cms-base/layout/components/pk-page-contents';
import {PagesModule} from "./pages/pages.module";
import {PkNewUzivatelPageComponent} from "./pages/pk-new-uzivatel-page/pk-new-uzivatel-page.component";
import { createAction } from '@ngrx/store';
import { MACMSRoutes, ColumnDefinitionProvider, EntityDefinitionProvider } from '@nx-monorepo/cms-base/helpers';
import {AuthGuard, ContentGuard, TokenInterceptor} from '@nx-monorepo/cms-base/services' 
import { PageNotFoundComponent } from '@nx-monorepo/cms-base/displays/404/page-not-found.component';
import { DisplayPagesModule } from '@nx-monorepo/cms-base/displays/display-pages.module';

const ADD_FOLDER_ACTION = createAction('[Navigace] Add Folder')();
const ADD_PAGE_ACTION = createAction('[Navigace] Add Page')();

const appRoutes: MACMSRoutes = [

  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: {entityType: null, activeTab: null, pageIcon: null, pageName: null, withSearch: false, withAddCategory: true}
  },
  {
    path: 'dashboard',
    component: PkDashboardPage,
    canActivate: [AuthGuard],
    data: {
      activeTab: 'Přehled',
      pageName: 'Dashboard',
      pageIcon: {id: 1, nazev: 'dashboard'},
      withSearch: false,
      withAddCategory: false,
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'dashboard'}
      ]
    }
  },
  {
    path: 'kontakty',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Kontakt,
      activeTab: 'Přehled',
      pageName: 'Kontakty',
      pageIcon: {id: 1, nazev: 'home'},
      withSearch: true,
      withAddCategory: true,
      dynamicTableColumns: true,
      buttons: [
        {id: 1, nazev: '+ Nový kontakt', url: 'kontakty/new'}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'kontakty'}
      ]
    }
  },
  {
    path: 'kontakty/new',
    component: PkNewKontaktPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Kontakt,
      activeTab: 'Přehled',
      pageName: 'Kontakty - tvorba',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'kontakty'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'kontakty/new'}
      ]
    }
  },
  {
    path: 'kontakty/edit/:id',
    component: PkNewKontaktPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Kontakt,
      activeTab: 'Přehled',
      pageName: 'Kontakt - editace',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'kontakty'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'formulare',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard],
    data: {
      entityType: EntityTypes.Formular,
      activeTab: 'Přehled',
      pageName: 'Formuláře',
      pageIcon: {id: 1, nazev: 'home'},
      withSearch: true,
      withAddCategory: true,
      dynamicTableColumns: true,
      buttons: [
        {id: 1, nazev: '+ Nový formulář', url: 'formulare/new'}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'formulare'},
        {id: 1, nazev: 'Nastavení', url: 'formulare/nastaveni', showFor: ['canManageForms']},
        {id: 2, nazev: 'Nastavení kategorií', url: 'formulare/nastaveni_kategorii', showFor: ['canManageForms']}
      ]
    }
  },
  {
    path: 'formulare/nastaveni',
    component: PkFormGridsterPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Formular,
      activeTab: 'Nastavení',
      pageName: 'Formuláře - nastavení kategorie',
      pageIcon: {id: 1, nazev: 'home'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'formulare'},
        {id: 1, nazev: 'Nastavení', url: 'formulare/nastaveni', showFor: ['canManageForms']},
        {id: 2, nazev: 'Nastavení kategorií', url: 'formulare/nastaveni_kategorii', showFor: ['canManageForms']}
      ]
    }
  },
  {
    path: 'formulare/nastaveni_kategorii',
    component: PkCategorySettingsPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Formular,
      activeTab: 'Nastavení kategorií',
      pageName: 'Formuláře - nastavení kategorií',
      pageIcon: {id: 1, nazev: 'home'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()},
        {id: 2, nazev: 'Smazat', action: new LayoutStoreActions.DeleteButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'formulare'},
        {id: 1, nazev: 'Nastavení', url: 'formulare/nastaveni', showFor: ['canManageForms']},
        {id: 2, nazev: 'Nastavení kategorií', url: 'formulare/nastaveni_kategorii', showFor: ['canManageForms']}
      ]
    }
  },
  {
    path: 'formulare/new',
    component: PkNewFormularComponent,
    canActivate: [AuthGuard],
    data: {
      entityType: EntityTypes.Formular,
      activeTab: 'Přehled',
      pageName: 'Formuláře - tvorba',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'formulare'},
      withSearch: false,
      withAddCategory: true,
      buttons: [],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'formulare/new'}
      ]
    }
  },
  {
    path: 'formulare/edit/:id',
    component: PkNewFormularComponent,
    canActivate: [AuthGuard],
    data: {
      entityType: EntityTypes.Formular,
      activeTab: 'Přehled',
      pageName: 'Formuláře - editace',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'formulare'},
      withSearch: false,
      withAddCategory: true,
      buttons: [],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },

  {
    path: 'evidence',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Evidence,
      activeTab: 'Přehled',
      pageName: 'Evidence',
      pageIcon: {id: 1, nazev: 'home'},
      withSearch: true,
      withAddCategory: true,
      dynamicTableColumns: true,
      buttons: [
        {id: 1, nazev: '+ Nová položka', url: 'evidence/new'}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'evidence'},
        {id: 1, nazev: 'Nastavení', url: 'evidence/nastaveni'},
        {id: 2, nazev: 'Nastavení kategorií', url: 'evidence/nastaveni_kategorii'}
      ]
    }
  },
  {
    path: 'evidence/nastaveni',
    component: PkFormGridsterPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Evidence,
      activeTab: 'Nastavení',
      pageName: 'Evidence - nastavení',
      pageIcon: {id: 1, nazev: 'home'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'evidence'},
        {id: 1, nazev: 'Nastavení', url: 'evidence/nastaveni'},
        {id: 2, nazev: 'Nastavení kategorií', url: 'evidence/nastaveni_kategorii'}
      ]
    }
  },
  {
    path: 'evidence/nastaveni_kategorii',
    component: PkCategorySettingsPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Evidence,
      activeTab: 'Nastavení kategorií',
      pageName: 'Evidence - nastavení kategorií',
      pageIcon: {id: 1, nazev: 'home'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()},
        {id: 2, nazev: 'Smazat', action: new LayoutStoreActions.DeleteButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'evidence'},
        {id: 1, nazev: 'Nastavení', url: 'evidence/nastaveni'},
        {id: 2, nazev: 'Nastavení kategorií', url: 'evidence/nastaveni_kategorii'}
      ]
    }
  },
  {
    path: 'evidence/new',
    component: PkVstupniPoleFormComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Evidence,
      activeTab: 'Přehled',
      pageName: 'Evidence - tvorba',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'evidence'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'evidence/new'}
      ]
    }
  },
  {
    path: 'evidence/edit/:id',
    component: PkVstupniPoleFormComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Evidence,
      activeTab: 'Přehled',
      pageName: 'Evidence - editace',
      pageIcon: { id: 1, nazev: 'keyboard_backspace', url: 'evidence' },
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'stranky',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Stranka,
      activeTab: 'Přehled',
      pageName: 'Stránky',
      pageIcon: {id: 1, nazev: 'web'},
      withSearch: true,
      withAddCategory: false,
      buttons: [
        { id: 1, nazev: '+Vytvořit', url: '/stranky/new' },
        { id: 2, nazev: 'Smazat', action: new LayoutStoreActions.DeleteButtonPressed() }
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'stranky/edit/:id',
    component: PkFrontendGridsterComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Stranka,
      activeTab: 'Přehled',
      pageName: 'Stranky - editace',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'stranky'},
      withSearch: false,
      withAddCategory: false,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'stranky/new',
    component: PkFrontendGridsterComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Stranka,
      activeTab: 'Přehled',
      pageName: 'Správa webových stránek',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'stranky'},
      withSearch: true,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'prispevky',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Prispevek,
      activeTab: 'Přehled',
      pageName: 'Příspěvky',
      pageIcon: {id: 1, nazev: 'notes'},
      withSearch: true,
      withAddCategory: true,
      buttons: [
        { id: 1, nazev: '+Vytvořit', url: '/prispevky/new' },
        { id: 2, nazev: 'Smazat', action: new LayoutStoreActions.DeleteButtonPressed() }
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'prispevky'},
        {id: 1, nazev: 'Nastavení', url: 'prispevky/nastaveni'}
      ]
    }
  },
  {
    path: 'prispevky/nastaveni',
    component: PkCategorySettingsPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Prispevek,
      activeTab: 'Nastavení',
      pageName: 'Příspěvky - nastavení kategorie',
      pageIcon: {id: 1, nazev: 'notes'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()},
        {id: 2, nazev: 'Smazat', action: new LayoutStoreActions.DeleteButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'prispevky'},
        {id: 1, nazev: 'Nastavení', url: 'prispevky/nastaveni'}
      ]
    }
  },
  {
    path: 'prispevky/new',
    component: PkNewEntityPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Prispevek,
      activeTab: 'Přehled',
      pageName: 'Příspěvky - tvorba',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'prispevky'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'prispevky/edit/:id',
    component: PkNewEntityPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Prispevek,
      activeTab: 'Přehled',
      pageName: 'Příspěvky - editace',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'prispevky'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'uredni_deska',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.UredniDeska,
      activeTab: 'Přehled',
      pageName: 'Úřední deska',
      pageIcon: {id: 1, nazev: 'assignment_late'},
      withSearch: true,
      withAddCategory: true,
      buttons: [
        { id: 1, nazev: '+Vytvořit', url: '/uredni_deska/new' },
        { id: 2, nazev: 'Smazat', action: new LayoutStoreActions.DeleteButtonPressed() }
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'uredni_deska'},
        {id: 1, nazev: 'Nastavení', url: 'uredni_deska/nastaveni'}
      ]
    }
  },
  {
    path: 'uredni_deska/nastaveni',
    component: PkCategorySettingsPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.UredniDeska,
      activeTab: 'Nastavení',
      pageName: 'Úřední deska - nastavení',
      pageIcon: {id: 1, nazev: 'assignment_late'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()},
        {id: 2, nazev: 'Smazat', action: new LayoutStoreActions.DeleteButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'uredni_deska'},
        {id: 1, nazev: 'Nastavení', url: 'uredni_deska/nastaveni'}
      ]
    }
  },
  {
    path: 'uredni_deska/new',
    component: PkNewEntityPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.UredniDeska,
      activeTab: 'Přehled',
      pageName: 'Úřední deska - tvorba',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'uredni_deska'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },

  {
    path: 'uredni_deska/edit/:id',
    component: PkNewEntityPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.UredniDeska,
      activeTab: 'Přehled',
      pageName: 'Úřední deska - editace',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'uredni_deska'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'platebni_prikazy',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard],
    data: {
      entityType: EntityTypes.PlatebniPrikaz,
      activeTab: 'Přehled',
      pageName: 'Platební příkazy',
      pageIcon: {id: 1, nazev: 'money'},
      withSearch: true,
      withAddCategory: true,
      dynamicTableColumns: false,
      buttons: [
        {id: 1, nazev: '+ Nová položka', url: 'platebni_prikazy/new', showFor: ['canManagePayments']}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'platebni_prikazy'},
        {id: 1, nazev: 'Transakce', url: 'platebni_prikazy/transakce', showFor: ['canManagePayments']},
        {id: 2, nazev: 'Nastavení', url: 'platebni_prikazy/nastaveni', showFor: ['canManagePayments']},
        {id: 3, nazev: 'Nastavení kategorií', url: 'platebni_prikazy/nastaveni_kategorii', showFor: ['canManagePayments']}
      ]
    }
  },
  {
    path: 'platebni_prikazy/new',
    component: PkNewPlatebniPrikazyPageComponent,
    canActivate: [AuthGuard],
    data: {
      entityType: EntityTypes.PlatebniPrikaz,
      activeTab: 'Přehled',
      pageName: 'Nový platební příkaz',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'platebni_prikazy'},
      withSearch: true,
      withAddCategory: true,
      dynamicTableColumns: true,
      buttons: [
        {id: 0, nazev: 'Změnit uživatele', action: createAction('[PlatebniPrikazy] Add Users')()},
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'platebni_prikazy/transakce',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard],
    data: {
      entityType: EntityTypes.Transakce,
      activeTab: 'Transakce',
      pageName: 'Platební příkazy',
      pageIcon: {id: 1, nazev: 'money'},
      withSearch: true,
      withAddCategory: true,
      dynamicTableColumns: false,
      shouldNavigateAfterRowClick: false,
      buttons: [
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'platebni_prikazy'},
        {id: 1, nazev: 'Transakce', url: 'platebni_prikazy/transakce', showFor: ['canManagePayments']},
        {id: 2, nazev: 'Nastavení', url: 'platebni_prikazy/nastaveni', showFor: ['canManagePayments']},
        {id: 3, nazev: 'Nastavení kategorií', url: 'platebni_prikazy/nastaveni_kategorii', showFor: ['canManagePayments']}
      ]
    }
  },
  {
    path: 'platebni_prikazy/nastaveni',
    component: PkPlatebniPrikazyNastaveniPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.PlatebniPrikaz,
      activeTab: 'Nastavení',
      pageName: 'Platební příkazy - nastavení',
      pageIcon: {id: 1, nazev: 'money'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'platebni_prikazy'},
        {id: 1, nazev: 'Transakce', url: 'platebni_prikazy/transakce', showFor: ['canManagePayments']},
        {id: 2, nazev: 'Nastavení', url: 'platebni_prikazy/nastaveni', showFor: ['canManagePayments']},
        {id: 3, nazev: 'Nastavení kategorií', url: 'platebni_prikazy/nastaveni_kategorii', showFor: ['canManagePayments']}
      ]
    }
  },
  {
    path: 'platebni_prikazy/nastaveni_kategorii',
    component: PkCategorySettingsPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.PlatebniPrikaz,
      activeTab: 'Nastavení kategorií',
      pageName: 'Platební příkazy - nastavení kategorií',
      pageIcon: {id: 1, nazev: 'money'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()},
        {id: 2, nazev: 'Smazat', action: new LayoutStoreActions.DeleteButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'platebni_prikazy'},
        {id: 1, nazev: 'Transakce', url: 'platebni_prikazy/transakce', showFor: ['canManagePayments']},
        {id: 2, nazev: 'Nastavení', url: 'platebni_prikazy/nastaveni', showFor: ['canManagePayments']},
        {id: 3, nazev: 'Nastavení kategorií', url: 'platebni_prikazy/nastaveni_kategorii', showFor: ['canManagePayments']}
      ]
    }
  },
  {
    path: 'platebni_prikazy/edit/:id',
    component: PkPreviewPlatebniPrikazyPageComponent,
    canActivate: [AuthGuard],
    data: {
      entityType: EntityTypes.PlatebniPrikaz,
      activeTab: 'Přehled',
      pageName: 'Platební příkaz - náhled',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'platebni_prikazy'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        /*{ id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed() }*/
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'uzivatele',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Uzivatel,
      activeTab: 'Přehled',
      pageName: 'Uživatelé',
      pageIcon: {id: 1, nazev: 'people'},
      withSearch: true,
      withAddCategory: false,
      buttons: [
        {id: 1, nazev: '+Vytvořit', url: '/uzivatele/new'},
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'uzivatele/new',
    component: PkNewUzivatelPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Uzivatel,
      activeTab: 'Přehled',
      pageName: 'Uživatelé - tvorba',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'uzivatele'},
      withSearch: false,
      withAddCategory: false,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'uzivatele/edit/:id',
    component: PkNewUzivatelPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Uzivatel,
      activeTab: 'Přehled',
      pageName: 'Uživatelé - editace',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'uzivatele'},
      withSearch: false,
      withAddCategory: false,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'galerie',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Galerie,
      activeTab: 'Přehled',
      pageName: 'Galerie',
      pageIcon: {id: 1, nazev: 'photo_album'},
      withSearch: true,
      withAddCategory: false,
      buttons: [
        { id: 1, nazev: '+Vytvořit', url: '/galerie/new' },
        { id: 2, nazev: 'Smazat', action: new LayoutStoreActions.DeleteButtonPressed() }
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'galerie/new',
    component: PkNewEntityPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Galerie,
      activeTab: 'Přehled',
      pageName: 'Galerie - tvorba',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'galerie'},
      withSearch: false,
      withAddCategory: false,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'galerie/edit/:id',
    component: PkNewEntityPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Galerie,
      activeTab: 'Přehled',
      pageName: 'Galerie - editace',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'galerie'},
      withSearch: false,
      withAddCategory: false,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'udalosti',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Udalost,
      activeTab: 'Přehled',
      pageName: 'Události',
      pageIcon: {id: 1, nazev: 'calendar_today'},
      withSearch: true,
      withAddCategory: true,
      buttons: [
        { id: 1, nazev: '+Vytvořit', url: '/udalosti/new' },
        { id: 2, nazev: 'Smazat', action: new LayoutStoreActions.DeleteButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'udalosti'},
        {id: 1, nazev: 'Nastavení', url: 'udalosti/nastaveni'}
      ]
    }
  },
  {
    path: 'udalosti/nastaveni',
    component: PkCategorySettingsPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Udalost,
      activeTab: 'Nastavení',
      pageName: 'Události - nastavení kategorie',
      pageIcon: {id: 1, nazev: 'calendar_today'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()},
        {id: 2, nazev: 'Smazat', action: new LayoutStoreActions.DeleteButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', url: 'udalosti'},
        {id: 1, nazev: 'Nastavení', url: 'udalosti/nastaveni'}
      ]
    }
  },
  {
    path: 'udalosti/new',
    component: PkNewEntityPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Udalost,
      activeTab: 'Přehled',
      pageName: 'Událost - tvorba',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'udalosti'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'udalosti/edit/:id',
    component: PkNewEntityPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Udalost,
      activeTab: 'Přehled',
      pageName: 'Událost - editace',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'udalosti'},
      withSearch: false,
      withAddCategory: true,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled'}
      ]
    }
  },
  {
    path: 'media',
    component: PkMediaPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Media,
      activeTab: 'Přehled',
      pageName: 'Média a soubory',
      pageIcon: {id: 1, nazev: 'insert_drive_file'},
      withSearch: true,
      withAddCategory: false,
      buttons: [
        { id: 1, nazev: '+ Přidat', url: 'media/upload/files' },
        { id: 2, nazev: 'Smazat', action: new LayoutStoreActions.DeleteButtonPressed() }
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', action: null}
      ]
    }
  },
  {
    path: 'media/upload/files',
    component: PkUploadPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Media,
      activeTab: 'Soubory',
      pageName: 'Média a soubory - soubory',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'media'},
      withSearch: false,
      withAddCategory: false,
      componentInput: {
        isUrl: false
      },
      buttons: [
        {id: 1, nazev: 'Zahájit nahrávání', action: createAction('[Media] Upload File')()}
      ],
      tabs: [
        {id: 0, nazev: 'Soubory', url: 'media/upload/files'},
        {id: 1, nazev: 'URL', url: 'media/upload/external'}
      ]
    }
  },
  {
    path: 'media/upload/external',
    component: PkUploadPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Media,
      activeTab: 'URL',
      pageName: 'Média a soubory - zdroj z URL',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'media'},
      withSearch: false,
      withAddCategory: false,
      componentInput: {
        isUrl: true
      },
      buttons: [
        {id: 2, nazev: 'Přidat další URL', action: createAction('[Media] Add Url')()},
        {id: 1, nazev: 'Nahrát URL odkazy', action: createAction('[Media] Upload External File')()}
      ],
      tabs: [
        {id: 0, nazev: 'Soubory', url: 'media/upload/files'},
        {id: 1, nazev: 'URL', url: 'media/upload/external'}
      ]
    }
  },
  {
    path: 'navigace',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Navigace,
      activeTab: 'Přehled',
      pageName: 'Navigace',
      pageIcon: {id: 1, nazev: 'directions'},
      withSearch: false,
      withAddCategory: false,
      buttons: [
        {id: 1, nazev: '+ Vytvořit', url: '/navigace/new'},
        {id: 2, nazev: 'Smazat', action: new TableStoreActions.DeleteSelectedItems()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', action: null}
      ]
    }
  },
  {
    path: 'navigace/new',
    component: PkNavigationPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Navigace,
      activeTab: 'Přehled',
      pageName: 'Navigace - tvorba',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'navigace'},
      withSearch: false,
      withAddCategory: false,
      buttons: [
        {id: 1, nazev: '+ Přidat složku', action: ADD_FOLDER_ACTION},
        {id: 2, nazev: '+ Přidat stránku', action: ADD_PAGE_ACTION},
        {id: 3, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', action: null}
      ]
    }
  },
  {
    path: 'navigace/edit/:id',
    component: PkNavigationPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Navigace,
      activeTab: 'Přehled',
      pageName: 'Navigace - editace',
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'navigace'},
      withSearch: false,
      withAddCategory: false,
      buttons: [
        {id: 1, nazev: '+ Přidat složku', action: ADD_FOLDER_ACTION},
        {id: 2, nazev: '+ Přidat stránku', action: ADD_PAGE_ACTION},
        {id: 3, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Přehled', action: null}
      ]
    }
  },
  {
    path: 'current-obec',
    component: PkOwnerSettingsPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Owner,
      activeTab: 'Obecní informace',
      pageName: 'Obecní informace',
      pageIcon: {id: 1, nazev: 'location_city'},
      withSearch: false,
      withAddCategory: false,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Obecní informace', url: 'current-obec'},
        {id: 1, nazev: 'Přední strana', url: 'current-obec/front'}
      ]
    }
  },
  {
    path: 'current-obec/front',
    component: PkOwnerSettingsPageComponent,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      entityType: EntityTypes.Owner,
      activeTab: 'Přední strana',
      pageName: 'Přední strana',
      pageIcon: {id: 1, nazev: 'location_city'},
      withSearch: false,
      withAddCategory: false,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: [
        {id: 0, nazev: 'Obecní informace', url: 'current-obec'},
        {id: 1, nazev: 'Přední strana', url: 'current-obec/front'}
      ]
    }
  },
  {
    path: 'napoveda/admin',
    component: PkHelpOwnerPage,
    canActivate: [AuthGuard, ContentGuard],
    data: {
      activeTab: 'Nápověda (administrátor)',
      pageName: 'Nápověda',
      pageIcon: {id: 1, nazev: 'location_city'},
      withSearch: false,
      withAddCategory: false,
      buttons: [],
      tabs: [
        {id: 0, nazev: 'Nápověda (administrátor)', url: 'napoveda/admin', showFor: ['canManageContent']},
        {id: 1, nazev: 'Nápověda (uživatel)', url: 'napoveda/user'},
        { id: 2, nazev: 'Nápověda (videa)', url: 'napoveda/videa', showFor: ['canManageContent'] },
        { id: 3, nazev: 'Helpdesk', url: 'napoveda/helpdesk'}
      ]
    }
  },
  {
    path: 'napoveda/videa',
    component: PkVideoHelpPageComponent,
    canActivate: [AuthGuard],
    data: {
      entityType: EntityTypes.Owner,
      activeTab: 'Nápověda (videa)',
      pageName: "Nápověda",
      pageIcon: { id: 1, nazev: 'location_city' },
      withSearch: false,
      withAddCategory: false,
      buttons: [],
      tabs: [
        { id: 0, nazev: 'Nápověda (administrátor)', url: 'napoveda/admin', showFor: ['canManageContent'] },
        { id: 1, nazev: 'Nápověda (uživatel)', url: 'napoveda/user' },
        { id: 2, nazev: 'Nápověda (videa)', url: 'napoveda/videa', showFor: ['canManageContent'] },
        { id: 3, nazev: 'Helpdesk', url: 'napoveda/helpdesk'}
      ]
    }
  },
  {
    path: 'napoveda/user',
    component: PkHelpUserPage,
    canActivate: [AuthGuard],
    data: {
      entityType: EntityTypes.Owner,
      activeTab: 'Nápověda (uživatel)',
      pageName: "Nápověda",
      pageIcon: { id: 1, nazev: 'location_city' },
      withSearch: false,
      withAddCategory: false,
      buttons: [],
      tabs: [
        { id: 0, nazev: 'Nápověda (administrátor)', url: 'napoveda/admin', showFor: ['canManageContent'] },
        { id: 1, nazev: 'Nápověda (uživatel)',  url: 'napoveda/user' },
        { id: 2, nazev: 'Nápověda (videa)', url: 'napoveda/videa', showFor: ['canManageContent'] },
        { id: 3, nazev: 'Helpdesk', url: 'napoveda/helpdesk'}
      ]
    }
  },
  {
    path: 'napoveda/helpdesk',
    component: PkHelpHelpdeskPage,
    canActivate: [AuthGuard],
    data: {
      entityType: EntityTypes.Owner,
      activeTab: 'Helpdesk',
      pageName: "Nápověda",
      pageIcon: { id: 1, nazev: 'location_city' },
      withSearch: false,
      withAddCategory: false,
      buttons: [],
      tabs: [
        { id: 0, nazev: 'Nápověda (administrátor)', url: 'napoveda/admin', showFor: ['canManageContent'] },
        { id: 1, nazev: 'Nápověda (uživatel)',  url: 'napoveda/user' },
        { id: 2, nazev: 'Nápověda (videa)', url: 'napoveda/videa', showFor: ['canManageContent'] },
        { id: 3, nazev: 'Helpdesk', url: 'napoveda/helpdesk'}
      ]
    }
  },
  {
    path: 'change-password',
    component: PkChangePasswordPageComponent,
    canActivate: [AuthGuard],
    data: {
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'dashboard'},
      withSearch: false,
      withAddCategory: false,
      pageName: 'Změna hesla',
      buttons: [],
      tabs: [],
    }
  },
  {
    path: 'change-profile',
    component: PkChangeProfilePage,
    canActivate: [AuthGuard],
    data: {
      entityType: EntityTypes.Uzivatel,
      pageName: 'Změnit profilové informace',
      pageIcon: {id: 1, nazev: 'account_circle'},
      withSearch: false,
      withAddCategory: false,
      buttons: [
        {id: 1, nazev: 'Uložit', action: new LayoutStoreActions.SaveButtonPressed()},
      ],
      tabs: []
    }
  },
  {
    path: 'sms/new',
    component: PkNewEntityPageComponent,
    canActivate: [AuthGuard],
    data: {
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'sms'},
      entityType: EntityTypes.Sms,
      withSearch: false,
      withAddCategory: false,
      hideCategories: true,
      pageName: 'Nová SMS',
      buttons: [
        {id: 1, nazev: "Odeslat", action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: []
    }
  },
  {
    path: 'sms',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard],
    data: {
      pageIcon: {id: 1, nazev: 'sms'},
      entityType: EntityTypes.Sms,
      withSearch: true,
      withAddCategory: false,
      hideCategories: true,
      shouldNavigateAfterRowClick: false,
      pageName: 'Přehled odeslaných SMS',
      activeTab: 'Přehled',
      buttons: [{id: 0, nazev: "Nový", url: '/sms/new'}],
      tabs: [
        {id: 1, nazev: 'Přehled', url: 'sms'},
        {id: 2, nazev: 'Vyúčtování', url: 'sms/vyuctovani'}
      ]
    }
  },
  {
    path: 'sms/vyuctovani',
    component: PkVyuctovaniPageComponent,
    canActivate: [AuthGuard],
    data: {
      pageIcon: {id: 1, nazev: 'sms'},
      entityType: EntityTypes.Sms,
      withSearch: false,
      withAddCategory: false,
      hideCategories: true,
      pageName: 'Vyúčtování SMS',
      activeTab: 'Vyúčtování',
      buttons: [],
      tabs: [
        {id: 1, nazev: 'Přehled', url: 'sms'},
        {id: 2, nazev: 'Vyúčtování', url: 'sms/vyuctovani'}
      ]
    }
  },
  {
    path: 'email/new',
    component: PkNewEntityPageComponent,
    canActivate: [AuthGuard],
    data: {
      pageIcon: {id: 1, nazev: 'keyboard_backspace', url: 'email'},
      entityType: EntityTypes.Email,
      withSearch: false,
      withAddCategory: false,
      hideCategories: true,
      pageName: 'Nový email',
      //activeTab: 'Napsání emailu',
      buttons: [
        {id: 1, nazev: "Odeslat", action: new LayoutStoreActions.SaveButtonPressed()}
      ],
      tabs: []
    }
  },
  {
    path: 'email',
    component: PkDataTablePageComponent,
    canActivate: [AuthGuard],
    data: {
      pageIcon: {id:1, nazev: 'email'},
      entityType: EntityTypes.Email,
      withSearch: true,
      withAddCategory: false,
      hideCategories: true,
      shouldNavigateAfterRowClick: false,
      pageName: 'Přehled odeslaných emailů',
      activeTab: 'Přehled',
      buttons: [{id: 0, nazev: "Nový", url: '/email/new'}],
      tabs: [
        {id: 1, nazev: 'Přehled', url: 'email'},
        {id: 2, nazev: 'Vyúčtování', url: 'email/vyuctovani'}
        ]
    }
  },
  {
    path: 'email/vyuctovani',
    component: PkVyuctovaniPageComponent,
    canActivate: [AuthGuard],
    data: {
      pageIcon: {id: 1, nazev: 'email'},
      entityType: EntityTypes.Email,
      withSearch: false,
      withAddCategory: false,
      hideCategories: true,
      pageName: 'Vyúčtování emailů',
      activeTab: 'Vyúčtování',
      buttons: [],
      tabs: [
        {id: 1, nazev: 'Přehled', url: 'email'},
        {id: 2, nazev: 'Vyúčtování', url: 'email/vyuctovani'}
      ]
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {entityType: null, activeTab: null, pageIcon: null, pageName: null, withSearch: false, withAddCategory: true}
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    DisplayPagesModule,
    CmsModule,
    PagesModule,
    RouterModule.forRoot(appRoutes),
    FuseModule.forRoot(fuseConfig)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {provide: ColumnDefinitionProvider, useClass: ObecColumnDefinitionProvider},
    {provide: EntityDefinitionProvider, useClass: ObecEntityDefinitionProvider},
    {provide: KATEGORIE_SERVICE_TOKEN, useClass: KategorieService},
    {provide: KONTAKT_SERVICE_TOKEN, useClass: KontaktService},
    {provide: ROLE_SERVICE_TOKEN, useClass: RoleService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
