<div>
  <section id="prispevky"
           [style.background]="settings.outerBackground">
    <macms-heading
      *ngIf="settings.showHeading"
      [settings]="settings.headingProps"
      [text]="data.nazev || 'Příspěvky'">
    </macms-heading>
    <ng-template *ngIf="settings.uvod" [ngTemplateOutlet]="popis">
    </ng-template>
    <div class="grid">
      <ng-container *ngFor="let prispevek of data.items">
        <a [routerLink]="(prispevek.slug ? prispevek.slug : prispevek.id) | DetailUrl:'prispevky':prispevek.kategorie.nazev"
           class="item" [style.background]="'#fff'" [paddingDefinition]="settings.itemPadding" [marginDefinition]="settings.itemMargin"  [style.flexBasis]="settings.itemFlexBasis">
          <ng-template [ngTemplateOutlet]="category"
                       [ngTemplateOutletContext]="{polozka:prispevek}"></ng-template>
          <div *ngIf="prispevek.media" class="image">
            <img src="{{prispevek.media.url}}" alt="">
          </div>
          <div [style.color]="settings.primaryColor" [style.margin-top]="prispevek.media ? '0' : '1.1rem'" class="content">
            <h2 [style.fontFamily]="obecSettings.title_font">{{prispevek?.nazev}}</h2>
            <div class="obsah"><p [style.fontFamily]="obecSettings.paragraph_font" [innerHtml]="prispevek.uvod ? prispevek.uvod : prispevek.obsah"></p></div>
            <div class="prispevek-footer" [contrastForeground]="'#fff'" [preferedColors]="[settings.primaryColor]">
              <div class="datum"
                   [style.fontFamily]="obecSettings.paragraph_font">{{prispevek.datum_zverejneni | date:'mediumDate':undefined:'cs-CZ' }}</div>
              <div class="autor"
                   [style.fontFamily]="obecSettings.paragraph_font">{{prispevek?.autor.jmeno}} {{prispevek?.autor.prijmeni}}</div>
            </div>
          </div>
          <!--<div class="bottom" fxLayout="row" [style.color]="onSubSecondaryColor" [style.background]="secondaryColor">
            <ng-template [ngTemplateOutlet]="downloadList"
                         [ngTemplateOutletContext]="{polozka:prispevek}"></ng-template>
          </div>-->
        </a>
      </ng-container>
    </div> <!-- end of grid -->

    <macms-next-button href="/prispevky" [settings]="nextButtonSettings" text="Další příspěvky"></macms-next-button>
  </section>
</div>

<ng-template #category let-polozka="polozka">
  <div class="category" fxLayout="row" fxLayoutAlign="center center"
       macmsColor
       [mc_isInverted]="true"
       [mc_wantedPrimary]="polozka.kategorie.color ? polozka.kategorie.color : settings.primaryColor"
       [style.fontFamily]="obecSettings.title_font">
    {{polozka.kategorie.nazev}}
  </div>
</ng-template>

<ng-template #popis>
  <div [style.color]="settings.primaryColor" [style.fontFamily]="obecSettings.paragraph_font" style="width:100%;" class="intro-text">
    <p>{{ settings.uvod }}</p>
  </div>
</ng-template>

<!--<ng-template #downloadList let-polozka="polozka">
  <div [style.background]="subSecondaryColor" fxLayoutAlign="center center" class="file">
    <div class="icon-wrapper" fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 0"
         [style.background]="accentColor"
         [contrastForeground]="accentColor"
         [preferedColors]="[primaryColor]"
    >
    <fa-icon [icon]="downloadIcon"></fa-icon>
    </div>
    <div class="attachment"
         [style.color]="onSubSecondaryColor"
         [style.fontFamily]="obecSettings.paragraph_font">
      Počet příloh: {{polozka.priloha.length}}
    </div>
  </div>
</ng-template>-->
