import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PkInputTextFieldComponent } from './pk-input-text-field/pk-input-text-field.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexModule } from '@angular/flex-layout';
import { PkInputDateFieldComponent } from './pk-input-date-field/pk-input-date-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PkHiddenFieldComponent } from './pk-hidden-field/pk-hidden-field.component';
import { PkSelectFieldComponent } from './pk-select-field/pk-select-field.component';
import { PkInputChipsFieldComponent } from './pk-input-chips-field/pk-input-chips-field.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PkInputWysiwygComponent } from './pk-input-wysiwyg/pk-input-wysiwyg.component';
import { PkInputCheckboxFieldComponent } from './pk-input-checkbox-field/pk-input-checkbox-field.component';
import { PkInputAutocompleteComponent } from './pk-input-autocomplete/pk-input-autocomplete.component';
import { PkInputEntityFieldComponent } from './pk-input-entity-field/pk-input-entity-field.component';
import { PkIconFieldComponent } from './pk-icon-field/pk-icon-field.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PkColorPickerFieldComponent } from './pk-color-picker-field/pk-color-picker-field.component';
import { ColorChromeModule } from 'ngx-color/chrome';
import { PkInputRepeaterFieldComponent } from './pk-input-repeater-field/pk-input-repeater-field.component';
import { NgSharedModule } from '@nx-monorepo/ng-shared';
import { DynamicRendererModule } from '../../dynamic-renderer/dynamic-renderer.module';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { UtilsModule } from 'libs/frontend-components/src/lib/utils/utils.module';
import { PkInputDateTimeFieldComponent } from './pk-input-date-time-field/pk-input-date-time-field.component';
import { MatDatetimepickerModule, MatNativeDatetimeModule} from '@mat-datetimepicker/core';
import { CustomDateAdapter } from './pk-input-date-time-field/custom-date-adapter';
import { PkInputBoxFieldComponent } from './pk-input-box-field/pk-input-box-field.component';

@NgModule({
  declarations: [
    PkInputWysiwygComponent,
    PkInputTextFieldComponent,
    PkInputDateFieldComponent,
    PkHiddenFieldComponent,
    PkSelectFieldComponent,
    PkInputChipsFieldComponent,
    PkInputCheckboxFieldComponent,
    PkInputAutocompleteComponent,
    PkInputEntityFieldComponent,
    PkIconFieldComponent,
    PkColorPickerFieldComponent,
    PkInputRepeaterFieldComponent,
    PkInputDateTimeFieldComponent,
    PkInputBoxFieldComponent
  ],
  exports: [
    PkInputTextFieldComponent,
    PkInputDateFieldComponent,
    PkHiddenFieldComponent,
    PkSelectFieldComponent,
    PkInputChipsFieldComponent,
    PkInputCheckboxFieldComponent,
    PkInputWysiwygComponent,
    PkInputAutocompleteComponent,
    PkInputEntityFieldComponent,
    PkIconFieldComponent,
    PkColorPickerFieldComponent,
    PkInputRepeaterFieldComponent,
    PkInputDateTimeFieldComponent,
    PkInputBoxFieldComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    FlexModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatChipsModule,
    MatCheckboxModule,
    CKEditorModule,
    MatAutocompleteModule,
    MatButtonModule,
    FontAwesomeModule,
    ColorChromeModule,
    NgSharedModule,
    DynamicRendererModule,
    UtilsModule,
    MatDatetimepickerModule,
    MatNativeDatetimeModule,
  ],
  entryComponents: [
    PkInputTextFieldComponent,
    PkInputDateFieldComponent,
    PkHiddenFieldComponent,
    PkSelectFieldComponent,
    PkInputChipsFieldComponent,
    PkInputCheckboxFieldComponent,
    PkInputWysiwygComponent,
    PkInputAutocompleteComponent,
    PkInputEntityFieldComponent,
    PkIconFieldComponent,
    PkColorPickerFieldComponent,
    PkInputRepeaterFieldComponent,
    PkInputDateTimeFieldComponent,
    PkInputBoxFieldComponent
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'cs-CZ'},
    {provide: DateAdapter, useClass: CustomDateAdapter},
  ]

})
export class PkInputFieldsModule {
}
