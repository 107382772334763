<div class="wrapperUdalosti" [style.background]="wrapperBackground | fixBackgroundUrl">
  <macms-heading
    *ngIf="settings.showHeading"
    [settings]="headingSettings"
    [text]="settings.nazev || 'Události'"
  >
  </macms-heading>

  <section
    [style.background]="settings.sectionBackground ? (settings.sectionBackground | fixBackgroundUrl): ''"
    resizeObserver
    (resize)="onResize($event)"
    id="udalosti"
  >
    <ng-template *ngIf="settings.uvod" [ngTemplateOutlet]="popis">
    </ng-template>
    <ng-container *ngIf="(calendarData$| async) as calendarData">
      <div
        class="calendar-wrap"
        macmsColor
        [mc_underTransparentColor]="settings.sectionBackground ? settings.sectionBackground : wrapperBackground"
        [mc_otherColors]="[settings.accentColor, settings.secondaryColor]"
        [mc_useTransparent]="true"
        [mc_wantedPrimary]="settings.primaryColor"
      >
        <div class="left-col col">
          <div class="calendar-switch" [style.fontFamily]="obecSettings.title_font"
          >
            <fa-icon (click)="nextMonth(false)" icon="arrow-left"></fa-icon>
            <span>{{months[currentMonth]}} {{currentYear}}</span>
            <fa-icon (click)="nextMonth()" icon="arrow-right"></fa-icon>
          </div>
          <ul
            [style.margin]="'-'+calendarSpacing"
          >
            <li class="head"
                [style.fontFamily]="obecSettings.title_font"
                [style.border]="'none'"
                *ngFor="let day of days">
              <span
                [style.fontFamily]="obecSettings.title_font">
                {{ day }}
              </span>
            </li>
            <ng-container *ngIf="firstDateOfCurrentMonth > 0">
              <li *ngFor="let x of [].constructor(firstDateOfCurrentMonth)">
              </li>
            </ng-container>
            <ng-container
              *ngFor="let item of calendarData.calendarEvents | keyvalue; let idx = index">
              <li (click)="changeSelected(idx)"
                  [style.padding]="calendarSpacing"
                  class="day">
                <div class="squareBox"
                     [borderDefinition]="settings.dayBorder"
                     [style.fontFamily]="obecSettings.title_font"
                     macmsColor
                     [mc_contrastThreshold]="5"
                     [mc_underTransparentColor]="settings.sectionBackground ? settings.sectionBackground : wrapperBackground"
                     [mc_useTransparent]="true"
                     [mc_wantedPrimary]="idx | determineCalendarColor:[settings.primaryColor, settings.secondaryColor, settings.accentColor]:calendarData.selectedIdx:todayIndex"
                     [mc_otherColors]="[settings.primaryColor]"
                     [mc_isInverted]="idx === calendarData.selectedIdx ? settings.invertCurrentDay : settings.areCalendarDaysInverted"
                >
                  <div class="squareContentWrapper">
                    <div class="squareContent">
                      {{ item.key | amDateFormat:"DD"}}
                      <div class="dots">
                        <ng-container *ngFor="let dot of item.value">
                          <div class="dot" [style.background]="dot.kategorie.color"></div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <div [style.color]="settings.primaryColor" class="middleLine"></div>
        <div class="right-col">

          <span [style.fontFamily]="obecSettings.title_font">Události dne {{calendarData.selectedIdx + 1}}. {{currentMonth + 1}}. {{currentYear}}</span>
          <ng-container *ngIf="calendarData.showedEvents.length; else noUdalost">
            <ng-container *ngFor="let udalost of calendarData.showedEvents">
              <ng-template [ngTemplateOutlet]="udalostTemplate"
                           [ngTemplateOutletContext]="{udalost: udalost}"></ng-template>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="settings.showIncomingEvents && calendarData.showedEvents.length < this.settings.maxItemCount">
            <span [style.fontFamily]="obecSettings.title_font">Další nadcházející události</span>
            <ng-container
              *ngFor="let udIdx of (this.settings.maxItemCount - calendarData.showedEvents.length) | times:calendarData.nextEvents.length">
              <ng-template [ngTemplateOutlet]="udalostTemplate"
                           [ngTemplateOutletContext]="{udalost: calendarData.nextEvents[udIdx]}"></ng-template>
            </ng-container>
          </ng-container>

        </div>
      </div>
    </ng-container>
    <macms-next-button
      href="/udalosti"
      [settings]="nextButtonSettings"
      text="Další události"
    ></macms-next-button>
  </section>
</div>


<ng-template #category let-udalost="udalost">
  <div [style.fontFamily]="obecSettings.title_font" style="width:100%; text-align:left;" class="category">
    <p>Kategorie: {{ udalost.kategorie.nazev }}</p>
  </div>
</ng-template>

<ng-template #popis>
  <div [style.fontFamily]="obecSettings.title_font" [style.color]="settings.primaryColor"
       style="width:100%;" class="intro-text">
    <p>{{ settings.uvod }}</p>
  </div>
</ng-template>

<ng-template #udalostTemplate let-udalost="udalost">
  <a
    [routerLink]="(udalost.slug) | DetailUrl:'udalosti':udalost.kategorie.nazev"
    class="item-right-col">
    <div class="item-wrap">
      <div class="date"
           macmsColor
           [style.fontFamily]="obecSettings.title_font"
           [mc_contrastThreshold]="4"
           [mc_wantedPrimary]="settings.primaryColor"
           [mc_otherColors]="[settings.secondaryColor]"
           [mc_isInverted]="settings.areEventsInverted"
           [mc_underTransparentColor]="settings.sectionBackground ? settings.sectionBackground : settings.wrapperBackground ? settings.wrapperBackground : '#fff'"
           [borderDefinition]="settings.eventBorder">
        <!-- {{udalost.datum_zacatek}} -->
        <div
          class="content"
        >
          <span class="day">
          {{
          udalost.datum_zacatek | amLocale: 'cs' | amDateFormat: 'DD'
          }}.
         </span>

          <span class="month">
          {{
            udalost.datum_zacatek | amLocale: 'cs' | amDateFormat: 'MMM'
            }}
          </span>
        </div>
      </div>
      <div class="content">
        <div class="left">
          <h3
            style="margin-top: 0; margin-bottom: 0.5rem;"
            [style.fontFamily]="obecSettings.title_font"
            macmsColor
            [mc_contrastThreshold]="5"
            [mc_wantedPrimary]="settings.primaryColor"
            [mc_otherColors]="[settings.secondaryColor]"
            [mc_isInverted]="false"
            [mc_underTransparentColor]="settings.sectionBackground ? settings.sectionBackground : settings.wrapperBackground ? settings.wrapperBackground : '#fff'"
          >
            {{ udalost.nazev }}
          </h3>
          <p
            style="margin:0;"
            *ngIf="settings.showPopis"
            [style.fontFamily]="obecSettings.paragraph_font"
            [style.color]="settings.primaryColor"
          >
            {{ udalost.popis }}
          </p>
        </div>
        <div class="right" *ngIf="udalost.media">
          <img src="{{ udalost.media.url }}"/>
        </div>
      </div>
      <!-- <ng-template [ngTemplateOutlet]="category"
      [ngTemplateOutletContext]="{udalost:udalost}"></ng-template> -->
    </div>
  </a>
</ng-template>

<ng-template #noUdalost>
  <div
    [style.color]="settings.primaryColor"
    [style.fontFamily]="obecSettings.paragraph_font"
    class="item-right-col" fxLayout="column">
    V tento den není žádná událost.
  </div>
</ng-template>
