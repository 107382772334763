import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {of as observableOf, Subject} from 'rxjs';
import {BasePageComponent} from '../base-page.component';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {Action, ActionsSubject, Store} from '@ngrx/store';
import {CategoryTabsStoreSelector, MACMSStoreState} from '@nx-monorepo/cms-base/store';
import {FormControl, FormGroup} from '@angular/forms';
import {KolekceService, VstupniPoleService} from '@nx-monorepo/obce/ng/services';
import {ApolloQueryResult} from 'apollo-client';
import {catchError, filter, map, take, takeUntil} from 'rxjs/operators';
import { GraphQLError } from 'graphql';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import { PkModuleItem } from '@nx-monorepo/cms-base/components';
import { LayoutActionTypes } from '@nx-monorepo/cms-base/store/layout/layout.actions';
import { displaySnack } from '@nx-monorepo/cms-base/helpers';
import { SnackbarStoreActions } from '@nx-monorepo/cms-base/store/snackbar';

@Component({
  selector: 'pk-vstupni-pole-form',
  templateUrl: './pk-vstupni-pole-form.component.html',
  styleUrls: ['./pk-vstupni-pole-form.component.scss'],
  animations: fuseAnimations
})
export class PkVstupniPoleFormComponent extends BasePageComponent implements OnInit, OnDestroy {
  public currentFormId: number; // in case of Edit is set else null
  public formFieldDefinitions: IEntityDefinition[];
  public selectedCategoryId: number;
  public form: FormGroup = new FormGroup({});

  @Input() disabled = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  // private saveAction$: Subscription;

  constructor(store$: Store<MACMSStoreState.State>, currentRoute: ActivatedRoute, private router: Router, private actionsSubject$: ActionsSubject, private vstupniPoleService: VstupniPoleService, private kolekceService: KolekceService) {
    super(store$, currentRoute);
  }

  ngOnInit(): void {
    const formId = this.currentRoute.snapshot.paramMap.get('id');
    if (formId) {
      // edit
      this.initForEdit(formId);

    } else {
      // create
      this.initForCreate();
    }

    this.subscribeToSaveButtonAction();
  }


  private initForEdit(formId: string) {
    // set the current form id globally
    this.currentFormId = parseInt(formId, 10);

    // fetch the form
    this.fetchFormData(this.currentFormId).subscribe((formular: any) => {
      // set selected category id from the form
      this.selectedCategoryId = formular.kategorie.id;

      // fetch vstupni pole, generate the form and patch the values from form
      this.fetchVstupniPole(this.selectedCategoryId).subscribe(vstupniPole => {
        this.generateForm(vstupniPole);

        const patchValues = {};
        formular.pole.forEach(pole => {
          patchValues[pole.vstupni_pole_id] = this.getPoleDataValue(pole.data);
        });
        this.form.patchValue(patchValues);
      });
    });
  }

  // neco se musi parsovat, stejne jako se neco mumselo stringifikvoat na radku 199
  private getPoleDataValue(poleData: any) {
    let data = null;
    try {
      // zkusime parse - vetsinou jen pro multiple select a jine array based veci
      data = JSON.parse(poleData);
    } catch (e) {
      // kdzy failneme tak dame hodnotu
      data = poleData;
    } finally {
      // z cisel potrebujeme string hodnoty
      data = typeof data === 'number' ? data.toString() : data;
    }

    return data;
  }

  private initForCreate() {
    // subscribe to category changes
    this.store$.select(CategoryTabsStoreSelector.selectActiveCategory)
      .pipe(takeUntil(this.destroy$))
      .subscribe((activeCategory) => {
        this.selectedCategoryId = activeCategory.id;

        // fetch vstupni pole for selected category
        this.fetchVstupniPole(this.selectedCategoryId).subscribe(vstupniPole => this.generateForm(vstupniPole));
      });
  }

  private fetchVstupniPole(category_id: number) {
    return this.vstupniPoleService.fetchAll({categories: [category_id]}).pipe(
      take(1),
      map((result: any) => {
        return result.data.response.items.map(item => {
          const settings = {
            ...JSON.parse(item.vstupni_komponenta_settings),
            formControlName: item.id.toString()
          };

          const formFieldDefinition = {
            componentName: this.getComponentNameFromVstupniKomponenta(JSON.parse(item.vstupni_komponenta)),
            settings: settings,
            data: JSON.parse(item.vstupni_komponenta_data),
            id: item.id,
            cols: item.cols,
            x: item.coord_x,
            y: item.coord_y
          };
          return formFieldDefinition;
        });
      }),
      catchError((error) => {
        // todo zobrazit error
        console.error(error);
        return observableOf(error);
      })
    );
  }

  private fetchFormData(id: number) {
    return this.kolekceService.fetchSingle(this.routeData.entityType, id).pipe(
      take(1)
    );
  }

  private generateForm(formFieldDefinitions: IEntityDefinition[]) {
    // set the form field definitions
    this.formFieldDefinitions = formFieldDefinitions;

    // pro kazdou field entity
    this.formFieldDefinitions.forEach((entityDefinition: IEntityDefinition) => {
      // nastavit jim konkretni form
      entityDefinition.settings.form = this.form;

      // console.log("IS FORM DISABLED ??? ", this.disabled);
      // pridat do formy control
      this.form.addControl(
        entityDefinition.settings.formControlName,
        new FormControl({
          value: entityDefinition.settings.defaultValue || '',
          disabled: this.disabled /*|| entityDefinition.settings.disabled*/
        }, entityDefinition.validators)
      );
    });
  }

  ngOnDestroy(): void {
    // reset form
    this.form.reset();

    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  private getComponentNameFromVstupniKomponenta(vstupniKomponenta: PkModuleItem) {
    switch (vstupniKomponenta.type) {
      case 'PkGridsterItemTextfieldComponent':
        return 'PkInputTextFieldComponent';
      case 'PkGridsterItemDateComponent':
        return 'PkInputDateFieldComponent';
      case 'PkGridsterItemSelectComponent':
      case 'PkGridsterItemMultipleSelectComponent':
        return 'PkSelectFieldComponent';
      case 'PkGridsterItemCheckboxComponent':
        return 'PkInputCheckboxFieldComponent';
      case 'PkGridsterInputWysiwygComponent':
        return 'PkInputWysiwygComponent';
    }

  }

  private subscribeToSaveButtonAction() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === LayoutActionTypes.SAVE_BUTTON_PRESSED)
    ).subscribe(action => {
      console.log(this.form.value);
      if (this.form.valid) {
        this.saveForm();
      } else {
        displaySnack('Vyplňte prosím všechny položky!', this.store$);
      }
    });
  }

  private getFormularPayload(additionalPayloadVars?: {}) {
    const pole = [];
    for (const [key, value] of Object.entries(this.form.value)) {
      pole.push({
        vstupni_pole_id: parseInt(key, 10),
        data: (Array.isArray(value) || typeof value === 'boolean') ? JSON.stringify(value) : value // arraye a booleany se musi stringifikovat
      });
    }

    return {
      [`input${this.routeData.entityType}`]: [{
        id: this.currentFormId || undefined,
        kategorie_id: this.selectedCategoryId,
        pole: pole,
        ...additionalPayloadVars
      }]
    };
  }

  private getHistoriePayload(stav: string, text: string, formular_id: number) {
    return ;
  }

  public saveForm(formExtraVars?: any, historieText: string = null): void {
    // if this shit aint valid, return
    if (!this.form.valid && !this.form.disabled) {
      displaySnack('Formulář není správně vyplněn!', this.store$);
      return;
    }

    // make formular payload for gql
    const formularPayload = this.getFormularPayload(formExtraVars);
    const formularMutation$ = this.kolekceService.save(this.routeData.entityType, {...formularPayload, historieText });
    console.log('formular payload is', formularPayload);

    // save it in the db
    formularMutation$.pipe(
      take(1),
    ).toPromise()
      .then((formularResult: ApolloQueryResult<any>) => {
        // navigate back
        this.router.navigateByUrl(this.currentRoute.snapshot.url[0].path || '/');
        this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({
          message: 'Úspěšně uloženo!',
          action: 'OK',
          config: {duration: 5000}
        }));

        // create history record
        const {id, stav} = formularResult.data.response[0];
        //this.createHistoryRecord(id, stav, historieText);
      })
  }

  private createHistoryRecord(id: number, stav: string, historieText: string): void {
    const historiePayload = {
      input: [{
        stav,
        text: historieText,
        formular_id: id
      }]
    }

   this.kolekceService.save(EntityTypes.Historie, historiePayload).toPromise()
     .then(() => {
       console.log('historie byla uspesne ulozena');

     })
     .catch((err: GraphQLError) => {
       console.error('nepodarilo se ulozit historii formulare', err.message);
     })
  }


}
