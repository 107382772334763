import { MediaActions, MediaActionTypes } from './media.actions';
import { initialMediaState, MediaState } from './media.state';


export function mediaReducer(state = initialMediaState, action: MediaActions): MediaState {
  switch (action.type) {
    case MediaActionTypes.CREATE_FOLDER:
      return {
        ...state
      };
    case MediaActionTypes.UPLOAD_FILE:
      return {
        ...state
      };
    default: {
      return state;
    }
  }
}
