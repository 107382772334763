import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '../../utils/utils.module';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@NgModule({
  imports: [CommonModule,  MatMenuModule, RouterModule, UtilsModule],
  declarations: [BreadcrumbsComponent],
  entryComponents: [BreadcrumbsComponent],
  providers: [ColorUtilsService],
  exports: [BreadcrumbsComponent]
})
export class BreadcrumbsModule {}

