import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of, pipe } from 'rxjs';
import { Action } from '@ngrx/store';
import * as snackbarActions from '../snackbar/snackbar.actions';
import { map, tap } from 'rxjs/operators';
import { SnackbarActionOpen } from './snackbar.actions';


@Injectable()
export class SnackbarStoreEffects {
  constructor(private actions$: Actions, private matSnackBar: MatSnackBar) {
  }

  @Effect({
    dispatch: false
  })
  closeSnackbar: Observable<Action> = this.actions$.pipe(
    ofType<snackbarActions.SnackbarActionClose>(snackbarActions.SnackbarActionTypes.SNACKBAR_CLOSE),
    tap(() => this.matSnackBar.dismiss())
  );


  @Effect({
    dispatch: false
  })
  showSnackbar: Observable<Action> = this.actions$.pipe(
    ofType<snackbarActions.SnackbarActionOpen>(snackbarActions.SnackbarActionTypes.SNACKBAR_OPEN),
    map((action: SnackbarActionOpen) => action.payload),
    tap(payload => this.matSnackBar.open(payload.message, payload.action, payload.config)),
    map(() => null)
  );
}
