import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Action, ActionsSubject, Store} from '@ngrx/store';
import {filter, takeUntil} from 'rxjs/operators';
import { Subject} from 'rxjs';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {KolekceService} from '@nx-monorepo/obce/ng/services';
import {GraphQLError} from 'graphql';
import {PKApolloQueryResult} from "@nx-monorepo/api-base/lib";
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { IAction } from '@nx-monorepo/cms-base/interfaces';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { SnackbarStoreActions } from '@nx-monorepo/cms-base/store/snackbar';


@Component({
  selector: 'pk-upload-url',
  templateUrl: './pk-upload-url.component.html',
  styleUrls: ['./pk-upload-url.component.scss']
})
export class PkUploadUrlComponent implements OnInit, OnDestroy {
  public urlForm: FormGroup;
  public fieldSettings = [];

  get formData() {
    return <FormArray>this.urlForm.get('urls');
  }

  destroy$: Subject<boolean> = new Subject<boolean>();
  // categories: IAction[];

  @Output()
  uploaded: EventEmitter<number[]> = new EventEmitter<number[]>();

  @Input()
  categories: IAction[];

  constructor(private store$: Store<MACMSStoreState.State>, private actionsSubject$: ActionsSubject, private fb: FormBuilder, private kolekceService: KolekceService) {
  }

  ngOnInit(): void {
    this.urlForm = this.fb.group({
      urls: this.fb.array([], Validators.required)
    });

    this.subscribeToSaveButtonAction();

    this.subscribeToAddUrlAction();

    this.addUrl();
  }

  ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  private removeUrl(index: number) {
    const formUrls = this.urlForm.get('urls') as FormArray;
    formUrls.removeAt(index);
    this.fieldSettings.splice(index, 1);
  }

  private addUrl() {
    // add url control to form
    const formUrls = this.urlForm.get('urls') as FormArray;
    const urlControl = this.fb.group({
      nazev: ['', Validators.nullValidator],
      url: ['', Validators.required],
      kategorie_id: ['', Validators.required]
    });
    formUrls.push(urlControl);

    // add to settings
    this.fieldSettings.push({
      nazevSettings: {
        nazev: 'Název',
        form: urlControl,
        formControlName: 'nazev',
        type: 'text',
        isTextArea: false,
        povinnost: false,
        defaultValue: ''
      },
      urlSettings: {
        nazev: 'URL odkaz',
        form: urlControl,
        formControlName: 'url',
        type: 'text',
        isTextArea: false,
        povinnost: true,
        defaultValue: ''
      },
      kategorieSettings: {
        isMultiple: false,
        form: urlControl,
        povinnost: true,
        formControlName: 'kategorie_id',
        nazev: 'Kategorie'
      }
    });
  }

  private subscribeToSaveButtonAction() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === '[Media] Upload External File')
    ).subscribe(action => {

      if (this.urlForm.valid) {
        // get the value of form, add nazev if missing
        const payload = this.urlForm.value.urls.map(url => ({...url, nazev: url.nazev || url.url, mime: 'image/jpeg'}));

        this.kolekceService.save(EntityTypes.Media, {input: payload}).toPromise()
          .then((res: PKApolloQueryResult) => {
            this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({
              message: 'URL odkazy byly úspěšně nahrány.',
              action: 'OK'
            }));
            return res.data.response;
          })
          .then((items) => {
            const ids = items.map(item => item.id);
            this.uploaded.emit(ids);
            return items;
          })
          .catch((err: GraphQLError) => {
            this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({
              message: err.message || 'URL odkazy se nepodařilo nahrát.',
              action: 'OK'
            }));
          });

      } else {
        this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({
          message: 'Vyplňte prosím všechna pole formuláře!',
          action: 'OK'
        }));
      }
    });
  }

  private subscribeToAddUrlAction() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === '[Media] Add Url')
    ).subscribe(action => {
      this.addUrl();
    });
  }
}

