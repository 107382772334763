import { Component, Input, OnInit } from '@angular/core';
import { IPkHiddenFieldProps } from './pk-hidden-field.props';

@Component({
  selector: 'pk-hidden-field',
  templateUrl: './pk-hidden-field.component.html',
  styleUrls: ['./pk-hidden-field.component.scss']
})
export class PkHiddenFieldComponent implements OnInit {
  @Input()
  settings: IPkHiddenFieldProps;

  ngOnInit(): void {}
}

