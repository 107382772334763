import { IPkDialogData, IPkFormDialogCompanion } from '../../../pk-dialog/pk-dialog-data';
import { Validators } from '@angular/forms';
import { getDefaultDialogButtons, getNazevSettings } from '../common-settings-objects';
import { IPkInputCheckboxFieldProps } from '../../../pk-input-fields/pk-input-checkbox-field/pk-input-checkbox-field.props';
import { IPkInputTextFieldProps } from '../../../pk-input-fields/pk-input-text-field/pk-input-text-field.props';
import { IPkSelectFieldProps } from '../../../pk-input-fields/pk-select-field/pk-select-field.props';
import { IPkColorPickerProps } from '../../../pk-input-fields/pk-color-picker-field/pk-color-picker-field.props';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { IPkInputEntityFieldProps } from '../../../pk-input-fields/pk-input-entity-field/pk-input-entity-field.props';
import { UredniDeskyBarrel } from '@nx-monorepo/node-frontend';

export class PkGridsterItemUredniDeskyCommonDialogCompanion implements IPkFormDialogCompanion {
  getDialogData(settings: UredniDeskyBarrel.UredniDeskyCommonProps, data: {}, emitters: {dialogSubmit, dialogClose}) {
    const dialogData: IPkDialogData = {
      title: 'Upravit úřední desky',
      components: [
        getNazevSettings(settings.nazev),
        // {
        //   headingProps
        //  button? na PkGridsterItemHeaderDialogCompanion
        // }
        {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'maxColumns',
              type: 'number',
              nazev: 'Počet sloupců při maximální velikosti',
              povinnost: false,
              defaultValue: settings.maxColumns ? settings.maxColumns : ''
            } as IPkInputTextFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 1
        },
          {
            componentName: 'PkInputCheckboxFieldComponent',
            settings: {
              povinnost: true,
              nazev: 'Zobraz ikonu kalendáře',
              formControlName: 'showCalendarIcon',
              defaultValue: settings.showCalendarIcon ? settings.showCalendarIcon : false,
            } as IPkInputCheckboxFieldProps,
            data: null,
            validators: [Validators.required],
            cols: 2,
            x: 0,
            y: 2,
          },
          {
            componentName: 'PkColorPickerFieldComponent',
            settings: {
              defaultValue: settings.secondaryColor ? settings.secondaryColor : '',
              showAlways: false,
              povinnost: false,
              type: 'text',
              nazev: 'Sekundární barva',
              formControlName: 'secondaryColor',
            } as IPkColorPickerProps,
            data: null,
            validators: [],
            cols: 2,
            x: 0,
            y: 2,
          },
          {
            componentName: 'PkColorPickerFieldComponent',
            settings: {
              povinnost: false,
              showAlways: false,
              type: 'text',
              nazev: 'Accent color',
              formControlName: 'accentColor',
              defaultValue: settings.accentColor ? settings.accentColor : '',
            } as IPkColorPickerProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 3,
          },
          {
            componentName: 'PkColorPickerFieldComponent',
            settings: {
              showAlways: false,
              povinnost: false,
              type: 'text',
              nazev: 'Barva textu',
              formControlName: 'textColor',
              defaultValue: settings.textColor ? settings.textColor : '',
            } as IPkColorPickerProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 4,
          },
          {
            componentName: 'PkSelectFieldComponent',
            settings: {
              povinnost: true,
              nazev: 'Ikona',
              isMultiple: false,
              formControlName: 'icon',
              defaultValue: settings.icon ? settings.icon : 'faDownload',
            } as IPkSelectFieldProps,
            data: [
              {
                id: 'faDownload',
                nazev: 'faDownload'
              },
              {
                id: 'faAirFreshener',
                nazev: 'faAirFreshener'
              },
              {
                id: 'faFilePdf',
                nazev: 'faFilePdf'
              }
            ],
            validators: [Validators.required],
            cols: 1,
            x: 0,
            y: 5,
          },
          {
            componentName: 'PkInputEntityFieldComponent',
            settings: {
              nazev: 'Pozadí sekce',
              povinnost: false,
              isMultiple: false,
              withPreview: true,
              entityType: EntityTypes.Media,
              formControlName: 'sectionBackground',
              defaultValue: settings.sectionBackground ? settings.sectionBackground : null,
            } as IPkInputEntityFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 6,
          },
          {
            componentName: 'PkInputEntityFieldComponent',
            settings: {
              nazev: 'Pozadí wrapperu',
              povinnost: false,
              isMultiple: false,
              withPreview: true,
              entityType: EntityTypes.Media,
              formControlName: 'wrapperBackground',
              defaultValue: settings.wrapperBackground ? settings.wrapperBackground : null,
            } as IPkInputEntityFieldProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 7,
          },
      ],
      buttons: getDefaultDialogButtons(emitters)
    };
    return dialogData;
  }
}
