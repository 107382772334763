import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { Border, BoxSides } from './border-definition';

@Directive({
  selector: '[borderDefinition]'
})
export class BorderDefinitionDirective implements AfterViewInit{

  @Input() borderDefinition: BoxSides<Border>;

  constructor(private el: ElementRef) {

  }

  stringify(border:Border){
    if(border && border.width){
      if(!border.style){
        border.style = "solid";
      }
      if(!border.color){
         border.color = "";
      } else {
        border.color = " "+border.color;
      }
      return border.width + " " + border.style + border.color;
    }
  }

  ngAfterViewInit(): void {
    if(this.borderDefinition) {
      if (this.borderDefinition.bottom) {
        this.el.nativeElement.style.borderBottom = this.stringify(this.borderDefinition.bottom);
      }
      if (this.borderDefinition.top) {
        this.el.nativeElement.style.borderTop = this.stringify(this.borderDefinition.top);
      }
      if (this.borderDefinition.left) {
        this.el.nativeElement.style.borderLeft = this.stringify(this.borderDefinition.left);
      }
      if (this.borderDefinition.right) {
        this.el.nativeElement.style.borderRight = this.stringify(this.borderDefinition.right);
      }
    }
  }
}
