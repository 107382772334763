<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <form [formGroup]="dialogForm">
    <ng-template *ngFor="let component of data.components" cmsComponentFactory [componentName]="component.componentName" [settings]="component.settings" [data]="component.data"></ng-template>
  </form>
</div>
<div mat-dialog-actions>
  <button *ngFor="let button of data.buttons"
          mat-button
          [color]="button.color"
          (click)="emitAction(button.action, button.shouldValidateForm)">
    {{button.text}}
  </button>
</div>
