import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { layoutReducer } from './layout.reducer';
import { LayoutStoreEffects } from './layout.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('layout', layoutReducer),
        EffectsModule.forFeature([LayoutStoreEffects])
    ]
})
export class LayoutStoreModule {}
