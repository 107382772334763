import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { PkDataTableComponent } from './pk-data-table.component';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { PkStatusCellComponent } from './pk-status-cell/pk-status-cell/pk-status-cell.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSharedModule } from '@nx-monorepo/ng-shared';
import { TranslateService } from '@ngx-translate/core';
import { PaginatorI18n } from './paginator-i18n/PaginatorI18n';

@NgModule({
  declarations: [
    PkDataTableComponent,
    PkStatusCellComponent
  ],
  exports: [
    PkDataTableComponent,
  ],
  providers: [
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
    }
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    FlexModule,
    MatTableModule,
    MatCheckboxModule,
    ExtendedModule,
    MatPaginatorModule,
    FuseDirectivesModule,
    MatSortModule,
    FontAwesomeModule,
    NgSharedModule
  ],
  entryComponents: [
    PkDataTableComponent
  ]
})
export class PkDataTableModule {
}
