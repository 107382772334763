import gql from 'graphql-tag';

export const changePassword = gql`
    mutation changePassword($passwordNew: String!, $passwordCurrent: String!) {
        response: changePassword(
            passwordCurrent: $passwordCurrent, passwordNew: $passwordNew
        ) {
            message
            isSuccessful
        }
    }
`