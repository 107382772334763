import { IPkDialogData, IPkFormDialogCompanion } from '../../../pk-dialog/pk-dialog-data';
import { Validators } from '@angular/forms';
import { getDefaultDialogButtons, getNazevSettings } from '../common-settings-objects';
import { ItemNavProps } from 'libs/frontend-components/src/lib/components/navigace';
import { IPkInputCheckboxFieldProps } from '../../../pk-input-fields/pk-input-checkbox-field/pk-input-checkbox-field.props';
import { IPkSelectFieldProps } from '../../../pk-input-fields/pk-select-field/pk-select-field.props';
import { IPkInputTextFieldProps } from '../../../pk-input-fields/pk-input-text-field/pk-input-text-field.props';
import { FotogalerieProps } from 'libs/frontend-components/src/lib/components/fotogalerie';

export class PkGridsterItemGalerieDialogCompanion implements IPkFormDialogCompanion {
  getDialogData(settings: FotogalerieProps, data: {}, emitters: {dialogSubmit, dialogClose}) {
    const dialogData: IPkDialogData = {
      title: 'Upravit galerii',
      components: [
        getNazevSettings(settings.nazev),
        // {
        //     headingProps
        // },
        {
          componentName: 'PkInputTextFieldComponent',
          settings: {
            isTextArea: false,
            formControlName: 'itemFlexBasis',
            type: 'number',
            nazev: 'Šířka prvku',
            povinnost: false,
            defaultValue: settings.itemFlexBasis ? settings.itemFlexBasis : ''
          } as IPkInputTextFieldProps,
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 0
        },
        {
          componentName: 'PkInputBoxFieldComponent',
          settings: {
            nazev: 'Odsazení prvku',
            formControlName: 'itemMargin',
            povinnost: false,
            napoveda: {
              isIcon: false,
              text: 'Zadejte celé číslo',
            },
            defaultValue: settings.itemMargin ? settings.itemMargin : null,
          },
          data: null,
          validators: [Validators.pattern(/^\d+$/)],
          cols: 4,
          x: 0,
          y: 2,
        },
        {
          componentName: 'PkInputTextFieldComponent',
          settings: {
            nazev: 'Pozadí wrapperu',
            formControlName: 'wrapperBackground',
            isTextArea: false,
            type: 'text',
            povinnost: false,
            defaultValue: settings.wrapperBackground ? settings.wrapperBackground : '',
          },
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 3
        },
        {
          componentName: 'PkSelectFieldComponent',
          settings: {
            povinnost: false,
            nazev: 'Zarovnání',
            isMultiple: false,
            formControlName: 'flexAlign',
            defaultValue: settings.flexAlign ? settings.flexAlign : 'flex-start',
          } as IPkSelectFieldProps,
          data: [
            {
              id: 'flex-start',
              nazev: 'flex-start'
            },
            {
              id: 'space-around',
              nazev: 'space-around'
            },
            {
              id: 'space-between',
              nazev: 'space-between'
            },
            {
              id: 'center',
              nazev: 'center'
            }
          ],
          validators: [],
          cols: 1,
          x: 0,
          y: 4,
        },
        {
          componentName: 'PkInputTextFieldComponent',
          settings: {
            nazev: 'Pozadí sekce',
            formControlName: 'sectionBackground',
            povinnost: false,
            isTextArea: false,
            type: 'text',
            defaultValue: settings.sectionBackground ? settings.sectionBackground : '',
          },
          data: null,
          validators: [],
          cols: 4,
          x: 0,
          y: 5
        },
        {
          componentName: 'PkSelectFieldComponent',
          settings: {
            povinnost: true,
            nazev: 'Typ',
            isMultiple: false,
            formControlName: 'type',
            defaultValue: settings.type ? settings.type : 'single',
          } as IPkSelectFieldProps,
          data: [
            {
              id: 'single',
              nazev: 'single'
            },
            {
              id: 'multi',
              nazev: 'multi'
            },
          ],
          validators: [Validators.required],
          cols: 1,
          x: 0,
          y: 6,
        },
      ],
      buttons: getDefaultDialogButtons(emitters)
    };
    return dialogData;
  }
}
