import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'times'})
export class TimesPipe implements PipeTransform {
  transform(value: number, max?: number): any {
    if(!max){
      max = 9999;
    }
    const iterable = <Iterable<any>> {};
    iterable[Symbol.iterator] = function* () {
      let n = 0;
      while (n < value && n < max) {
        yield n++;
      }
    };
    return iterable;
  }
}
