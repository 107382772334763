<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    {{data.content}}
</div>
<div mat-dialog-actions>
    <div *ngFor="let button of data.buttons">
        <button 
        mat-button
        [color]="button.color"
        (click)="button.action()">
        {{button.text}}
        </button>
    </div>
</div>
