import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit, Output
} from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { IPkComponentWithFormDialog, PkGridsterItem } from '../pk-gridster-item';
import { MatDialogRef } from '@angular/material/dialog';
import { PkDialogComponent } from '../../pk-dialog/pk-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { PkGridsterItemSelectDialogCompanion } from '../companions/form-items/pk-gridster-item-select-dialog-companion';
import { PkGridsterItemDefinitionService } from '@nx-monorepo/cms-base/layout/components/pk-page-contents/pk-gridster-page/pk-gridster-item-definition.service';
import { IPkSelectFieldProps } from '../..';

@Component({
  selector: 'pk-gridster-item-multiple-select',
  templateUrl: './pk-gridster-item-multiple-select.component.html',
  styleUrls: ['./pk-gridster-item-multiple-select.component.scss']
})
export class PkGridsterItemMultipleSelectComponent implements OnInit, OnDestroy, IPkComponentWithFormDialog {
  @Input()
  widget: PkGridsterItem;

  @Input()
  resizeEvent: EventEmitter<any>;

  @Input()
  dialogRef: MatDialogRef<PkDialogComponent, string>;

  @Output()
  dialogClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  dialogSubmit: EventEmitter<any> = new EventEmitter<any>();

  private resizeSub: Subscription;
  public settings: IPkSelectFieldProps;
  public selectFieldData: any;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  formDialogCompanion = new PkGridsterItemSelectDialogCompanion();


  constructor(private itemDefinitionService: PkGridsterItemDefinitionService, private changeDetection: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.subscribeToDialogClose();
    this.subscribeToDialogSubmit();

    this.resizeSub = this.resizeEvent.subscribe(widget => {
      if (widget === this.widget) {
        // or check id , type or whatever you have there
        // resize your item, chart, map , etc.
      }
    });

    this.settings = this.itemDefinitionService.fillItemSettingsByType('PkGridsterItemMultipleSelectComponent', this.widget.settings, {});
    this.widget.settings = this.settings;

    const data = this.widget.data;

    if (data && Object.keys(data).length > 0) {
      this.setSelectFieldData(data);
    } else {
      this.setSelectFieldData([]);
    }
  }


  private setSelectFieldData(data: any) {
    console.log('setting data', data);
    this.selectFieldData = data;
    this.widget.data = data;
  }

  private subscribeToDialogClose() {
    this.dialogClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.dialogRef.close();
    });
  }

  private subscribeToDialogSubmit() {
    this.dialogSubmit.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      console.log('dialog result', result);
      result.povinnost = result.povinnost === '1';

      // set settings
      this.settings = this.itemDefinitionService.fillItemSettingsByType('PkGridsterItemMultipleSelectComponent', result, this.settings);
      this.widget.settings = this.settings;

      // set data
      this.setSelectFieldData(result.options);

      // gridster is fucked up and doesnt push changes, we need to call it manually
      this.changeDetection.markForCheck();

      // close the dialog
      this.dialogRef.close();
    });
  }

  getDialogData() {
    return this.formDialogCompanion.getDialogData(
      this.settings,
      this.selectFieldData,
      { dialogSubmit: this.dialogSubmit, dialogClose: this.dialogClose }
    );
  }

  ngOnDestroy(): void {
    this.resizeSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
