import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { WysiwygBarrel } from '@nx-monorepo/node-frontend';
import { BaseComponent } from '../base.component';
import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { ObecService, ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@Component({
  selector: 'macms-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WysiwygComponent extends BaseComponent implements IDynamicComponent, OnInit, AfterViewInit {
  @Input() data: string;
  @Input() settings: WysiwygBarrel.WysiwygProps;

  @ViewChild('content') content: ElementRef;

  constructor(public colorUtilsService: ColorUtilsService, protected obecService: ObecService) {
    super(colorUtilsService, obecService);
  }

  ngOnInit(): void {
    registerLocaleData(localeCs, 'cs-CZ');
    const regex = /<oembed.+?url="https?:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})"><\/oembed>/g;
    this.data = this.data.replace(regex,'<div class="videoWrapper"><iframe src="https://www.youtube.com/embed/$1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>')


  }

  getComponentName() {
    return WysiwygBarrel.name;
  }

  ngAfterViewInit(): void {
    }

}
