import gql from 'graphql-tag';

export const deleteStrankaObsah = gql`
  mutation deleteStrankaobsah($ids: [Int!]!) {
    response: deleteStrankaobsah(ids: $ids) {
      description
      userMessage
      error
    }
  }
`;
