import gql from 'graphql-tag';

export const readAllVstupnipoleQuery = gql`
  query readAllVstupnipole($categories: [Int!]) {
    response: readAllVstupnipole(categories: $categories) {
      items {
        cols
        coord_x
        coord_y
        id
        kategorie_id
        nazev
        typ
        vstupni_komponenta
        vstupni_komponenta_settings
        vstupni_komponenta_data
      }
    }
  }
`;
