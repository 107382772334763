import { CategoryTabsState, initialCategoryTabsState } from './category-tabs.state';
import { CategoryTabsActions, CategoryTabsActionTypes } from './category-tabs.actions';


export function categoryTabsReducer(state = initialCategoryTabsState, action: CategoryTabsActions): CategoryTabsState {
  switch (action.type) {
    case CategoryTabsActionTypes.FETCH_LAYOUT_CATEGORIES:
      return {
        ...state,
        entityType: action.payload.entityType
      };
    case CategoryTabsActionTypes.CREATE_CATEGORY:
      return state;
    case CategoryTabsActionTypes.FINISH_CREATE_CATEGORY_ERROR:
      console.error('Error while creating new category', action.payload.error);
      return {
        ...state,
        error: action.payload.error
      };
    case CategoryTabsActionTypes.FINISH_CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, action.payload.new_category]
      };
    case CategoryTabsActionTypes.SET_ACTIVE_CATEGORY:
      return {
        ...state,
        activeCategory: getActiveCategory(state, action.payload)
      };
    case CategoryTabsActionTypes.FINISH_FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.categories
      };
    case CategoryTabsActionTypes.FINISH_FETCH_CATEGORIES_ERROR:
      console.error('Error while fetching categories', action.payload.error);
      return {
        ...state,
        error: action.payload.error
      };
    case CategoryTabsActionTypes.SHOULD_REFETCH_CATEGORIES:
      return {
        ...state,
        shouldRefetch: action.payload.shouldRefetch
      };
    case CategoryTabsActionTypes.UPDATE_CATEGORY_AFTER_SAVE:
      const { updatedCategory } = action.payload;
      const newCategories = state.categories.map(item => item.id === updatedCategory.id ? updatedCategory : item);
      return {
        ...state,
        categories: newCategories,
        activeCategory: updatedCategory
      };
    default:
      return state;
  }
}

function getActiveCategory(state: CategoryTabsState, payload: { category_title?: string, category_id?: number }) {
  if (payload.category_id !== undefined && payload.category_id != null) {
    return state.categories.find((el) => el.id === payload.category_id);
  }
  return state.categories.find((el) => el.nazev === payload.category_title);
}
