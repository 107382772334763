import gql from 'graphql-tag';

export const readSelectedMediumQuery = gql`
  query ReadSelectedMedium($ids: [Int!]!) {
    response: readSelectedMedium(
      ids: $ids
    ) {
      id
      nazev
      mime
      url
    }
  }
`;
