import {EntityTypes} from "@nx-monorepo/obce/common/enums";

export interface PaginatedResponse<T> {
  items: T[];
  page: number;
  total: number;
  hasMore: boolean;
}

export interface TableState<T> {
  isLoading: boolean;
  error: string;
  data: PaginatedResponse<T>;
  selectedItems: T[];
  currentTableSettings?: ITableSettings;
}

export interface ITableSettings {
  entityType: EntityTypes;
  page?: number;
  take?: number;
  search?: string;
  where?: {};
  sortColumn?: string;
  sortOrder?: string;
  categories?: number[]
}

export const initialTableState: TableState<any> = {
  isLoading: false,
  error: null,
  data: {
    items: [],
    hasMore: false,
    page: 0,
    total: 0
  },
  currentTableSettings: undefined,
  selectedItems: []
};
