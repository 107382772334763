import { SnackbarActions, SnackbarActionTypes } from './snackbar.actions';
import { initialSnackbarState, SnackbarState } from './snackbar.state';


export function snackbarReducer(state = initialSnackbarState, action: SnackbarActions): SnackbarState {
  switch (action.type) {
    case SnackbarActionTypes.SNACKBAR_CLOSE:
      return {
        ...state,
        show: false
      };
    case SnackbarActionTypes.SNACKBAR_OPEN:
      return {
        ...state,
        show: true
      };
    default: {
      return state;
    }
  }
}
