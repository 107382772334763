import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAuthResponse } from '../helpers/auth/i-auth-response';
import * as fromAuth from '../store/auth/auth.actions';
import { Store } from '@ngrx/store';
import * as MACMSStoreState from '../store/store.state';
import { Token } from '@nx-monorepo/api-base/lib/interfaces/i-token';
import { Observable, Subject, BehaviorSubject } from 'rxjs';


@Injectable()
export class AuthService {

  token: Token;
  token$: BehaviorSubject<Token> = new BehaviorSubject(null);

  constructor(private http: HttpClient,
              private store$: Store<MACMSStoreState.State>) {
  }

  loginFromStorage(){
    const decoded = this.getTokenData(null);
    this.token$.next(decoded);
    return decoded;
  }

  getTokenObservable(){
    return this.token$;
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  private processAuthResponse(response: IAuthResponse): boolean {
    if (response && response.success) {
      const decoded = this.createSessionAndDecode(response.data.token, response.data.refresh_token);
      this.store$.dispatch(new fromAuth.LoginSuccess({ role: decoded.role as any, username: decoded.email }));
      return true;
    } else {
      this.store$.dispatch(new fromAuth.LoginFailure({ error: response.message }));
      return false;
    }
  }


  async login(email: string, password: string) {
    return this.http.post<{ data?: { login: IAuthResponse } }>('/graphql', {
      operationName: 'Login',
      query: 'query Login($password: String!, $email: String!){login(password: $password, email: $email) {success message data {token refresh_token}}}',
      variables: { password, email }
    })
      .toPromise()
      .catch((error) => {
          console.error(error);
          return null;
        }
      )
      .then((result) => {
        console.log(result);
        return this.processAuthResponse(result.data.login);
      });
  }

  tryToRefresh(token: string, refresh_token: string) {

  }

  refresh(token: string, refresh_token: string) {
    return this.http.post<{ data?: { refresh: IAuthResponse } }>('/graphql', {
      operationName: 'Refresh',
      query: 'query Refresh($token: String!, $refresh_token: String!){refresh(token: $token, refresh_token: $refresh_token) {success message data {token refresh_token}}}',
      variables: { token, refresh_token }
    })
      .toPromise()
      .catch((error) => {
        console.error(error);
      })
      .then((result:any) =>{
        if(!result.errors){
          return this.processAuthResponse(result.data.refresh);
        }
        else {
          console.error(result.errors);
        }
      }
        
      );
  }

  isLoggedIn() {
    return !this.isTokenExpired();
  }

  hasValidRefreshToken() {
    return !this.isTokenExpired(this.getRefreshToken());
  }

  public decodeToken(token: string = this.getToken()): Token {
    if (token == null || token === '') {
      return null;
    }

    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error('Špatný formát JWT');
    }

    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Nečitelný token');
    }
    return typeof decoded === "string" ? JSON.parse(decoded) : decoded;
  }

  public getTokenExpirationDate(token: string = this.getToken()): Date | null {
    let decoded: any;
    decoded = this.decodeToken(token);
    if (!decoded || !decoded.hasOwnProperty('exp')) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  public isTokenExpired(token: string = this.getToken(), offsetSeconds?: number): boolean {
    if (token == null || token === '') {
      return true;
    }
    const date = this.getTokenExpirationDate(token);
    //console.log('Expires at', date);
    offsetSeconds = offsetSeconds || 0;

    if (date === null) {
      return false;
    }

    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }

  public urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw new Error('Illegal base64url string!');
      }
    }
    return this.b64DecodeUnicode(output);
  }

  private b64decode(str: string): string {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';

    str = String(str).replace(/=+$/, '');

    if (str.length % 4 === 1) {
      throw new Error(
        '\'atob\' failed: The string to be decoded is not correctly encoded.'
      );
    }

    for (
      // initialize result and counters
      let bc = 0, bs: any, buffer: any, idx = 0;
      // get next character
      (buffer = str.charAt(idx++));
      // character found in table? initialize bit storage and add its ascii value;
      // tslint:disable-next-line:no-bitwise
      ~buffer &&
      (
        (bs = bc % 4 ? bs * 64 + buffer : buffer),
          // and if not first of each 4 characters,
          // convert the first 8 bits to one ascii character
        bc++ % 4
      )
        // tslint:disable-next-line:no-bitwise
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  }

  private b64DecodeUnicode(str: any) {
    return decodeURIComponent(
      Array.prototype.map
        .call(this.b64decode(str), (c: any) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  getTokenData(token?: any): Token{
    if (!token) {
      token = this.getToken();
    }
    return this.decodeToken(token);
  }

  createSessionAndDecode(token: string, refresh_token: string) {
    const decoded = this.decodeToken(token);
    localStorage.setItem('token', token);
    this.token$.next(decoded);
    localStorage.setItem('refresh_token', refresh_token);
    return decoded;
  }

  getSession() {
    return {
      token: localStorage.getItem('token'),
      refresh_token: localStorage.getItem('refresh_token')
    };
  }

  cleanSession() {
    this.token$.next(null);
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    console.log("Cleaning session", localStorage);
  }

}
