import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { IDynamicComponent } from '@nx-monorepo/ng-shared';
import { UdalostiBarrel } from '@nx-monorepo/node-frontend';
import { BaseComponent } from '../base.component';
import { HeadingProps } from '../heading/heading.props';
import { NextButtonProps } from '../next-button/next-button.props';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { ColorUtilsService, ObecService, UdalostService } from '@nx-monorepo/obce/ng/services';

interface CalendarData{
  nextEvents: [],
  showedEvents: [],
  calendarEvents: {},
  selectedIdx: number
}

@Component({
  selector: 'macms-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent extends BaseComponent implements IDynamicComponent, OnInit {
  @Input() data: any;
  @Input() settings: UdalostiBarrel.CalendarProps;

  nextButtonSettings: NextButtonProps;
  headingSettings: HeadingProps;
  primaryColor: string;

  currentYear: number;
  currentMonth: number;

  days = ['PO', 'ÚT', 'ST', 'ČT', 'PÁ', 'SO', 'NE'];
  months = ['Leden', 'Únor', "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"];

  firstDateOfCurrentMonth: number;
  arrayOfDaysForCurrentMonth;
  isCurrentMonth = true;

  todayIndex = -1;
  selectedIndex = 0;

  nextEvents$;
  currentMonthEventsSubscription;
  currentMonthEventsSubject$ = new Subject();
  currentlyShownSubjectIdx$ = new Subject<number>();

  //final shit that will be read by angular
  calendarData$ : Observable<CalendarData>;


  //CSS PRECOMPUTED THINGS
  wrapperBackground;
  maxItems = 5;
  calendarSpacing = "1px";
  showIncomingEvents = true;


  constructor(private renderer: Renderer2, public colorUtilsService: ColorUtilsService, protected obecService: ObecService, private  udalostService: UdalostService) {
    super(colorUtilsService, obecService);
  }

  //month++ or month--, including modulo and year switch
  nextMonth(goForward = true){
    if(goForward){
      if(this.currentMonth === 11){
        this.currentMonth = 0;
        this.currentYear += 1;
      } else {
        this.currentMonth += 1;
      }
    } else {
      if(this.currentMonth === 0){
        this.currentMonth = 11;
        this.currentYear -= 1;
      } else {
        this.currentMonth -= 1;
      }
    }
    this.changeMonth(this.currentMonth, this.currentYear);
  }

  //get Int representing how many days are in month (year for leap february)
  daysInMonth(month, year)
  {
    return 32 - new Date(year, month, 32).getDate();
  }

  getDaysArray(start, end) {
    const arr=[];
    const dateStart = new Date(start);
    const dateEnd = new Date(end);
    for(const dt=dateStart; dt<=dateEnd; dt.setDate(dt.getDate()+1)){
      arr.push(new Date(dt));
    }
    return arr;
  };

  stripTimefromDateTime(dt){
    if(dt){
      return moment(dt).format("YYYY-MM-DD");
    }
    return undefined;
  }

  pad(number, size) {
    let s = String(number);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
  };

  changeSelected(idx){
    this.currentlyShownSubjectIdx$.next(idx);
  }


  // perform change to specific month and year
  changeMonth(month, year){
    if(this.currentMonthEventsSubscription){
      this.currentMonthEventsSubscription.unsubscribe();
      this.currentMonthEventsSubscription = null;
    }
    this.currentMonthEventsSubscription = this.udalostService.getSpecificMonth(month+1, year).pipe(
      map(result => {
        this.firstDateOfCurrentMonth = (new Date(year, month).getDay() + 6)%7;
        // tento objekt reprezentuje aktuální měsíc... bude obsahovat klíče reprezentující jednotlivé dny... každý klíč má array akcí
        const resultObject = {};
        //  reprezentující každý den v měsíci.. každá array
        Array.from({length: this.daysInMonth(month, year)}, (v, k) => {
          resultObject[`${year}-${this.pad(month+1,2)}-${this.pad(k+1,2)}`] = [];
        });
        result.forEach(
          (event) => {
          const dat_konec = this.stripTimefromDateTime(event.datum_konec);
          const dat_start = this.stripTimefromDateTime(event.datum_zacatek);
          if(dat_konec && dat_konec !== dat_start){
            //jedná se o rozsah více dnů - udělej array obsahující všechny dny v intervalu
            const array = this.getDaysArray(dat_start, dat_konec);
            array.forEach(date => {
              const strippedDate = this.stripTimefromDateTime(date);
              // pokud se datum nachází v aktuálním zvoleném měsíci - přidej akci k datu
              if(resultObject.hasOwnProperty(strippedDate)){
                resultObject[strippedDate].push(event);
              } else {
                console.log("event without corresponding date", strippedDate, event, resultObject);
              }
            });
          } else {
            // pokud se datum nachází v aktuálním zvoleném měsíci - přidej akci k datu
            // prakticky není důvod aby tato podmínka nikdy neproběhla
            if(resultObject.hasOwnProperty(this.stripTimefromDateTime(dat_start))){
              resultObject[dat_start].push(event);
            }
          }
        });
        const today = new Date();
        this.isCurrentMonth = (month === today.getMonth()) && (year === today.getFullYear());
        if(this.isCurrentMonth){
          // označ dnešní datum
          this.todayIndex = today.getDate() - 1;
          this.currentlyShownSubjectIdx$.next(today.getDate() - 1);
        } else {
          // označ první den v měsíci
          this.todayIndex = -1;
          this.currentlyShownSubjectIdx$.next(0);
        }
        return resultObject;
      }
    )).subscribe(result => {
      this.currentMonthEventsSubject$.next(result);
    });

  }

  ngOnInit(): void {
    const today = new Date();
    this.currentMonth = today.getMonth();
    this.currentYear = today.getFullYear();
    this.changeMonth(today.getMonth(), today.getFullYear());
    this.primaryColor = this.settings.primaryColor;
    this.generateColors();
    if(this.settings.maxItemCount){
      this.maxItems = this.settings.maxItemCount;
    }
    this.nextEvents$ = this.udalostService.getNextEvents(today, this.maxItems);
    this.calendarData$ = combineLatest(this.nextEvents$, this.currentMonthEventsSubject$, this.currentlyShownSubjectIdx$).pipe(
      map(([next, current, selectedIdx]) => {
        const events = current[`${this.currentYear}-${this.pad(this.currentMonth+1,2)}-${this.pad(selectedIdx + 1,2)}`];
        return { showedEvents: events, nextEvents: next, calendarEvents: current, selectedIdx: selectedIdx} as CalendarData;
      })
    );

    if (this.settings.calendarSpacing){
      this.calendarSpacing = this.settings.calendarSpacing;
    }

    this.wrapperBackground = this.settings.wrapperBackground;
    if(this.settings.automaticWrapperColor){
      if(!this.settings.wrapperBackground){
        this.wrapperBackground = this.colorUtilsService.setLightness(this.settings.primaryColor,90)
      }
    }

    if (this.showIncomingEvents !== null){
      this.showIncomingEvents = this.settings.showIncomingEvents;
    }

    if(this.settings.headingProps){
      this.headingSettings = this.settings.headingProps;
    } else {
      this.headingSettings = {
        icon: 'newspaper',
        iconPosition: 'start',
        boxMargin: {top: 'auto', bottom: 'auto', left: 'auto', right: 'auto'},
        primaryColor: this.primaryColor,
        font: this.obecSettings.title_font
      };
    }
    this.nextButtonSettings = {
      primaryColor: this.primaryColor,
      fontTitle: this.obecSettings.title_font,
      bottomPaddingEm: 2,
      topPaddingEm: 2,
    };

   /* if(this.settings.dateFont){
      this.settings.dateFont = this.settings.dateFont;
    } else {
      this.settings.dateFont = this.obecSettings.title_font;
    }

    if(this.settings.titleFont){
      this.settings.titleFont = this.settings.titleFont;
    } else {
      this.settings.titleFont = this.obecSettings.title_font;
    }

    if(this.settings.textFont){
      this.settings.textFont = this.settings.textFont;
    } else {
      this.settings.textFont = this.obecSettings.paragraph_font;
    }*/
  }

  onResize(ev) {
    if (ev.contentRect.width < 640) {
      this.renderer.addClass(ev.target, 'vertical');
    } else {
      this.renderer.removeClass(ev.target, 'vertical');
    }
  }

  getComponentName() {
    return UdalostiBarrel.name_calendar;
  }
}
