import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UdalostiComponent } from './udalosti.component';
import { GridModule } from '@angular/flex-layout';
import { MomentModule} from 'ngx-moment';
import { HeadingModule } from '../heading/heading.module';
import { NextButtonModule } from '../next-button/next-button.module';
import { UtilsModule } from '../../utils/utils.module';
import { RouterModule } from '@angular/router';
import { CalendarComponent } from './calendar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DetermineCalendarColorPipe } from './determine-calendar-color.pipe';

@NgModule({
  imports: [CommonModule, GridModule, MomentModule, HeadingModule, NextButtonModule, UtilsModule, RouterModule, FontAwesomeModule],
  exports: [UdalostiComponent, CalendarComponent],
  entryComponents: [UdalostiComponent, CalendarComponent],
  declarations: [UdalostiComponent, CalendarComponent, DetermineCalendarColorPipe]
})
export class UdalostiModule {}

