import {AbstractControl, FormGroup} from '@angular/forms';

function formErrorHandler(formControl: AbstractControl): string {
  if (formControl) {
    const errorKeys = formControl.errors ? Object.keys(formControl.errors) : [];
    const errorMsg = errorKeys.length > 0 ? formControl.errors[errorKeys[0]] : null;

    if (errorMsg && formControl.errors[errorKeys[0]]) {
      if (errorMsg && formControl.errors[errorKeys[0]]) {
        //regex pattern OR date pattern
        if (errorKeys[0] === "pattern" || errorKeys.includes("matDatetimepickerParse")) {
          return "Hodnota není ve správném formátu!";
        } else if (errorKeys[0] === "required") {
          return "Toto pole je povinné!"
        } else if (errorKeys[0] === 'minlength') {
          const {requiredLength} = formControl.errors[errorKeys[0]];
          return `Minimální délka je ${requiredLength} znaků!`;
        } else if (errorKeys[0] === 'pkerror') {
          return formControl.errors[errorKeys[0]];
        }else {
          return 'Toto pole není validní!';
        }
      }
    }
  }
}

export const getFirstError = (formControlName: string, form: FormGroup) => {
  const formControl = form.get(formControlName);
  return formErrorHandler(formControl);
}
