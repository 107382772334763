import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { IPkInputEntityFieldProps } from './pk-input-entity-field.props';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { PkEntityDialogComponent } from '../../media-module/pk-entity-dialog/pk-entity-dialog.component';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { ClearAllSelected, SetSelectedItems } from '@nx-monorepo/cms-base/store/table/table.actions';

@Component({
  selector: 'pk-input-entity-field',
  templateUrl: './pk-input-entity-field.component.html',
  styleUrls: ['./pk-input-entity-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PkInputEntityFieldComponent implements OnInit, OnDestroy {

  private mediaDialogRef: MatDialogRef<PkEntityDialogComponent, string>;
  public selectedEntityItems: any[] = [];
  private editObserver: Subscription;

  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input()
  settings: IPkInputEntityFieldProps;

  @Input()
  data: any;


  constructor(public dialog: MatDialog, private store$: Store<MACMSStoreState.State>) {
  }

  ngOnInit(): void {
    const formControl = this.settings.form.get(this.settings.formControlName);

    this.editObserver = formControl.valueChanges.pipe(
      map(entity => entity === null ? [] : entity), // pri deletu je null, chceme prazdnou array aby se refreshli selectedMediaItems
      map(entity => !Array.isArray(entity) ? [entity] : [...entity]) // nekdy je to array, nekdy objekt, vzdy chceme array
    ).subscribe((entities: []) => {
      // set selected entities here and also in the store (this is so when we are removing items by buttons we also remove them as selected from the store)
      this.selectedEntityItems = entities;

    });
  }

  ngOnDestroy(): void {
    this.editObserver.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.store$.dispatch(new ClearAllSelected());
  }


  public openEntityPicker() {
    this.store$.dispatch(new SetSelectedItems({selectedItems: this.selectedEntityItems}));
    if (this.mediaDialogRef == null) {
      const { isMultiple, entityType } = this.settings;
      // create dialog config and pass dialog data to it
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        isMultiple: isMultiple,
        entityType: entityType,
        overrideColumnDefinitions: this.settings.overrideColumnDefinitions,
      };
      dialogConfig.width = "90%";

      // open dialog and subscribe to its afterClosed event to remove the reference
      this.mediaDialogRef = this.dialog.open(PkEntityDialogComponent, dialogConfig);
      this.mediaDialogRef.afterClosed().pipe(take(1)).subscribe(() => {
        this.mediaDialogRef = null;

        // clear all selected after
        // this.store$.dispatch(new ClearAllSelected());
      });

      this.mediaDialogRef.componentInstance.entitySelected.subscribe(res => {
        this.onEntityItemsSelected(res);
        this.mediaDialogRef.close();
      });
    }
  }

  private onEntityItemsSelected(items) {
    // patch form with media id(s)
    const { isMultiple } = this.settings;

    const patchValues = {
      [this.settings.formControlName]:
        items.length ? // vybral alespon jeden vs nic
          (isMultiple ? items.map(item => this.stripItem(item)) : this.stripItem(items[0])) : //muze zvolit vice, nebo jen jeden
          null
    };
    this.settings.form.patchValue(patchValues);
  }

  private stripItem(item) {
    // get rid of object shit we don't need todo prepsat to nejak jinak, tohle je kvuli tomu, ze pak GQL rve neocekavane input types
    return {
      ...item,
      kategorie: undefined,
      created_at: undefined
    };
  }


  public removeAllEntities(event: Event) {
    // dont propagate to input underneath it
    event.stopPropagation();

    const patchValues = {
      [this.settings.formControlName]: null
    };
    this.settings.form.patchValue(patchValues);
  }

  public removeEntity(entity) {
    // get current form values
    const currentFormValues = this.settings.form.value;
    const selectedEntities = currentFormValues[this.settings.formControlName];

    // create new update values - removing the entity clicked
    const patchValues = {
      ...currentFormValues,
      [this.settings.formControlName]: Array.isArray(selectedEntities) ?
        selectedEntities.filter(entityItem => entityItem.id !== entity.id) : // bud je to array tak filtrujeme
        null // nebo je to single entita a rovnou to nastavime na null
    };

    // set the new values to form
    this.settings.form.patchValue(patchValues);
  }
}

