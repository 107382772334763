import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@angular/flex-layout';
import { MomentModule} from 'ngx-moment';
import { HeadingModule } from '../heading/heading.module';
import { NextButtonModule } from '../next-button/next-button.module';
import { UtilsModule } from '../../utils/utils.module';
import { ClassicPrispevkyComponent } from './classic/classic-prispevky.component';
import { ImagePrispevkyComponent } from './image/image-prispevky.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [CommonModule, GridModule, MomentModule, HeadingModule, NextButtonModule, UtilsModule, RouterModule, FontAwesomeModule],
  exports: [ClassicPrispevkyComponent, ImagePrispevkyComponent],
  entryComponents: [ClassicPrispevkyComponent, ImagePrispevkyComponent],
  declarations: [ClassicPrispevkyComponent, ImagePrispevkyComponent]
})
export class PrispevkyModule {}

