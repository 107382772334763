import { Action } from '@ngrx/store';
import { MatDialogRef } from '@angular/material/dialog';
import { IAction } from '@nx-monorepo/cms-base/interfaces';

export enum LayoutActionTypes {
  SET_ACTIVE_TAB = '[Layout] Set Active Tab',
  SET_LAYOUT_BUTTONS = '[Layout] Set Buttons',
  SET_LAYOUT_TABS = '[Layout] Set Page Tabs',
  SET_LAYOUT_PAGE_NAME = '[Layout] Set Page Name',
  SET_LAYOUT_PAGE_ICON = '[Layout] Set Page Icon',
  SET_LAYOUT_SEARCH = '[Layout] Set Search',
  SET_LAYOUT_SEARCH_VISIBILITY = '[Layout] Set Search Visibility',
  SAVE_BUTTON_PRESSED = '[Layout] Save Button Pressed',
  DELETE_BUTTON_PRESSED = '[Layout] Delete Button Pressed',
  BUTTON_PRESSED = '[Layout] Button Pressed',
  SET_WITH_ADD_CATEGORY = '[Layout] With Add Category',
  SHOW_CONFIRM_DIALOG = '[Layout] Show Confirm Dialog',
  SET_CONFIRM_DIALOG_REF = '[Layout] Set Confirm Dialog Reference',
  SEND_SMS_BUTTON_PRESSED = '[Layout] Send Sms Button Pressed',
  SEND_EMAIL_BUTTON_PRESSED = '[Layout] Send Email Button Pressed',
  SET_LAYOUT_CATEGORIES = '[Layout] Set Layout Categories'
}

export class SetLayoutButtonsAction implements Action {
  readonly type = LayoutActionTypes.SET_LAYOUT_BUTTONS;

  constructor(public payload: { buttons: IAction[] }) {
  }
}

export class SetLayoutTabsAction implements Action {
  readonly type = LayoutActionTypes.SET_LAYOUT_TABS;

  constructor(public payload: { tabs: IAction[] }) {
  }
}

export class SetLayoutPageNameAction implements Action {
  readonly type = LayoutActionTypes.SET_LAYOUT_PAGE_NAME;

  constructor(public payload: { pageName: string }) {
  }
}

export class SetLayoutWithAddCategory implements Action {
  readonly type = LayoutActionTypes.SET_WITH_ADD_CATEGORY;

  constructor(public payload: { withAddButton: boolean }) {
  }
}

export class SetLayoutPageIconAction implements Action {
  readonly type = LayoutActionTypes.SET_LAYOUT_PAGE_ICON;

  constructor(public payload: { pageIcon: IAction }) {
  }
}

export class SetLayoutSearchVisibilityAction implements Action {
  readonly type = LayoutActionTypes.SET_LAYOUT_SEARCH_VISIBILITY;

  constructor(public payload: { withSearch: boolean }) {
  }
}

export class SetLayoutSearch implements Action {
  readonly type = LayoutActionTypes.SET_LAYOUT_SEARCH;

  constructor(public payload: { search: string }) {
  }
}

export class SetLayoutActiveTabAction implements Action {
  readonly type = LayoutActionTypes.SET_ACTIVE_TAB;

  constructor(public payload: { activeTabName: string }) {
  }
}

export class SaveButtonPressed implements Action {
  readonly type = LayoutActionTypes.SAVE_BUTTON_PRESSED;

  constructor() {
  }
}

export class DeleteButtonPressed implements Action {
  readonly type = LayoutActionTypes.DELETE_BUTTON_PRESSED;

  constructor() {
  }
}

export class ButtonPressed implements Action {
  readonly type = LayoutActionTypes.BUTTON_PRESSED;

  constructor(public payload: { button_code: string }) {
  }
}

export class ShowConfirmDialog implements Action {
  readonly type = LayoutActionTypes.SHOW_CONFIRM_DIALOG;

  constructor(public payload: {title:string, content:string, onConfirm: any}){

  }
}

export class SetConfirmDialogReference implements Action {
  readonly type = LayoutActionTypes.SET_CONFIRM_DIALOG_REF;

  constructor(public payload: MatDialogRef<any>){

  }
}

export class SetLayoutWithCategories implements Action {
  readonly type = LayoutActionTypes.SET_LAYOUT_CATEGORIES;

  constructor(public payload: { hideCategories: boolean }) {
  }
}

export type LayoutActions =
  | SetLayoutButtonsAction
  | SetLayoutTabsAction
  | SetLayoutPageNameAction
  | SetLayoutPageIconAction
  | SetLayoutSearch
  | SaveButtonPressed
  | DeleteButtonPressed
  | ButtonPressed
  | SetLayoutWithAddCategory
  | SetLayoutSearchVisibilityAction
  | SetLayoutActiveTabAction
  | ShowConfirmDialog
  | SetConfirmDialogReference
  | SetLayoutWithCategories;
