<div class="color-picker-wrapper">
  <!-- MATERIAL INPUT SHIZZLE -->
  <mat-form-field appearance="outline" fxFlex [formGroup]="settings.form">
    <mat-label>{{ settings.nazev }}</mat-label>

    <!-- PREVIEW -->
    <mat-icon matPrefix [ngStyle]="{color: settings.form.get(settings.formControlName).value}" appearance="filled">bookmark</mat-icon>

    <!-- INPUT -->
    <input
      (focusin)="showPicker = true"
      (focusout)="showPicker = false"
      matInput
      [required]="settings.povinnost"
      [formControlName]="settings.formControlName"/>

    <!-- HINTS -->
    <mat-hint *ngIf="settings.napoveda && !settings.napoveda.isIcon">{{settings.napoveda.text}}</mat-hint>
    <mat-icon *ngIf="settings.napoveda && settings.napoveda.isIcon" matSuffix [matTooltip]="settings.napoveda.text">info</mat-icon>

    <!-- ERROR -->
    <mat-error *ngIf="settings.form.get(settings.formControlName)?.invalid">Toto pole je povinné!</mat-error>

  </mat-form-field>

  <!-- COLOR PICKER -->
  <color-chrome
    [ngClass]="{'absolute': !settings.showAlways}"
    *ngIf="this.showPicker || settings.showAlways"
    [color]="settings.form.get(settings.formControlName).value || '#fff'"
    (onChangeComplete)="colorChanged($event)">
  </color-chrome>
</div>

