import { FormularStav } from '@nx-monorepo/obce/common/enums';
import { IColumnDefinition } from '@nx-monorepo/cms-base/interfaces';

export const formularColumnDefinitions: IColumnDefinition[] = [
  {
    title: 'select',
    key: ['select'],
    type: 'select',
    sortable: false,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'ID',
    key: ['id'],
    type: 'string',
    sortable: true,
    align: 'left',
    shrink: true,
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
/*  <mat-icon matListIcon *ngSwitchCase="'Rozpracovany'">create</mat-icon>
    <mat-icon matListIcon *ngSwitchCase="'Podany'">next_week</mat-icon>
    <mat-icon matListIcon *ngSwitchCase="'Odvolany'">backspace</mat-icon>
    <mat-icon matListIcon *ngSwitchCase="'Schvaleny'">check_circle_outline</mat-icon>
    <mat-icon matListIcon *ngSwitchCase="'Vraceny'">undo</mat-icon>
    <mat-icon matListIcon *ngSwitchCase="'Zamitnuty'">highlight_off</mat-icon>*/
  {
    title: 'Stav',
    key: ['stav'],
    type: 'status',
    settings:{
      canManageContent: [
        {
          text: FormularStav.Podany,
          color: 'orange',
          iconName: 'next_week'
        },
        {
          text: FormularStav.Schvaleny,
          color: 'green',
          iconName: 'check'
        },
        {
          text: FormularStav.Vraceny,
          color: 'grey',
          iconName: 'undo'
        },
        {
          text: FormularStav.Zamitnuty,
          color: 'grey',
          iconName: 'highlight_off'
        },
      ],
      default: [
        {
          text: FormularStav.Rozpracovany,
          color: 'yellow',
        },
        {
          text: FormularStav.Vraceny,
          color: 'orange',
        },
        {
          text: FormularStav.Podany,
          color: 'blue',
        },
        {
          text: FormularStav.Odvolany,
          color: 'yellow',
        },
        {
          text: FormularStav.Zamitnuty,
          color: 'red',
          iconName: 'cancel'
        },
        {
          text: FormularStav.Schvaleny,
          color: 'green',
          iconName: 'check'
        },
      ]
    },
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'Jméno autora',
    key: ['autor', 'jmeno'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'Příjmení autora',
    key: ['autor', 'prijmeni'],
    type: 'string',
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  },
  {
    title: 'Datum vytvoření',
    key: ['created_at'],
    type: 'format',
    settings:{
      method: 'date',
    },
    sortable: true,
    align: 'left',
    fxHide: {
      sm: false,
      md: false,
      lg: false,
    }
  }
];
