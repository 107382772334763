import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule, GridModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NextButtonComponent } from './next-button.component';
import { UtilsModule } from '../../utils/utils.module';
import { ColorUtilsService } from '@nx-monorepo/obce/ng/services';

@NgModule({
  imports: [CommonModule, GridModule, FlexModule, FontAwesomeModule, MatMenuModule, RouterModule, MatIconModule, MatButtonModule, BrowserAnimationsModule, UtilsModule],
  declarations: [NextButtonComponent],
  entryComponents: [NextButtonComponent],
  providers: [ColorUtilsService],
  exports: [NextButtonComponent]
})
export class NextButtonModule {}

