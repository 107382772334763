import { Validators } from '@angular/forms';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { PkValidator } from '@nx-monorepo/ng-shared';
import { KATEGORIE_SERVICE_TOKEN } from '@nx-monorepo/obce/ng/services';
import { IEntityDefinition } from '@nx-monorepo/cms-base/interfaces';
import {
  IPkHiddenFieldProps,
  IPkInputEntityFieldProps,
  IPkInputTextFieldProps,
  IPkInputCheckboxFieldProps,
  IPkSelectFieldProps,
  IPkInputDateFieldProps,
  IPkInputWysiwygProps
} from '@nx-monorepo/cms-base/components';

export const prispevekEntityDefinition: IEntityDefinition[] = [
  {
    componentName: 'PkHiddenFieldComponent',
    settings: {
      type: 'number',
      formControlName: 'id',
      defaultValue: 0
    } as IPkHiddenFieldProps,
    data: null,
    validators: [],
    cols: 0,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputEntityFieldComponent',
    settings: {
      nazev: 'Hlavní obrázek',
      povinnost: false,
      napoveda: {
        isIcon: false,
        text: 'Hlavní obrázek příspěvku'
      },
      isMultiple: false,
      entityType: EntityTypes.Media,
      withPreview: true,
      formControlName: 'media',
      defaultValue: ''
    } as IPkInputEntityFieldProps,
    data: null,
    validators: [],
    cols: 4,
    x: 0,
    y: 0
  },
  {
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'Název',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Zadejte název příspěvku'
      },
      isTextArea: false,
      formControlName: 'nazev',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 3,
    x: 0,
    y: 1
  },
  {
    componentName: 'PkInputCheckboxFieldComponent',
    settings: {
      nazev: 'Upozornit odběratele',
      formControlName: 'featured',
      defaultValue: false,
    } as IPkInputCheckboxFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 1,
    x: 3,
    y: 1
  },
  /*{
    componentName: 'PkInputTextFieldComponent',
    settings: {
      nazev: 'URL',
      povinnost: false,
      napoveda: {
        isIcon: true,
        text: 'Napoveda lorem ipsum'
      },
      isTextArea: false,
      formControlName: 'url',
      defaultValue: '',
      type: 'text'
    } as IPkInputTextFieldProps,
    data: null,
    validators: [],
    cols: 2,
    x: 2,
    y: 1
  },*/
  {
    componentName: 'PkSelectFieldComponent',
    settings: {
      nazev: 'Kategorie',
      povinnost: true,
      napoveda: {
        isIcon: true,
        text: 'Vyberte kategorii příspěvku'
      },
      formControlName: 'kategorie_id',
      isMultiple: false,
      service: {
        token: KATEGORIE_SERVICE_TOKEN,
        fetchAllArgs: {
          types: [EntityTypes.Prispevek]
        },
        fetchSingleArgs: {}
      }
    } as IPkSelectFieldProps,
    data: null,
    validators: [Validators.required],
    cols: 2,
    x: 0,
    y: 2
  },
  {
    componentName: 'PkInputDateFieldComponent',
    settings: {
      nazev: 'Datum zveřejnění',
      povinnost: true,
      napoveda: {
        text: 'Zvolte datum, kdy se má příspěvek zveřejnit',
        isIcon: true
      },
      formControlName: 'datum_zverejneni',
      defaultValue: new Date()
    } as IPkInputDateFieldProps,
    validators: [Validators.required, PkValidator.validatorDeliveryTimeFactory()],
    data: null,
    cols: 2,
    x: 2,
    y: 2
  },
  {
    componentName: 'PkInputWysiwygComponent',
    settings: {
      nazev: 'Úvod',
      formControlName: 'uvod',
      plainTextOnly: true,
      defaultValue: ''
    } as IPkInputWysiwygProps,
    validators: [Validators.required],
    data: null,
    cols: 4,
    x: 0,
    y: 3
  },
  {
    componentName: 'PkInputWysiwygComponent',
    settings: {
      nazev: 'Obsah',
      formControlName: 'obsah',
      defaultValue: ''
    } as IPkInputDateFieldProps,
    validators: [Validators.required],
    data: null,
    cols: 4,
    x: 0,
    y: 4
  },
  {
    componentName: 'PkInputEntityFieldComponent',
    settings: {
      nazev: 'Přidat přílohy',
      povinnost: false,
      napoveda: {
        isIcon: false,
        text: 'Přílohy k příspěvku'
      },
      isMultiple: true,
      withPreview: false,
      entityType: EntityTypes.Media,
      formControlName: 'priloha',
      defaultValue: null,
    } as IPkInputEntityFieldProps,
    data: null,
    validators: [],
    cols: 4,
    x: 0,
    y: 5
  },
];
