<h1>{{settings.nazev}}</h1>

<button mat-raised-button (click)="createItem()">
  + Nový řádek
</button>

<ng-container class="rows" [formGroup]="settings.form">
  <ng-container [formArrayName]="settings.formControlName">

    <!-- radky pomoci gridu-->
    <div class="row"
         *ngFor="let row of formControl.controls; let rowIndex = index"
         [formGroupName]="rowIndex">

      <!-- policka -->
      <div
        *ngFor="let field of settings.rowDefinition; let fieldIndex = index"
        [ngClass]="field.componentName === 'PkHiddenFieldComponent' ? 'hidden-field' : ''"
        [style.grid-row-start]="field.y + 1"
        [style.grid-column-start]="field.x + 1"
        [style.grid-column-end]="field.x + 1 + field.cols">
        <ng-container
          cmsComponentFactory
          [componentName]="settings.rowDefinition[fieldIndex % settings.rowDefinition.length].componentName"
          [settings]="settings.rowDefinition[fieldIndex % settings.rowDefinition.length].settings | formArray:settings.formControlName:rowIndex"
          [data]="settings.rowDefinition[fieldIndex % settings.rowDefinition.length].data">
        </ng-container>
      </div>

      <!-- delete button -->
      <button
        mat-icon-button
        class="delete-button"
        (click)="deleteItem(rowIndex)"
        [style.grid-row-start]="0"
        [style.grid-column-start]="13">
        <mat-icon>delete</mat-icon>
      </button>

    </div>
  </ng-container>

</ng-container>

