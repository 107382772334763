import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class InjectableToolbarService {
    private _state = new BehaviorSubject([]);
    private _newCategoryText = new BehaviorSubject('');

    newCategoryObservable = this._newCategoryText.asObservable();
    currentTabs = this._state.asObservable();

    constructor() {}

    setTabs(tabs: Array<string>): void {
        this._state.next(tabs);
    }

    sendNewCategoryText(text: string): void {
        this._newCategoryText.next(text);
    }
}
