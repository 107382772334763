import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {fuseAnimations} from '@fuse/animations';
// noinspection TypeScriptPreferShortImport
import {filter, map} from 'rxjs/operators';
import {Token} from '@nx-monorepo/api-base/lib/interfaces/i-token';
import {AuthService} from '../../services/auth.service';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { IAction } from '@nx-monorepo/cms-base/interfaces';
import { selectLayoutButtons } from '@nx-monorepo/cms-base/store/layout/layout.selectors';

@Component({
  selector: 'pk-page-action-buttons',
  templateUrl: './pk-page-action-buttons.component.html',
  styleUrls: ['./pk-page-action-buttons.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PKPageActionButtons implements OnInit {
  private _userToken: Token;

  constructor(private store$: Store<MACMSStoreState.State>,
              private authService: AuthService) {
  }

  buttons$: Observable<IAction[]>;

  ngOnInit() {
    this._userToken = this.authService.getTokenData();
    this.buttons$ = this.store$.select(selectLayoutButtons).pipe(
      filter(actions => actions !== undefined && this.authService.isLoggedIn()),
      map((actions) => {
        const newArray = [];
        actions.forEach(action => {
          //default is false
          let show = false;
          if (!action.showFor) {
            //if there are no showFors specified than show for everybody
            show = true;
          } else {
            //show only if you have the right privilege
            action.showFor.every(privilege => {
              if (Object.keys(this._userToken?.role.pravomoce || {}).includes(privilege)) {
                // it might be set to a false tho
                if (this._userToken.role.pravomoce[privilege]) {
                  show = true;
                }
                return false;
              } else {
                return true;
              }
            });
          }
          if (show) {
            newArray.push(action);
          }
        });
        return newArray;

      }));
  }

  performAction(action) {
    if (action) {
      this.store$.dispatch(action);
    }
  }
}
