import { Component, Input, OnInit } from '@angular/core';
import { HeadingProps } from './heading.props';
import { DomSanitizer } from '@angular/platform-browser';
import { ObecService,ColorUtilsService } from '@nx-monorepo/obce/ng/services';
import { BoxSides } from '@nx-monorepo/ng-shared';

@Component({
  selector: 'macms-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent implements OnInit {
  @Input() settings: HeadingProps;
  @Input() text: string;

  boxBackground;
  boxBackgroundColor: string;
  textColor;

  public localSettings: HeadingProps;

  constructor(private sanitizer: DomSanitizer, private colorUtilsService: ColorUtilsService, private obecService: ObecService) {
  }

  ngOnInit(): void {
    this.obecService.settings$.subscribe(obecSettings => {
        this.localSettings = {
          //@ts-ignore
          primaryColor: obecSettings.primary_color,
          limitMaxWidthToSection: true,
          boxMargin: BoxSides.applySideToAll('auto'),
          isInverted: false,
          //@ts-ignore
          font: obecSettings.title_font
        } as HeadingProps;
        if (this.settings) {
          this.localSettings = {
            ...this.localSettings,
            ...this.settings
          };
          //console.log(this.localSettings);
        }
        const primaryColor = this.localSettings.primaryColor;
        if (this.localSettings.isInverted) {
          this.textColor = this.colorUtilsService.decideForegroundColor(primaryColor, 6.7, '#fff', '#000');
          this.boxBackgroundColor = primaryColor;
          if (this.localSettings.isSlanted) {
            this.boxBackground = this.sanitizer.bypassSecurityTrustStyle('linear-gradient(135deg, transparent 15px, ' + primaryColor + ' 15px)');
          } else {
            this.boxBackground = primaryColor;
          }
        } else {
          this.boxBackground = 'transparent';
          this.boxBackgroundColor = this.localSettings.assumedBackgroundColor ? this.localSettings.assumedBackgroundColor : '#fff';
          this.textColor = this.colorUtilsService.decideForegroundColor(this.boxBackgroundColor, 3, primaryColor, '#fff', '#000');
        }
      }
    );
  }
}
