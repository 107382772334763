import { Directive, Output, EventEmitter, Input } from "@angular/core";
import { NgControl } from '@angular/forms';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[currency]',
    // tslint:disable-next-line: no-host-metadata-property
    host: {
        '(focus)': 'onFocus()',
        '(blur)': 'onBlur()',
        '(ngModelChange)': 'onChange()',
    }
})

export class CurrencyDirective {
    constructor(public model: NgControl) {
        this.onBlur();
    }

    private isFocused = false;

    // tslint:disable-next-line: use-lifecycle-interface
    ngOnInit(){
        this.model.valueAccessor.writeValue((this.model.value/100).toLocaleString('cs-CZ', { minimumFractionDigits: 2 }) + ' Kč');
    }

    onChange(){
        if(!this.isFocused){
            this.model.valueAccessor.writeValue((this.model.value/100).toLocaleString('cs-CZ', { minimumFractionDigits: 2 }) + ' Kč');
        }
    }
    
    onBlur(){
        this.isFocused = false;
        this.onChange();            
    }

    onFocus(){
        this.isFocused = true;
        this.model.valueAccessor.writeValue(parseFloat(this.model.value.toString().replace(' Kč', '')));
    }
}