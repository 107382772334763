import { Component, EventEmitter, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BasePageComponent } from '../base-page.component';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, map, take, takeUntil } from 'rxjs/operators';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { ITreeOptions, TreeComponent, TreeNode } from 'angular-tree-component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KolekceService } from '@nx-monorepo/obce/ng/services';
import { ApolloQueryResult } from 'apollo-client';
import { ITreeNode } from 'angular-tree-component/dist/defs/api';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { EntityTypes } from '@nx-monorepo/obce/common/enums';
import { PkDialogComponent } from '@nx-monorepo/cms-base/components/pk-dialog/pk-dialog.component';
import { IPkInputTextFieldProps, IPkColorPickerProps } from '@nx-monorepo/cms-base/components';
import { MACMSStoreState } from '@nx-monorepo/cms-base/store';
import { SnackbarStoreActions } from '@nx-monorepo/cms-base/store/snackbar';
import { IPkDialogData } from '@nx-monorepo/cms-base/components/pk-dialog/pk-dialog-data';
import { LayoutActionTypes } from '@nx-monorepo/cms-base/store/layout/layout.actions';

declare type TreeDataType =
  'Slozka' |
  'Stranka';

interface TreeChild {
  id?: number;
  nazev: string;
  popis?: string;
  ikonka?: string;
  barva?: string;
  page?: any;
  isSavedInDb: boolean;
  children: TreeChild[];
  type: TreeDataType;
  order: number;
}

@Component({
  selector: 'pk-navigation-page',
  templateUrl: './pk-navigation-page.component.html',
  styleUrls: ['./pk-navigation-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PkNavigationPageComponent extends BasePageComponent implements OnInit, OnDestroy {
  @ViewChild('tree') private tree: TreeComponent;

  public navigaceForm: FormGroup = new FormGroup({
    nazev: new FormControl('', [Validators.required])
  });

  public nazev_settings = {
    isTextArea: false,
    form: this.navigaceForm,
    formControlName: 'nazev',
    type: 'text',
    nazev: 'Název',
    povinnost: true,
    napoveda: {
      isIcon: true,
      text: 'Zadejte název navigace'
    }
  } as IPkInputTextFieldProps;

  public options: ITreeOptions;
  public nodes: TreeChild[] = [];

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private dialogRef: MatDialogRef<PkDialogComponent, string>;
  private stranky = [];
  private navigace_id: number;
  private loading = true;

  startLoading(): void
  {
    this.loading = true;
    this._fuseProgressBarService.show();
  }

  stopLoading(): void
  {
    this.loading = false;
    this._fuseProgressBarService.hide();
  }

  constructor(store$: Store<MACMSStoreState.State>,
              currentRoute: ActivatedRoute,
              private router: Router,
              private actionsSubject$: ActionsSubject,
              private dialog: MatDialog,
              private kolekceService: KolekceService,
              private _fuseProgressBarService: FuseProgressBarService) {
    super(store$, currentRoute);
  }

  public ngOnInit(): void {
    // subscribe to layout buttons
    this.subscribeToAddFolder();
    this.subscribeToAddPage();
    this.subscribeToSaveButtonAction();

    // init default tree options
    this.options = {
      allowDrag: true,
      allowDrop: this.onTreeNodeDrop
    };

    // get stranky
    this.fetchStranky().subscribe(stranky => {
      this.stranky = stranky;
      // todo fetchStranky a fetchNavigace by mohly byt paralelni cally a pak combineLatest..
      // if we have ID from route, find the entity and fill the form
      const navigaceId = this.currentRoute.snapshot.paramMap.get('id');
      if (navigaceId) {
        this.fetchNavigaceForEdit(parseInt(navigaceId, 10));
      } else {
        this.stopLoading();
      }
    });
  }

  private fetchNavigaceForEdit(navigaceId: number) {
    this.kolekceService.fetchSingle(EntityTypes.Navigace, navigaceId).pipe(
      take(1),
      map((result: any) => result)
    ).subscribe(navigace => {
        if (navigace) {
          this.navigace_id = navigace.id;
          this.nodes = this.transfromNodesForNgTree(navigace.children);
          this.tree.treeModel.update();
          this.navigaceForm.patchValue({ nazev: navigace.nazev });
          this.nodes = this.sortByOrder(this.nodes);
        } else {
          this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({ message: 'Tuto navigaci se nepodařilo načíst', action: 'OK' }));
        }
        this.stopLoading();
      }
    );
  }

  private sortByOrder(nodes: TreeChild[]) {
    nodes.sort((a, b) => (a.order > b.order) ? 1 : -1);
    for (const node of nodes) {
      if ('children' in node) {
        this.sortByOrder(node.children);
      }
    }
    return nodes;
  }

  private fetchStranky(): Observable<any[]> {
    return this.kolekceService.fetchAll(EntityTypes.Stranka).pipe(
      takeUntil(this.destroy$),
      map((result: ApolloQueryResult<{ response: { items: any[] } }>) => {
        return result.data.response.items.map((stranka: any) => ({ id: stranka.id, nazev: stranka.nazev, description: stranka.url || '' }));
      })
    );
  }

  public ngOnDestroy(): void {
    // trigger the destroying subject
    this.destroy$.next(true);

    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }

  private onTreeNodeDrop(node: TreeNode, { parent, index }: { parent: TreeNode, index: number }): boolean {
    const { data }: { data: TreeChild } = parent;
    // pokud se jedna o slozku nebo pokud nema typ (to jest root)
    return data.type === 'Slozka' || !data.type;
  }

  private openDialogForFolder(submitEvent: EventEmitter<any>, defaultValueNazev = '', defaultValueIkonka = '', barva = '#ff0453', possitiveButtonText = 'Vytvořit') {
    if (this.dialogRef == null) {
      // create dialog data
      const closeEvent = new EventEmitter<void>();

      const dialogData: IPkDialogData = {
        title: 'Přidat novou složku',
        components: [
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'nazev',
              type: 'text',
              nazev: 'Název složky',
              povinnost: true,
              defaultValue: defaultValueNazev
            },
            data: null,
            validators: [Validators.required],
            cols: 4,
            x: 0,
            y: 0
          },
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'ikonka',
              type: 'text',
              nazev: 'Ikonka',
              povinnost: false,
              defaultValue: defaultValueIkonka
            },
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 1
          },
          {
            componentName: 'PkColorPickerFieldComponent',
            settings: {
              nazev: 'Barva',
              povinnost: false,
              napoveda: {
                isIcon: true,
                text: 'Napoveda lorem ipsum'
              },
              formControlName: 'barva',
              defaultValue: barva
            } as IPkColorPickerProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 2
          }
        ],
        buttons: [
          {
            color: 'warn',
            text: 'Ne, díky',
            action: closeEvent
          },
          {
            color: 'primary',
            text: possitiveButtonText,
            shouldValidateForm: true,
            action: submitEvent
          }
        ]
      };

      // make it closeable with No button
      this.subscribeToDialogClose(closeEvent);

      // create dialog config and pass dialog data to it
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = dialogData;

      // open dialog and subscribe to its afterClosed event to remove the reference
      this.dialogRef = this.dialog.open(PkDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().pipe(
        take(1)
      ).subscribe(() => {
        this.dialogRef = null;
      });
    }
  }

  private openDialogForPage(submitEvent: EventEmitter<any>, defaultValueNazev = '', defaultValuePopis = '', defaultValueIkonka = '', defaultValueUrl = '', barva = '#ff0453', possitiveButtonText = 'Vytvořit') {
    if (this.dialogRef == null) {
      // create dialog data
      const closeEvent = new EventEmitter<void>();

      const dialogData: IPkDialogData = {
        title: 'Přidat novou stránku',
        components: [
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'nazev',
              type: 'text',
              nazev: 'Název stránky v navigaci',
              povinnost: true,
              defaultValue: defaultValueNazev
            },
            data: null,
            validators: [Validators.required],
            cols: 1,
            x: 0,
            y: 0
          },
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: true,
              formControlName: 'popis',
              type: 'text',
              nazev: 'Popis',
              povinnost: false,
              defaultValue: defaultValuePopis
            },
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 1
          },
          {
            componentName: 'PkInputTextFieldComponent',
            settings: {
              isTextArea: false,
              formControlName: 'ikonka',
              type: 'text',
              nazev: 'Ikonka',
              povinnost: false,
              defaultValue: defaultValueIkonka
            },
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 2
          },
          {
            componentName: 'PkColorPickerFieldComponent',
            settings: {
              nazev: 'Barva',
              povinnost: false,
              napoveda: {
                isIcon: true,
                text: 'Napoveda lorem ipsum'
              },
              formControlName: 'barva',
              defaultValue: barva || '#ffffff'
            } as IPkColorPickerProps,
            data: null,
            validators: [],
            cols: 4,
            x: 0,
            y: 3
          },
          {
            componentName: 'PkInputAutocompleteComponent',
            settings: {
              formControlName: 'page',
              nazev: 'Odkaz na stránku',
              napoveda: {
                isIcon: true,
                text: 'Vyberte již vytvořenou stránku ze seznamu, nebo zadejte URL adresu (např.: https://www.example.cz)'
              },
              povinnost: true,
              defaultValue: defaultValueUrl
            },
            data: this.stranky,
            validators: [Validators.required],
            cols: 4,
            x: 0,
            y: 4
          }
        ],
        buttons: [
          {
            color: 'warn',
            text: 'Ne, díky',
            action: closeEvent
          },
          {
            color: 'primary',
            text: possitiveButtonText,
            shouldValidateForm: true,
            action: submitEvent
          }
        ]
      };

      // make it closeable with No button
      this.subscribeToDialogClose(closeEvent);

      // create dialog config and pass dialog data to it
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = dialogData;

      // open dialog and subscribe to its afterClosed event to remove the reference
      this.dialogRef = this.dialog.open(PkDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().pipe(
        take(1)
      ).subscribe(() => {
        this.dialogRef = null;
      });
    }
  }

  private findClosestSlozkaNode(activeNode: TreeNode): TreeNode | null {
    // if no parent nothing is selected or the node has no parent, we return null
    if (!activeNode || activeNode.parent === null) {
      return null;
    }

    // check if the selected node is slozka
    const { data }: { data: TreeChild } = activeNode;
    if (data.type === 'Slozka') {
      return activeNode;
    }

    // keep on searching
    return this.findClosestSlozkaNode(activeNode.parent);
  }

  private addNode(nazev: string, popis: string, ikonka: string, type: TreeDataType, barva: string, page?: any, order?: number) {
    const node: TreeChild = {
      nazev: nazev,
      popis: popis,
      ikonka: ikonka,
      page: page,
      isSavedInDb: false,
      type: type,
      barva: barva,
      children: [],
      order: order
    };

    // insert at closest possible slozka
    const closestSlozka = this.findClosestSlozkaNode(this.tree.treeModel.getActiveNode());
    if (closestSlozka) {
      closestSlozka.data.children.push(node);
    } else {
      this.nodes.push(node);
    }

    // trigger update
    this.tree.treeModel.update();

    // expand node if possible
    const focusedNode = this.tree.treeModel.getFocusedNode();
    if (focusedNode) {
      focusedNode.expand();
    }
  }

  public editNode(node: TreeNode) {
    const { data }: { data: TreeChild } = node;
    const editDialog = new EventEmitter<any>();

    // open a concrete dialog
    if (node.data.type === 'Slozka') {
      this.openDialogForFolder(editDialog, data.nazev, data.ikonka, data.barva, 'Uložit');
    } else {
      this.openDialogForPage(editDialog, data.nazev, data.popis, data.ikonka, data.page, data.barva, 'Uložit');
    }

    // watch the result
    editDialog.pipe(take(1)).subscribe((result) => {
      const { nazev, page, barva, popis, ikonka } = result;
      data.nazev = nazev;
      data.page = page;
      data.barva = barva;
      data.popis = popis;
      data.ikonka = ikonka;

      // update tree and close dialog
      this.tree.treeModel.update();
      this.dialogRef.close();
    });
  }

  private saveNavigation() {
    if (!this.navigaceForm.valid || this.nodes.length === 0) {
      this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({
        message: 'Vyplňtě prosím název navigace a přidejte jí položky',
        action: 'OK'
      }));
      return;
    }

    // transform nodes for api and create object for service
    const transformed = this.transformNodesForApi(this.nodes);
    const navigace = {
      id: this.navigace_id,
      nazev: this.navigaceForm.value.nazev,
      children: transformed
    };

    // save it
    this.kolekceService.save(EntityTypes.Navigace, { input: [navigace] }).pipe(
      take(1),
      map((result: ApolloQueryResult<{ response: any }>) => {
        return {
          ...result.data.response,
          stranka: undefined,
          children: this.transfromNodesForNgTree(result.data.response.children)
        };
      }),
      catchError((error: Error) => {
        this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({ message: error.message, action: 'OK' }));
        return observableOf(error);
      })
    ).subscribe(dbNavigace => {
      // set nazev, id, and nodes + update ngtree
      this.navigaceForm.patchValue({ nazev: dbNavigace.nazev });
      this.navigace_id = dbNavigace.id;
      this.nodes = dbNavigace.children;
      this.tree.treeModel.update();

      // dispatch success
      this.store$.dispatch(new SnackbarStoreActions.SnackbarActionOpen({
        message: 'Navigace byla úspěšně uložena',
        action: 'OK',
        config: { duration: 5000 }
      }));

      // route them back
      this.router.navigateByUrl(this.currentRoute.snapshot.url[0].path || '/');
    });
  }

  // todo vicemene stejna funkce je v navigace.component.ts, ale bez transformace
  private transfromNodesForNgTree(dbChildren: any[], tree = {}) {
    // this is an "ID -> node" map so we can easily find wanted element and add his children
    const missingParentsChildren = [];

    dbChildren.forEach((child) => {
      // transform child for ng-tree
      const transformedChild: TreeChild = {
        ...child,
        isSavedInDb: true,
        page: child.url || this.stranky.find(stranka => stranka.id === child.stranka_id),
        stranka: undefined,
        children: [],
        url: undefined,
        __typename: undefined
      };

      //add new child to the map of all nodes
      tree[child.id] = transformedChild;

      // if the node is not root/parentless -> push it to the parent.children
      if (child.parent_id != null) {
        if (tree[child.parent_id] == null) {
          // saving child so I dont have to check if it is transformed or some BS
          missingParentsChildren.push(child);
        } else {
          tree[child.parent_id].children.push(transformedChild);
        }
      }
    });

    //recursion in case of incomplete tree parsing - should not happen, but does due to incorrect saving
    if (missingParentsChildren.length > 0) {
      console.log('Input children have wrong order, need to go again');
      tree = this.transfromNodesForNgTree(missingParentsChildren, tree);
    }

    //final array.. basically find parentless nodes and add them into array
    const root = [];

    Object.keys(tree).forEach(key => {
      if (tree[key].parent_id == null) {
        root.push(tree[key]);
      }
    });
    return root;
  }

  private transformNodesForApi(children: any[]) {
    let i = 0;
    return children.map((child: TreeChild) => {
      let tempChild =  {
        ...child,
        id: child.isSavedInDb ? child.id : undefined,
        url: typeof child.page === 'string' ? child.page : null,
        stranka_id: typeof child.page === 'object' ? child.page.id : null,
        page: undefined,
        isSavedInDb: undefined,
        children: this.transformNodesForApi(child.children),
        order: i,
      };
      i++;
      //console.log(tempChild);
      return tempChild;
    });
  }

  public deleteNode(node: TreeNode): void {
    if (node.parent != null) {
      const nodeIndex = node.parent.data.children.indexOf(node.data);
      node.parent.data.children.splice(nodeIndex, 1);
      this.tree.treeModel.update();
    }
  }

  public onNodeActivate({ eventName, node }: { eventName: string, node: ITreeNode }) {
    console.log('activated', node);
  }

  private subscribeToDialogClose(dialogCloseEvent: EventEmitter<any>) {
    dialogCloseEvent.pipe(
      take(1)
    ).subscribe(() => {
      this.dialogRef.close();
    });
  }

  private subscribeToAddFolder() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === '[Navigace] Add Folder')
    ).subscribe((action: Action) => {
      if(!this.loading) {
        const submitDialog = new EventEmitter<any>();
        this.openDialogForFolder(submitDialog);

        // watch the result
        submitDialog.pipe(take(1)).subscribe((result) => {
          const { nazev, barva, ikonka, popis, order } = result;
          this.addNode(nazev, popis, ikonka, 'Slozka', barva, order);
          this.dialogRef.close();
        });
      }
    });
  }

  private subscribeToAddPage() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === '[Navigace] Add Page')
    ).subscribe((action: Action) => {
      if(!this.loading) {
        const submitDialog = new EventEmitter<any>();
        this.openDialogForPage(submitDialog);

        // watch the result
        submitDialog.pipe(take(1)).subscribe((result) => {
          console.log('add page result', result);
          const { nazev, page, barva, popis, ikonka, order } = result;
          if (nazev) {
            this.addNode(nazev, popis, ikonka, 'Stranka', barva, page, order);
          }
          this.dialogRef.close();
        });
      }
    });
  }

  private subscribeToSaveButtonAction() {
    this.actionsSubject$.pipe(
      takeUntil(this.destroy$),
      filter((action: Action) => action.type === LayoutActionTypes.SAVE_BUTTON_PRESSED)
    ).subscribe(action => {
      this.saveNavigation();
    });
  }
}

