import gql from 'graphql-tag';

export const readAllAssignableRoleQuery = gql`
  query {
    response: readAllAssignableRoles {
      items{
        id
        nazev
      }
    }
  }
`;
