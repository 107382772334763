<button mat-button [matMenuTriggerFor]="menu" class="user-button">
  <div fxLayout="row" fxLayoutAlign="center center">
    <!--<img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/Velazquez.jpg">-->
    <mat-icon class="username s-24 mr-4">account_circle</mat-icon>
    <span class="username mr-12" fxHide fxShow.gt-sm>{{ username$ | async }}</span>
    <mat-icon class="s-16" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
  </div>
</button>

<mat-menu #menu="matMenu" [overlapTrigger]="false" xPosition="after">
  <button
    *ngFor="let item of menuItems"
    mat-menu-item
    (click)="item.onClick()"
    [routerLink]="[item.url]"
  >
    <mat-icon>{{ item.icon }}</mat-icon>
    <span>{{ item.text }}</span>
  </button>
</mat-menu>
