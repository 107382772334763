import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActionsSubject, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { MACMSStoreState } from 'libs/cms-base/src';
import { gitVersion } from 'apps/obce/cms/src/environments/git-version';
import { AuthActionTypes, LoginFailure, PerformLogin } from '@nx-monorepo/cms-base/store/auth/auth.actions';
import { selectLastAuthMessage } from '@nx-monorepo/cms-base/store/auth/auth.selectors';


@Component({
  selector     : 'pk-login-page',
  templateUrl  : './pk-login-page.component.html',
  styleUrls    : ['./pk-login-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class PkLoginPageComponent implements OnInit, OnDestroy
{
  loginForm: FormGroup;
  returnUrl: string;
  loading = false;
  destroyed$ = new Subject<boolean>();
  lastAuthMessage$: Observable<string>;

  username = 'testadmin@mesto.cz';
  password = null;

  errorMessage = null;

  versionInfo: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private store$: Store<MACMSStoreState.State>,
    private actionsSubject$: ActionsSubject,
    private _fuseProgressBarService: FuseProgressBarService,
    library: FaIconLibrary
  )
  {
    this.versionInfo = gitVersion;
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar   : {
          hidden: true
        },
        toolbar  : {
          hidden: true
        },
        footer   : {
          hidden: true
        },
        sidepanel: {
          hidden: true
        },
        header: {
          hidden: true
        },
        content:{
          carded: false
        }
      }
    };
    library.addIcons(faExclamationCircle);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    this.loginForm = this._formBuilder.group({
      email   : ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    // navigate to route on login success
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.actionsSubject$.subscribe(action => {
      if (action.type === AuthActionTypes.LoginSuccess) {
        this.loading = false;
        this._fuseProgressBarService.hide();
        this.router.navigateByUrl(this.returnUrl);
      } else if(action.type === AuthActionTypes.LoginFailure){
        this.loading = false;
        const loginFailure: LoginFailure = action as LoginFailure;
        this.errorMessage = loginFailure.payload.error;
        this._fuseProgressBarService.hide();
      }
    });

    //read potential login error
    this.lastAuthMessage$ = this.store$.select(selectLastAuthMessage);
    this._fuseProgressBarService.hide();
  }

  onSubmit(){
    this.errorMessage = null;
    this._fuseProgressBarService.show();
    this.loading = true;
    this.store$.dispatch(new PerformLogin({password: this.loginForm.get("password").value, email: this.loginForm.get("email").value}));
  }

  ngOnDestroy() {
    this.loading = false;
    this._fuseProgressBarService.hide();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
